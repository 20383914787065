import { gql } from '@apollo/client'
import { Integration } from '@blissbook/common/integration'
import { client } from '@blissbook/ui/util/apollo'

export const sendEmailAuth = async (email) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation sendEmailAuth($email: EmailAddress!) {
        sendEmailAuth(email: $email) {
          signInUrl
          userId
        }
      }
    `,
    variables: { email },
  })

  return data.sendEmailAuth
}

export const enterAuthCode = async (variables) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation enterAuthCode($userId: Int!, $authCode: String!) {
        enterAuthCode(userId: $userId, authCode: $authCode)
      }
    `,
    variables,
  })

  return data.enterAuthCode
}

export const signUp = async (variables) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation signUp(
        $organization: NewOrganizationInput!
        $owner: NewOrganizationOwnerInput!
      ) {
        organization: signUp(
          organization: $organization
          owner: $owner
        ) {
          id
          subdomain
        }
      }
    `,
    variables,
  })

  return data.organization
}

export const addRipplingIntegration = async (variables) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation addRipplingIntegration($code: String!) {
        integration: addRipplingIntegration(code: $code) {
          id
          providerId
          lastSyncAt
          lastErrorAt
          lastError
          createdAt
          updatedAt
          ... on RipplingIntegration {
            ripplingGroupIds
          }
        }
      }
    `,
    variables,
  })

  return Integration.fromJSON(data.integration)
}

export const isExistingOrganization = async (emailDomain) => {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: gql`
      query isExistingOrganization($emailDomain: String!) {
        isExistingOrganization(emailDomain: $emailDomain)
      }
    `,
    variables: { emailDomain },
  })

  return data.isExistingOrganization
}
