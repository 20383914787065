import { colors } from '@blissbook/ui/branding'
import { Fragment } from 'react'
import { Typography } from '../../../components'
import {
  DemoDesktopBrowser,
  DemoHandbook,
  DemoTextLine,
  clickAnimation,
  scrollAnimation,
} from './components'

const browserTransitionDuration = 2000
const browserTransition = `all ${browserTransitionDuration}ms ease`
const linkTransitionDuration = 500

const LINKS = [
  {
    top: 472,
    left: '30%',
    width: 40,
  },
  {
    top: 1852,
    left: '45%',
    width: 80,
  },
]

const TARGETS = [
  {
    icon: 'intranet-link.svg',
    label: 'Intranet',
  },
  {
    icon: 'email-link.svg',
    label: 'Email',
  },
  {
    icon: 'files-link.svg',
    label: 'Files/Forms',
  },
  {
    icon: 'payroll-link.svg',
    label: 'Payroll',
  },
  {
    icon: 'hris-link.svg',
    label: 'HRIS',
  },
  {
    icon: 'benefits-link.svg',
    label: 'Benefits',
  },
]

const BrowserContainer = (props) => (
  <div
    {...props}
    css={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      transition: browserTransition,
    }}
  />
)

const Component = ({ isAnimationComplete, keyframe }) => (
  <Fragment>
    <BrowserContainer
      css={{
        marginLeft: keyframe.showLinks ? -300 : 0,
        opacity: keyframe.showLinks ? 0 : 1,
        transform: `scale(${keyframe.showLinks ? 0.5 : 1})`,
      }}
    >
      <DemoDesktopBrowser disabled={isAnimationComplete}>
        <DemoHandbook
          css={{
            marginTop: -keyframe.scrollTop,
            transition: `all ${scrollAnimation.duration}ms ease`,
          }}
          sections={[
            { type: 'chapter' },
            { type: 'text' },
            { type: 'chapter' },
            { type: 'text' },
          ]}
        />

        {LINKS.map((link, index) => (
          <div
            key={index}
            className='tw-px-1'
            css={{
              background: 'white',
              left: link.left,
              position: 'absolute',
              transition: `top ${scrollAnimation.duration}ms ease`,
              top: link.top - keyframe.scrollTop,
            }}
          >
            <DemoTextLine
              key={index}
              variant='p'
              color={colors['aqua-500']}
              css={{
                animation: keyframe.clickAnimation,
                borderRadius: 0,
              }}
              width={link.width}
            />
          </div>
        ))}
      </DemoDesktopBrowser>
    </BrowserContainer>

    <BrowserContainer
      css={{
        marginRight: keyframe.showLinks ? 0 : -300,
        opacity: keyframe.showLinks ? 1 : 0,
        transform: `scale(${keyframe.showLinks ? 1 : 0.5})`,
        transitionDelay: '200ms',
      }}
    >
      <DemoDesktopBrowser disabled={isAnimationComplete}>
        <div className='tw-mx-6 tw-my-4'>
          {TARGETS.map((target, index) => (
            <Typography
              key={index}
              variant='h2'
              className='tw-flex tw-items-center tw-mb-8'
              css={{
                marginLeft: index % 2 === 1 ? '4rem' : 0,
                opacity: keyframe.showLinks ? 1 : 0,
                transform: `translateY(${keyframe.showLinks ? 0 : -20}px)`,
                transition: `all ${linkTransitionDuration}ms ease`,
                transitionDelay: `${
                  browserTransitionDuration + index * linkTransitionDuration
                }ms`,
              }}
            >
              <img
                alt={`Link ${target.icon}`}
                className='tw-mr-4'
                css={{ maxHeight: 50 }}
                src={`/images/marketing/product/${target.icon}`}
              />
              {target.label}
            </Typography>
          ))}
        </div>
      </DemoDesktopBrowser>
    </BrowserContainer>
  </Fragment>
)

export default {
  id: 'links',
  title: 'Links',
  description:
    'Since your Blissbook handbook is a website, you can easily link employees to other content including benefits systems, payroll, your intranet, an email address - and even other policies within your handbook.',
  Component,

  keyframes: [
    {
      duration: 2000,
      scrollTop: 400,
    },
    {
      duration: clickAnimation.duration,
      clickAnimation: clickAnimation.style,
    },
    {
      duration: 1000,
      clickAnimation: undefined,
      scrollTop: 1550,
    },
    {
      duration: clickAnimation.duration,
      clickAnimation: clickAnimation.style,
    },
    {
      duration:
        browserTransitionDuration + TARGETS.length * linkTransitionDuration,
      clickAnimation: undefined,
      showLinks: true,
    },
  ],
}
