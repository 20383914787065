import { colors, getColor } from '@blissbook/ui/branding'
import { BackgroundImage } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DemoDesktopBrowser, DemoHandbook, DemoVideo } from './components'

const mapTransitionDuration = 2000

const scrollPxPerSecond = 250
const scrollPxPerMillisecond = scrollPxPerSecond / 1000
const combinedScrollHeight = 800
const splitScrollHeight = 1600
const splitTransitionDuration = 1500

const browserMarginX = 15
const browserScale = 0.5
const browserWidth = 400 * browserScale

const mapMarkers = [
  { x: -290, y: 220, color: 'sunshine-500', isPrimary: true },
  { x: -270, y: 80, color: 'red-500' },
  { x: -250, y: 200, color: 'sunshine-700' },
  { x: -230, y: 280, color: 'purple-500' },
  { x: 0, y: 180, color: 'blurple-700' },
  { x: 10, y: 270, color: 'red-500' },
  { x: -40, y: 340, color: 'blurple-700' },
  { x: 115, y: 210, color: 'sunshine-700' },
  { x: 150, y: 340, color: 'purple-500' },
  { x: 195, y: 410, color: 'sunshine-500' },
  { x: 250, y: 160, color: 'red-500', isPrimary: true },
]

const textSectionItems = [
  { variant: 'h1', width: '40%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '35%' },

  { variant: 'h2', width: '25%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '75%' },
  { variant: 'p' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '75%' },
  { variant: 'p' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '35%' },
  { variant: 'li', width: '10%' },
  { variant: 'li', width: '15%' },
  { variant: 'li', width: '14%' },
  { variant: 'li', width: '13%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '75%' },
  { variant: 'p' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '35%' },
  { variant: 'p' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '15%' },
  { variant: 'p' },
  { variant: 'p', width: '94%' },
  { variant: 'p', width: '40%' },
]

const combinedSections = [
  { type: 'chapter' },
  { type: 'letter', video: <DemoVideo /> },
  { type: 'chapter' },
  { type: 'text', items: [...textSectionItems, ...textSectionItems] },
]

const leftOnlySections = [
  { type: 'chapter' },
  { type: 'text', items: [...textSectionItems, ...textSectionItems] },
  { type: 'chapter' },
  { type: 'text', items: textSectionItems },
  { type: 'chapter' },
  { type: 'text', items: textSectionItems },
].map((section) => ({
  ...section,
  theme: 'light',
}))

const rightOnlySections = [
  { type: 'chapter' },
  { type: 'text', items: textSectionItems },
  { type: 'chapter' },
  { type: 'text', items: textSectionItems },
  { type: 'chapter' },
  { type: 'text', items: textSectionItems },
].map((section) => ({
  ...section,
  theme: 'branded',
}))

const Browser = ({ left, opacity, keyframe, sections, ...props }) => (
  <DemoDesktopBrowser
    {...props}
    css={{
      left,
      opacity,
      position: 'absolute',
      top: 0,
      transform: `scale(${browserScale})`,
      transformOrigin: 'top left',
      transition: `all ${splitTransitionDuration}ms linear`,
    }}
    height={1000}
  >
    <DemoHandbook
      css={{
        marginTop: -keyframe.scrollTop,
        transition: `all ${keyframe.scrollTransitionDuration}ms linear`,
      }}
      sections={sections}
    />
  </DemoDesktopBrowser>
)

const MapMarker = ({ color, ...props }) => (
  <div {...props}>
    <FontAwesomeIcon
      icon='map-marker'
      css={{ color: getColor(color), fontSize: 24 }}
    />
    <div
      css={{
        alignItems: 'center',
        background: colors['aqua-500'],
        borderRadius: '50%',
        color: 'white',
        display: 'flex',
        left: 3,
        padding: 2,
        position: 'absolute',
        top: 3,
      }}
    >
      <FontAwesomeIcon icon='user' css={{ fontSize: 9 }} />
    </div>
  </div>
)

const AMap = ({ keyframe, ...props }) => (
  <BackgroundImage
    {...props}
    src='/images/marketing/product/usa.svg'
    size='contain'
    css={{
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
      transition: `all ${mapTransitionDuration}ms linear`,
    }}
  >
    {mapMarkers.map((marker, index) => (
      <MapMarker
        key={index}
        color={marker.color}
        css={{
          position: 'absolute',
          left: `calc(50% + ${marker.x}px)`,
          opacity: !keyframe.showPrimaryMapMarkers || marker.isPrimary ? 1 : 0,
          top: marker.y,
          transition: `all ${splitTransitionDuration}ms linear`,
          transform:
            keyframe.showPrimaryMapMarkers &&
            marker.isPrimary &&
            'scale(3) translateY(-6px)',
        }}
      />
    ))}
  </BackgroundImage>
)

const Component = ({ isAnimationComplete, keyframe }) => (
  <div className='tw-w-full tw-h-full' css={{ position: 'relative' }}>
    <AMap keyframe={keyframe} />

    <Browser
      disabled={isAnimationComplete}
      left={
        keyframe.splitBrowsers
          ? `calc(50% + ${browserMarginX}px)`
          : `calc(50% - ${browserWidth / 2}px)`
      }
      keyframe={keyframe}
      opacity={keyframe.splitBrowsers ? 1 : 0}
      sections={[...combinedSections, ...rightOnlySections]}
    />

    <Browser
      disabled={isAnimationComplete}
      left={
        keyframe.splitBrowsers
          ? `calc(50% - ${browserWidth + browserMarginX}px)`
          : `calc(50% - ${browserWidth / 2}px)`
      }
      keyframe={keyframe}
      opacity={1}
      sections={[...combinedSections, ...leftOnlySections]}
    />

    <AMap
      css={{ opacity: keyframe.showMapFront ? 1 : 0, zIndex: 10 }}
      keyframe={keyframe}
    />
  </div>
)

export default {
  id: 'personalized-content',
  title: 'Personalized Content',
  description:
    'Have content that only applies to a specific group of employees (ahem, California)? Keep it all in one place! That makes it easy for employees to find and they’ll see (and sign off on) only the content that’s relevant to them.',
  Component,

  keyframes: [
    {
      duration: 1000,
      scrollTop: 0,
      showMapFront: true,
      showPrimaryMapMarkers: false,
    },
    {
      duration: mapTransitionDuration,
      showMapFront: false,
    },
    {
      duration: combinedScrollHeight / scrollPxPerMillisecond,
      scrollTop: combinedScrollHeight,
      scrollTransitionDuration: combinedScrollHeight / scrollPxPerMillisecond,
    },
    {
      duration: splitTransitionDuration,
      showPrimaryMapMarkers: true,
      splitBrowsers: true,
    },
    {
      duration: splitScrollHeight / scrollPxPerMillisecond,
      scrollTop: combinedScrollHeight + splitScrollHeight,
      scrollTransitionDuration: splitScrollHeight / scrollPxPerMillisecond,
    },
  ],
}
