import { gridBreakpoints } from '@blissbook/ui/branding'
import {
  Container,
  ShapeConfetti,
  ShapeGrid,
  confettiShapeSize,
} from '@blissbook/ui/lib'
import { useParallaxScreenRatio } from '@blissbook/ui/util/parallax'
import { css } from '@emotion/react'
import classnames from 'classnames'
import isNumber from 'lodash/isNumber'
import { useState } from 'react'
import { Section, Typography } from '../../components'

const shapeSize = confettiShapeSize
const medalHeight = shapeSize * Math.sqrt(2)

const ShieldMedal = ({ className, ...props }) => {
  const [node, setNode] = useState()
  const ratio = useParallaxScreenRatio(node, {
    attachment: 'center',
    range: [0.8, 0.4],
  })

  const medalRatio = isNumber(ratio) ? Math.max(ratio * 2 - 1, 0) : 1
  const medalTranslateY = (1 - medalRatio) * medalHeight
  const shieldRatio = isNumber(ratio) ? Math.min(ratio * 2, 1) : 1
  const shieldTranslateY = (1 - shieldRatio) * shapeSize
  const wedgeStyle = { background: shieldRatio < 1 && 'white' }

  return (
    <ShapeGrid
      {...props}
      className={classnames(className, 'parallax')}
      css={{
        height: shapeSize * 2 + medalHeight,
        width: shapeSize * 2,
      }}
      ref={setNode}
      shapeSize={shapeSize}
      shapeDefaults={{ fill: true, style: { background: 'white' } }}
      shapes={[
        {
          x: 0.5,
          y: 2,
          type: 'square',
          color: 'sunshine-500',
          rotate: 45,
          scale: shieldRatio,
          translateY: (medalHeight - shapeSize) / 2 - medalTranslateY,
        },

        {
          x: 0,
          y: 0,
          type: 'square',
          color: 'blurple-300',
          translateY: shieldTranslateY,
        },
        {
          x: 1,
          y: 0,
          type: 'square',
          color: 'blurple-500',
          translateY: shieldTranslateY,
        },

        {
          x: 0,
          y: 1,
          type: 'wedge',
          color: 'aqua-300',
          rotate: 90,
          style: wedgeStyle,
        },
        { x: 1, y: 1, type: 'wedge', color: 'aqua-500', style: wedgeStyle },
      ]}
    />
  )
}

export default () => (
  <Section id='protect' className='py-sm'>
    <Container
      className='tw-flex tw-flex-col tw-items-center'
      css={css`
        position: relative;
        @media (min-width: ${gridBreakpoints.lg}px) {
          height: 400px;
        }
      `}
    >
      <ShapeConfetti
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;
        `}
        className='tw-hidden lg:tw-block'
        shapes={[
          { x: -550, y: 30, type: 'wedge', color: 'aqua-500', rotate: 180 },
          { x: -540, y: 230, type: 'wedge', color: 'purple-500', fill: true },
          { x: -440, y: 70, type: 'square', color: 'red-500', fill: true },
          {
            x: -400,
            y: 270,
            type: 'quote',
            color: 'aqua-500',
            fill: true,
            size: 40,
          },
          { x: -300, y: 210, type: 'square', color: 'blurple-500', fill: true },
          { x: -180, y: 290, type: 'wedge', color: 'sunshine-500' },
          {
            x: 40,
            y: 315,
            type: 'quote',
            color: 'purple-500',
            fill: true,
            size: 40,
          },
          {
            x: 180,
            y: 250,
            type: 'wedge',
            color: 'aqua-500',
            rotate: 180,
            fill: true,
          },
          { x: 290, y: 150, type: 'square', color: 'blurple-500' },
          { x: 400, y: 270, type: 'wedge', color: 'purple-500', fill: true },
          {
            x: 420,
            y: 80,
            type: 'wedge',
            color: 'sunshine-500',
            fill: true,
            rotate: 180,
          },
          {
            x: 470,
            y: 190,
            type: 'quote',
            color: 'sunshine-500',
            fill: true,
            size: 40,
          },
          {
            x: 550,
            y: 310,
            type: 'square',
            color: 'red-500',
            fill: true,
            rotate: 45,
          },
          { x: 560, y: 130, type: 'wedge', color: 'purple-500' },
        ]}
        showCannonAnimation
        showOrbitAnimation
      />

      <ShieldMedal className='tw-mb-6' />
      <Typography
        variant='h2'
        className='text-center'
        style={{ maxWidth: 500 }}
      >
        Protect your company <em>and</em> show employees they’re valued.
      </Typography>
    </Container>
  </Section>
)
