import { colors } from '@blissbook/ui/branding'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DemoBrowserOverlay,
  DemoDesktopBrowser,
  DemoHandbook,
  DemoTextLine,
  demoBrowserDisabledOpacity,
  demoColors,
  demoTextVariants,
  scrollAnimation,
} from './components'

const showResultDuration = 500
const showResultsDuration = 1000
const showSearchTextDuration = 500

const searchBox = {
  top: 10,
  height: 24,
  left: 10,
  width: 140,
}

const searchResult = {
  height: 20,
}

const searchText = {
  width: 45,
}

const RESULTS = [
  {
    top: 568,
    left: '20%',
    postTextWidth: 20,
  },
  {
    top: 656,
    left: '50%',
    preTextWidth: 20,
    postTextWidth: 20,
  },
  {
    top: 868,
    left: '30%',
    preTextWidth: 15,
    postTextWidth: 30,
  },
  {
    top: 1040,
    left: '60%',
    preTextWidth: 30,
    postTextWidth: 15,
  },
  {
    top: 1200,
    left: '25%',
    preTextWidth: 20,
    postTextWidth: 20,
  },
]

const Component = ({ isAnimationComplete, keyframe }) => (
  <DemoDesktopBrowser disabled={isAnimationComplete}>
    <DemoHandbook
      css={{
        marginTop: -keyframe.scrollTop,
        transition: `all ${scrollAnimation.duration}ms ease`,
      }}
      sections={[{ type: 'chapter' }, { type: 'text' }]}
    />

    <div
      className='box-shadow'
      css={{
        ...searchBox,
        background: 'white',
        borderRadius: 3,
        height:
          searchBox.height +
          (keyframe.showResults ? RESULTS.length * searchResult.height : 0),
        overflow: 'hidden',
        position: 'absolute',
        transition: `all ${showResultsDuration}ms linear`,
        zIndex: 10,
      }}
    >
      <div
        className='tw-flex tw-items-center'
        css={{
          color: demoColors.gray,
          height: searchBox.height,
          fontSize: 12,
          paddingLeft: 6,
        }}
      >
        <FontAwesomeIcon icon={['far', 'search']} />

        <DemoTextLine
          variant='p'
          className='tw-ml-2 tw-mb-0'
          css={{
            opacity: keyframe.showSearchText ? 1 : 0,
            transition: `all ${showSearchTextDuration}ms ease`,
          }}
          color={demoColors.gray}
          width={
            keyframe.showSearchText ? searchText.width : searchText.width / 3
          }
        />
      </div>

      {RESULTS.map((result, index) => {
        const borderColor =
          index === 0 ? demoColors.grayLight : demoColors.grayLighter
        const isActive = keyframe.resultIndex === index
        const otherTextColor = isActive
          ? demoColors.grayLight
          : demoColors.grayLighter
        return (
          <div
            key={index}
            className='tw-flex tw-items-center'
            css={{
              ...searchResult,
              background: isActive ? demoColors.grayWhite : 'white',
              borderTop: `1px solid ${borderColor}`,
              overflow: 'hidden',
              paddingLeft: 26,
              transform: keyframe.showResults ? 'scaleY(1)' : 'scaleY(0)',
              transformOrigin: 'top center',
              transition: [
                `background ${showResultDuration}ms ease`,
                `transform ${showResultsDuration / RESULTS.length}ms linear ${
                  index * (showResultsDuration / RESULTS.length)
                }ms`,
              ].join(', '),
            }}
          >
            {result.preTextWidth && (
              <DemoTextLine
                variant='p'
                className='tw-mr-1 tw-mb-0'
                color={otherTextColor}
                css={{
                  transition: `color ${showResultDuration}ms ease`,
                }}
                width={result.preTextWidth}
              />
            )}

            <DemoTextLine
              variant='p'
              className='tw-mb-0'
              color={demoColors.gray}
              width={searchText.width}
            />

            {result.postTextWidth && (
              <DemoTextLine
                variant='p'
                className='tw-ml-1 tw-mb-0'
                color={otherTextColor}
                css={{
                  transition: `color ${showResultDuration}ms ease`,
                }}
                width={result.postTextWidth}
              />
            )}
          </div>
        )
      })}

      <DemoBrowserOverlay
        opacity={isAnimationComplete ? 1 - demoBrowserDisabledOpacity : 0}
      />
    </div>

    {RESULTS.map((result, index) => (
      <div
        key={index}
        css={{
          position: 'absolute',
          top: result.top - keyframe.scrollTop - 2,
          height: demoTextVariants.p.height + 4,
          left: result.left,
          width: searchText.width,

          background:
            index === keyframe.resultIndex
              ? colors['sunshine-700']
              : colors['sunshine-300'],
          opacity: keyframe.showResults ? 0.7 : 0,
          transition: `background ${showResultDuration}ms ease, opacity ${showResultsDuration}ms ease, top ${scrollAnimation.duration}ms ease`,
        }}
      />
    ))}
  </DemoDesktopBrowser>
)

export default {
  id: 'search',
  title: 'Search',
  description: `Employees can easily search your entire handbook for the content they need, even if it’s behind one of our fancy "Read More" buttons.`,
  Component,

  keyframes: [
    {
      duration: 2000,
      scrollTop: 0,
    },
    {
      duration: showSearchTextDuration + 200,
      showSearchText: true,
    },
    {
      duration: showResultsDuration + 200,
      showResults: true,
    },

    ...RESULTS.map((result, index) => ({
      duration: showResultDuration + 1250,
      resultIndex: index,
      scrollTop: result.top - 200,
    })),
  ],
}
