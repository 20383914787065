export type UserAuthType = 'email' | 'employeeId' | 'customSsoId'
export type SingleSignOnProviderId =
  | 'azure'
  | 'google'
  | 'jwt'
  | 'okta'
  | 'rippling'
  | 'saml'
  | 'saml_secondary'

type LinkTextFunction = (organization: IOrganization) => string

export interface AuthError {
  authId?: string
  authType?: UserAuthType
  code?: string
  message: string
}

// SSO Providers
export type SingleSignOnProvider = {
  // Unique identifier for the provider
  id: SingleSignOnProviderId
  // Used for errors
  name: string
  // Used on Security Settings page
  title: string
  // Text to use for the SSO button
  buttonText: string
  // Text to use for the SSO link
  linkText: string | LinkTextFunction
  // Whether or not X509 certficates are required for this SSO
  hasCertificates: boolean
  // Feature Flag required to install this SSO
  featureFlagId?: string
  // Form fields
  fields: {
    key: string
    label?: string
    placeholder?: string
  }[]
}

class SingleSignOnProviderArray extends Array<SingleSignOnProvider> {
  findById(id: string) {
    return this.find((p) => p.id === id)
  }
}

const azureText = 'Sign in via Azure/Office 365'
const buttonText = 'Sign in via Single Sign-On'
const googleText = 'Sign in via Google SSO'
const ripplingText = ' Sign in via Rippling'

const saml: SingleSignOnProvider = {
  id: 'saml',
  name: 'SAML',
  title: 'SAML',
  buttonText,
  linkText: (organization) =>
    `Sign in with ${organization.name} Single Sign-On (secondary)`,
  hasCertificates: true,
  fields: [
    {
      key: 'buttonText',
      placeholder: buttonText,
    },
    {
      key: 'loginUrl',
      label: 'SSO Endpoint',
    },
    {
      key: 'authKey',
    },
  ],
}

export const ssoProviders = new SingleSignOnProviderArray(
  {
    id: 'azure',
    name: 'Azure',
    title: 'Azure/Office 365',
    buttonText: azureText,
    linkText: azureText,
    hasCertificates: false,
    fields: [
      {
        key: 'buttonText',
        placeholder: azureText,
      },
    ],
  },
  {
    id: 'google',
    name: 'Google',
    title: 'Google',
    buttonText: googleText,
    linkText: googleText,
    hasCertificates: false,
    fields: [
      {
        key: 'buttonText',
        placeholder: googleText,
      },
    ],
  },
  {
    id: 'jwt',
    name: 'JWT',
    title: 'JWT (JSON Web Token)',
    buttonText,
    linkText: (organization) =>
      `Sign in with ${organization.name} Single Sign-On`,
    hasCertificates: true,
    fields: [
      {
        key: 'buttonText',
        placeholder: buttonText,
      },
      {
        key: 'loginUrl',
        label: 'SSO Endpoint',
        placeholder: 'https://sso.yourcompany.com/idp/jwt',
      },
      {
        key: 'issuer',
        placeholder: 'https://yourcompany.com',
      },
    ],
  },
  {
    id: 'okta',
    name: 'Okta',
    title: 'Okta',
    buttonText,
    linkText: (organization) =>
      `Sign in with ${organization.name} Single Sign-On (Okta)`,
    hasCertificates: true,
    fields: [
      {
        key: 'buttonText',
        placeholder: buttonText,
      },
      {
        key: 'loginUrl',
        label: 'SSO URL',
      },
      {
        key: 'issuer',
      },
      {
        key: 'authKey',
      },
    ],
  },
  {
    id: 'rippling',
    name: 'Rippling',
    title: 'Rippling',
    buttonText: ripplingText,
    linkText: ripplingText,
    hasCertificates: false,
    fields: [
      {
        key: 'buttonText',
        placeholder: ripplingText,
      },
    ],
  },
  saml,
  {
    ...saml,
    id: 'saml_secondary',
    name: 'Secondary SAML',
    title: 'Secondary SAML',
    featureFlagId: 'allowMultipleSamlSsos',
  },
)

export const jwtAuthTypes = new Set<UserAuthType>(['email', 'employeeId'])
