import { Button, Collapse } from '@blissbook/ui/lib'
import findIndex from 'lodash/findIndex'
import {
  DemoDesktopBrowser,
  DemoTextBlock,
  clickAnimation,
  textSectionItems,
} from './components'

const showReadMoreDuration = 500
const showReadMoreButtonDuration = 500
const toggleReadMoreContentDuration = 1500

// Pull out the sections
const startIndex = findIndex(textSectionItems, (item) => item.variant === 'h2')
const endIndex = findIndex(
  textSectionItems,
  (item) => item.variant === 'h1',
  startIndex + 1,
)
const nextStartIndex = findIndex(
  textSectionItems,
  (item) => item.variant === 'h2',
  endIndex + 1,
)

const ReadMoreButton = ({ isExpanded, ...props }) => (
  <div
    {...props}
    css={{
      background: `linear-gradient(
        0deg, 
        rgba(255,255,255,1) 0%, 
        rgba(255,255,255,1) 48%, 
        rgba(208,208,208,1) 50%, 
        rgba(255,255,255,1) 52%, 
        rgba(255,255,255,1) 100%)
      `,
    }}
  >
    <Button
      children={isExpanded ? 'Collapse' : 'Read More'}
      color={isExpanded ? 'dark' : 'info'}
      className='tw-block tw-mx-auto tw-py-0 tw-px-4'
      css={{
        fontSize: 12,
        fontWeight: 500,
        textTransform: 'uppercase',
        transition: `all ${showReadMoreButtonDuration}ms ease`,
      }}
    />
  </div>
)

const Component = ({ isAnimationComplete, keyframe }) => (
  <DemoDesktopBrowser disabled={isAnimationComplete}>
    <div className='tw-px-6 tw-py-2'>
      <DemoTextBlock items={textSectionItems.slice(0, startIndex)} />

      <Collapse
        animationDuration={showReadMoreDuration}
        css={{ marginBottom: -8 }}
        isOpen={keyframe.showReadMore}
      >
        <ReadMoreButton
          className='tw-my-4'
          css={{
            opacity: keyframe.showReadMoreButton ? 1 : 0,
            transition: `all ${toggleReadMoreContentDuration}ms ease`,
            '.btn': {
              animation: keyframe.readMoreAnimation,
            },
          }}
          isExpanded={keyframe.showReadMoreExpanded}
        />
      </Collapse>

      <Collapse
        animationDuration={toggleReadMoreContentDuration}
        animationTimingFunction='ease'
        isOpen={keyframe.showReadMoreContent}
      >
        <DemoTextBlock items={textSectionItems.slice(startIndex, endIndex)} />
      </Collapse>

      <DemoTextBlock items={textSectionItems.slice(endIndex, nextStartIndex)} />

      <ReadMoreButton className='tw-my-4' />

      <DemoTextBlock items={textSectionItems.slice(endIndex, nextStartIndex)} />

      <ReadMoreButton className='tw-my-4' />

      <DemoTextBlock items={textSectionItems.slice(endIndex, nextStartIndex)} />
    </div>
  </DemoDesktopBrowser>
)

export default {
  id: 'read-more',
  title: '“Read More” Buttons',
  description:
    'Put case-specific content (aka the dry/legal/boring stuff most people don’t read) behind a button to keep it out of the flow but remain searchable and accessible to employees trying to find it.',
  Component,

  keyframes: [
    {
      duration: 2000,
      showReadMoreContent: true,
    },
    {
      duration: showReadMoreDuration,
      showReadMore: true,
    },
    {
      duration: showReadMoreButtonDuration - 100,
      showReadMoreButton: true,
    },
    {
      duration: toggleReadMoreContentDuration + 1000,
      showReadMoreContent: false,
    },
    {
      duration: clickAnimation.duration,
      readMoreAnimation: clickAnimation.style,
    },
    {
      duration: toggleReadMoreContentDuration + 750,
      readMoreAnimation: undefined,
      showReadMoreExpanded: true,
      showReadMoreContent: true,
    },
    {
      duration: clickAnimation.duration,
      readMoreAnimation: clickAnimation.style,
    },
    {
      duration: toggleReadMoreContentDuration + 750,
      readMoreAnimation: undefined,
      showReadMoreExpanded: false,
      showReadMoreContent: false,
    },
  ],
}
