import { Button, MountCSSTransition } from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import flatMap from 'lodash/flatMap'
import {
  DemoDesktopBrowser,
  DemoHandbook,
  DemoTextBlock,
  clickAnimation,
  scrollAnimation,
} from './components'

const fadeDuration = 250
const completeDuration = 1000

const annotations = [
  {
    scrollTop: 430,
    textItems: [
      { variant: 'p', width: '98%' },
      { variant: 'p', width: '93%' },
      { variant: 'p', width: '98%' },
      { variant: 'p', width: '34%' },
    ],
  },
  {
    scrollTop: 1150,
    textItems: [
      { variant: 'p', width: '94%' },
      { variant: 'p', width: '97%' },
      { variant: 'p', width: '92%' },
      { variant: 'p', width: '65%' },
    ],
  },
  {
    scrollTop: 1700,
    textItems: [
      { variant: 'p', width: '98%' },
      { variant: 'p', width: '93%' },
      { variant: 'p', width: '98%' },
      { variant: 'p', width: '34%' },
    ],
  },
]

const doneStep = annotations.length + 1

const Annotation = (props) => (
  <div
    {...props}
    className='box-shadow tw-px-4 tw-py-2'
    css={css`
      position: absolute;
      bottom: 10px;
      left: 10px;
      background: white;
      border-radius: 3px;
      font-size: 16px;
      width: 200px;

      &.enter {
        opacity: 0;
        transition: all linear ${fadeDuration}ms;
      }
      &.enter-active {
        opacity: 1;
      }
      &.exit {
        opacity: 1;
        transition: all linear ${fadeDuration}ms;
      }
      &.exit-active {
        opacity: 0;
      }
    `}
  />
)

const AnnotationButton = ({ className, ...props }) => (
  <Button
    {...props}
    className={classnames('tw-px-0 tw-py-1', className)}
    css={{ width: '100%' }}
  />
)

const Component = ({ isAnimationComplete, keyframe }) => (
  <DemoDesktopBrowser disabled={isAnimationComplete}>
    <DemoHandbook
      css={{
        marginTop: -keyframe.scrollTop,
        transition: `all ${scrollAnimation.duration}ms ease`,
      }}
      sections={[
        { type: 'chapter' },
        { type: 'text' },
        { type: 'chapter' },
        { type: 'text' },
      ]}
    />

    <MountCSSTransition in={keyframe.step === 0}>
      <Annotation>
        <div className='tw-mb-4'>3 changes since you last viewed...</div>
        <AnnotationButton
          children='View'
          className='btn-block'
          color='danger'
          css={{
            animation: keyframe.clickAnimation,
          }}
        />
      </Annotation>
    </MountCSSTransition>

    {annotations.map((annotation, index) => (
      <MountCSSTransition key={index} in={keyframe.step === index + 1}>
        <Annotation>
          <div className='tw-mt-1 tw-mb-4'>
            <DemoTextBlock items={annotation.textItems} />
          </div>

          <div className='tw-flex tw-items-center'>
            <AnnotationButton
              className='tw-mr-1'
              color='secondary'
              css={{ flex: 1 }}
            >
              <FontAwesomeIcon icon='arrow-left' />
            </AnnotationButton>

            {keyframe.step < annotations.length && (
              <AnnotationButton
                className='tw-mr-1'
                color='secondary'
                css={{ flex: 1 }}
              >
                <FontAwesomeIcon icon='arrow-right' />
              </AnnotationButton>
            )}

            <AnnotationButton
              color='danger'
              css={{
                animation: keyframe.clickAnimation,
                flex: keyframe.step < annotations.length ? 2 : 1,
              }}
            >
              <FontAwesomeIcon icon='check' />
            </AnnotationButton>
          </div>
        </Annotation>
      </MountCSSTransition>
    ))}

    <AnnotationButton
      color='danger'
      css={{
        bottom: keyframe.step >= doneStep ? 10 : 18,
        fontSize: keyframe.step >= doneStep ? 20 : 16,
        height: keyframe.step >= doneStep ? 110 : 34,
        left: keyframe.step >= doneStep ? 10 : 112,
        opacity: keyframe.step >= doneStep + 1 ? 0 : 1,
        position: 'absolute',
        transition: `all ${completeDuration}ms ease`,
        visibility: keyframe.step >= doneStep ? 'visible' : 'hidden',
        width: keyframe.step >= doneStep ? 200 : 82,
      }}
    >
      <FontAwesomeIcon icon='check' />
      <br />
      All Done!
    </AnnotationButton>
  </DemoDesktopBrowser>
)

export default {
  id: 'annotations',
  title: 'Annotations',
  description:
    'When employees view or sign their handbook, they’re guided through the changes that occurred since the last time they viewed or signed. Don’t worry, you have total control over what they see!',
  Component,

  keyframes: [
    {
      duration: 2000,
      scrollTop: 0,
      showAnnotations: true,
      step: 0,
    },
    {
      duration: clickAnimation.duration,
      clickAnimation: clickAnimation.style,
    },

    ...flatMap(annotations, (annotation, index) => [
      {
        duration: scrollAnimation.duration + 1000,
        clickAnimation: undefined,
        scrollTop: annotation.scrollTop,
        step: index + 1,
      },
      {
        duration: clickAnimation.duration,
        clickAnimation: clickAnimation.style,
      },
    ]),

    {
      duration: completeDuration + 1000,
      step: doneStep,
    },
    {
      duration: 0,
      step: doneStep + 1,
    },
  ],
}
