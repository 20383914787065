import { gridBreakpoints } from '@blissbook/ui/branding'
import { css } from '@emotion/react'
import { Fragment } from 'react'

import { ResourceLink } from '../../components'

import HowToSection from './HowToSection'
import WorkbookStack from './WorkbookStack'

import { urls } from '../howToConfig'

const defaultText =
  'Guess what?! We created a companion Policies & Procedures Workbook to help you stay organized on your policy update journey. Download it here!'

export default ({
  extraMarginTop,
  extraMarginBottom,
  text,
  noImage,
  stack,
  ...props
}) => (
  <HowToSection
    className='container'
    rowClass='tw-items-center'
    css={css`
      @media (min-width: ${gridBreakpoints.md}px) {
        margin-top: ${extraMarginTop || 0};
        margin-bottom: ${extraMarginBottom || 0};
      }
    `}
    {...props}
    main={
      <ResourceLink
        href={urls.workbook}
        icon={['far', 'arrow-alt-circle-down']}
        style={{ maxWidth: '100%' }}
        download={true}
      >
        {text || defaultText}
      </ResourceLink>
    }
    side={
      noImage ? (
        <Fragment />
      ) : (
        <WorkbookStack top={stack[0]} left={stack[1]} right={stack[2]} />
      )
    }
  />
)
