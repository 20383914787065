import { addRipplingIntegration } from '@blissbook/ui/marketing/queries'
import { useStore } from '@blissbook/ui/util/store'
import qs from 'query-string'
import type React from 'react'
import { useLocation } from 'react-router-dom'
import { IntegrationPage } from './util'

export const InstallRipplingPage: React.FC = () => {
  const location = useLocation()
  const { integration } = useStore()
  const query = qs.parse(location.search)
  const { code, redirect_uri } = query
  const blissbookUrl = integration ? '/employees/sync' : '/home'

  return (
    <IntegrationPage
      label='Rippling'
      onInstall={async () => {
        await addRipplingIntegration({ code })

        window.open(blissbookUrl, '_blank', 'noopener noreferrer')

        window.location.href = redirect_uri
      }}
    />
  )
}
