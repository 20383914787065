import { colors } from '@blissbook/ui/branding'

import { urls } from '../howToConfig'

const imgStyle = {
  position: 'absolute',
  border: 'solid',
  borderColor: colors['blurple-500'],
  borderWidth: '1px',
  borderRadius: '8px',
  height: '350px',
  width: '271px',
  boxShadow: '2px 2px 4px 0px rgba(0,0,0,0.25)',
}

export default ({ top, left, right, ...props }) => (
  <div
    className='workbook-stack'
    style={{
      height: '370px',
      position: 'relative',
    }}
    {...props}
  >
    <a href={urls.workbook} download>
      {/* biome-ignore lint/a11y/useAltText: if you see this line, try to fix it */}
      <img
        {...right}
        style={{
          top: '13px',
          left: '55px',
          transform: 'rotate(6deg)',
          ...imgStyle,
        }}
      />
      {/* biome-ignore lint/a11y/useAltText: if you see this line, try to fix it */}
      <img
        {...left}
        style={{
          top: '13px',
          left: '15px',
          transform: 'rotate(-4deg)',
          ...imgStyle,
        }}
      />
      {/* biome-ignore lint/a11y/useAltText: if you see this line, try to fix it */}
      <img
        {...top}
        style={{
          top: '0',
          left: '35px',
          ...imgStyle,
        }}
      />
    </a>
  </div>
)
