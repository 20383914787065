import { Link, Shape } from '@blissbook/ui/lib'
import { Fragment } from 'react'
import { Section } from '../../components'
import FeaturesContainer from './FeaturesContainer'

const shapeSize = 60
const wrapHeading = (shapes) => (
  <div className='tw-flex tw-items-center'>
    {shapes.map((shape, index) => (
      <Shape
        {...shape}
        key={index}
        rotate={45}
        size={shapeSize}
        translateY={12}
        type='wedge'
      />
    ))}
  </div>
)

const FEATURES = [
  {
    heading: wrapHeading([
      { color: 'aqua-300', translateX: shapeSize / 4, fill: true },
    ]),
    title: 'Change is easy.',
    description:
      'Update your entire handbook or just one section, any time, as many times as you want.',
  },
  {
    heading: wrapHeading([
      { color: 'aqua-100', translateX: shapeSize / 2 + 5, fill: true },
      {
        color: 'aqua-300',
        translateX: -shapeSize * 0.75,
        strokeDasharray: '4 4',
      },
    ]),
    title: 'You’re in control.',
    description:
      'Changes are staged in a draft version so employees never see your work-in-progress.',
  },
  {
    heading: wrapHeading([
      { color: 'aqua-300', translateX: shapeSize / 4, fill: true },
      { color: 'aqua-500', translateX: 0, fill: true },
      { color: 'aqua-700', translateX: -shapeSize / 4, fill: true },
    ]),
    title: 'Track your history.',
    description:
      'All revisions are auto-tracked so you can see exactly what changed and when.',
  },
]

const ACCORDION_FEATURES = [
  {
    title: 'Know what to change with law change alerts.',
    description: (
      <Fragment>
        Blissbook’s{' '}
        <Link to='/employee-handbook-hr-policy-content'>Law Change Alerts</Link>{' '}
        keep you informed of changes in federal, state, county, and city laws
        that may require you to update your handbook policies.
        <br />
        <br />
        You can take it a step further and have us make changes for you. All you
        need to do is review &amp; approve our proposed updates.
      </Fragment>
    ),
  },
  {
    title: 'Never create a “summary of changes” again.',
    description: (
      <Fragment>
        Blissbook’s <Link to='#annotations'>annotations functionality</Link>{' '}
        keeps employees up-to-date with changes to your handbook. When an
        employee views or signs your handbook, they’re guided through the
        changes that happened since the last time they viewed or signed. Don’t
        worry, you have complete control over what they see!
      </Fragment>
    ),
  },
  {
    title: 'Stay protected as content is changed.',
    description: (
      <Fragment>
        All policies have a history with a diff so you can see exactly what
        changed, when the new version was published, and who published it.
        <br />
        <br />
        You can collect new signatures at any time. Blissbook automatically
        tracks signature rounds, what versions of your policies employees sign
        off on, and more.
      </Fragment>
    ),
  },
  {
    title: 'Collaborate with co-workers.',
    description: (
      <Fragment>
        Edit your handbook with an unlimited number of collaborators at the same
        time.
        <br />
        <br />
        Add annotations for collaborators' eyes only, so your whole team will
        always be in-the-know about what changed and why.
        <br />
        <br />
        Easily preview new versions of your handbook and allow other
        stakeholders in your company to preview as well so you can collect the
        feedback you need to launch!
      </Fragment>
    ),
  },
  {
    title: 'Personalize content by location or other groups.',
    description: (
      <Fragment>
        Blissbook’s{' '}
        <Link to='#personalized-content'>personalized content features</Link>{' '}
        eliminate the need to manage multiple handbooks or special sets of
        content for different groups of people when most of the content is the
        same. Eliminate double work and the inevitable mistakes that happen when
        you change the same content in multiple places!
      </Fragment>
    ),
  },
  {
    title: 'Sync employees and send invitations automatically.',
    description:
      'Synchronize your employee list from your Active Directory or HRIS to automatically send invitations to new employees and archive separated employees.',
  },
]

export default () => (
  <Section id='policy-management'>
    <FeaturesContainer
      color='aqua-500'
      accordionFeatures={ACCORDION_FEATURES}
      features={FEATURES}
      maxColumns={3}
      shapeType='arrows'
      title='Update.'
      titleOffset={2}
      topLeftShapes={[
        { x: 0, y: 1, type: 'square', color: 'aqua-100', fill: true },
        {
          x: 0,
          y: 2,
          type: 'wedge',
          color: 'aqua-300',
          rotate: -90,
          fill: true,
          className: 'tw-hidden xl:tw-block',
        },
        {
          x: 0,
          y: 1,
          type: 'arrows',
          color: 'aqua-500',
          translateX: 60,
          translateY: -30,
        },
      ]}
    />
  </Section>
)
