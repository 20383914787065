import { getColor, gridBreakpoints } from '@blissbook/ui/branding'
import {
  BackgroundShapeGrid,
  Col,
  Container,
  DesktopBrowser,
  Row,
} from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Card,
  DotsHorizontal,
  HeadingDivider,
  MarketingLayout,
  MaxWidthContainer,
  Section,
  Typography,
} from '../components'
import { CallToActionSection, SolutionSection } from './sections'

import config from '@blissbook/ui-config'
import { Link } from 'react-router-dom'
const { assetsUrl = '' } = config

const color = 'aqua-500'

const FEATURES = [
  {
    icon: ['far', 'flag-usa'],
    title: 'Multi-state',
    description:
      'Hundreds of model policy templates compliant with current city, county, state, and federal laws are ready and waiting for you.',
  },
  {
    icon: ['far', 'swap-arrows'],
    title: 'Handbook Updates',
    description:
      'An experienced HR expert will work with you to customize your current policies to fit your unique requirements and get them up-to-date with current laws.',
  },
  {
    icon: ['far', 'fingerprint'],
    title: 'one-off policies',
    description:
      'Whichever “how things work around here” topics you want to cover, we’ve got template content and an expert team who will help you publish compliant content.',
  },
]

const HR_CONSULTING_FEATURES = [
  {
    icon: ['fa-light', 'toolbox'],
    title: 'General HR Administration',
    description:
      'Need help with HR tasks? We can help with HR department setup, job descriptions, interviewing, labor law research, surveys, onboarding, employee communications, documentation, hiring, and more.',
  },
  {
    icon: ['fa-light', 'user-secret'],
    title: 'Employee Relations & Investigations',
    description:
      'Employee complaints & investigations must be handled with care to stay on the right side of the law. We can help with complaints, investigations, reporting, and recommendations for closure.',
  },
  {
    icon: ['fa-light', 'face-awesome'],
    title: 'Employee Engagement Initiatives',
    description:
      'Can you spot the difference between “engaged” and “satisfied” employees? Up your culture with engagement surveys, data analysis, improvement plans, employee interviews, and culture change plans.',
  },
  {
    icon: ['fa-light', 'clipboard-list-check'],
    title: 'Career Coaching & Development',
    description:
      'Unlock potential, nurture new leaders, and improve teamwork with our help, including 360 surveys, leadership training, and group or personalized coaching based on 360 reports.',
  },
]

const imgFolder =
  assetsUrl + '/images/marketing/resources/hr-policy-content-services/'

const assets = {
  chapterSample: imgFolder + 'chapter-sample.jpg',
  approveIllustration: imgFolder + 'approve-illustration.png',
  illustration: imgFolder + 'illustration.png',
  lawChangeAlerts: imgFolder + 'law-change-alerts-illustration.png',
  sheet: imgFolder + 'sheet.png',
  thumbsUp: imgFolder + 'thumbs-up.svg',
  ffrown: imgFolder + 'ffrown.svg',
}

export default () => (
  <MarketingLayout
    title='HR Policy Content Services & Employment Law Change Alerts'
    description={`Create or revise an employee handbook with Blissbook's HR policy content and employee handbook builder services, including law change alerts.`}
  >
    <SolutionSection
      shapeColor='aqua-100'
      color={color}
      paddingBottom={0}
      image='AquaPapers.svg'
      imageDesktopCss={css(`
        top: 40px;
        right: -230px;
        height: 480px;

        @media (min-width: ${gridBreakpoints.xl}px) {
          right: -110px;
        }
      `)}
      greeting='We’ll Do It For You'
      title='Employee Handbook Builder Services'
      description='Create a new handbook or update your existing one, then keep it up-to-date with changing laws with Blissbook’s suite of expert HR services.'
    />

    <Section
      css={{
        background: `linear-gradient(180deg, white 0%, ${color} 100%);`,
      }}
    >
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Row className='tw-flex-row-reverse tw-items-center'>
            <Col xs={12} lg={4} className='mb-sm'>
              <div className='tw-flex tw-flex-col tw-items-center'>
                <DesktopBrowser className='box-shadow' css={{ width: 260 }}>
                  <img
                    src={assets.chapterSample}
                    width='100%'
                    alt='an example of chapter content'
                  />
                </DesktopBrowser>
              </div>
            </Col>

            <Col xs={12} lg={8} className='mb-sm'>
              <HeadingDivider variant='h2' divider={{ color }}>
                New or Updated Employee Handbook
              </HeadingDivider>

              <Typography variant='p2'>
                Whether you need a brand-new handbook or to update your existing
                policies, you’re covered with engaging policy content written
                “the Blissbook way”.
              </Typography>
            </Col>
          </Row>

          <Row>
            {FEATURES.map((feature, index) => (
              <Col
                key={index}
                xs={12}
                md={4}
                className='tw-mb-10 tw-translate-x-8'
              >
                <MaxWidthContainer md={250}>
                  <Row>
                    <FontAwesomeIcon
                      icon={feature.icon}
                      className='-tw-translate-x-full'
                      css={{ color: getColor(color) }}
                    />
                    <Typography variant='h4' css={{ color: getColor(color) }}>
                      {feature.title}
                    </Typography>
                  </Row>
                  <Typography variant='p2'>{feature.description}</Typography>
                </MaxWidthContainer>
              </Col>
            ))}
          </Row>
        </MaxWidthContainer>

        <div className='tw-flex tw-justify-center'>
          <Link className='btn btn-primary' to='/request-a-demo'>
            Interested? Let's chat about your project.
          </Link>
        </div>
      </Container>
    </Section>

    <Section style={{ backgroundColor: 'rgba(40, 170, 226, 0.06)' }}>
      <Container className='py-sm text-center'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <img
            alt='Sheet of paper'
            src={assets.sheet}
            className='tw-mx-auto tw-mb-8'
            style={{
              height: '96px',
              width: '94px',
            }}
          />
          <Typography variant='h2'>
            Conversational, Approachable Tone
          </Typography>
          <Typography variant='p1'>
            Lawyers are great, but they write terrible copy. We learn the law,
            reference model policies and advice from expert lawyers, then write.
            You get approachable, compliant policies employees may actually
            read.
          </Typography>
          <Typography variant='p1'>
            Change how your employees think about their employment relationship:
          </Typography>
          <div className='row lg:tw-mx-18 tw-items-center'>
            <div className='col-lg-6 tw-my-8'>
              <Card
                className='text-left tw-mx-auto lg:tw-mb-0 tw-mb-14'
                color='sunshine-500'
                style={{ maxWidth: '370px', overflow: 'visible' }}
                title='From:'
                titleCss={{ textAlign: 'left' }}
                shape={
                  <img
                    alt='frowning face'
                    src={assets.ffrown}
                    className='tw-mx-auto tw-mb-8'
                    style={{
                      position: 'absolute',
                      right: 20,
                      top: -13,
                      height: '46px',
                      width: '46px',
                    }}
                  />
                }
              >
                <Typography variant='p2'>
                  I’m entering a legal transaction between two adversarial
                  parties.
                </Typography>
              </Card>
            </div>

            <div className='col-lg-6 tw-my-8'>
              <Card
                className='text-left tw-mx-auto'
                color='aqua-500'
                style={{ maxWidth: '370px', overflow: 'visible' }}
                title='To:'
                titleCss={{ textAlign: 'left' }}
                shape={
                  <img
                    alt='thumbs up'
                    src={assets.thumbsUp}
                    className='tw-mx-auto tw-mb-8'
                    style={{
                      position: 'absolute',
                      right: 20,
                      top: -25,
                      height: '55px',
                      width: '47px',
                    }}
                  />
                }
              >
                <Typography variant='p2'>
                  I’m joining a team on a mission with some rules for how we
                  work together successfully.
                </Typography>
              </Card>
            </div>
          </div>
        </MaxWidthContainer>
      </Container>
    </Section>

    <Section>
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Row className='tw-flex-row-reverse tw-items-center'>
            <Col lg={6}>
              <DesktopBrowser
                className='box-shadow tw-mx-auto tw-mb-8 lg:tw-mb-0'
                css={css(`
                  max-width: 100%;
                  @media (min-width: ${gridBreakpoints.lg}px) {
                    width: 1000px;
                    max-width: 1000px;
                  }
                `)}
              >
                <img
                  src={assets.lawChangeAlerts}
                  style={{ width: '100%' }}
                  alt='an example of a alert modal of law changes'
                />
              </DesktopBrowser>
            </Col>
            <Col lg={6}>
              <Typography variant='h4' css={{ color: getColor(color) }}>
                Take a Break
              </Typography>
              <Typography variant='h2'>
                Law Change Alerts
                <FontAwesomeIcon icon={['far', 'bell']} className='tw-ml-4' />
              </Typography>
              <Typography variant='p2' css={{ color: getColor('gray-800') }}>
                Ding-a-ling! Reduce your risk from outdated policies.{' '}
                <Link to='/employment-law-updates'>We track changing laws</Link>{' '}
                across city, county, state, and US federal jurisdictions, tell
                you about them, then tell you what to do, sans noise.
              </Typography>
              <Typography variant='p2' css={{ color: getColor('gray-800') }}>
                Our law change alerts are an anti-firehose of information. We
                focus only on the changes to laws that are likely to drive a
                review of or changes to your policy content.
              </Typography>
              <Typography variant='p2' css={{ color: getColor('gray-800') }}>
                We specifically do not include other HR-related but
                non-policy-focused law changes (e.g. minimum wage laws), and
                focus on enacted laws only so you aren’t overwhelmed by a
                torrent of information.
              </Typography>

              <Link className='btn btn-primary' to='/employment-law-updates'>
                <FontAwesomeIcon
                  icon={['far', 'envelope']}
                  className='tw-mr-2'
                />
                Get employment law updates in your inbox
              </Link>
            </Col>
            <Col lg={6} className='tw-mb-6 offset-lg-2' />
          </Row>
        </MaxWidthContainer>
      </Container>
      <BackgroundShapeGrid
        className='tw-hidden lg:tw-block'
        alignX='left'
        alignY='bottom'
        style={{
          position: 'relative',
          bottom: '-120px',
        }}
        shapes={[
          { x: 0, y: 2, type: 'square', color: 'aqua-700', fill: true },
          {
            x: 1,
            y: 1,
            type: 'wedge',
            color: 'aqua-300',
            rotate: 0,
            fill: true,
          },
        ]}
      />
    </Section>

    <Section className='tw-mt-14 lg:tw-mt-0'>
      <Container className='py-sm'>
        <MaxWidthContainer className='row tw-mx-auto' xs={960}>
          <div className='col-lg-6 lg:tw-mt-0 tw-mt-8'>
            <img
              alt='Approve a change'
              className=' tw-mb-14 lg:tw-mb-6'
              src={assets.approveIllustration}
              style={{
                height: '151px',
              }}
            />

            <Typography variant='h2' css={{ color: getColor('gray-800') }}>
              Automatic Policy Updates
            </Typography>

            <Typography variant='p2'>
              Don’t want to deal with even our user-friendly law change alerts?
              No problem. When laws change, we’ll suggest edits to your
              specific, custom content. All you need to do is approve and
              publish.
            </Typography>
          </div>

          <div className='col-lg-6 lg:tw-mt-0 tw-mt-8'>
            <img
              alt='document illustration'
              src={assets.illustration}
              className='tw-mb-14 lg:tw-mb-6'
              style={{
                height: '151px',
              }}
            />

            <Typography variant='h2' css={{ color: getColor('gray-800') }}>
              Custom Updates
            </Typography>

            <Typography variant='p2'>
              Leadership had a change of heart? Keep it compliant with our
              expert advice. We’ll help you make other custom changes to your
              content as needed.
            </Typography>
          </div>
        </MaxWidthContainer>
      </Container>
    </Section>

    <Section
      css={{
        background: `linear-gradient(180deg, white 0%, ${getColor(
          'aqua-100',
        )} 100%);`,
      }}
    >
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <div className='tw-flex tw-items-center tw-mb-14'>
            <DotsHorizontal color={color} css={{ flex: 1 }} />
            <FontAwesomeIcon
              icon={['fa-light', 'messages-question']}
              className='tw-mx-2'
              css={{
                width: 133,
                height: 109,
                color: getColor(color),
              }}
            />
            <DotsHorizontal color={color} css={{ flex: 1 }} />
          </div>

          <Col className='tw-mb-14 tw-text-center'>
            <Typography variant='h2' css={{ color: getColor('gray-800') }}>
              HR Consulting & Fractional CHRO
            </Typography>

            <Typography variant='p2'>
              Whatever HR support you need, we can help!
            </Typography>
          </Col>

          <Row className='tw-mb-14'>
            {HR_CONSULTING_FEATURES.map((feature, index) => (
              <Col key={index} xs={12} md={6} className='tw-mb-10'>
                <MaxWidthContainer md={380}>
                  <FontAwesomeIcon
                    icon={feature.icon}
                    css={{
                      width: 80,
                      height: 80,
                      color: getColor(color),
                      fontWeight: 300,
                    }}
                  />

                  <Typography
                    variant='h2'
                    css={{ color: getColor('gray-800') }}
                  >
                    {feature.title}
                  </Typography>

                  <Typography variant='p2'>{feature.description}</Typography>
                </MaxWidthContainer>
              </Col>
            ))}
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <CallToActionSection
      title='Contact Us to Learn More'
      description='Let’s connect! Tell us about yourself, your organization, and your project, and we’ll tell you how we can help, including pricing and a demo.'
    >
      <Link className='btn btn-primary tw-mr-6' to='/request-a-demo'>
        Request a Demo
      </Link>
    </CallToActionSection>
  </MarketingLayout>
)
