import { getColor, gridBreakpoints } from '@blissbook/ui/branding'
import { BackgroundImage } from '@blissbook/ui/lib'
import { Shape } from '@blissbook/ui/lib/shape'
import { css } from '@emotion/react'
import Section from '../../components/Section'
import Typography from '../../components/Typography'
import { MaxWidthContainer } from '../../components/containers'

const baseImageUrl = '/images/marketing/solutions/'

/**
 * @param {Record<string, any>} props
 */
export default ({
  color,
  shapeColor,
  description,
  greetingColor,
  image,
  imageDesktopCss,
  imageMobileCss,
  greeting,
  shapeSize = 500,
  paddingBottom = 100,
  title,
}) => (
  <Section
    className='tw-flex tw-flex-col lg:tw-flex-row tw-items-center container px-lg'
    css={css`
      @media (min-width: ${gridBreakpoints.lg}px) {
        min-height: ${shapeSize + paddingBottom}px;
        padding-bottom: ${paddingBottom}px;
        padding-left: 75px;
        padding-right: 75px;
      }
    `}
  >
    <Shape
      className='tw-hidden lg:tw-block'
      css={css`
        position: absolute;
        top: 0;
        right: -150px;
        z-index: -1;
        opacity: 0.5;

        @media (min-width: ${gridBreakpoints.xl}px) {
          right: -120px;
        }
      `}
      type='wedge'
      color={shapeColor || color}
      size={shapeSize}
      rotate={180}
      fill
    />

    <img
      alt={image}
      src={baseImageUrl + image}
      className='tw-hidden lg:tw-block'
      css={[{ position: 'absolute' }, imageDesktopCss]}
    />

    <BackgroundImage
      src={baseImageUrl + image}
      className='tw-block lg:tw-hidden mb-sm'
      css={[
        {
          backgroundPosition: '50% 100%',
          height: 200,
          width: '100%',
        },
        imageMobileCss,
      ]}
    />

    <MaxWidthContainer lg={505} xl={600}>
      <Typography
        variant='h4'
        css={{ color: getColor(greetingColor || color) }}
      >
        {greeting}
      </Typography>

      <Typography variant='h1'>{title}</Typography>

      <Typography variant='p1'>{description}</Typography>
    </MaxWidthContainer>
  </Section>
)
