import { colors } from '@blissbook/ui/branding'
import { keyframes } from '@emotion/react'
import times from 'lodash/times'
import {
  DemoDesktopBrowser,
  DemoHandbook,
  DemoMobileBrowser,
  DemoVideo,
} from './components'

const phaseDuration = 6000
const scrollPxPerSecond = 20
const scrollPxPerMillisecond = scrollPxPerSecond / 1000

const SunIcon = (props) => {
  const radius = 75
  const circleRadius = 37
  const rayCount = 8
  const rayLength = 24

  const animationName = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(-90deg); }
  `

  return (
    <svg
      {...props}
      css={{
        animation: `${animationName} ${phaseDuration}ms linear 1 forwards`,
      }}
      height={radius * 2}
      width={radius * 2}
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      role='img'
      aria-label='sun icon'
    >
      <g fill='none' stroke={colors['sunshine-500']} strokeWidth='4'>
        <circle cx={radius} cy={radius} r={circleRadius} />
        {times(rayCount).map((index) => {
          const angle = (index / rayCount) * (2 * Math.PI)
          const x1 = radius + Math.cos(angle) * (radius - rayLength)
          const y1 = radius + Math.sin(angle) * (radius - rayLength)
          const x2 = radius + Math.cos(angle) * radius
          const y2 = radius + Math.sin(angle) * radius
          return <path key={index} d={`M${x1},${y1} L${x2},${y2}`} />
        })}
      </g>
    </svg>
  )
}

const MoonIcon = () => {
  const width = 108
  const height = 96

  const getAnimation = (name) => `${name} ${phaseDuration}ms linear 1 forwards`

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      role='img'
      aria-label='moon-icon'
    >
      <g stroke='#f2f2f2' fill='none' fillRule='evenodd'>
        <path
          d='M67.9255143,81.8546352 C66.6537329,81.903962 65.158561,81.8833298 63.1977956,81.8068393 C45.8532047,81.1302172 31.9789523,67.5998121 31.9789523,50.4335438 C31.9789523,39.1703434 35.6827749,31.1066497 44.1152489,24.2349654 C44.6569205,23.7935533 45.1616864,23.411903 45.6468435,23.0780798 C44.4686438,22.9732467 43.0951116,22.942112 41.5316744,22.9986745 C21.6318148,23.7186185 5.99855503,39.1444072 5.99855503,58.4998927 C5.99855503,78.1697216 21.9281732,94.1773715 41.4411743,93.999886 C53.6911446,93.8884633 62.5738915,89.230344 67.9255143,81.8546352 Z'
          strokeWidth='4'
          transform='translate(37.922252, 58.486086) rotate(-6.000000) translate(-37.922252, -58.486086) '
        />
        <path
          d='M94.0125051,44.1289522 L100.270774,40.9906111 L93.98848,37.8401492 C92.5898332,37.1191646 91.459322,35.9900395 90.7269426,34.5729981 L87.6169491,28.3346074 L84.4970546,34.592606 C83.7582291,36.0217658 82.6085941,37.1817833 81.2305818,37.8477204 L74.9740525,40.9851889 L81.2458039,44.104905 C82.6443618,44.8262838 83.7747198,45.9553695 84.5070098,47.3722383 L87.6197089,53.6160561 L90.7368978,47.3886473 C91.4738808,45.9630516 92.619627,44.8052574 94.0125051,44.1289522 Z'
          strokeWidth={3.25}
          css={{
            animation: getAnimation(keyframes`
              from, 50%, to { transform: scale(1.25); }
              25%, 75% { transform: scale(.75); }
            `),
            transformOrigin: '90px 40px',
          }}
        />
        <path
          d='M65.3748584,16.5138873 L67.754495,11.7546142 L72.5137681,9.37497765 L67.754495,6.99534109 L65.3748584,2.23606798 L62.9952219,6.99534109 L58.2359488,9.37497765 L62.9952219,11.7546142 L65.3748584,16.5138873 Z'
          css={{
            animation: getAnimation(keyframes`
              from, 50%, to { transform: translateY(2px) scale(.75); }
              25%, 75% { transform: translateY(2px) scale(1.25); }
            `),
            transformOrigin: '64px 10px',
          }}
          strokeWidth={1.75}
        />
      </g>
    </svg>
  )
}

const lightPhase = {
  darkMode: false,
  icon: <SunIcon />,
}

const darkPhase = {
  darkMode: true,
  icon: <MoonIcon />,
}

const phases = [lightPhase, darkPhase, lightPhase, darkPhase]

const orbit = {
  fromAngle: 120,
  radius: 60,
  toAngle: 420,
}

const orbitKeyframes = keyframes`
  from { transform: rotate(${orbit.fromAngle}deg) translateX(${
    orbit.radius
  }px) rotate(${-orbit.fromAngle}deg) translateY(${orbit.radius}px); }
  to { transform: rotate(${orbit.toAngle}deg) translateX(${
    orbit.radius
  }px) rotate(${-orbit.toAngle}deg) translateY(${orbit.radius}px); }
`

const Handbook = ({ keyframe }) => {
  const theme = 'light'
  return (
    <DemoHandbook
      css={{
        marginTop: -keyframe.scrollTop,
        transition: `all ${keyframe.duration}ms linear`,
      }}
      sections={[
        { type: 'chapter' },
        { type: 'letter', video: <DemoVideo theme={theme} /> },
        { type: 'chapter' },
        { type: 'text' },
      ]}
      theme={theme}
    />
  )
}

const Component = ({ isAnimationComplete, keyframe }) => (
  <div>
    <div
      className='tw-flex tw-items-start tw-justify-center'
      css={{
        height: 90,
      }}
    >
      <div
        key={keyframe.phaseIndex}
        children={keyframe.icon}
        css={{
          animation: `${orbitKeyframes} ${phaseDuration}ms linear 1 forwards`,
        }}
      />
    </div>

    <div className='tw-flex tw-items-end'>
      <DemoDesktopBrowser
        children={<Handbook keyframe={keyframe} />}
        darkMode={keyframe.darkMode}
        disabled={isAnimationComplete}
        height={300}
      />

      <DemoMobileBrowser
        css={{
          marginBottom: -40,
          marginLeft: -140,
        }}
        darkMode={keyframe.darkMode}
        disabled={isAnimationComplete}
        height={260}
        width={160}
      >
        <div
          children={<Handbook keyframe={keyframe} />}
          css={{
            transform: 'scale(0.5)',
            transformOrigin: 'top left',
            width: '200%',
          }}
        />
      </DemoMobileBrowser>
    </div>
  </div>
)

export default {
  id: 'accessibility',
  title: '24/7 Accessibility',
  description:
    'Since an online handbook is accessible 24/7 via a web browser on any device at a single, easy-to-remember URL, they can’t be thrown away, lost in a drawer, or forgotten where they are on a server or Sharepoint.',
  Component,

  keyframes: [
    {
      duration: 2000,
      scrollTop: 0,
    },

    ...phases.map((phase, index) => ({
      duration: phaseDuration,
      ...phase,
      phaseIndex: index,
      scrollTop: phaseDuration * (index + 1) * scrollPxPerMillisecond,
    })),

    {
      duration: 1000,
      icon: undefined,
    },
  ],
}
