import { Section } from '../../components'
import FeaturesContainer from './FeaturesContainer'

const FEATURES = [
  {
    heading: 'Stay organized',
    title: 'Always know your next step.',
    description:
      'Never launched a digital handbook? No worries. Built-in project management walks your team through every task that needs to get done to launch successfully.',
  },
  {
    heading: 'Get the word out',
    title: 'Communicate easily with in-app email.',
    description:
      'Inform employees about your new digital handbook with Blissbook’s built-in email notification system. You can even send from your own email address.',
  },
]

export default () => (
  <Section id='online-employee-handbook'>
    <FeaturesContainer
      color='red-500'
      features={FEATURES}
      shapeType='balloon'
      title='Launch.'
      titleOffset={2}
      topRightShapes={[
        { x: 1, y: 1, type: 'square', color: 'red-50', fill: true },
        {
          x: 0,
          y: 2,
          type: 'wedge',
          color: 'red-50',
          rotate: 90,
          fill: true,
        },
        { x: 1, y: 2, type: 'square', color: 'red-300', fill: true },
        { x: 2, y: 2, type: 'square', color: 'red-500', fill: true },
        {
          x: 0,
          y: 3,
          type: 'wedge',
          color: 'red-500',
          rotate: -90,
          fill: true,
          className: 'tw-hidden xl:tw-block',
        },
        {
          x: 1,
          y: 1,
          type: 'balloon',
          color: 'red-500',
          translateX: -60,
          translateY: -50,
        },
      ]}
    />
  </Section>
)
