import { Typography } from '../../components'

export default ({ img, name, title, ...props }) => (
  <div className='media tw-mt-8' {...props}>
    <img
      src={img}
      className='tw-mr-4'
      alt={name}
      style={{
        width: '50px',
        height: '50px',
        borderRadius: '25px',
      }}
    />
    <div className='media-body'>
      <Typography
        variant='p2'
        className='tw-mb-1'
        style={{
          textTransform: 'uppercase',
          fontWeight: '600',
          letterSpacing: '2px',
          fontSize: '90%',
        }}
      >
        {name}
      </Typography>
      <Typography
        variant='p2'
        className='tw-mb-1'
        style={{
          fontSize: '90%',
        }}
      >
        {title}
      </Typography>
    </div>
  </div>
)
