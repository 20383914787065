import { colors } from '@blissbook/ui/branding'
import { Link } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'
import tinycolor from 'tinycolor2'

import { Section, Typography } from '../../components'
import HowToSection from './HowToSection'

const backgroundColorFrom = colors.white
const backgroundColorTo = tinycolor(colors['aqua-100'])
  .setAlpha(0.5)
  .toRgbString()

export default ({ title, subtitle, prev, next, img, ...props }) => (
  <Section
    style={{
      paddingBottom: '20px',
      backgroundImage: `linear-gradient(180deg, ${backgroundColorFrom} 0%, ${backgroundColorTo} 100%)`,
    }}
    {...props}
  >
    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h4'>
            How to Write Policies &amp; Procedures
          </Typography>
          <Typography variant='h1' style={{ marginBottom: '15px' }}>
            {title}
          </Typography>
          <Typography
            variant='h2'
            style={{
              fontWeight: '300',
              fontSize: '26px',
              marginBottom: '40px',
            }}
          >
            {subtitle}
          </Typography>
          <div className='row'>
            {prev && (
              <div className='col-lg-5 col-md-6 col-sm-12 tw-mb-2'>
                <Link
                  href={prev.url}
                  className='btn btn-outline-primary btn-block tw-p-2'
                >
                  <FontAwesomeIcon
                    className='tw-mr-2 tw-float-left'
                    style={{ marginTop: '4px' }}
                    icon='arrow-left'
                  />
                  <span className='tw-float-right'>{prev.text}</span>
                  <span className='clearfix' />
                </Link>
              </div>
            )}
            {next && (
              <div className='col-lg-5 offset-lg-2 col-md-6 col-sm-12 tw-mb-2'>
                <Link
                  href={next.url}
                  className='btn btn-outline-primary btn-block tw-p-2'
                >
                  <span className='tw-float-left'>{next.text}</span>
                  <FontAwesomeIcon
                    className='tw-mr-2 tw-float-right'
                    style={{ marginTop: '4px' }}
                    icon='arrow-right'
                  />
                  <span className='clearfix' />
                </Link>
              </div>
            )}
          </div>
        </Fragment>
      }
      side={
        img && (
          <div
            className='text-right tw-my-6 md:tw-my-0 md:tw-block tw-hidden'
            style={{
              maxWidth: '100%',
              maxHeight: 'auto',
            }}
          >
            {/* biome-ignore lint/a11y/useAltText: if you see this line, try to fix it */}
            <img
              src={img}
              style={{
                width: '285px',
                height: '255px',
                top: '60px',
                position: 'relative',
              }}
            />
          </div>
        )
      }
    />
  </Section>
)
