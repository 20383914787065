import accessibility from './accessibility'
import annotations from './annotations'
import links from './links'
import personalized from './personalized'
import readMore from './read-more'
import search from './search'
import sso from './sso'
import toc from './toc'
import trusted from './trusted'
import video from './video'

export const EXPERIENCES = [
  personalized,
  readMore,
  annotations,
  search,
  links,
  sso,
  accessibility,
  video,
  trusted,
  toc,
]
