// IMPORTANT: THIS FILE IS IN TWO PROJECTS. TO MODIFY THIS FILE:
// Go to the blissbook project
// Make changes
// Copy the changes to the blissbook-marketing project
// Find and replace "tw-" with ""
'use client'

import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import { useState } from 'react'
import { type NavItem, useNavItems } from './useNavItems'
import './navbar.scss'

type Location = {
  pathname: string
}

const linkClassName =
  'tw-flex tw-items-center tw-font-normal tw-text-black tw-cursor-pointer hover:tw-no-underline hover:tw-text-blurple-500'

const NavDropdown = ({
  children,
  className,
  isOpen,
  ...props
}: {
  children: React.ReactNode
  className: string
  isOpen?: boolean
  onClick?: () => void
}) => (
  <a {...props} className={`${linkClassName} ${className}`}>
    {children}
    <FontAwesomeIcon
      icon={faChevronDown}
      className='tw-ml-2 tw-text-[10px] tw-transition-transform tw-duration-300'
      style={{
        transform: `rotate(${isOpen ? 180 : 0}deg)`,
      }}
    />
  </a>
)

function NavItemView(props: {
  location: Location
  navItem: NavItem
}) {
  const { navItem } = props
  const Component = navItem.items ? NavItemMenu : NavItemLink
  return <Component {...props} />
}

function NavItemLink({
  location,
  navItem,
}: {
  location: Location
  navItem: NavItem
}) {
  return (
    <div
      className={`tw-relative ${
        isNavItemActive(navItem, location) ? 'active' : ''
      }`}
    >
      <a
        className={`${linkClassName} tw-py-2 lg:tw-px-2 lg:tw-py-4`}
        href={navItem.href}
      >
        {navItem.label}
      </a>
    </div>
  )
}

type LinkProps = {
  className: string
  children: React.ReactNode
  href: string
}

function SubLink({ className, children, ...props }: LinkProps) {
  return (
    <a
      {...props}
      className={`${linkClassName} tw-justify-between tw-cursor-pointer ${className}`}
    >
      <div>{children}</div>
      <FontAwesomeIcon icon={faChevronRight} />
    </a>
  )
}

function SubNavItemMenu({
  subItem,
  location,
}: {
  subItem: NavItem
  location: Location
}) {
  const Component = subItem.items
    ? (props: LinkProps) => <SubLink {...props} />
    : ({ className, ...props }: LinkProps) => (
        <a {...props} className={`${linkClassName} ${className}`} />
      )
  return (
    <div className='tw-group/sub-nav'>
      <Component
        href={subItem.href}
        className={`tw-px-4 tw-py-2 ${isNavItemActive(subItem, location) ? 'active' : ''}`}
      >
        {subItem.label}
      </Component>

      {subItem.items && (
        <div
          className='tw-absolute tw-top-0 tw-left-full tw-min-h-full tw-bg-gray-100 tw-opacity-0 tw-pointer-events-none tw-transition-all tw-duration-100 -tw-ml-1 tw-rounded-r  tw-shadow-lg tw-shadow-gray-400 lg:tw-py-2 lg:group-hover/sub-nav:tw-opacity-100 lg:group-hover/sub-nav:tw-pointer-events-auto'
          style={{
            clipPath: 'inset(-30px -30px -30px 0px)',
            width: 300,
          }}
        >
          {subItem.items.map((subItem, index) => (
            <a
              href={subItem.href}
              key={index}
              className={`${linkClassName} tw-px-4 tw-py-2 ${isNavItemActive(subItem, location) ? 'active' : ''}`}
            >
              {subItem.label}
            </a>
          ))}
        </div>
      )}
    </div>
  )
}

function NavItemMenu({
  location,
  navItem,
  ...props
}: {
  location: Location
  navItem: NavItem
}) {
  const [isMobileOpen, setMobileOpen] = useState(false)
  return (
    <div
      {...props}
      className={`tw-group tw-relative ${
        isNavItemActive(navItem, location) ? 'active' : ''
      }`}
    >
      {/* Mobile */}
      <NavDropdown
        className='tw-flex tw-items-center tw-justify-between tw-py-2 lg:tw-hidden lg:tw-py-4'
        isOpen={isMobileOpen}
        onClick={() => setMobileOpen(!isMobileOpen)}
      >
        {navItem.label}
      </NavDropdown>
      {navItem.items && (
        <div
          className='tw-pl-4 tw-transition-all tw-overflow-hidden tw-duration-500'
          style={{ maxHeight: isMobileOpen ? 36 * navItem.items.length : 0 }}
        >
          {navItem.items.map((subItem, index) => (
            <a
              key={index}
              className={`${linkClassName} tw-text-sm tw-py-2 ${
                isNavItemActive(subItem, location) ? 'active' : ''
              }`}
              href={subItem.href}
            >
              {subItem.label}
            </a>
          ))}
        </div>
      )}

      {/* Desktop */}
      <div className='tw-hidden lg:tw-block'>
        <NavDropdown className='lg:tw-py-4'>{navItem.label}</NavDropdown>
      </div>
      {navItem.items && (
        <div
          className='tw-absolute tw-bg-white tw-hidden tw-left-0 tw-opacity-0 tw-pointer-events-none tw-top-full tw-transition-all tw-duration-100 tw-rounded tw-shadow-lg tw-shadow-gray-400 lg:group-hover:tw-opacity-100 lg:group-hover:tw-pointer-events-auto lg:tw-block lg:tw-py-2'
          style={{ minWidth: 230 }}
        >
          {navItem.items.map((subItem, index) => (
            <SubNavItemMenu key={index} subItem={subItem} location={location} />
          ))}
        </div>
      )}
    </div>
  )
}

// Determine if this navItem is active (if we know the location)
function isNavItemActive(navItem: NavItem, location: Location) {
  if (!location) return false
  const subItems = navItem.items || []
  const items = [navItem, ...subItems]
  return items.find((item) => item.href && item.href === location.pathname)
}

type NavbarContainerProps = {
  assetsUrl?: string
  blogUrl?: string
  isAuthenticated?: boolean
  location: Location
}

export function NavbarContainer({
  assetsUrl = '',
  blogUrl = '/blog',
  isAuthenticated,
  location,
}: NavbarContainerProps) {
  const { desktopNav, mobileNav } = useNavItems({ blogUrl })
  const [isMobileOpen, setMobileOpen] = useState(false)
  return (
    <div className='tw-h-full'>
      <div className='tw-flex tw-items-center tw-justify-between tw-h-full tw-container tw-mx-auto tw-px-6 xl:tw-max-w-[1280px]'>
        <div className='tw-flex tw-items-center tw-gap-10'>
          <a className='tw-py-2' href='/' aria-label='Blissbook'>
            <img
              alt='Blissbook Logo'
              src={`${assetsUrl}/images/logo-offblack-vector.svg`}
              className='tw-block'
              height={20}
              width={125}
            />
          </a>

          <div className='tw-hidden lg:tw-flex tw-items-center tw-gap-10'>
            {desktopNav.map((navItem, index) => (
              <NavItemView key={index} location={location} navItem={navItem} />
            ))}
          </div>
        </div>

        <div className='tw-flex tw-items-center tw-gap-2'>
          <button
            className='lg:tw-hidden u-hamburger'
            type='button'
            aria-controls='navbar-collapse'
            aria-expanded={isMobileOpen}
            aria-label='Toggle navigation'
            onClick={() => setMobileOpen(!isMobileOpen)}
          >
            <span className='u-hamburger__box'>
              <span className='u-hamburger__inner' />
            </span>
          </button>

          <div className='tw-hidden lg:tw-flex tw-items-center tw-gap-6'>
            <a
              href={isAuthenticated ? '/home' : '/sign-in'}
              style={{ fontWeight: 600, textDecoration: 'underline' }}
            >
              Sign In
            </a>

            {!isAuthenticated && (
              <a className='btn btn-primary tw-text-sm' href='/request-a-demo'>
                Get Started
              </a>
            )}
          </div>
        </div>
      </div>

      <div
        className='lg:tw-hidden tw-bg-white tw-shadow-lg tw-transition-all tw-duration-500 tw-overflow-hidden'
        style={{ maxHeight: isMobileOpen ? 1000 : 0 }}
      >
        <div className='tw-container tw-mx-auto tw-px-6 tw-overflow-y-auto'>
          {mobileNav.map((navItem, index) => (
            <NavItemView key={index} location={location} navItem={navItem} />
          ))}
        </div>
      </div>
    </div>
  )
}

// Get the class for the navbar
export const getNavbarClass = (scrollTop: number) =>
  `navbar ${scrollTop > 120 ? 'navbar-collapsed' : ''} ${scrollTop > 0 ? 'navbar-scrolled' : ''}`

type MarketingNavbarProps = NavbarContainerProps & {
  scrollTop: number
}

export function MarketingNavbar({ scrollTop, ...props }: MarketingNavbarProps) {
  return (
    <nav className={getNavbarClass(scrollTop)}>
      <NavbarContainer {...props} />
    </nav>
  )
}
