import { getColor } from '@blissbook/ui/branding'
import { useDimensions } from '@blissbook/ui/util/hooks'
import { css } from '@emotion/react'
import { useState } from 'react'

const defaultDotColor = 'blurple-500'
const defaultDotRadius = 2.5
const defaultDotSpacing = 20

export const DotsBorder = ({
  borderColor = defaultDotColor,
  borderRadius = 30,
  borderWidth = defaultDotRadius * 2,
  children,
  dotSpacing = defaultDotSpacing,
  ...props
}) => {
  // Calculate border
  const [node, setNode] = useState()
  const { height, width } = useDimensions(node) || {}
  const isDots = !!height && !!width

  // Re-calculdate dotSpacing
  const dotRadius = borderWidth / 2
  const perimeter =
    (width - borderRadius * 2 + height - borderRadius * 2) * 2 +
    Math.PI * borderRadius ** 2
  const dotCount = Math.floor(perimeter / dotSpacing)
  dotSpacing = perimeter / dotCount

  return (
    <div
      {...props}
      css={{ position: 'relative' }}
      ref={setNode}
      style={{
        borderColor: isDots ? 'transparent' : getColor(borderColor),
        borderStyle: 'dotted',
        borderWidth,
        borderRadius,
      }}
    >
      {isDots && (
        <svg
          css={css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            top: ${-dotRadius * 2}px;
            height: ${height}px;
            left: ${-dotRadius * 2}px;
            width: ${width}px;
            z-index: -1;
          `}
          role='presentation'
          viewBox={`${-dotRadius} ${-dotRadius} ${width + dotRadius * 2} ${
            height + dotRadius * 2
          }`}
        >
          <path
            d={[
              `M ${borderRadius} 0`,
              `L ${width - borderRadius} 0`,
              `A ${borderRadius} ${borderRadius} 0 0 1 ${width},${borderRadius}`,
              `L ${width} ${height - borderRadius}`,
              `A ${borderRadius} ${borderRadius} 0 0 1 ${
                width - borderRadius
              },${height}`,
              `L ${borderRadius} ${height}`,
              `A ${borderRadius} ${borderRadius} 0 0 1 0,${
                height - borderRadius
              }`,
              `L 0 ${borderRadius}`,
              `A ${borderRadius} ${borderRadius} 0 0 1 ${borderRadius},0`,
            ].join(' ')}
            fill='none'
            stroke={getColor(borderColor)}
            strokeDasharray={`0 ${dotSpacing} 0 ${dotSpacing}`}
            strokeLinecap='round'
            strokeWidth={dotRadius * 2}
          />
        </svg>
      )}

      {children}
    </div>
  )
}

const AXES = {
  horizontal: {
    coordinate: 'x',
    dimension: 'width',
  },
  vertical: {
    coordinate: 'y',
    dimension: 'height',
  },
}

const DotsLine = ({
  axisKey,
  color = defaultDotColor,
  dotCount,
  dotSpacing = defaultDotSpacing,
  dotRadius = defaultDotRadius,
  ...props
}) => {
  // Calculate length
  const axis = AXES[axisKey]
  const [node, setNode] = useState()
  const dimensions = useDimensions(node) || {}
  const length = dimensions[axis.dimension]

  // Determine dots
  let fixedLength
  let lineLength
  if (dotCount) {
    // Fixed number of dots
    lineLength = (dotCount - 1) * dotSpacing
    fixedLength = lineLength + dotRadius * 2
  } else if (length) {
    // Space out the dots perfectly
    lineLength = length - dotRadius * 2
    dotCount = Math.round(lineLength / dotSpacing) + 1
    dotSpacing = lineLength / (dotCount - 1)
    lineLength = (dotCount - 1) * dotSpacing
  } else {
    // Space out the dots to infinity and cut them off to start
    lineLength = 10000
  }

  // Determine area
  const area = { width: dotRadius * 2, height: dotRadius * 2 }
  area[axis.dimension] = fixedLength

  // Determine coordinates
  const p1 = { x: dotRadius, y: dotRadius }
  const p2 = { x: dotRadius, y: dotRadius }
  p2[axis.coordinate] = lineLength + dotRadius * 2

  return (
    <div
      {...props}
      css={{
        overflow: 'hidden',
        ...area,
      }}
      ref={setNode}
    >
      <svg className='tw-block' height='100%' width='100%' role='presentation'>
        <line
          x1={p1.x}
          y1={p1.y}
          x2={p2.x}
          y2={p2.y}
          stroke={getColor(color)}
          strokeDasharray={`0 ${dotSpacing} 0 ${dotSpacing}`}
          strokeLinecap='round'
          strokeWidth={dotRadius * 2}
        />
      </svg>
    </div>
  )
}

export const DotsHorizontal = (props) => (
  <DotsLine {...props} axisKey='horizontal' />
)

export const DotsVertical = (props) => (
  <DotsLine {...props} axisKey='vertical' />
)
