import { colors } from '@blissbook/ui/branding'
import { Col, Row } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import get from 'lodash/get'
import React from 'react'
import { DemoTextBlock, demoColors } from './text'

const defaultTransitionDuration = 500

export const chapterSectionItems = [
  { variant: 'h1', width: '30%' },
  { variant: 'p', width: '20% ' },
]

const chapterThemes = {
  branded: {
    backgroundColor: colors['red-50'],
    color: colors['red-300'],
  },
  light: {
    backgroundColor: colors['sunshine-100'],
    color: colors['sunshine-300'],
  },
}

export const DemoChapterSection = ({
  className,
  theme,
  transitionDuration = defaultTransitionDuration,
  ...props
}) => {
  const backgroundColor = get(
    chapterThemes[theme],
    'backgroundColor',
    demoColors.grayLight,
  )
  const color = get(chapterThemes[theme], 'color', demoColors.gray)

  return (
    <div
      {...props}
      className={classnames(
        'tw-flex tw-flex-col tw-items-center tw-py-10',
        className,
      )}
      css={{
        backgroundColor,
        transition: `all ${transitionDuration}ms ease`,
      }}
    >
      <DemoTextBlock
        color={color}
        css={{ transition: `all ${transitionDuration}ms ease` }}
        items={chapterSectionItems}
      />
    </div>
  )
}

const videoThemes = {
  branded: {
    backgroundColor: colors['red-50'],
    color: colors['red-300'],
  },
  light: {
    backgroundColor: colors['aqua-100'],
    color: colors['aqua-300'],
  },
}

export const DemoVideoContainer = ({
  backgroundColor,
  theme,
  transitionDuration = defaultTransitionDuration,
  ...props
}) => {
  backgroundColor =
    backgroundColor ||
    get(videoThemes[theme], 'backgroundColor', demoColors.grayLighter)
  return (
    <div
      {...props}
      css={{
        backgroundColor,
        height: 120,
        position: 'relative',
        width: 240,
        transition: `all ${transitionDuration}ms ease`,
      }}
    />
  )
}

export const DemoVideo = ({ color, ...props }) => {
  const { theme, transitionDuration = defaultTransitionDuration } = props
  color = color || get(videoThemes[theme], 'color', demoColors.gray)
  return (
    <DemoVideoContainer {...props}>
      <div className='tw-flex tw-items-center tw-justify-center tw-h-full tw-w-full'>
        <FontAwesomeIcon
          icon='play'
          css={{
            color,
            fontSize: 32,
            transition: `all ${transitionDuration}ms ease`,
          }}
        />
      </div>
    </DemoVideoContainer>
  )
}

export const letterSectionItems = [
  { variant: 'h1', width: '40%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '35%' },

  { variant: 'h2', width: '25%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '75%' },
  { variant: 'p' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '35%' },
  { variant: 'p' },
  { variant: 'p', width: '25%' },
]

const letterThemes = {
  branded: {
    photoColor: colors['red-50'],
    signatureColor: colors['red-300'],
  },
  light: {
    photoColor: colors['aqua-100'],
    signatureColor: colors['aqua-300'],
  },
}

export const DemoLetterSection = ({
  className,
  theme,
  transitionDuration = defaultTransitionDuration,
  video,
  ...props
}) => {
  const photoColor = get(
    letterThemes[theme],
    'photoColor',
    demoColors.grayLighter,
  )
  const photoSize = 80
  const signatureColor = get(
    letterThemes[theme],
    'signatureColor',
    demoColors.grayLighter,
  )
  return (
    <div {...props} className={classnames('tw-px-14 tw-py-6', className)}>
      <Row>
        <Col xs={8}>
          <DemoTextBlock
            items={letterSectionItems}
            theme={theme}
            transitionDuration={transitionDuration}
          />
          <FontAwesomeIcon
            icon='signature'
            className='tw-mt-1'
            css={{
              color: signatureColor,
              fontSize: 24,
              transition: `all ${transitionDuration}ms ease`,
            }}
          />
        </Col>
        <Col xs={4}>
          <div
            className='tw-mt-2'
            css={{
              background: photoColor,
              borderRadius: '50%',
              height: photoSize,
              transition: `all ${transitionDuration}ms ease`,
              width: photoSize,
            }}
          />
        </Col>
      </Row>

      {video && (
        <div className='tw-flex tw-justify-center tw-mt-4'>{video}</div>
      )}
    </div>
  )
}

export const textSectionItems = [
  { variant: 'h1', width: '40%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '35%' },

  { variant: 'h2', width: '25%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '75%' },
  { variant: 'p' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '75%' },
  { variant: 'p' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '35%' },
  { variant: 'li', width: '10%' },
  { variant: 'li', width: '15%' },
  { variant: 'li', width: '14%' },
  { variant: 'li', width: '13%' },

  { variant: 'h2', width: '25%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '75%' },
  { variant: 'p' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '35%' },
  { variant: 'p' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '15%' },
  { variant: 'p' },
  { variant: 'p', width: '94%' },
  { variant: 'p', width: '40%' },

  { variant: 'h2', width: '22%' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '83%' },
  { variant: 'p' },
  { variant: 'p', width: '35%' },
  { variant: 'li', width: '20%' },
  { variant: 'li', width: '28%' },
  { variant: 'li', width: '24%' },
  { variant: 'li', width: '23%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '88%' },

  { variant: 'h1', width: '50%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '35%' },
  { variant: 'p' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '94%' },
  { variant: 'p', width: '75%' },

  { variant: 'h2', width: '22%' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '83%' },
  { variant: 'p' },
  { variant: 'p', width: '35%' },
  { variant: 'li', width: '20%' },
  { variant: 'li', width: '28%' },
  { variant: 'li', width: '24%' },
  { variant: 'li', width: '23%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '88%' },

  { variant: 'h2', width: '25%' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '75%' },
  { variant: 'p' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '35%' },
  { variant: 'li', width: '10%' },
  { variant: 'li', width: '15%' },
  { variant: 'li', width: '14%' },
  { variant: 'li', width: '13%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '75%' },
  { variant: 'p' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '75%' },

  { variant: 'h1', width: '50%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '35%' },
  { variant: 'p' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '94%' },
  { variant: 'p', width: '75%' },

  { variant: 'h2', width: '22%' },
  { variant: 'p', width: '93%' },
  { variant: 'p', width: '83%' },
  { variant: 'p' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '88%' },
]

export const DemoTextSection = ({
  className,
  items = textSectionItems,
  theme,
  ...props
}) => (
  <div {...props} className={classnames('tw-px-10 tw-py-4', className)}>
    <DemoTextBlock items={items} theme={theme} />
  </div>
)

const DEMO_SECTIONS = {
  chapter: DemoChapterSection,
  letter: DemoLetterSection,
  text: DemoTextSection,
}

export const DemoSection = React.memo(({ type, ...props }) => {
  const Component = DEMO_SECTIONS[type]
  return <Component {...props} />
})

export const DemoHandbook = React.memo(({ sections, theme, ...props }) => (
  <div {...props}>
    {sections.map((section, index) => (
      <DemoSection key={index} theme={theme} {...section} />
    ))}
  </div>
))
