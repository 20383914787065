import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { find, findIndex } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import tinycolor from 'tinycolor2'

import { colors } from '@blissbook/ui/branding'
import {
  Carousel,
  ClipboardText,
  ForwardLink,
  Link,
  Shape,
} from '@blissbook/ui/lib'
import { MarketingLayout, Section, Typography } from '../components'
import CallToActionSection from './sections/CallToActionSection'

import config from '@blissbook/ui-config'
const { assetsUrl = '' } = config
const imgFolder =
  assetsUrl + '/images/marketing/resources/awkward-hr-conversations/'

const pageAttr = {
  title: 'Avoid Awkward HR Conversations: Comics by Blissbook',
  titlePrefix: 'Avoid Awkward HR Conversations',
  description: 'Fun comics for employees who violate HR policies',
}

const shareConfig = {
  twitter: {
    defaultMessage:
      'Want an easy button for employees who violate #hr policies? @blissbookapp made fun cards that have you covered. Check this one out!',
    card: 'summary_large_image',
    site: '@blissbookapp',
    creator: '@blissbookapp',
  },
  email: {
    subject: 'Check out these HR comics',
    body: `Don't you wish you could just hand a misbehaving employee a card like this instead of having an awkward conversation?\n\n`,
  },
}

const assets = {
  cardFull: imgFolder + 'card-full.png',
  download: assetsUrl + 'assets/awkward-HR-convos-Blissbook.pdf',
  cards: [
    {
      url: imgFolder + 'sleeping-with-mask.jpg',
      title: `${pageAttr.titlePrefix}: Mask Policy Violation`,
      slug: 'sleeping-with-mask',
      description: `Woman sleeping at her desk with mask that's for her nose/mouth over her eyes instead, with advice on how to wear it properly.`,
    },
    {
      url: imgFolder + 'office-dating.jpg',
      title: `${pageAttr.titlePrefix}: Office Dating Policy Violation`,
      slug: 'office-dating',
      description:
        'Cupid being dragged out of office by security with a joke about finding love outside the office.',
    },
    {
      url: imgFolder + 'support-animal.jpg',
      title: `${pageAttr.titlePrefix}: Emotional Support Animal Policy Violation`,
      slug: 'support-animal',
      description:
        'Man hugging a plant, with advice that plants can provide emotional support (rather than a ferret).',
    },
    {
      url: imgFolder + 'desk-by-bathroom.jpg',
      title: `${pageAttr.titlePrefix}: Desk by Bathroom`,
      slug: 'desk-by-bathroom',
      description: `Man with a new desk by the bathroom, with advice that it's an opportunity to network with colleagues.`,
    },
    {
      url: imgFolder + 'foul-language.jpg',
      title: `${pageAttr.titlePrefix}: Foul Language`,
      slug: 'foul-language',
      description:
        'Woman presenting to new hires, using foul language, with advice on more work-approriate swear words she could use.',
    },
    {
      url: imgFolder + 'bad-breath.jpg',
      title: `${pageAttr.titlePrefix}: Personal Hygiene Policy Violation`,
      slug: 'bad-breath',
      description:
        'Tongue-in-cheek dragon trophy with a coupon code for free mints, to give to an employee with bad breath.',
    },
    {
      url: imgFolder + 'dress-code.jpg',
      title: `${pageAttr.titlePrefix}: Dress Code Policy Violation`,
      slug: 'dress-code',
      description:
        'Man in bathrobe by water cooler, being told his casual friday outfit is too casual.',
    },
    {
      url: imgFolder + 'remote-work-bathroom.jpg',
      title: `${pageAttr.titlePrefix}: Remote Work Policy Etiquette Advice`,
      slug: 'remote-work-bathroom',
      description:
        'Man on toilet working, on video chat, with advice that remote work-anywhere policy should not be taken literally.',
    },
    {
      url: imgFolder + 'punctuality.jpg',
      title: `${pageAttr.titlePrefix}: Attendance Policy Violation`,
      slug: 'punctuality',
      description: `Fridge with Grandma's Hangover Recipe stuck to it, with advice on how to come to work on time.`,
    },
    {
      url: imgFolder + 'social-media.jpg',
      title: `${pageAttr.titlePrefix}: Social Media`,
      slug: 'social-media',
      description:
        'Woman using various social media apps on her computer and phone, with coupon code for free membership to Employee Assistance Program.',
    },
    {
      url: imgFolder + 'promotions.jpg',
      title: `${pageAttr.titlePrefix}: Promotions`,
      slug: 'promotions',
      description: `Open gift box and mug that says "Future VP" on it, with advice that you need to work here for more than 3 months to get a promotion.`,
    },
    {
      url: imgFolder + 'pet-bereavement.jpg',
      title: `${pageAttr.titlePrefix}: Pet Bereavement Policy Advice`,
      slug: 'pet-bereavement',
      description: `Coupon for free pet taxidermy, showing a mounted taxidermy dog head with 'Cody, best friend ever' plaque, with plea to come back to work.`,
    },
    {
      url: imgFolder + 'video-chat-cat.jpg',
      title: `${pageAttr.titlePrefix}: Video Chat Poor Etiquette`,
      slug: 'video-chat-cat',
      description: `Computer display showing a video chat app with woman and her cat on screen, with the cat's butt pointed towards the camera, and advice on video chat etiquette.`,
    },
  ],
}

const transitionDelayMs = 15000
const backgroundColorFrom = colors.white
const backgroundColorTo = tinycolor(colors['aqua-100'])
  .setAlpha(0.5)
  .toRgbString()

const popupParams = {
  facebook: 'width=550,height=650',
  linkedin: 'width=550,height=650',
}

const navButtonStyle = {
  fontSize: '22px',
  lineHeight: '20px',
  padding: '12px',
  height: '50px',
  width: '50px',
  borderRadius: '25px',
}
const dotProps = {
  width: '6px',
  height: '6px',
  style: { margin: '0 3px' },
  fill: colors['gray-300'],
}
const dotActiveProps = {
  width: '10px',
  height: '10px',
  style: { margin: '0 3px' },
  fill: colors['sunshine-500'],
}
const shareBrandProps = {
  size: '2x',
  style: {
    verticalAlign: 'middle',
    height: '36px',
  },
}
const shareLinkProps = {
  style: {
    verticalAlign: 'middle',
    height: '36px',
  },
}
const brandColors = {
  linkedin: '#0072B1',
  twitter: '#1DA1F2',
  facebook: '#4267B2',
}

function cardUrl(card, location) {
  return `${config.origin}${location.pathname}${
    card.slug ? `?card=${card.slug}` : ''
  }`
}

function cardImgUrl(card) {
  return `${config.origin}${card.url}`
}

function getPageMeta(cardName, location) {
  const url = `${config.origin}${location.pathname}`
  if (cardName) {
    const card = find(assets.cards, (c) => c.slug === cardName)
    if (card) {
      return [
        { property: 'og:title', content: card.title },
        { property: 'og:image', content: cardImgUrl(card) },
        { property: 'og:description', content: card.description },
        { property: 'og:url', content: cardUrl(card, location) },
        { property: 'twitter:card', content: shareConfig.twitter.card },
        { property: 'twitter:site', content: shareConfig.twitter.site },
        { property: 'twitter:creator', content: shareConfig.twitter.creator },
      ]
    }
  }
  return [
    { property: 'og:title', content: pageAttr.title },
    { property: 'og:image', content: assets.cardFull },
    { property: 'og:description', content: pageAttr.description },
    { property: 'og:url', content: url },
  ]
}

function shareLink(type, cardIndex, location) {
  const card = assets.cards[cardIndex]
  const url = encodeURIComponent(cardUrl(card, location))
  if (type === 'linkedin') {
    return `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
  }
  if (type === 'twitter') {
    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      shareConfig.twitter.defaultMessage,
    )}&url=${url}`
  }
  if (type === 'facebook') {
    return `https://www.facebook.com/sharer/sharer.php?u=${url}`
  }
  if (type === 'clipboard') {
    return cardUrl(card, location)
  }
  return `mailto:?subject=${encodeURIComponent(
    shareConfig.email.subject,
  )}&body=${encodeURIComponent(shareConfig.email.body)}${url}`
}

export default () => {
  const location = useLocation()

  const cardParam = new URLSearchParams(location.search).get('card')

  const [index, setIndex] = useState(
    cardParam ? findIndex(assets.cards, (c) => c.slug === cardParam) : 0,
  )
  const [pause, setPause] = useState(false)
  const prev = () => setIndex(index <= 0 ? assets.cards.length - 1 : index - 1)
  const next = () => setIndex(index >= assets.cards.length - 1 ? 0 : index + 1)
  const advance = () => setIndex((index + 1) % assets.cards.length)

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (!pause) advance()
    }, transitionDelayMs)
    return () => clearTimeout(timerId)
  }, [index, pause])

  return (
    <MarketingLayout
      title={pageAttr.title}
      description={pageAttr.description}
      className='awkward-hr-conversations'
      metaProps={getPageMeta(cardParam, location)}
    >
      <Section className='container'>
        <Shape
          className='lg:tw-block tw-hidden'
          type='wedge'
          color='aqua-100'
          fill={true}
          size={430}
          rotate={180}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        />
        <div className='row'>
          <div className='col-lg-7 col-md-12'>
            <Typography variant='h4' className='tw-mt-8'>
              Easy Button Alert!
            </Typography>
            <Typography
              variant='h1'
              style={{
                margin: '30px 0',
              }}
            >
              Avoid awkward HR conversations.
            </Typography>
            <Typography
              variant='h2'
              style={{ fontWeight: '300', fontSize: '26px' }}
            >
              Wouldn’t it be great if, instead of having an awkward
              conversation, you could give a misguided employee a card that
              fixes everything (in a caring way)?
            </Typography>
            <p
              className='headline'
              style={{ lineHeight: '40px', marginBottom: '5px' }}
            >
              <em style={{ fontSize: '30px' }}>That’s these cards!</em>
            </p>
            <p>
              <small style={{ color: colors['gray-400'] }}>
                *Now featuring pandemic policies!
              </small>
            </p>
          </div>
          <div className='col-lg-4 offset-lg-1 text-center'>
            <img
              aria-label='Card Full'
              src={assets.cardFull}
              className='lg:tw-block tw-hidden tw-mt-10'
              style={{
                height: '400px',
              }}
            />
          </div>
        </div>
      </Section>

      <Section
        className='tw-pt-8 tw-pb-6'
        style={{
          backgroundImage: `linear-gradient(180deg, ${backgroundColorFrom} 0%, ${backgroundColorTo} 100%)`,
        }}
      >
        <div className='container'>
          <div
            className='no-auto-advance'
            onMouseOver={() => setPause(true)}
            onMouseOut={() => setPause(false)}
            onFocus={() => setPause(true)}
            onBlur={() => setPause(false)}
          >
            <div className='dots text-center tw-my-8'>
              {assets.cards.map((_card, cardIndex) => {
                const props = cardIndex === index ? dotActiveProps : dotProps
                return (
                  <svg
                    role='presentation'
                    {...props}
                    key={cardIndex}
                    viewBox='0 0 100 100'
                    onClick={() => setIndex(cardIndex)}
                  >
                    <circle cx='50' cy='50' r='50' />
                  </svg>
                )
              })}
            </div>
            <div className='row'>
              <div
                className='col-md-2 col-6 md:tw-my-auto tw-mt-8 text-center order-1 order-md-0'
                style={{ zIndex: 2 }}
              >
                {
                  <button
                    onClick={prev}
                    className='btn btn-primary'
                    style={navButtonStyle}
                    type='button'
                  >
                    <FontAwesomeIcon icon='arrow-left' />
                  </button>
                }
              </div>
              <div className='col-md-8 col-12 order-0 order-md-1 tw-p-0 box-shadow'>
                <Carousel.Component activeIndex={index} fade>
                  {assets.cards.map((card, cardIndex) => (
                    <Carousel.Item key={cardIndex}>
                      <img
                        style={{ maxWidth: '100%' }}
                        src={card.url}
                        title={card.title}
                        alt={card.description}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel.Component>
              </div>
              <div className='col-md-2 col-6 md:tw-my-auto tw-mt-8 text-center order-2'>
                {
                  <button
                    onClick={next}
                    className='btn btn-primary'
                    style={navButtonStyle}
                    type='button'
                  >
                    <FontAwesomeIcon icon='arrow-right' />
                  </button>
                }
              </div>
            </div>

            <div className='share tw-mt-12'>
              <Typography variant='p1' className='text-center tw-w-full'>
                <span style={{ verticalAlign: 'middle' }}>
                  Share this card:{' '}
                </span>
                <span style={{ whiteSpace: 'nowrap' }}>
                  <button
                    className='btn-link'
                    style={{ marginLeft: '12px' }}
                    onClick={() =>
                      window.open(
                        shareLink('linkedin', index, location),
                        'linkedin',
                        popupParams.linkedin,
                      )
                    }
                    type='button'
                  >
                    <FontAwesomeIcon
                      {...shareBrandProps}
                      color={brandColors.linkedin}
                      icon={['fab', 'linkedin']}
                    />
                  </button>
                  <a
                    rel='noopener noreferrer'
                    target='_blank'
                    style={{ marginLeft: '12px' }}
                    href={shareLink('twitter', index, location)}
                  >
                    <FontAwesomeIcon
                      {...shareBrandProps}
                      color={brandColors.twitter}
                      icon={['fab', 'twitter-square']}
                    />
                  </a>
                  <button
                    className='btn-link'
                    type='button'
                    style={{ marginLeft: '12px' }}
                    onClick={() =>
                      window.open(
                        shareLink('facebook', index, location),
                        'facebook',
                        popupParams.facebook,
                      )
                    }
                  >
                    <FontAwesomeIcon
                      {...shareBrandProps}
                      color={brandColors.facebook}
                      icon={['fab', 'facebook-square']}
                    />
                  </button>
                  <ClipboardText
                    style={{ marginLeft: '18px' }}
                    value={shareLink('clipboard', index, location)}
                  >
                    <FontAwesomeIcon
                      {...shareLinkProps}
                      color={colors.black}
                      icon={['far', 'link']}
                    />
                  </ClipboardText>
                  <a
                    rel='noopener noreferrer'
                    target='_blank'
                    style={{ marginLeft: '18px' }}
                    href={shareLink('email', index, location)}
                  >
                    <FontAwesomeIcon
                      {...shareLinkProps}
                      color={colors.black}
                      icon={['far', 'envelope']}
                    />
                  </a>
                </span>
              </Typography>
            </div>
          </div>

          <div className='download'>
            <Typography variant='p2' className='text-center tw-w-full'>
              <Link className='btn btn-primary' href={assets.download} download>
                <strong style={{ fontSize: '110%' }}>
                  Download the Whole Set
                </strong>
              </Link>
            </Typography>
          </div>

          <div className='disclaimer'>
            <Typography
              variant='muted'
              className='text-center tw-w-full'
              style={{ margin: '40px auto 0', maxWidth: '530px' }}
            >
              <em style={{ fontSize: '18px' }}>
                Note: These cards are for fun. Blissbook is not responsible if
                you share one with an employee instead of talking to them!
              </em>
            </Typography>
            <Typography
              variant='muted'
              className='text-center tw-w-full'
              style={{ margin: '25px auto', maxWidth: '530px' }}
            >
              <em style={{ fontSize: '18px' }}>
                Have an idea for a card?{' '}
                <a href='mailto:support@blissbook.com?subject=I have an idea for an awkward HR conversation card'>
                  Let us know!
                </a>
              </em>
            </Typography>
          </div>
        </div>
      </Section>

      <CallToActionSection
        title={
          <Fragment>
            Enjoyed these ways to protect your company <em>and</em> show
            employees they're valued?
          </Fragment>
        }
        titleVariant='h2'
        description="If you're looking for help doing exactly this when it comes to your employee handbook and/or policy management, let us know!"
      >
        <Link className='btn btn-primary tw-mr-6' to='/request-a-demo'>
          Request a Demo
        </Link>
        <ForwardLink className='tw-block tw-my-6' to='/'>
          Learn More
        </ForwardLink>
      </CallToActionSection>
    </MarketingLayout>
  )
}
