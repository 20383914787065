// IMPORTANT: THIS FILE IS IN TWO PROJECTS. TO MODIFY THIS FILE:
// Go to the blissbook project
// Make changes
// Copy the changes to the blissbook-marketing project
// Find and replace "tw-" with ""
import { faCat } from '@fortawesome/pro-solid-svg-icons/faCat'
import { faDog } from '@fortawesome/pro-solid-svg-icons/faDog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'

export type FooterProps = {
  assetsUrl?: string
  blogUrl?: string
}

function FooterCategory({
  children,
  label,
}: {
  children: React.ReactNode
  label: string
}) {
  return (
    <div className='tw-flex tw-flex-col tw-gap-2'>
      <div className='tw-font-bold tw-text-sm tw-text-white tw-tracking-[0.2em] tw-uppercase tw-mb-2'>
        {label}
      </div>

      {children}
    </div>
  )
}

function FooterLink({
  children,
  href,
}: {
  children: React.ReactNode
  href: string
}) {
  return (
    <a className='tw-block tw-text-sunshine-300' href={href}>
      {children}
    </a>
  )
}

export function FooterContainer({
  assetsUrl = '',
  blogUrl = '/blog',
}: FooterProps) {
  return (
    <div className='tw-container tw-mx-auto tw-grid tw-grid-cols-2 tw-gap-4 tw-px-6 tw-py-10 md:tw-grid-cols-4 lg:tw-flex lg:tw-justify-between xl:tw-max-w-[1280px]'>
      <FooterCategory label='Solutions'>
        <FooterLink href='/employee-handbook-software'>
          Online Employee Handbook
        </FooterLink>
        <FooterLink href='/enterprise-policy-management'>
          Enterprise Policy Management
        </FooterLink>
        <FooterLink href='/employee-handbook-hr-policy-content'>
          Handbook Content Services
        </FooterLink>
        <FooterLink href='/legal-compliance'>SOC 2 Audit Readiness</FooterLink>
        <FooterLink href='/branded-employee-handbook'>
          Branded Employee Handbook
        </FooterLink>
        <FooterLink href='/employee-handbook-management'>
          PEOs and Agencies
        </FooterLink>
      </FooterCategory>

      <FooterCategory label='Capabilities'>
        <FooterLink href='/personalized-handbooks'>
          Personalized Handbooks
        </FooterLink>
        <FooterLink href='/policy-communication'>
          Policy Communication
        </FooterLink>
        <FooterLink href='/manager-participation'>
          Manager Participation
        </FooterLink>
      </FooterCategory>

      <FooterCategory label='Resources'>
        <FooterLink href={blogUrl}>Blog</FooterLink>
        <FooterLink href='/request-a-demo'>Request a Demo</FooterLink>
        <FooterLink href='/newsletter'>Newsletter</FooterLink>
        <FooterLink href='/how-to-write-a-culture-first-employee-handbook'>
          Culture Handbooks
        </FooterLink>
        <FooterLink href='/how-to-write-policies-procedures'>
          How to Write Policies
        </FooterLink>
        <FooterLink href='/awkward-hr-conversations'>
          Awkward HR Convos
        </FooterLink>
      </FooterCategory>

      <FooterCategory label='Support'>
        <FooterLink href='/about'>About</FooterLink>
        <FooterLink href='https://help.blissbook.com/'>Help Center</FooterLink>
        <FooterLink href='mailto:support@blissbook.com'>Email Us</FooterLink>
        <FooterLink href='/pricing'>Pricing</FooterLink>
        <FooterLink href='https://status.blissbook.com/'>Status</FooterLink>
        <FooterLink href='https://twitter.com/blissbookapp'>Twitter</FooterLink>
      </FooterCategory>

      <div className='tw-col-span-2 md:tw-col-span-4 tw-flex tw-flex-col tw-gap-3 tw-text-white tw-text-xs'>
        <div className='tw-flex tw-items-center tw-justify-center'>
          <img
            alt='rainbow'
            role='presentation'
            src={`${assetsUrl}/images/monogram-rainbow.svg`}
          />
        </div>

        <div className='tw-flex tw-items-center tw-justify-center'>
          Built alongside&nbsp;
          <FontAwesomeIcon icon={faCat} />s &amp;&nbsp;
          <FontAwesomeIcon icon={faDog} />s in the good ol’ USA
        </div>

        <div className='tw-flex tw-items-center tw-justify-center'>
          <span>© {new Date().getFullYear()} Blissbook</span>
          <span className='tw-mx-4'>|</span>
          <a className='tw-text-white' href='/terms'>
            Terms
          </a>
          <span className='tw-mx-4'>|</span>
          <a className='tw-text-white' href='/privacy-policy'>
            Privacy
          </a>
        </div>
      </div>
    </div>
  )
}

export const marketingFooterClassName = 'tw-bg-blurple-500 tw-text-sunshine-300'

export function MarketingFooter(props: FooterProps) {
  return (
    <footer className={marketingFooterClassName}>
      <FooterContainer {...props} />
    </footer>
  )
}
