import { MailtoSupportLink } from '@blissbook/ui/lib'
import {
  HeadingDivider,
  LegalHeader,
  LegalIndent,
  LegalLayout,
  LineDivider,
} from '../components'

export default () => (
  <LegalLayout
    title='Blissbook Terms of Use'
    description='Using Blissbook for your online employee handbook means there are some some basic rules you must follow to ensure the service remains valuable for all of our customers.'
  >
    <LegalHeader
      heading='Read Through Our'
      lastUpdated='May 26, 2023'
      title='Terms &amp; Conditions.'
    />
    <p>Thanks for using Blissbook.</p>
    <p>
      These terms and conditions form an agreement between you and Rocket Whale
      Products, LLC, a Delaware limited liability corporation (“Blissbook”).
      Your use of the services (the “Services”) provided on
      https://blissbook.com or another website bearing these Terms (each a
      “Website”) is subject to these terms and conditions. Unless otherwise
      agreed in writing with Blissbook, your agreement with us includes the
      terms and conditions set out in this document and the terms of any legal
      notices applicable to the Services which we may make available to you
      (collectively, the “Terms”).
    </p>
    <p>
      In these Terms, “you” or “Client” means the persons or employees to whom
      we offered access to the Website or use of the Services. Unless otherwise
      stated, “we” “us” or “our” will refer collectively to Blissbook and its
      subsidiaries, affiliates, directors, officers, stockholders, employees,
      agents, and contractors. Each of the parties hereto may be referred to as
      a “Party” individually and as “Parties” collectively.
    </p>
    <LineDivider />
    <HeadingDivider variant='h2'>1. Accepting these Terms</HeadingDivider>
    <p>
      Your browsing of the Website and/or use of the Services constitute
      acceptances of these Terms. Do not use the Services or access the Website
      if you do not agree to these Terms. We may revise and update these Terms
      at any time without notice by posting such revised or updated Terms on
      this Website and updating the “Last Updated” date above. Any changes to
      these Terms will become effective upon posting such revisions or updates.
      Your continued usage of the Services or access of the Website will mean
      that you accept the then-current Terms at the time of such usage or
      access, as applicable.
    </p>
    <p>
      You may not use the Services or access the Website and may not accept
      these Terms if (a) you are not of legal age to form a binding contract
      with us, or (b) you are a person barred from receiving the Services or
      accessing the Website under the laws of the United States or other
      countries including the country in which you reside or from which you use
      the Services and/or access the Website.
    </p>
    <p>
      These Terms shall also apply to your use of any reports, forms,
      applications, data, documents, or other materials available via the
      Website or the Services. If you have contributed or provided Client Data
      (as defined below) to the Services or the Website, you acknowledge and
      agree that such Client Data may be subject to the terms set forth in a
      master services (or similar) agreement, as the case may be, between the
      Client and Blissbook (the “MSA”).
    </p>
    <p>
      In the event of a conflict or inconsistency between these Terms and an
      MSA, the MSA will govern and control. If you provide Client Data
      individually and you have not executed an MSA with Blissbook, your
      relationship with Blissbook is governed by these Terms.
    </p>
    <p>
      By purchasing the Services, you agree to our pricing policies in effect at
      the time of purchase. Unless otherwise agreed between Client and
      Blissbook, payment for the services is due on the date your subscription
      is activated. See https://blissbook.com/pricing for more information on
      our pricing and payment terms.
    </p>
    <HeadingDivider variant='h2'>2. Definitions</HeadingDivider>
    <p>
      “Confidential Information”: non-public information of value to its owner,
      (a) that is marked confidential, restricted or proprietary by the
      disclosing Party (or by any other person to whom such disclosing Party has
      an obligation of confidence), (b) is disclosed under circumstances where
      the receiving Party either knew or should have known that the information
      qualified as Confidential Information, and regardless of whether the
      Confidential Information is in written, graphic, computer, recorded,
      photographic or any machine-readable form or is orally conveyed to or is
      otherwise known by receiving Party, or (c) is Proprietary Information.
      Confidential Information does not include information (a) that the
      receiving Party rightfully possessed at the time of the disclosure by the
      disclosing Party or rightfully received from a third party without
      restriction on disclosure, (b) that the receiving Party develops
      independently and without reference to any Confidential Information of the
      disclosing Party, or (c) that is or becomes available to the public
      through no act or omission of the receiving Party.
    </p>
    <p>
      “Client Data”: any data, material or other information that originated
      from you, a Client, or a User and can be accessed by Blissbook, or is in
      Blissbook’s possession or control, because of the provision of the
      Services by Blissbook to you. Client Data may include both Personal
      Information (as defined in our Privacy Policy) and non-personally
      identifiable information (e.g., aggregation/compilation of Client Data),
      but shall not include any data which is publicly available. Client Data
      shall include but not be limited to any Personal Information, Client and
      User profiles, records, databases, information regarding page views or
      clickstreams, information about Client’s or a User’s activity, and
      summaries thereof, generated, developed, or created by the interaction
      with and use by Client or User(s) of the Services, and all other
      information about the Client or User(s) that is machine generated or
      generated by a Client’s or User’s use of the Services.
    </p>
    <p>
      “Documentation”: the materials related to the Services, in any format,
      including but not limited to any manuals, performance standards, technical
      manuals, training materials, support request responses, or other materials
      currently in use and/or as provided by Blissbook from time to time.
      “Intellectual Property Rights”: any and all now known or hereafter known
      tangible and intangible patent, copyright, moral, trademark, or other
      intellectual property rights, whether arising by operation of law,
      contract, license, or otherwise, and all registrations, initial
      applications, renewals, extensions, continuations, or divisions or
      reissues thereof, appertaining to a Party’s Proprietary Information, as of
      the Effective Date or thereafter in force (including any license or other
      rights in the foregoing).
    </p>
    <p>
      “Proprietary Information”: collectively, and without regard to form, (i)
      any third-party information which a Party has agreed to treat as
      confidential, (ii) information regulated by applicable state or federal
      law concerning disclosure or use, and (iii) Confidential Information;
      provided, however, that without granting any right or license, the Parties
      agree that no obligation of nondisclosure or nonuse under these Terms
      shall apply to any information (with the burden of proof upon the
      receiving Party): (a) that the receiving Party rightfully possessed at the
      time of the disclosure by the disclosing Party or rightfully received from
      a third party without restriction on disclosure, (b) that the receiving
      Party develops independently and without reference to any Proprietary
      Information of the disclosing Party or (c) that is or becomes available to
      the public through no act or omission of the receiving Party.
    </p>
    <p>
      “Services”: the services described in the Services Schedule and Section
      3.2, including without limitation access to the Services via a
      downloadable mobile application.
    </p>
    <p>
      “Term”: the term for which you request and pay for the Services (“Initial
      Term”) and each respective monthly or yearly (according to your plan)
      renewal period following the end of the Initial Term, each of which shall
      come into effect automatically and consecutively unless these Terms are
      prior terminated in accordance with Section 7 (each, a “Renewal Term”).
    </p>
    <p>
      “Users”: Client personnel authorized to use or access the Services. Users
      may be added, archived, deleted, or modified by Blissbook upon written
      request from Client or by Client within the administrative section of the
      Services.
    </p>
    <p>
      “Website”: the user interface, functionality, data, and information made
      available on pages, operated, and maintained by or for Blissbook at such
      web address(es) as Blissbook may select from time to time.
    </p>
    <HeadingDivider variant='h2'>
      3. Restriction on Use; Obligations of Client and Users
    </HeadingDivider>
    <p>
      3.1 Client agrees that: (i) Client will not allow access to or use of the
      Services (or any portion thereof) by anyone other than its authorized
      Users or for any purpose prohibited under these Terms; (ii) Client agrees
      to keep confidential and not disclose to any third parties any usernames,
      passwords and/or account numbers associated with the Services; (iii)
      Client will ensure that all of its authorized Users comply with these
      Terms; (iv) Client will be responsible for managing Users within the
      Services, uploading, creating and importing Client Data to be used in the
      Services, and use of the Services (in accordance with Blissbook’s
      instructions); and (v) Client will be responsible for any violation of
      these Terms by any of its authorized Users, and for any unauthorized use
      of the usernames, passwords, or other security credentials associated with
      Client’s account.
    </p>
    <p>
      Client further agrees to notify Blissbook promptly if Client becomes aware
      of any loss or theft or unauthorized use of the Services, or of any other
      suspected or alleged violation of these Terms, and Client will reasonably
      cooperate with Blissbook in any investigation or enforcement efforts.
      Without limiting any of Blissbook’s other rights and remedies, Blissbook
      may suspend or terminate any authorized User’s access to the Services upon
      notice to Client in the event Blissbook determines that the authorized
      User has violated these Terms. Blissbook shall have no obligation to
      provide Client with access to the internet and Client, at its own cost and
      expense, is solely responsible for acquiring, purchasing, installing,
      configuring, and maintaining the internet system requirements and any
      other software or hardware needed to access and use the Services.
    </p>
    <p>
      3.2 During the Term and subject to your performance of these Terms,
      Blissbook grants to you the non-exclusive, term-limited, non-assignable,
      non-sublicensable, revocable right to use the Services and Documentation
      (including a limited, nontransferable license to use any mobile
      application or mobile website provided by Blissbook to Client and its
      Users to access the Services), subject to the terms and conditions set
      forth herein. Except as expressly set forth herein, no express or implied
      license or right of any kind is granted to Client regarding the Services,
      including any right to obtain possession of any source code or other
      technical material relating to the Services, or regarding Blissbook’s
      patents, trademarks, or service marks. All rights not expressly granted to
      Client are reserved to Blissbook.
    </p>
    <p>
      Blissbook reserves the right, in its sole discretion and without prior
      notice to Client, to modify, discontinue, add, adapt, or otherwise change
      any design or specification of the Services; provided that such
      modifications shall not intentionally materially diminish the
      functionality or security of the Services, and any modifications that
      unintentionally diminish the functionality or security of the Services
      must be repaired in a timely manner.
    </p>
    <p>
      3.3 Client agrees to and is responsible for its (and its Users’) continual
      compliance with these Terms, Blissbook’s Privacy Policy located at
      https://blissbook.com/privacy-policy, and, if Client is located in the EEA
      or providing Blissbook with personal information of data subjects in the
      EEA, Blissbook’s Data Processing Addendum located at
      https://blissbook.com/dpa. Client shall maintain the security of the
      Services in accordance with these Terms, the Blissbook Privacy Policy, and
      other guidelines released by Blissbook, all as issued and amended from
      time to time.
    </p>
    <p>
      Client shall not, and will not permit others, including its authorized
      Users, to engage in activities prohibited by Blissbook (and will
      investigate any alleged instances thereof), including, without limitation:
      (a) intentionally accessing data not intended for Client’s use; (b)
      logging into an account for which Client is not authorized; (c) attempting
      to probe, scan, disassemble, decompile, reverse engineer, or test the
      vulnerability of the Services; (d) attempting to breach security or
      authentication measures without proper authorization or otherwise
      interfere with the Services; (e) attempting to discover or reproduce
      source or object code for any part of the Services, or any Blissbook IP;
      (f) taking any action in order to obtain Services or other Blissbook
      services to which Client is not entitled; (g) making the Services
      available for use in a time-sharing, service bureau or similar
      environment; (h) using any part of the Services or any Blissbook IP to
      create, invent or develop any computer program or other invention, work or
      device that performs, replicates, or utilizes the same or substantially
      similar functions as the Services (other than as developed by Client for
      internal use only); or (i) using the Services to create, transmit,
      distribute, or store material that (i) violates the Intellectual Property
      Rights of third parties, (ii) violates the privacy, publicity, or other
      personal rights of third parties, (iii) impairs the privacy of
      communications of any party, or (iv) assists or permits any persons in
      engaging in any of the activities described above.
    </p>
    <p>
      3.4 Client shall use the Services created by Blissbook for lawful purposes
      only and shall not knowingly transmit through Blissbook or ask Blissbook
      to transmit any material which violates or infringes upon the rights of
      others or is obscene, indecent, racist, defamatory, or otherwise
      actionable under any law.
    </p>
    <p>
      3.5 To use the Website or the Services, you may be required to provide
      information about yourself as part of the user/registration process for
      the Website and Services. You agree that, (a) any
      registration/user-related information you give to Blissbook will be
      accurate, correct, and current, (b) you are responsible for maintaining
      the confidentiality of user names and passwords associated with any
      account you use to access the Website or the Services, (c) you will be
      responsible to us for all activities that occur under your account and/or
      user name, and (d) if you become aware of any unauthorized use of your
      account, user name and/or password, you agree to notify us immediately.
    </p>
    <HeadingDivider variant='h2'>4. Support </HeadingDivider>
    <p>
      Client may request support from Blissbook by emailing
      support@blissbook.com, by following prompts on the Website, or by
      requesting help in Client’s account.
    </p>
    <HeadingDivider variant='h2'>5. Ownership; Confidentiality</HeadingDivider>
    <h3>5.1 Blissbook Ownership</h3>
    <p>
      Blissbook, or its applicable licensor, owns and shall retain all right,
      title and interest in and to the Services, the Documentation, and all
      related Intellectual Property Rights and all Proprietary Information of
      Blissbook, including, without limitation, all applicable software, source
      and object code, specifications, designs, processes, techniques, concepts,
      improvements, discoveries and inventions, including, without limitation,
      any modifications, improvements or derivative works thereof, and all works
      of authorship created, invented, reduced to practice, authored, developed,
      or delivered by Blissbook or any third party, either solely or jointly
      with others, arising from these Terms or any amendment to it, including,
      without limitation, all copies and portions thereto, whether made by or
      under the direction of Blissbook or Client (the “Blissbook IP”).
    </p>
    <h3>5.2 Client Ownership</h3>
    <p>
      Client-Provided Material. Client owns and shall retain all right, title,
      and interest in and to the Client Data and content or materials provided
      by Client to Blissbook in order for Blissbook to produce the Handbook and
      provide the Services, content, graphics, or designs created by Blissbook
      for Client, and other Proprietary Information of Client and all
      Intellectual Property Rights related thereto (the “Client IP”). Client
      represents and warrants to Blissbook that Client is the owner of, or has
      the right to authorize Blissbook to utilize, the Client IP as necessary
      for Blissbook to perform development or performance obligations under
      these Terms, and to its knowledge, the use of such materials as authorized
      does not and will not infringe any existing copyright, trade secret or
      other contractual or confidentiality right of any third party. Client
      represents and warrants that nothing in the Client IP will be obscene,
      indecent, racist, defamatory, or otherwise actionable under any law.
    </p>
    <h3>5.3 Aggregation; Rights of Use</h3>
    <p>
      Client acknowledges that Blissbook may compile aggregate, anonymized data
      related to Client’s and Users’ usage of the Services, and that Blissbook
      may disclose such aggregate, anonymized data to third parties. This
      Section 5.3 does not give Blissbook the right to identify Client as the
      source of any aggregated, anonymized data or other data without written
      permission from Client. Further, Client acknowledges and agrees that
      Blissbook has sole and exclusive rights to obtain, sell, aggregate,
      analyze and utilize the Client Data (including associated Intellectual
      Property Rights) as necessary for its performance hereunder and at any
      time for its business purposes; provided, that Blissbook shall not (a) use
      such Client Data in violation of Client’s right to use the Client Data or
      (b) sell or otherwise provide non-aggregated, non-anonymized Client Data
      to any third party unless in connection with a merger; sale of
      substantially all of the assets or stock of Blissbook or similar
      transaction; or with approval from Client provided in writing or through
      the use of the Services by a Client administrator.
    </p>
    <h3>5.4 Nondisclosure</h3>
    <p>
      Client acknowledges that the Services and other information provided by
      Blissbook to Client hereunder represents Proprietary Information of
      Blissbook, and that the Client Data owned by Client may be or may contain
      Proprietary Information of Client. Except as expressly set forth herein,
      each Party shall (i) hold the Proprietary Information of the other Parties
      in confidence, applying commercially reasonable measures but never less
      than a reasonable standard of care, (ii) not, directly or indirectly,
      copy, reproduce, distribute, manufacture, duplicate, reveal, report,
      publish, disclose, cause to be disclosed, or otherwise transfer the
      Proprietary Information of another Party to any third party, and (iii) not
      make use of the Proprietary Information other than for the permitted
      purposes under these Terms. Each Party hereto shall institute internal,
      commercially reasonable operating procedures to assure limited access and
      use of Proprietary Information consistent with this Section 5.4 and shall
      exercise due care to monitor and ensure compliance with its provisions.
    </p>
    <p>
      A receiving Party will not disclose Confidential Information except: (a)
      to its employees, contractors and agents: (x) whose duties reasonably
      justify their need to know such Proprietary Information for the purpose of
      performing the receiving Party’s obligations under these Terms and (y) who
      have been informed by receiving Party of their obligation to maintain its
      confidentiality (each, an “Authorized Party”); (b) to the extent required
      by applicable federal, state or local law, regulation, court order or
      other legal process. The nondisclosure and confidentiality obligations set
      forth in this Section 5.4 shall survive termination of these Terms for any
      reason.
    </p>
    <HeadingDivider variant='h2'>
      6. Disclaimers: representations; Limitations of Liability
    </HeadingDivider>
    <h3>6.1 Disclaimer of Warranties</h3>
    <p>
      Blissbook shall have no responsibility or liability of any kind, whether
      for breach of its obligations under these Terms, warranty, contract or
      otherwise, arising or resulting from: (a) factors outside of Blissbook’s
      reasonable control; (b) Client’s or a User’s failure to comply with these
      Terms, to use the Services in accordance with the Documentation (including
      without limitation the minimum system requirements set forth therein) or
      to correctly operate any error corrections or other modifications to the
      Services provided by Blissbook, or any actions or inactions by third
      parties; (c) problems caused by failed Internet connections or other
      hardware, software or equipment which is not owned, controlled or operated
      by Blissbook or its subcontractors, or caused by failure of Client or User
      to provide and maintain the supported environment or security
      authorization; (d) nonconformities resulting from misuse, abuse,
      negligence, revision, modification, or improper or unauthorized use of all
      or any part of the Services by Client or any party other than Blissbook or
      Blissbook’s authorized representatives, or any other causes external to
      the Services or Blissbook, including problems to or caused by Client’s,
      User’s or other third party products, services or equipment; or (e) data
      or data input, output or integrity, all of which shall be deemed under
      Client’s exclusive control. Any use of or reliance on data or data output
      from the Services is Client’s sole responsibility and at Client’s sole
      risk. Except as otherwise set forth in this agreement, the Services, the
      Website and its content, and any other information provided or accessible
      via the Services or Website are provided “as is”.
    </p>
    <p>
      Blissbook, its subsidiaries, affiliates, and their respective licensors
      make no representations or warranties of any kind, either express or
      implied, about the services website, data, reports, forms, applications or
      other documents or materials provided via the website or the services,
      including, without limitation (a) warranties of merchantability, fitness
      for a particular purpose, or non-infringement, to the fullest extent
      permissible under applicable law; (b) that access to the Services or the
      Website content will be uninterrupted or error-free, that defects will be
      corrected, or that the Website or the server that makes the Services
      available are free of viruses or other harmful components; and or (c) that
      the use of, or the results from the use of, the Services or the Website
      content will be current, correct, complete, accurate, reliable or
      otherwise. Accordingly, Client acknowledges that its use of the website
      and the services is at its own risk. Client (and not Blissbook) assumes
      the entire cost of all necessary servicing, repair, or correction
      resulting from computer or device malfunction, viruses or other issues
      that may arise from the download or use of material or data via the
      services or the website.
    </p>
    <p>
      In addition, Blissbook is not responsible for and shall have no
      liabilities with respect to claims arising from or related to client’s
      non-compliance with federal and state law, it being explicitly agreed to
      and understood by Client that Blissbook is not providing legal advice or
      legally compliant language or content via the Services. Applicable law may
      not allow the exclusion of some of the implied warranties, so some or all
      of the above exclusions may not apply.
    </p>
    <h3>6.2 Blissbook Representations</h3>
    <p>
      Blissbook represents and warrants to Client that: (a) it will perform the
      Services in an effective, timely, professional and workmanlike manner in
      accordance with prevailing industry practices, (b) it has used and will
      continue to use commercially reasonable efforts to ensure that the
      Services do not contain any “back doors” or “trap doors” which allow for
      unauthorized access to Confidential Information or the bypassing of any
      security features; and (c) it will use commercially reasonable means to
      (i) employ software generally accepted in the industry as sufficient to
      protect the Services and Client Data, and (ii) scan and check for
      malicious code in the Services; and (d) the Services will perform
      materially in accordance with these Terms during the Term.{' '}
    </p>
    <h3>6.3 Limitation of Liability</h3>
    <p>
      BLISSBOOK’S MAXIMUM LIABILITY ARISING OUT OF OR IN ANY WAY CONNECTED
      HERETO SHALL BE, IN THE AGGREGATE, LIMITED TO CLIENT’S DIRECT ACTUAL
      DAMAGES, NOT TO EXCEED THE TOTAL FEES ACTUALLY PAID TO BLISSBOOK FOR THE
      TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE MONTH IN WHICH A CLAIM ARISES
      HEREUNDER, REDUCED BY ANY AMOUNT DUE TO BLISSBOOK BY CLIENT. IN NO EVENT
      SHALL BLISSBOOK OR ANY OF ITS RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES OR
      REPRESENTATIVES, BE LIABLE: (A) TO ANY THIRD PARTY FOR DAMAGES OF ANY KIND
      OR NATURE OR IN ANY MANNER WHATSOEVER, REGARDLESS OF THE CAUSE OF ACTION;
      (B) FOR ANY SPECIAL, INDIRECT, INCIDENTAL; OR (C) FOR ANY CONSEQUENTIAL
      DAMAGES, LOSS OF PROFITS, OR LOSS OF GOODWILL, EVEN IF BLISSBOOK HAS BEEN
      NOTIFIED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING. IT IS ACKNOWLEDGED
      THAT LIMITATIONS OF LIABILITY MAY NOT APPLY IN CERTAIN JURISDICTIONS, AND
      IN SUCH INSTANCES THIS LIMITATION SHALL APPLY TO THE FULLEST EXTENT OF
      APPLICABLE LAW. THE LIMITATIONS OF LIABILITY SET FORTH HEREIN REPRESENT A
      FUNDAMENTAL TERM OF THESE TERMS AND THE PARTIES HERETO WOULD NOT HAVE
      ENTERED INTO THESE TERMS WITHOUT INCLUSION THEREOF.
    </p>
    <HeadingDivider variant='h2'>Term and Termination</HeadingDivider>
    <h3>7.1 Term</h3>
    <p>
      These Terms shall commence on the Effective Date and automatically renew
      in a Renewal Term unless earlier terminated as provided below.
    </p>
    <h3>7.2 Termination</h3>
    <p>
      These Terms may be terminated by either you, the Client, or Blissbook as
      set forth below or pursuant to the MSA (if applicable); provided, however
      that these Terms will continue to apply after such termination: a. with
      respect to any Client Data you have downloaded from the Website or via the
      Services, b. with respect to any other legal rights, obligations, and
      liabilities that you have been subject to (or which have accrued over time
      while these Terms have been in force) or which by their nature, or as
      expressed herein, continue after termination; or c. as set forth in the
      MSA (if applicable). The “Jurisdiction and Governing Law” section below
      shall continue to apply to all such rights, obligations, and liabilities
      indefinitely. You may cancel these Terms at any time by: notifying
      Blissbook in writing or cancelling the Services within your account and
      ceasing to use the Services and accessing the Website. When you, the
      Client, or Blissbook terminates these Terms or the MSA (if applicable),
      your login credentials may be de-activated.
    </p>
    <p>
      In addition to any termination rights set forth in the MSA (if
      applicable), Blissbook may terminate its legal agreement with you if (A)
      immediately without prior notice to you if you have breached any provision
      of these Terms (or have acted in manner which demonstrates, in our sole
      discretion, that you do not intend to, or are unable to comply with these
      Terms), (B) Blissbook is required to do so by law (for example, where the
      provision of the Services to, or access to the Website by, you is, or
      becomes, unlawful), (C) the Client to whom we offered access to the
      Website or use of the Services for your use has terminated its MSA with us
      or ceased to offer the Services or access to the Website to you, (D)
      Blissbook is transitioning to no longer providing the Services or the
      Website to users in the country in which you reside or from which you use
      the Services or access the Website, (E) the provision of the Services or
      access to the Website to you by Blissbook is, in our opinion, no longer
      commercially viable, or (F) at any time in Blissbook’s sole discretion by
      providing 60 calendar days’ prior written notice to you; provided that, if
      Blissbook terminates these Terms for convenience pursuant to this
      Subsection 7.2(F), Blissbook will refund you the pro-rata portion of
      prepaid but unused fees (if any) equal to the total days after termination
      remaining in the then-current Term divided by the total days in such Term
      .
    </p>
    <h3>7.3 Effects of Termination</h3>
    <p>
      Upon termination or expiration of these Terms for any reason whatsoever:
      a. all rights and access bestowed by these Terms shall immediately cease,
      except as otherwise provided hereunder, and Client shall, as applicable,
      immediately cease use of the Services and return to Blissbook all copies
      of all Blissbook Proprietary Information, and provide Blissbook written
      certification thereof; b. all fees earned and/or owed to Blissbook shall
      become due and owing. Unless otherwise agreed by Blissbook with you in a
      signed writing, termination of these Terms will not entitle you or Client
      to any refund of fees; c. all terms which by their content or context are
      intended to survive termination or expiration of these Terms shall so
      survive in full force and effect; and d. if you request, a back-up of
      Client Data and other data, content, or materials owned by you will be
      provided to the Client, at Client’s sole cost based on Blissbook’s
      then-current rates, during the thirty (30) days following termination of
      these Terms; thereafter, Blissbook shall be free to discard Client’s
      stored data, Client Data, or back-ups. During such thirty (30) day period
      and notwithstanding the foregoing, Blissbook will allow Client’s account
      administrator(s) to access the Services to view, download, print, and/or
      export its Client Data. After such thirty (30) day period, Blissbook may
      allow such access in its sole discretion.
    </p>
    <HeadingDivider variant='h2'>8. Indemnification</HeadingDivider>
    <p>
      You agree to indemnify, defend, and hold harmless Blissbook, its
      respective affiliates and subsidiaries, and their respective directors,
      officers, employees, shareholders, and representatives, harmless from and
      against any claims, damages, losses, liabilities, causes of action or
      injuries, together with all costs and expenses, including penalties,
      interest levied, reasonable attorneys’ fees and court costs (collectively,
      “Losses”), arising out of or resulting from allegations concerning: a. any
      failure by you, Client, your employees, or representatives to comply fully
      with (i) the obligations set forth in these Terms, including, without
      limitation, obligations for payment of taxes, and disclosure of
      Proprietary Information, or (ii) posted or published policies and
      guidelines of Blissbook with respect to the Services, as amended from time
      to time; b. misuse of the Services by you, Client, or a User in violation
      of this Agreement, the Documentation, or applicable law; c. a breach of
      any representation or warranty made in these Terms; or d. any claim that
      (i) the use of the Services or (ii) the Client’s stored data or Client
      Data or any data or materials generated, selected, or provided by Client
      or its employees or representatives) as a result of these Terms violates
      or misappropriates the Intellectual Property Rights of a third party.
    </p>
    <HeadingDivider variant='h2'>9. Dispute and resolution</HeadingDivider>
    <p>
      Blissbook and Client shall arbitrate any dispute relating to or arising
      from the Agreement. Any such arbitration shall be in accordance with the
      commercial rules of arbitration of the American Arbitration Association
      (“AAA”) in effect at the time the dispute is filed, except to the extent
      such rules conflict with these Terms. The cost of the arbitration will be
      borne equally by the respective Parties hereto. The following shall apply
      with respect to the arbitration proceeding: (a) the arbitration
      proceedings shall be conducted by one (1) arbitrator selected by the
      Parties, provided, that if the Parties fail to make such designation
      within five (5) days after receipt by the AAA of the demand for
      arbitration, the AAA commercial rules shall govern the appointment of an
      arbitrator; (b) the arbitrator will apply Delaware law and will have no
      power to alter any provision of these terms nor to determine any matter,
      except as provided in this Section 9, and (c) the arbitration will take
      place either virtually or in person in the Metro Atlanta area of Georgia.
      The arbitrator will not be bound by legal rules of procedure and may
      receive evidence in any manner designed to achieve an equitable result for
      the Parties.
    </p>{' '}
    <p>
      The existence, subject, evidence, proceedings and rulings resulting from
      the arbitration proceedings shall be deemed Confidential Information, and
      shall not be disclosed by either Party, their representatives, or the
      arbitrator, except: (i) to the professional advisors of each of the
      Parties; (ii) in connection with a public offering of securities of either
      of the Parties; (iii) as ordered by any court of competent jurisdiction;
      or (iv) as required to comply with any applicable governmental statute or
      regulation. Either Party may seek equitable relief in arbitration prior to
      arbitration on the merits to preserve the status quo. The Parties hereto
      hereby waive their respective right to trial by jury of any cause of
      action, claim, counterclaim, or cross-complaint in any action, proceeding
      and/or hearing brought by any Party against another on any matter
      whatsoever relating to, resulting from, arising out of, or in any way
      connected with these Terms.
    </p>
    <HeadingDivider variant='h2'>10. Security</HeadingDivider>
    <p>
      Blissbook shall maintain commercially reasonable standards, processes,
      procedures, and/or safeguards, including administrative, technical and
      physical safeguards, which are at least as rigorous as the measures
      standard in the industry which are reasonably designed to (i) ensure the
      security and confidentiality of Client’s Confidential Information, Client
      Data and other such information, (ii) protect against any anticipated
      threats or hazards to the security or integrity of Client’s Confidential
      Information, Client Data and other such information, and (iii) protect
      against unauthorized access to or use of Client’s Confidential
      Information, Client Data or other such information. Client is prohibited
      from violating or attempting to violate the security of the Website or the
      Services.
    </p>
    <HeadingDivider variant='h2'>11. Compliance with Laws</HeadingDivider>
    <p>
      You represent and warrant that your use of the Service will comply with
      all applicable laws and regulations. You are responsible for determining
      whether the Service is suitable for you to use in light of your
      obligations under any privacy laws or regulations like the California
      Privacy Rights Act of 2020, the Health Insurance Portability and
      Accountability Act (“HIPAA”), the Graham Leach Bliley Act, EU data privacy
      laws including without limitation the General Data Protection Regulation,
      United States export control laws and regulations and economic sanctions
      laws and regulations, and other applicable laws. If you are subject to
      regulations (like HIPAA) and you use the Service, then we will not be
      liable if the Service does not meet those requirements. You may not use
      the Service for any unlawful or discriminatory activities, including acts
      prohibited by the Federal Trade Commission Act, Fair Credit Reporting Act,
      Equal Credit Opportunity Act, Children’s Online Privacy Protection Act, or
      other laws that apply to commerce. If you are in the European Economic
      Area, the United Kingdom, or Switzerland (collectively, the “EEA”) and/or
      distribute Personal Information through the Service to anyone located in
      the EEA via the Service or Website, you represent and warrant to Blissbook
      that:
    </p>
    <ul className='tw-list-none'>
      <li>
        a. You will clearly post, maintain, and abide by a publicly accessible
        privacy notice on the digital properties from which the underlying
        Content is collected that satisfies the requirements of applicable data
        protection laws, describes your use of the Service, notifies your users
        or employees of your data collection practices in a way that complies
        with applicable data privacy laws, and includes a link to the Blissbook
        Privacy Policy.
      </li>
      <li>
        b. You will get and maintain all necessary permissions and valid
        consents required to lawfully transfer data to Blissbook and to enable
        such data to be lawfully collected, processed, and shared by Blissbook
        for the purposes of providing the Service or as otherwise directed by
        you.
      </li>
      <li>
        c. You will comply with all laws and regulations applicable to your use
        of the Service.
      </li>
      <li>
        d. You will provide and obtain all notices and obtain all necessary
        consents required by applicable data protection laws to enable Blissbook
        to deploy cookies and similar tracking technologies (like web beacons or
        pixels) lawfully on and collect data from the devices of contacts and
        end users of the Service in accordance with and as described in the
        Blissbook Privacy Policy.
      </li>
      <li>
        e. You are subject to Blissbook’s Data Processing Addendum (located at
        https://blissbook.com/dpa), which sets out your and Blissbook’s
        obligations with respect to data protections and security when
        processing personal information. The Data Processing Addendum forms part
        of and is incorporated into these Terms.
      </li>
    </ul>
    <HeadingDivider variant='h2'>12. Miscellaneous</HeadingDivider>
    <h3>12.1. Assignment; Subcontracting</h3>
    <p>
      You may not assign these Terms. Any assignment contrary to these
      provisions shall be null and void and of no legal effect. These Terms
      shall be binding upon and inure to the benefit of the permitted assigns
      and successors of the Parties. Blissbook may subcontract to any third
      party all or any portion of its obligations under these Terms to provide
      Services.
    </p>
    <h3>12.2 Force Majeure</h3>
    <p>
      Blissbook shall not be liable for failure to perform or delay in
      performing all or any part of its obligations under these Terms to the
      extent that it is unable to perform due to any cause or circumstance
      beyond its the reasonable control, including, without limitation, failure
      of communication lines or the internet, acts of God, terrorism, fire,
      flood, storms, earthquake, epidemic, pandemic, strike, or other labor
      dispute (each a “Force Majeure Event”).
    </p>
    <h3>12.3 Linked Sites</h3>
    <p>
      The Website may provide a link to other sites by allowing the user to
      leave the Website or the Services to access third-party material or by
      bringing the third-party material into the Services or the Website via
      “inverse” hyperlinks and framing technology (a “Linked Site”). Blissbook
      has no ability or discretion to alter, update, or control the content on a
      Linked Site. Client acknowledges and agrees that Blissbook is not
      responsible for the availability of any such external sites or resources,
      and does not endorse any advertising, products, or other materials on or
      available from such Linked Site. Blissbook is not liable for any loss or
      damage which may be incurred because of the availability of Linked Sites,
      or because of any reliance on the completeness, accuracy or existence of
      any advertising, products, or other materials on, or available from, such
      Linked Site. Any information Client provides to Linked Sites is not
      covered by the Blissbook Privacy Policy.
    </p>
    <h3>12.4 Jurisdiction and Governing Law</h3>
    <p>
      The terms and your relationship with Blissbook under these Terms, shall be
      governed by and construed in accordance with the laws of the State of
      Delaware, USA, without giving effect to any principles of conflicts of
      law. Subject to the arbitration provision set forth above, you agree that
      any action at law or in equity arising out of or relating to these Terms
      shall be filed exclusively in the local, state, or federal courts located
      in the Metro Atlanta area of Georgia. You hereby consent and submit to the
      personal jurisdiction of such courts for the purposes of litigating any
      such action. Notwithstanding the foregoing section, you agree that
      Blissbook shall be permitted to apply for injunctive remedies (or an
      equivalent type of urgent legal relief) in any jurisdiction.
    </p>
    <h3>12.5 Injunctive Relief</h3>
    <p>
      In the event you are in breach, or threaten to breach any term of these
      Terms, you agree that Blissbook will be greatly damaged, and that such
      breach will be irreparable and the resultant damages difficult to
      quantify; therefore, notwithstanding the provisions of Section 9,
      Blissbook may obtain injunctive or other equitable relief to restrain such
      breach or threat of breach, without impairing, invalidating, negating or
      voiding Blissbook’s rights to relief either at law or in equity.
    </p>
    <h3>12.6 Severability and Waiver</h3>
    <p>
      The invalidity of any portion of these Terms will not and shall not be
      deemed to affect the validity of any other provision, and any such finding
      of invalidity or unenforceability in any jurisdiction shall not invalidate
      or render unenforceable such provision in any other jurisdiction. If any
      provision of these Terms is held to be invalid or unenforceable, the
      parties agree that the remaining provisions shall be deemed to be in full
      force and effect as if they had been executed by both parties after the
      expungement of the invalid provision. If any part of these Terms is
      determined to be invalid or unenforceable pursuant to applicable law, then
      the invalid or unenforceable provision(s) will, rather than be stricken in
      their entirety, be deemed superseded by a valid, enforceable provision
      that most closely matches the intent of the original provision and the
      remainder of these Terms shall continue in effect.
    </p>
    <p>
      No delay or omission by Blissbook to exercise any right or power occurring
      upon any non-compliance or default by Client with respect to any of the
      terms of these Terms shall impair any such right or power or be construed
      to be a waiver thereof. A waiver by Blissbook of any of the covenants,
      conditions, or agreements to be performed by the other shall not be
      construed to be a waiver of any subsequent breach thereof or of any
      covenant, condition, or agreement herein contained. Unless otherwise
      stated herein, all remedies provided for in these Terms shall be
      cumulative and in addition to and not in lieu of any other remedies
      available to Blissbook at law, in equity, or otherwise.
    </p>
    <h3>12.7 Publicity</h3>
    <p>
      Blissbook may use Client’s name and logo(s) on their website at
      https://blissbook.com. Client hereby grants Blissbook a limited,
      revocable, non-exclusive, term-limited, worldwide, royalty-free, license
      in and to Client’s logo and name solely for Blissbook’s use as described
      in this Section. Blissbook will change or update Client’s logo or name as
      requested. Any other use of Client’s logo, name, or affiliation with
      Blissbook requires prior written approval by Client.
    </p>
    <h3>12.8 Independent Contractors</h3>
    <p>
      The relationship between you and Blissbook is that of independent
      contractors, and no agency, partnership, joint venture, or
      employer-employee relationship is intended or created by these Terms.
      Neither Party shall have the power to obligate or bind the other Party.
    </p>
    <h3>12.9 DMCA Notice</h3>
    <p>
      We respect the intellectual property rights of others and require users to
      do the same. In accordance with the Digital Millennium Copyright Act of
      1998, the text of which may be found on the U.S. Copyright Office website
      at{' '}
      <a
        href='http://www.copyright.gov/legislation/dmca.pdf'
        rel='noopener noreferrer'
        target='_blank'
      >
        http://www.copyright.gov/legislation/dmca.pdf
      </a>
      , we will address the claims of copyright infringement committed using our
      Services or via the Website if such claims are reported to our designated
      DMCA Copyright Agent identified below. If we believe that any posted
      material violates any applicable law, we will remove or disable access to
      any such material.
    </p>
    <p>
      Our DMCA Copyright Agent to receive notices of infringing material is:
    </p>
    <LegalIndent>
      <p>
        Rocket Whale Products, LLC
        <br />
        Attention: Tom O’Dea
        <br />
        RE: DMCA Notice
        <br />
        1442 Miller Avenue, Atlanta, GA 30307
        <br />
        Email: <MailtoSupportLink />
        <br />
        Phone: 404-635-6332
      </p>
    </LegalIndent>
    <p>
      In notifying us of alleged copyright infringement, the DMCA requires that
      you include the following information: (a) description of the copyrighted
      work that is the subject of claimed infringement, (b) description of the
      infringing material and information sufficient to permit us to locate the
      alleged material, (c) contact information for you, including your address,
      telephone number and/or e-mail address, (d) a statement by you that you
      have a good faith belief that the material in the manner complained of is
      not authorized by the copyright owner, or its agent, or by the operation
      of any law, (e) a statement by you, signed under penalty of perjury, that
      the information in the notification is accurate and that you have the
      authority to enforce the copyrights that are claimed to be infringed, and
      (f) a physical or electronic signature of the copyright owner or a person
      authorized to act on the copyright owner’s behalf. Failure to include all
      the above-listed information may result in the delay of the processing of
      your complaint.
    </p>
    <h3>12.10 Feedback</h3>
    <p>
      We appreciate any feedback or comments regarding our Website, including
      the Services available thereby. Any such feedback may be submitted to us
      via email at support@blissbook.com. Upon receipt, please note that such
      information will be considered non-confidential and shall become our
      property; therefore, by transmitting such information to us, you hereby
      assign all right, title, and interest in and to such information, idea, or
      feedback at no cost, and acknowledge that we will be free to use such
      information as we see fit.
    </p>
    <h3>12.11 Contact Us</h3>
    <p>
      For questions about these Terms, please contact us at
      support@blissbook.com.
    </p>
  </LegalLayout>
)
