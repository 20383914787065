import { gridBreakpoints } from '@blissbook/ui/branding'
import { css } from '@emotion/react'
import classnames from 'classnames'
import { Section } from '../../components'

const paddingX = 30
const paddingY = 15

export default ({ background, children, className, ...props }) => (
  <Section
    {...props}
    className={classnames('container tw-flex tw-items-center', className)}
    css={css`
      display: flex;
      align-items: center;

      @media (min-width: ${gridBreakpoints.lg}px) {
        padding-left: 75px;
        padding-right: 75px;
      }
    `}
  >
    {background}
    <div
      className='tw-my-4'
      css={css`
        background: rgba(255, 255, 255, 0.9);
        max-width: 700px;
        padding: ${paddingY}px ${paddingX}px;

        @media (min-width: ${gridBreakpoints.lg}px) {
          margin: -${paddingY}px -${paddingX}px;
        }
      `}
    >
      {children}
    </div>
  </Section>
)
