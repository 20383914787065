import config from '@blissbook/ui-config'
import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import {
  Col,
  Container,
  FadeOutInTransition,
  Link,
  Row,
  ShapeConfetti,
} from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'
import {
  HeadingDivider,
  MaxWidthContainer,
  Section,
  Typography,
} from '../../components'
import { EXPERIENCES } from './experiences'
import { AnimatedExperience } from './experiences/components'

const desktopHeight = 532
const transformBrowser =
  'perspective(2400px) rotateY(-25deg) rotateX(4deg) rotate(1deg)'
const transitionDuration = 500

// Get the experience to render for this hash
const findExperienceByHash = (hash) =>
  EXPERIENCES.find((experience) => `#${experience.id}` === hash) ||
  EXPERIENCES[0]

// Experience navigation link
const NavLink = (props) => (
  <Link
    {...props}
    css={css`
      color: ${colors['sunshine-500']};
      &:hover {
        color: ${colors['sunshine-700']};
      }
    `}
  />
)

const DesktopExperiences = ({ history, location }) => {
  // Determine when we can start playing
  const [canPlay, setCanPlay] = useState(false)

  // Determine the current experience
  const currentExperience = canPlay && findExperienceByHash(location.hash)

  // Set the current experience
  const setCurrentExperience = (experience) => {
    history.replace({ ...window.location, hash: experience.id })
  }

  // Move to the prev/next experience
  const moveCurrentIndex = (delta) => {
    let index = EXPERIENCES.indexOf(currentExperience)
    index = (index + delta + EXPERIENCES.length) % EXPERIENCES.length
    setCurrentExperience(EXPERIENCES[index])
  }

  return (
    <Container css={{ height: desktopHeight, position: 'relative' }}>
      {EXPERIENCES.map((experience) => (
        // biome-ignore lint/a11y/useAnchorContent: requires some work (div is onclick)
        <a
          key={experience.id}
          name={experience.id}
          css={{ marginTop: '-4rem' }}
        />
      ))}
      <Row
        className='tw-align-stretch tw-flex-row-reverse'
        css={{ height: '100%' }}
      >
        <Col
          lg={6}
          xl={6}
          className='tw-hidden lg:tw-block'
          css={{
            transform: config.env.development ? undefined : transformBrowser,
          }}
        >
          {EXPERIENCES.map((experience) => (
            <AnimatedExperience
              key={experience.id}
              experience={experience}
              in={experience === currentExperience}
            />
          ))}
        </Col>
        <Col lg={6} xl={5}>
          <MaxWidthContainer className='tw-mx-auto' xs={450} lg={380}>
            <Waypoint onEnter={() => setCanPlay(true)}>
              <div className='tw-mt-6 tw-mb-8'>
                {EXPERIENCES.map((experience, index) => (
                  <Fragment key={experience.id}>
                    {index > 0 && (
                      <span
                        className='tw-mx-1'
                        css={css`
                          color: ${colors['sunshine-300']};
                        `}
                      >
                        •
                      </span>
                    )}

                    <Link
                      css={css`
                        color: white;
                        &:hover {
                          color: ${colors['sunshine-500']};
                        }
                        ${
                          experience === currentExperience &&
                          `
                          color: ${colors['sunshine-500']};
                          text-decoration: underline;
                        `
                        }
                      `}
                      onClick={() => setCurrentExperience(experience)}
                    >
                      {experience.title}
                    </Link>
                  </Fragment>
                ))}
              </div>
            </Waypoint>

            <div css={{ position: 'relative' }}>
              {EXPERIENCES.map((experience) => (
                <FadeOutInTransition
                  key={experience.id}
                  fadeInDelay={transitionDuration}
                  fadeInDuration={transitionDuration}
                  fadeOutDuration={transitionDuration}
                  in={experience === currentExperience}
                >
                  <div
                    css={css`
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                    `}
                  >
                    <HeadingDivider variant='h2' divider={{ color: 'white' }}>
                      {experience.title}.
                    </HeadingDivider>
                    <Typography variant='p2' className='tw-mb-0'>
                      {experience.description}
                    </Typography>

                    <div
                      className='tw-flex tw-items-center tw-mt-6'
                      css={{ fontSize: 24 }}
                    >
                      <NavLink onClick={() => moveCurrentIndex(-1)}>
                        <FontAwesomeIcon icon='chevron-left' />
                      </NavLink>
                      <NavLink
                        className='tw-ml-6'
                        onClick={() => moveCurrentIndex(1)}
                      >
                        <FontAwesomeIcon icon='chevron-right' />
                      </NavLink>
                    </div>
                  </div>
                </FadeOutInTransition>
              ))}
            </div>
          </MaxWidthContainer>
        </Col>
      </Row>
    </Container>
  )
}

const HandbookLink = (props) => (
  <Link
    {...props}
    css={{
      color: colors['aqua-300'],
    }}
  />
)

const EmployeeExperienceSection = ({ history, location }) => (
  <Section
    id='employee-experience'
    className='py-sm'
    css={css`
      background: ${colors['blurple-700']};
      color: white;

      @media (min-width: ${gridBreakpoints.lg}px) {
        padding-top: 240px !important;
      }
    `}
  >
    <ShapeConfetti
      css={css`
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
      `}
      className='tw-hidden lg:tw-block container'
      shapes={[
        { x: -550, y: 30, type: 'wedge', color: 'aqua-500', rotate: 180 },
        { x: -540, y: 230, type: 'wedge', color: 'purple-500', fill: true },
        { x: -440, y: 70, type: 'square', color: 'red-500', fill: true },
        {
          x: -420,
          y: 330,
          type: 'quote',
          color: 'aqua-500',
          fill: true,
          size: 40,
        },
        { x: -290, y: 150, type: 'wedge', color: 'sunshine-500' },
        { x: -200, y: 50, type: 'square', color: 'blurple-500', fill: true },
        {
          x: -60,
          y: 170,
          type: 'quote',
          color: 'purple-500',
          fill: true,
          size: 40,
        },
        {
          x: 130,
          y: 80,
          type: 'wedge',
          color: 'aqua-500',
          rotate: 180,
          fill: true,
        },
        { x: 290, y: 150, type: 'square', color: 'blurple-500' },
        { x: 400, y: 270, type: 'wedge', color: 'purple-500', fill: true },
        {
          x: 420,
          y: 80,
          type: 'wedge',
          color: 'sunshine-500',
          fill: true,
          rotate: 180,
        },
        {
          x: 470,
          y: 190,
          type: 'quote',
          color: 'sunshine-500',
          fill: true,
          size: 40,
        },
        {
          x: 550,
          y: 310,
          type: 'square',
          color: 'red-500',
          fill: true,
          rotate: 45,
        },
        { x: 560, y: 130, type: 'wedge', color: 'purple-500' },
      ]}
    />

    <Container className='text-center mb-lg' css={{ maxWidth: 750 }}>
      <Typography variant='h1'>The Employee Experience.</Typography>
      <Typography variant='p1'>
        Show employees they’re valued with an accessible, interactive, online
        employee handbook. User-friendly tools improve employee engagement and
        communicating well reduces your litigation risk.
      </Typography>
      <Link to='/pricing' className='btn btn-info'>
        Check Pricing and Sign Up
      </Link>
    </Container>

    <DesktopExperiences history={history} location={location} />

    <Container className='text-center mt-lg' css={{ maxWidth: 750 }}>
      <Typography variant='p1'>
        Want to see an example handbook? Check out these three:
        <br />
        <HandbookLink href='https://parksandrec.blissbook.com/d/1005-pawnee-parks-and-rec/public/621d0274'>
          Pawnee Parks and Rec
        </HandbookLink>
        &nbsp;&nbsp;•&nbsp;&nbsp;
        <HandbookLink href='https://policies.rocketwhale.com/d/1000-rocket-whale/public/924b17e3'>
          Rocket Whale
        </HandbookLink>
        &nbsp;&nbsp;•&nbsp;&nbsp;
        <HandbookLink href='https://default.blissbook.com/d/6337-acme-incorporated'>
          Acme Incorporated
        </HandbookLink>
      </Typography>
    </Container>
  </Section>
)

export default withRouter(EmployeeExperienceSection)
