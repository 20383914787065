import config from '@blissbook/ui-config'
import { Head } from '@blissbook/ui/lib'
import { useScrollTop } from '@blissbook/ui/util/hooks'
import { useStore } from '@blissbook/ui/util/store'
import type React from 'react'
import { Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { MarketingFooter, MarketingNavbar } from './layout'

interface LayoutProps {
  children: React.ReactNode
  className?: string
  [key: string]: any
}

export function MarketingLayout({
  children,
  className,
  ...props
}: LayoutProps) {
  const location = useLocation()
  const { user } = useStore('session')
  const scrollTop = useScrollTop()

  return (
    <Fragment>
      <Head {...props} />
      <MarketingNavbar
        blogUrl={config.blogUrl}
        isAuthenticated={!!user}
        location={location}
        scrollTop={scrollTop}
      />
      <div id='main'>
        <article children={children} className={className} />
      </div>
      <MarketingFooter />
    </Fragment>
  )
}
