import classnames from 'classnames'
import React from 'react'

const variants = {
  h1: {
    tagName: 'h1',
  },
  h2: {
    tagName: 'h2',
  },
  h3: {
    tagName: 'h3',
  },
  h4: {
    tagName: 'h4',
  },
  headline: {
    tagName: 'p',
    className: 'headline',
  },
  p1: {
    tagName: 'p',
    className: 'p1',
  },
  p2: {
    tagName: 'p',
    className: 'p2',
  },
  ul2: {
    tagName: 'ul',
    className: 'ul2',
  },
  ol: {
    tagName: 'ol',
    className: 'ol',
  },
  muted: {
    tagName: 'div',
    className: 'tw-text-gray-500',
    style: { fontSize: 14 },
  },
  quote: {
    tagName: 'blockquote',
  },
}

export default React.forwardRef(
  /**
   * @param {Record<string, any>} props
   */
  function Typography(
    { className, size, style, tagName, variant, ...props },
    ref,
  ) {
    const variantProps = variants[variant]
    if (!variant) throw new Error(`Unknown variant: ${variant}`)

    return React.createElement(tagName || variantProps.tagName, {
      ...props,
      className: classnames(
        tagName ? variant : variantProps.className,
        size && `-${size}`,
        className,
      ),
      ref,
      style: {
        ...variantProps.style,
        ...style,
      },
    })
  },
)
