import { Col, ForwardLink, Row } from '@blissbook/ui/lib'
import { useParallaxScreenRatio } from '@blissbook/ui/util/parallax'
import isNumber from 'lodash/isNumber'
import { Fragment, useState } from 'react'
import {
  DotsHorizontal,
  MaxWidthContainer,
  Section,
  Typography,
} from '../../components'
import FeaturesContainer from './FeaturesContainer'

const FEATURES = [
  {
    title: 'Stay “in-the-know.”',
    description:
      'In your documentation center, assess risk across your entire organization and understand which employees are preventing you from 100% compliance.',
  },
  {
    title: (
      <Fragment>
        <span className='tw-hidden lg:tw-inline-block'>
          Achieve 100% coverage.
        </span>
        <span className='tw-inline-block lg:tw-hidden'>Get 100% coverage.</span>
      </Fragment>
    ),
    description:
      'Stay on top of employees with customizable 1-click invitations and reminders, or completely eliminate the busy work by automating these communications.',
  },
  {
    title: 'Involve managers.',
    description:
      'CC managers on auto-reminders to help get the signatures you need. Managers have their own dashboard so they’ll always know their staff’s signature status.',
  },
  {
    title: 'Never lose access.',
    description:
      'You own your documentation data. Export acknowledgements and more via PDF or CSV and retain access to your data even if you cancel your subscription.',
  },
]

const PaperclipIcon = (props) => {
  const [node, setNode] = useState()
  const ratio = useParallaxScreenRatio(node, {
    attachment: 'center',
    range: [0.8, 0.5],
  })

  const slashRatio = isNumber(ratio) ? ratio : 1
  const slashFrom = [2.6154, 1.6433]
  const slashTo = [63.9804, 68.4653]
  const slashPoint = [0, 1].map(
    (index) =>
      slashRatio * (slashTo[index] - slashFrom[index]) + slashFrom[index],
  )

  return (
    <svg
      role='img'
      aria-label='Paperclip Icon'
      width='66px'
      height='71px'
      viewBox='0 0 66 71'
      ref={setNode}
      {...props}
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-673.000000, -4648.000000)'>
          <g transform='translate(237.000000, 1493.000000)'>
            <g transform='translate(30.000000, 3155.000000)'>
              <g transform='translate(406.000000, 0.000000)'>
                <g transform='translate(0.000000, 0.108700)'>
                  <path
                    d='M4.8913,65.1625 C10.6283,69.9465 19.8503,69.1125 24.6303,63.3805 L60.2623,20.6445 C65.4793,14.3845 65.3463,7.6775 59.9093,3.1375 C59.9093,3.1375 59.9113,3.1335 59.9083,3.1325 C59.9063,3.1315 59.9063,3.1315 59.9063,3.1315 C59.9033,3.1295 59.9033,3.1295 59.9003,3.1265 C59.9003,3.1265 59.8973,3.1265 59.8963,3.1255 C54.9963,-0.9515 47.9083,0.3195 43.0433,6.1585 L17.1403,37.2245 C13.5633,41.5155 10.2533,46.5115 16.2733,51.5295 C22.2963,56.5505 26.6163,52.3945 30.1883,48.1065 L45.1633,30.1465 C45.7933,29.3935 45.6913,28.2735 44.9393,27.6455 C44.1843,27.0175 43.0643,27.1205 42.4393,27.8735 L27.4643,45.8325 C23.1193,51.0435 21.3513,51.1395 18.5483,48.8045 C15.7453,46.4675 15.5263,44.7095 19.8683,39.4985 L45.7703,8.4325 C48.7223,4.8925 53.6913,2.5815 57.6313,5.8595 C63.1303,10.4475 59.3963,16.1355 57.5353,18.3705 L21.9003,61.1035 C18.3353,65.3855 11.4473,66.0055 7.1653,62.4355 C2.8903,58.8695 2.2713,51.9885 5.8393,47.7095 L36.5453,10.8865 C37.1703,10.1345 37.0723,9.0135 36.3163,8.3845 C35.5653,7.7555 34.4433,7.8585 33.8153,8.6105 L3.1103,45.4365 C-1.6697,51.1685 -0.8357,60.3875 4.8913,65.1625'
                    id='Fill-1'
                    fill='#FFBE45'
                    mask='url(#mask-2)'
                  />
                </g>
                <path
                  d={`M${slashFrom[0]},${slashFrom[1]} L${slashPoint[0]},${slashPoint[1]}`}
                  stroke='#FD5D5D'
                  strokeWidth='4.859'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default () => (
  <Section id='policy-compliance-tracking'>
    <FeaturesContainer
      color='blurple-500'
      features={FEATURES}
      shapeType='binoculars'
      title='Track.'
      titleOffset={2}
      topLeftShapes={[
        {
          x: 0,
          y: 2,
          type: 'wedge',
          color: 'blurple-500',
          rotate: -90,
          fill: true,
          className: 'tw-hidden xl:tw-block',
        },
        {
          x: 0,
          y: 3,
          type: 'square',
          color: 'blurple-300',
          fill: true,
          className: 'tw-hidden xl:tw-block',
        },
      ]}
      topRightShapes={[
        { x: 1, y: 1, type: 'square', color: 'blurple-200', fill: true },
        {
          x: 0,
          y: 2,
          type: 'wedge',
          color: 'blurple-300',
          rotate: -90,
          fill: true,
        },
        {
          x: 1,
          y: 1,
          type: 'binoculars',
          color: 'blurple-500',
          translateX: -60,
          translateY: 60,
        },
      ]}
    />

    <div className='container'>
      <MaxWidthContainer
        className='text-center tw-mx-auto mt-sm mb-lg'
        xs={800}
      >
        <PaperclipIcon className='tw-mb-10' />
        <Typography variant='headline'>
          Digital policy management with Blissbook <em>reduces risk</em>, legal
          fees, and maybe even your blood pressure.
        </Typography>
        <ForwardLink to='/request-a-demo'>Request a Demo</ForwardLink>
      </MaxWidthContainer>

      <Row>
        <Col lg={6} xl={{ size: 6, offset: 1 }} className='text-center mb-lg'>
          <img
            alt='Audit Friendly Reporting'
            src='/images/marketing/product/Audit Friendly Reporting.svg'
            css={{
              maxWidth: '100%',
              transform:
                'perspective(2400px) rotateY(25deg) rotateX(4deg) rotate(-1deg)',
              borderRadius: '10px',
              boxShadow:
                '0 20px 60px -12px rgba(57,57,138,.15), 0 15px 36px -18px rgba(0,0,0,0.1), 0 -12px 36px -8px rgba(0,0,0,.020), -1px 1px 5px 0 rgba(57,57,138,.1), -60px 50px 125px -25px rgba(57,57,138,0.075), -30px 30px 75px -40px rgba(0,0,0,.2)',
            }}
          />
        </Col>
        <Col lg={6} xl={5} className='tw-mt-12 mb-sm'>
          <MaxWidthContainer className='tw-mx-auto' xs={500} lg={380}>
            <Typography variant='h2'>
              Trust our audit-friendly reporting.
            </Typography>
            <Typography variant='p1' className='tw-mb-0'>
              View or print detailed reports for every employee.
            </Typography>
            <DotsHorizontal className='tw-my-4' dotCount={4} />
            <Typography variant='p1' className='tw-mb-0'>
              Export documentation in a number of different ways.
            </Typography>
            <DotsHorizontal className='tw-my-4' dotCount={4} />
            <Typography variant='p1' className='tw-mb-0'>
              Blissbook automatically tracks the exact content employees view
              and sign.
            </Typography>
          </MaxWidthContainer>
        </Col>
      </Row>
    </div>
  </Section>
)
