import { Link } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'

import { MarketingLayout, Section, Typography } from '../components'

import { TOCList } from './TOC'
import {
  ChapterHeader,
  DownloadWorkbook,
  ExternalLink,
  GetStartedHero,
  HowToSection,
  QuoteBordered,
} from './components'

import { images, urls } from './howToConfig'

import './styles/how-to-ebook.scss'

export default () => (
  <MarketingLayout
    title='Policies and Procedures Review and Approval: The Yep Work'
    description={
      'How to get others to efficiently review and approve your new policies and procedures. Includes tips on the best ways to get feedback for your new employee handbook.'
    }
    className='how-to-ebook'
  >
    <ChapterHeader
      title='4: The Yep Work'
      subtitle='Revisions, Decisions, and Approval'
      prev={{ url: urls.depthWork, text: '3. The Depth Work' }}
      next={{ url: urls.pepWork, text: '5. The Pep Work' }}
      img={images.headerYepWork.src}
    />

    <HowToSection
      className='container tw-mt-14'
      main={
        <Fragment>
          <Typography variant='p2'>
            Hopefully the time you spent hyping up your new handbook and updated
            policies to colleagues will now pay off. Still, you can expect some
            friction once others are providing feedback, so this may be a good
            time to switch to decaf.
          </Typography>
          <Typography variant='p2'>
            Getting conflicting feedback from multiple viewpoints is
            challenging, particularly when you’re trying to balance protecting
            the company legally with preserving the company’s culture. You can
            reduce confusion and project delays by using software that makes it
            easy for everyone to collaborate, discuss, and suggest changes.
          </Typography>
          <Typography variant='p2'>
            Particularly, as you get closer to the final product, you may want
            to involve new people with fresh eyes. If they can review past
            comments and changes, they can see the evolution of the handbook and
            understand why a section is the way it is – without having to ask
            you directly. Think of all the emails, instant messages, conference
            calls, and one-on-one discussions that have happened thus far. Now
            imagine having to do all that and justify every decision to someone
            seeing the handbook for the first time. A new perspective will
            almost always improve your end result, but you’ll want it to be easy
            for people to get up-to-speed without having to ask you.
          </Typography>
        </Fragment>
      }
      side={<TOCList activeIndex={4} />}
    />

    <HowToSection
      className='container'
      main={
        <QuoteBordered
          quote='We needed something that brought us all together as one company.'
          attribution={{
            name: 'Chanin Christensen',
            title: (
              <span>
                SVP / HR Manager at{' '}
                <ExternalLink href='https://merrickbank.com/'>
                  Merrick Bank
                </ExternalLink>
              </span>
            ),
          }}
        />
      }
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            Get Your Delivery Method Approved
          </Typography>
          <Typography variant='p2'>
            Before you’re knee-deep in the design, be sure you’ve determined how
            your handbook will be delivered. Your distribution method may
            provide you with additional tools you can use to communicate your
            point. Live your brand – even while building your policies.
          </Typography>
        </Fragment>
      }
    />

    <DownloadWorkbook
      text="If you haven't already, download the free companion Workbook to see a checklist of to-dos related to each delivery method."
      extraMarginTop='-100px'
      extraMarginBottom='-120px'
      stack={[
        images.workbook.distributionMethods,
        images.workbook.milestones1,
        images.workbook.milestones2,
      ]}
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h4'>Print</Typography>
          <Typography variant='p2'>
            If your handbook will delivered in a printed format, you’ll want to
            include information like your company name, logo, chapter titles,
            page numbers, and other metadata such as version and publish dates
            at the beginning of each policy or even on every page; you never
            know who will stumble upon it and when.
          </Typography>
          <Typography variant='h4'>Electronic Document / PDF</Typography>
          <Typography variant='p2'>
            If distributing the handbook electronically, link to a document
            instead of attaching it to an email. Although the document itself
            will be static, a link can ensure that, when clicked, employees are
            accessing the most up-to-date version. You’ll still need to
            communicate when changes are made, but this eliminates some of the
            issues that accompany printed documents. Since employees will view
            the document on a computer, you can incorporate links to other files
            or resources. Take advantage!
          </Typography>
          <Typography variant='h4'>
            Employee Handbook or Policy Management Software
          </Typography>
          <Typography variant='p2'>
            This is the recommended form of delivery, as it’s efficient, reduces
            risk, and provides the best employee experience. Plus, depending on
            the software you choose, you can use video, gifs, read-more buttons,
            or other design elements to make your policies less of a drag for
            employees to read and sign (electronically, we hope).
          </Typography>
        </Fragment>
      }
    />

    <HowToSection className='container' main={<GetStartedHero />} />

    <Section className='container tw-p-18 text-center'>
      <Fragment>
        <Typography variant='h2'>Next Chapter: The Pep Work</Typography>
        <Typography variant='p2'>
          <Link className='btn btn-link tw-m-2' href={urls.depthWork}>
            <FontAwesomeIcon className='tw-mr-2' icon='arrow-left' />
            Previous
          </Link>
          <Link className='btn btn-primary tw-m-2' href={urls.pepWork}>
            Next: Designing Your Policies and Handbook
          </Link>
        </Typography>
      </Fragment>
    </Section>
  </MarketingLayout>
)
