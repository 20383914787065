import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import flatMap from 'lodash/flatMap'
import {
  DemoDesktopBrowser,
  DemoHandbook,
  DemoTextLine,
  demoColors,
  getClickExplosionAnimation,
  scrollAnimation,
} from './components'

const tocButtonClickAnimation = getClickExplosionAnimation('color')
const tocDuration = 500
const tocItemDelay = 50
const tocItemsDuration = 500
const tocSectionClickAnimation = getClickExplosionAnimation('background')
const tocWidth = 200

const tableOfContents = [
  { level: 1, width: 70 },
  { level: 1, width: 80 },
  { level: 1, width: 60, top: 735 },
  { level: 1, width: 90 },
  { level: 1, width: 85 },
  { level: 1, width: 65 },
  { level: 1, width: 75 },
  { level: 1, width: 65 },

  { level: 1, width: 65 },
  { level: 2, width: 100 },
  { level: 2, width: 120, top: 1012 },
  { level: 2, width: 80 },
  { level: 2, width: 70 },

  { level: 1, width: 110 },
  { level: 2, width: 90 },
  { level: 2, width: 90 },
  { level: 2, width: 100 },
  { level: 2, width: 80 },

  { level: 1, width: 110, top: 1500 },
  { level: 2, width: 90 },
  { level: 2, width: 90 },
  { level: 2, width: 100 },
  { level: 2, width: 80 },
]

const tocClicks = [2, 18, 10]

const Component = ({ isAnimationComplete, keyframe }) => (
  <DemoDesktopBrowser disabled={isAnimationComplete}>
    <DemoHandbook
      css={{
        marginTop: -keyframe.scrollTop,
        transition: `all ${scrollAnimation.duration}ms ease`,
      }}
      sections={[
        { type: 'chapter' },
        { type: 'text' },
        { type: 'chapter' },
        { type: 'text' },
      ]}
    />

    <div
      className='tw-flex tw-items-center box-shadow'
      css={{
        position: 'absolute',
        top: 10,
        height: 24,
        left: 10,
        width: 120,

        background: 'white',
        borderRadius: 3,
        color: demoColors.gray,
        fontSize: 12,
        paddingLeft: 6,
      }}
    >
      <FontAwesomeIcon
        icon={['far', 'bars']}
        css={{
          animation: keyframe.tocButtonClickAnimation,
        }}
      />
    </div>

    <div
      className='tw-px-4 tw-py-4 box-shadow'
      css={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: keyframe.showToc ? 0 : -tocWidth - 10,
        right: 0,
        width: tocWidth,

        background: 'white',
        overflow: 'hidden',
        transition: `all ${tocDuration}ms ease`,
      }}
    >
      <DemoTextLine
        className='tw-mt-0 tw-mb-4'
        color={demoColors.gray}
        variant='h2'
        width={100}
      />

      {tableOfContents.map((entry, index) => (
        <div
          key={index}
          className='tw-flex tw-items-center tw-my-4'
          css={{
            marginLeft: keyframe.showTocItems ? 0 : -50,
            opacity: keyframe.showTocItems ? 1 : 0,
            paddingLeft: 10 * entry.level,
            transition: `all ${tocItemsDuration}ms ease`,
            transitionDelay: `${index * tocItemDelay}ms`,
          }}
        >
          <DemoTextLine
            className='tw-my-0'
            color={entry.level > 1 ? demoColors.grayLight : demoColors.gray}
            css={{
              animation:
                index === keyframe.clickTocIndex
                  ? tocSectionClickAnimation.style
                  : undefined,
            }}
            variant='p'
            width={entry.width}
          />
        </div>
      ))}
    </div>
  </DemoDesktopBrowser>
)

export default {
  id: 'table-of-contents',
  title: 'Automatic Table of Contents',
  description:
    'No need to maintain a table of contents - it’s all done automatically and you can override items when necessary.',
  Component,

  keyframes: [
    {
      duration: 2000,
      scrollTop: 0,
    },

    ...flatMap(tocClicks, (index) => [
      {
        duration: tocButtonClickAnimation.duration - tocDuration / 2,
        clickTocIndex: undefined,
        tocButtonClickAnimation: tocButtonClickAnimation.style,
      },
      {
        duration: tocDuration / 4,
        showToc: true,
      },
      {
        duration: tocItemsDuration + tableOfContents.length * tocItemDelay,
        showToc: true,
        showTocItems: true,
      },
      {
        duration: tocSectionClickAnimation.duration,
        clickTocIndex: index,
        tocButtonClickAnimation: undefined,
      },
      {
        duration: scrollAnimation.duration + 500,
        scrollTop: tableOfContents[index].top,
        showToc: false,
        showTocItems: false,
      },
    ]),
  ],
}
