import { Link } from '@blissbook/ui/lib'
import {
  MarketingLayout,
  ResourceLink,
  ResourceSection,
  Typography,
} from '../components'

export default () => (
  <MarketingLayout
    title='How to Write a Culture-First Employee Handbook (a free eBook)'
    description='Learn how to write HR policies for non-lawyer humans. Includes sample employee handbook policy templates for your culture-first employee manual.'
  >
    <ResourceSection
      images={[
        '/images/marketing/resources/eBook-cover.jpg',
        '/images/marketing/resources/eBook-content.jpg',
      ]}
    >
      <img
        src='/images/marketing/resources/eBook.svg'
        className='tw-mb-10'
        alt='outlined ebook icon in blue, light blue, and red colors'
      />

      <Typography variant='h4'>Free eBook</Typography>

      <Typography variant='h1'>
        How to Write a Culture-First Employee Handbook (a free eBook).
      </Typography>

      <Typography variant='p2' className='tw-mb-10'>
        This eBook is a compilation of research from several books, hundreds of
        conversations with customers and experts, and years of experience
        helping companies create, share, and manage culture-first HR policies.
        The result is a step-by-step guide to creating a positive and meaningful
        employee handbook - one you can feel proud to share with employees.
      </Typography>

      <Link
        className='btn btn-primary tw-mb-10'
        href='/assets/how-to-write-a-culture-first-employee-handbook.pdf'
        download
      >
        Download for Free
      </Link>

      <ResourceLink to='/newsletter' icon={['far', 'envelope-open-text']}>
        Hey! Check out our newsletter, the Blissbook Bugle, for the best HR
        content in your inbox.
      </ResourceLink>
    </ResourceSection>
  </MarketingLayout>
)
