import { formatSubdomain } from '@blissbook/lib/sanitize/domain'
import { Store } from '@blissbook/ui/util/store'

export const setAuthValue = (key: string, value: any) => {
  Store.instance.produce((draft) => {
    // Update the target value
    const { auth } = draft
    auth[key] = value

    // If we need to update the subdomain
    const { subdomainDirty } = auth
    if (key === 'company' && !subdomainDirty) {
      const subdomain = formatSubdomain(value)
      auth.subdomain = subdomain
    } else if (key === 'subdomain') {
      auth.subdomainDirty = true
    }
  })
}

export const setSignUpStep = (step: number) => {
  Store.instance.produce((draft) => {
    const { signUp } = draft
    signUp.maxStep = Math.max(step, signUp.maxStep)
    signUp.step = step
  })
}

export const setShowRedirectSuggestion = (show: boolean) => {
  Store.instance.produce((draft) => {
    const { signUp } = draft
    signUp.showRedirectSuggestion = show
  })
}
