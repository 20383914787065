import { Link } from '@blissbook/ui/lib'
import Card from '../Card'
import Typography from '../Typography'

export default ({ post, ...props }) => {
  return (
    <Card
      {...props}
      color='purple-500'
      title='No Spam'
      titleCss={{ opacity: 0.5 }}
    >
      <Typography variant='p1' className='tw-mb-2'>
        Tune in to what's happening at Blissbook and with US Labor laws.
      </Typography>

      <Link
        className='btn btn-purple btn-sm tw-mt-4 tw-block tw-font-semibold tw-text-lg'
        to='/newsletter'
      >
        Sign Up
      </Link>
    </Card>
  )
}
