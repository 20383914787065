import { useEffect } from 'react'

declare global {
  interface Window {
    hbspt: any
  }
}

export function HubSpotForm({
  formId,
  ...props
}: {
  className?: string
  formId: string
}) {
  const id = `form-${formId}`

  useEffect(() => {
    const timerId = setInterval(() => {
      if (!window.hbspt) return

      // @ts-ignore: hbspt is not typed
      window.hbspt.forms.create({
        formId,
        portalId: '23496881',
        region: 'na1',
        target: `#${id}`,
      })

      clearInterval(timerId)
    }, 100)

    return () => {
      clearInterval(timerId)
    }
  }, [formId])

  return <div {...props} id={id} />
}
