import { Field } from '@blissbook/ui/lib'
import { setAuthValue } from '@blissbook/ui/marketing/actions'
import { sendEmailAuth } from '@blissbook/ui/marketing/queries'
import { handleError } from '@blissbook/ui/util/errors'
import { useStore } from '@blissbook/ui/util/store'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { AuthButton, Typography } from '../../components'

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
})

export async function submitEmail(email, onSubmit) {
  const { signInUrl, userId } = await sendEmailAuth(email)
  if (signInUrl) {
    window.location.href = signInUrl
    return
  }

  onSubmit({
    headerEl: <EmailAuthCodeHeader email={email} />,
    userId,
  })
}

export const EmailAuthForm = ({ onSubmit }) => {
  const { organization } = useStore()
  const values = useStore('auth')

  async function handleSubmit(values, { setStatus }) {
    setStatus({ isSubmitting: true })

    try {
      const { email } = values
      await submitEmail(email, onSubmit)
    } catch (error) {
      handleError(error)
      setStatus()
    }
  }

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ handleSubmit, status: { isSubmitting } = {} }) => (
        <form id='sign-in-email' noValidate onSubmit={handleSubmit}>
          <Typography className='auth' variant='p2'>
            {organization
              ? `Sign in to ${organization.name}.`
              : 'Come on in, the water’s fine!'}
          </Typography>

          <fieldset disabled={isSubmitting}>
            <Field
              name='email'
              label='Your Email Address'
              onChangeValue={(value) => setAuthValue('email', value)}
              type='email'
              autoFocus
            />

            <div className='tw-flex tw-items-center'>
              <AuthButton type='submit'>Sign In</AuthButton>
            </div>
          </fieldset>
        </form>
      )}
    </Formik>
  )
}

export function obfuscateEmail(email) {
  let [local, domain] = email.split('@')
  local = local[0] + Array(local.length).join('*')
  return `${local}@${domain}`
}

export function EmailAuthCodeHeader({ email }) {
  return (
    <>
      <Typography className='auth' variant='p2'>
        We sent a verification code to {obfuscateEmail(email)}.
      </Typography>

      <Typography className='auth' variant='p2'>
        Enter the code or click the special link/button in the email to log in.
      </Typography>
    </>
  )
}
