import { gridBreakpoints } from '@blissbook/ui/branding'
import { Col, Row } from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { Section, Typography } from '../../components'

const TextColumn = ({ children }) => (
  <Col
    xs={12}
    lg={6}
    css={css`
      @media (min-width: ${gridBreakpoints.lg}px) {
        max-width: 420px;
      }
    `}
  >
    <Typography variant='p2'>{children}</Typography>
  </Col>
)

export default () => (
  <Section id='our-vision' className='container py-sm'>
    <img
      alt='Telescope'
      src='/images/marketing/about/Telescope.svg'
      className='tw-block tw-mx-auto tw-mb-10'
    />

    <Typography
      variant='h3'
      className='tw-mx-auto text-center tw-text-blurple-500 tw-mb-10'
    >
      Our Vision
    </Typography>

    <Typography
      variant='h2'
      className='tw-mx-auto text-center tw-mb-0'
      style={{ maxWidth: 800 }}
    >
      Have or need an employee handbook?
    </Typography>

    <Typography
      variant='h2'
      className='tw-mx-auto text-center tw-mb-10'
      style={{ maxWidth: 800 }}
    >
      Your experience should be blissful.
    </Typography>

    <Row className='tw-justify-center'>
      <TextColumn>
        Whether your organization is small, in the Fortune 500, a school, a
        non-profit, partners with a PEO, or anything else, our vision is for you
        and your audience to have a blissful handbook experience.
      </TextColumn>
      <TextColumn>
        We’re working hard to build a platform and company that supports
        everyone, and we’d love to hear your ideas and project needs. Below are
        some ways to connect with us.
      </TextColumn>
    </Row>
  </Section>
)
