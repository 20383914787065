import { Link } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'

import { MarketingLayout, Section, Typography } from '../components'

import { TOCList } from './TOC'
import {
  CaseStudy,
  ChapterHeader,
  DownloadWorkbook,
  ExternalLink,
  HowToSection,
  PersonCard,
  QuoteBordered,
} from './components'

import { images, urls } from './howToConfig'

import './styles/how-to-ebook.scss'

export default () => (
  <MarketingLayout
    title='Project Planning an Employee Handbook Rewrite: The Step Work'
    description={
      'Project planning when undertaking policies and procedures or an employee handbook. Includes details on team collaboration, project management, technical writing, and distribution.'
    }
    className='how-to-ebook'
  >
    <ChapterHeader
      title='2: The Step Work'
      subtitle='Planning Your Handbook Project'
      prev={{ url: urls.prepWork, text: '1. The Prep Work' }}
      next={{ url: urls.depthWork, text: '3. The Depth Work' }}
      img={images.headerStepWork.src}
    />

    <HowToSection
      className='container tw-mt-14'
      main={
        <Fragment>
          <Typography variant='p2' className='tw-pb-6'>
            Now that you’ve put in the prep work, it’s time to focus on the
            tangible steps of your project. This means bringing in departments
            across your company and working on a project timeline.
          </Typography>
          <QuoteBordered
            quote='Pick a few people you trust. Keep the team small.'
            attribution={{
              name: 'Chanin Christensen',
              title: (
                <span>
                  SVP / HR Manager at{' '}
                  <ExternalLink href='https://merrickbank.com/'>
                    Merrick Bank
                  </ExternalLink>
                </span>
              ),
            }}
          />
        </Fragment>
      }
      side={<TOCList activeIndex={2} />}
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h2'>Who Can Add Value?</Typography>
          <Typography variant='p2'>
            You’ll want to start with a small team in the beginning; we all know
            that saying about too many cooks in the employee handbook. Different
            perspectives can feel like potential obstacles, but instead, reflect
            on ways in which multiple viewpoints can result in more
            comprehensive, beneficial handbook.
          </Typography>
          <Typography variant='p2'>
            <strong>Does IT need to support any software you select?</strong> If
            so, they’ll probably have questions for the vendor you choose. If
            your organization is audited as a requirement for obtaining certain
            certifications (e.g. SOC 2), you may want to ask IT or finance about
            reporting requirements.
          </Typography>
          <Typography variant='p2'>
            <strong>
              Will your new policies have an impact on sister companies,
              subsidiaries, departments, or parent companies?
            </strong>{' '}
            If you’re a global company, you may want to involve HR from another
            part of the world. By including HR colleagues from other locations
            or divisions, you have the opportunity to consider the needs of all
            employees.
          </Typography>
        </Fragment>
      }
    />

    <DownloadWorkbook
      extraMarginTop='-130px'
      extraMarginBottom='-120px'
      text='Did you know? The free companion Workbook can help you check off to-dos and requirements for each department.'
      stack={[
        images.workbook.whoToInvolve,
        images.workbook.milestones1,
        images.workbook.milestones2,
      ]}
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            The Right People at the Right Time
          </Typography>
          <Typography variant='p2'>
            As you identify potential collaborators, consider when to involve
            them. To give your project the greatest potential for success,
            working with these people at the right time is critical. For
            example, you’ll likely want to engage executive leadership early to
            let them know about policy changes and your proposed solutions, but
            you may also need to get budget approval for any outside counsel or
            software.
          </Typography>

          <Typography variant='h4'>Attorneys / Legal</Typography>
          <Typography variant='p2'>
            Engaging your attorneys early may prevent a rewriting frenzy later.
            While your attorneys review your existing handbook and flag areas
            that may invite litigation, you can gain buy-in for your recommended
            changes and identify areas to highlight your company culture.
          </Typography>

          <Typography variant='h4'>
            Marketing / Design / Communications
          </Typography>
          <Typography variant='p2'>
            In the initial planning stages, you may also want to talk to someone
            responsible for the brand, which can cover everything from visual
            design to editorial guidelines. To publish a guide that your
            employees will actually read, you’ll want to write it in the company
            voice and use a tone that’s conversational but still appropriate for
            communicating company policies.
          </Typography>
          <Typography variant='p2'>
            Your marketing team can also provide recommendations and resources
            for copywriting and visual design. They can work with you to
            translate legal-speak into more digestible copy, and provide
            summaries about your company, your policies, and the reasons
            employees should care (your mission, purpose, cause, values, vision,
            etc.). With enough notice, your marketing team can plan their
            workload to support you when you’re ready.
          </Typography>

          <Typography variant='h4'>External Help</Typography>
          <Typography variant='p2'>
            Sometimes it’s not feasible to have internal support for writing or
            visual design, so there are other options you can consider. For
            example, a design, branding, or communications agency can help you
            create a friendlier, more engaging handbook; plus, there are always
            freelancers who are eager to take on a new client. By providing them
            with your brand and editorial guides, they can add some magic to all
            your hard work. Some policy management platforms (like Blissbook!)
            can even provide this as a service.
          </Typography>

          <Typography variant='h4'>Leadership</Typography>
          <Typography variant='p2'>
            You can add emotional depth to your guide by including a brief
            statement from your CEO. It’s common practice for CEOs to provide a
            shareholder statement at the beginning of annual reports, so why not
            have your CEO write a short letter to your employees to serve as an
            introduction to your policies? Consider stories like why the company
            was founded and the importance it plays in the lives of employees,
            customers, and the community. If you’re looking for inspiration on
            how to build such a message, our other eBook:{' '}
            <a
              href='/how-to-write-a-culture-first-employee-handbook'
              target='_blank'
              rel='noreferrer'
            >
              How to Write a Culture-First Employee Handbook
            </a>
            , is an excellent resource.
          </Typography>
          <Typography variant='p2'>
            Also, don’t feel like everything has to be in writing. Instead of a
            letter from your CEO, try a video where the CEO speaks directly to
            your company. This is a great way to show your employees how much
            they are valued, and it sets a tone that’s likely to be different
            from any other handbook they’ve seen. Companies who build the right
            culture find that they don’t need to write page after page.
          </Typography>
        </Fragment>
      }
    />

    <CaseStudy title='Case Study: Policy Writing Process'>
      <Typography variant='p2'>
        When it comes to creating policies, many from scratch, Lindsey Kucharski
        has learned a lot about what works. Having been through it multiple
        times, she recommended the following process:
      </Typography>
      <ul className='p2 tw-mb-8'>
        <li>
          She starts by writing the first draft of any policy, then she engages
          her team to review.
        </li>
        <li>
          From there, she looks for legal buy-in to make sure the policy works
          to protect the company.
        </li>
        <li>
          Then, if she has an internal communications or branding team, she
          looks to them to provide recommendations on tone.
        </li>
        <li>
          After that, she’ll bring in executive stakeholders to provide
          feedback.
        </li>
      </ul>
      <PersonCard
        img={images.profileLindsey.src}
        name='Lindsey Kucharski'
        title={
          <span>
            Former Global HR Business Partner &amp; HR Manager at{' '}
            <ExternalLink href='https://www.blueprism.com/'>
              Blue Prism
            </ExternalLink>
          </span>
        }
      />
    </CaseStudy>

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h2'>What’s the Plan?</Typography>
          <Typography variant='p2'>
            Once you’ve defined your needs, who to involve, and your timing,
            you’re ready to develop your project plan. Some find it easier to
            start with the final product in mind, building backwards from the
            end state to the first discussion. By using a project management
            program, you can easily adjust timelines and communicate changes to
            all stakeholders. Since your handbook may take several months and
            require support from various departments, setting and communicating
            delivery dates will help keep your project on track.
          </Typography>
          <Typography variant='h3' className='dark'>
            Common milestones for an employee handbook overhaul project:
          </Typography>
          <ul className='p2 tw-mb-8'>
            <li>
              Build the project foundation with leadership approval, vendor
              approval, and paperwork
            </li>
            <li>
              Implement, draft, and get new content approved (the lengthiest
              part of the project!)
            </li>
            <li>Prep for distribution with your IT department</li>
            <li>Implement design with your marketing or design team</li>
            <li>Launch!</li>
          </ul>
        </Fragment>
      }
    />

    <DownloadWorkbook
      className='container tw-my-6'
      text="Need more? There's a detailed milestone timeline template in the free companion workbook. Get it here!"
      noImage={true}
    />

    <Section className='container tw-p-18 text-center'>
      <Fragment>
        <Typography variant='h2'>Next Chapter: The Depth Work</Typography>
        <Typography variant='p2'>
          <Link className='btn btn-link tw-m-2' href={urls.prepWork}>
            <FontAwesomeIcon className='tw-mr-2' icon='arrow-left' />
            Previous
          </Link>
          <Link className='btn btn-primary tw-m-2' href={urls.depthWork}>
            Next: Drafting Policies &amp; Procedures
          </Link>
        </Typography>
      </Fragment>
    </Section>
  </MarketingLayout>
)
