import { ForwardLink, Link, ShapeGrid } from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { MaxWidthContainer, Section, Typography } from '../../components'

export default () => (
  <Section id='leaders' className='container py-sm'>
    <ShapeGrid
      css={css`
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
      `}
      className='tw-hidden lg:tw-block'
      alignX='right'
      alignY='top'
      shapeDefaults={{ fill: true }}
      shapes={[
        { x: 0, y: 1, type: 'square', color: 'aqua-100' },
        { x: 1, y: 3, type: 'square', color: 'sunshine-100' },
        { x: 0, y: 4, type: 'square', color: 'sunshine-500' },
        { x: 1, y: 4, type: 'wedge', color: 'sunshine-300' },
        { x: 0, y: 5, type: 'square', color: 'sunshine-700' },
        { x: 0, y: 6, type: 'square', color: 'blurple-500' },
        { x: 0, y: 7, type: 'wedge', color: 'blurple-300', rotate: 180 },
      ]}
    />

    <MaxWidthContainer className='tw-mx-auto text-center' xs={780}>
      <Typography variant='headline'>
        Blissbook is designed for HR leaders like you.
      </Typography>
      <Typography variant='p1'>
        <em>Leaders who care about the employee experience.</em>
      </Typography>
      <Typography variant='p1'>
        Are you at a growing company that’s all about culture? An enterprise
        that needs policy management &amp; powerful reporting? Somewhere in
        between? Blissbook was built for you.
      </Typography>
      <div className='tw-mb-8'>
        <Link className='btn btn-primary' to='/request-a-demo'>
          Get Started
        </Link>
      </div>
      <ForwardLink to='/employee-handbook-software'>
        Learn More on our Product Page
      </ForwardLink>
    </MaxWidthContainer>
  </Section>
)
