import { ssoProviders } from '@blissbook/lib/auth'
import { renderContent } from '@blissbook/lib/document/renderer/html'
import config from '@blissbook/ui-config'
import { Button, ButtonLink, FullScreenPage, Head } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import { cx } from '@emotion/css'
import isFunction from 'lodash/isFunction'
import { useState } from 'react'
import { AuthBackLink, AuthLayout, Typography } from '../../components'
import { AuthCodeForm } from './AuthCodeForm'
import { EmailAuthForm } from './EmailAuthForm'
import { EmployeeIdAuthForm } from './EmployeeIdAuthForm'
import { SignInErrorPage, SignedOutPage } from './ErrorPage'
import { HelpForm } from './HelpForm'
export { SignedOutPage }

const emailAuthMethod = {
  Form: EmailAuthForm,
  authType: 'email',
  buttonText: 'Sign in via email address',
  helpMessage: `I'm having trouble signing in and I already checked my spam/junk folder for the email you sent. Can you help?`,
  waitMinutes: 15,
}

const employeeIdAuthMethod = {
  authType: 'employeeId',
  buttonText: 'Sign in via employee ID',
  Form: EmployeeIdAuthForm,
}

// Get the auth methods
const getAuthMethods = (authSettings) => {
  // If no auth settings, just return email
  if (!authSettings) return [emailAuthMethod]

  // Otherwise, return based on authSettings
  const { emailAuth, employeeIdAuth } = authSettings
  return [
    emailAuth && emailAuthMethod,
    employeeIdAuth && {
      ...employeeIdAuthMethod,
      buttonText: employeeIdAuth.buttonText,
      helpContent: employeeIdAuth.helpContent,
    },
  ].filter(Boolean)
}

// Get the ssos
const getSingleSignOns = (authSettings) => {
  const ssos = authSettings ? authSettings.singleSignOns : []
  return ssos.map((sso) => ({
    ...sso,
    provider: ssoProviders.findById(sso.providerId),
  }))
}

// Determine the initial authType
const getDefaultAuthMethod = ({ auth, authMethods, singleSignOns }) => {
  // Force email?
  const emailAuth = authMethods.find((m) => m.authType === 'email')
  if (emailAuth && auth.email.length) return emailAuth
  // SSO?
  if (singleSignOns.length > 0) return
  // Use first available
  return authMethods[0]
}

const renderSsoText = (sso, key, organization) => {
  // If there is an override, use that
  if (sso[key]) return sso[key]
  // Use the default text
  const ssoText = sso.provider[key]
  return isFunction(ssoText) ? ssoText(organization) : ssoText
}

const buttonStyle = { maxWidth: 300, padding: '.5rem 1.5rem', width: '100%' }

const SsoButton = ({ className, organization, sso, ...props }) => (
  <a
    {...props}
    className={`btn btn-sso -${sso.providerId} ${className}`}
    style={buttonStyle}
    href={`/auth/${sso.providerId}`}
  >
    {renderSsoText(sso, 'buttonText', organization)}
  </a>
)

const SsoLink = ({ organization, sso, ...props }) => (
  <div {...props}>
    <a href={`/auth/${sso.providerId}`}>
      {renderSsoText(sso, 'linkText', organization)}
    </a>
  </div>
)

export const SignInPage = (props) => {
  const { error } = useStore()
  const Component = error ? SignInErrorPage : SignInAuthPage
  return <Component {...props} />
}

const SignInAuthPage = () => {
  const { auth, authSettings, organization } = useStore()

  const imageUrl = organization
    ? undefined
    : '/images/monogram-shadow-outline-offblack.svg'
  const [authCodeState, setAuthCodeState] = useState()
  const [authMethods] = useState(() => getAuthMethods(authSettings))
  const [singleSignOns] = useState(() => getSingleSignOns(authSettings))
  const [authMethod, setAuthMethod] = useState(() =>
    getDefaultAuthMethod({ auth, authMethods, singleSignOns }),
  )
  const [showHelp, setShowHelp] = useState(false)
  const otherAuthMethods = authMethods.filter((m) => m !== authMethod)
  const SsoComponent = authMethod ? SsoLink : SsoButton

  const helpContent = authMethod?.helpContent ? (
    <>
      <Typography className='auth' variant='h1'>
        Help With Sign In.
      </Typography>

      <Typography className='auth' variant='p2'>
        {renderContent(authMethod.helpContent)}
      </Typography>

      <ButtonLink onClick={() => setShowHelp(false)}>Go Back</ButtonLink>
    </>
  ) : (
    <HelpForm
      helpContent={authMethod?.helpContent}
      initialBody={authMethod?.helpMessage}
      onClose={() => setShowHelp(false)}
    />
  )

  return (
    <FullScreenPage hideBackground={organization}>
      <Head
        title={
          organization
            ? `Sign In to ${organization.name}`
            : 'Sign In to Blissbook'
        }
        description='Sign in to your online employee handbook. HR Professional? Create and edit a handbook. Employee? Review current company policies or sign your handbook.'
      />
      <AuthLayout imageUrl={imageUrl}>
        {showHelp ? (
          helpContent
        ) : (
          <>
            {!authCodeState ? (
              <>
                <Typography className='auth' variant='h1'>
                  {organization ? 'Sign in.' : 'Sign in to Blissbook.'}
                </Typography>

                {authMethod ? (
                  <authMethod.Form onSubmit={setAuthCodeState} />
                ) : (
                  <Typography className='auth' variant='p2'>
                    {organization
                      ? `Sign in to ${organization.name}.`
                      : 'Come on in, the water’s fine!'}
                  </Typography>
                )}

                {singleSignOns.map((sso) => (
                  <SsoComponent
                    key={sso.providerId}
                    className='tw-block tw-mt-6'
                    organization={organization}
                    sso={sso}
                  />
                ))}

                {otherAuthMethods.map((method) => (
                  <Button
                    className={cx(
                      'tw-block tw-mt-6',
                      authMethod ? 'btn-link' : 'btn btn-outline-dark',
                    )}
                    key={method.authType}
                    onClick={() => setAuthMethod(method)}
                    style={authMethod ? undefined : buttonStyle}
                  >
                    {method.buttonText}
                  </Button>
                ))}
              </>
            ) : (
              <>
                <Typography className='auth' variant='h1'>
                  Enter the Verification Code.
                </Typography>

                {authCodeState.headerEl}

                <AuthCodeForm userId={authCodeState.userId} />

                <Typography variant='muted'>
                  Didn't get a code?{' '}
                  {!authMethod.waitMinutes ? (
                    <>
                      <ButtonLink onClick={() => setAuthCodeState()}>
                        Try again
                      </ButtonLink>
                      .
                    </>
                  ) : (
                    <>
                      You may need to wait up to {authMethod.waitMinutes}{' '}
                      minutes. Or,{' '}
                      <ButtonLink onClick={() => setAuthCodeState()}>
                        try again
                      </ButtonLink>
                      .
                    </>
                  )}
                </Typography>

                <ButtonLink
                  className='tw-block tw-mt-6'
                  onClick={() => setAuthCodeState()}
                >
                  Go back to the sign in page.
                </ButtonLink>
              </>
            )}

            {config.intercom && (authMethod || authMethods.length === 0) && (
              <ButtonLink
                className='tw-block tw-mt-6'
                onClick={() => setShowHelp(true)}
              >
                Trouble Signing In?
              </ButtonLink>
            )}
          </>
        )}

        {!organization && <AuthBackLink className='tw-block tw-mt-6' />}
      </AuthLayout>
    </FullScreenPage>
  )
}
