import {
  type Organization,
  PhoneAuthMethod,
} from '@blissbook/ui/application/graph'
import { Dropdown, Field, Link } from '@blissbook/ui/lib'
import { setAuthValue } from '@blissbook/ui/marketing/actions'
import {
  type AuthPerson,
  useGetEmployeeIdAuthPersonLazyQuery,
  useSendEmployeeIdAuthMutation,
} from '@blissbook/ui/marketing/graph'
import { handleError } from '@blissbook/ui/util/errors'
import { useStore } from '@blissbook/ui/util/store'
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope'
import { faMessageSms } from '@fortawesome/pro-regular-svg-icons/faMessageSms'
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import parsePhoneNumber from 'libphonenumber-js'
import { useState } from 'react'
import * as Yup from 'yup'
import { AuthButton, Typography } from '../../components'
import { submitEmail } from './EmailAuthForm'

const validationSchema = Yup.object().shape({
  employeeId: Yup.string().required(),
})

type EmployeeIdAuthFormValues = {
  employeeId: string
}

type PhoneAuthMethodMeta = {
  disclaimer: ({ organization }: { organization: Organization }) => string
  icon: IconDefinition
  label: string
}

const phoneAuthMethodMetas: Record<PhoneAuthMethod, PhoneAuthMethodMeta> = {
  [PhoneAuthMethod.Sms]: {
    disclaimer: ({ organization }) =>
      `By selecting SMS Text, you give ${organization.name} permission to send you a text message. You are responsible for any and all charges that result from this action and you will not be reimbursed.`,
    icon: faMessageSms,
    label: 'Receive an SMS Text',
  },
  [PhoneAuthMethod.Voice]: {
    disclaimer: ({ organization }) =>
      `By selecting Voice Call, you give ${organization.name} permission to call you. You are responsible for any and all charges that result from this action and you will not be reimbursed.`,
    icon: faPhone,
    label: 'Receive a Voice Call',
  },
}

type AuthCodeState = {
  headerEl: React.ReactNode
  userId: number
}

function getPhoneNumbers(person: AuthPerson) {
  const phoneNumbers = new Set<string>()
  const { mobilePhoneNumber, homePhoneNumber, workPhoneNumber } = person
  if (mobilePhoneNumber) phoneNumbers.add(mobilePhoneNumber)
  if (homePhoneNumber) phoneNumbers.add(homePhoneNumber)
  if (workPhoneNumber) phoneNumbers.add(workPhoneNumber)
  return [...phoneNumbers]
}

function formatPhoneNumber(phoneNumber: string) {
  const phone = parsePhoneNumber(phoneNumber)
  return phone.countryCallingCode === '1'
    ? phone.formatNational()
    : phone.formatInternational()
}

export const EmployeeIdAuthForm = ({
  onSubmit,
}: {
  onSubmit: (result: AuthCodeState) => void
}) => {
  const { organization } = useStore()
  const [person, setPerson] = useState<AuthPerson>()
  const [phoneAuthMethod, setPhoneAuthMethod] = useState(PhoneAuthMethod.Sms)
  const [isSubmitting, setSubmitting] = useState(false)
  const [getEmployeeIdAuthPerson] = useGetEmployeeIdAuthPersonLazyQuery()
  const [sendEmployeeIdAuth] = useSendEmployeeIdAuthMutation()
  const values = useStore('auth')

  async function handleSubmitEmployeeId(values: EmployeeIdAuthFormValues) {
    setSubmitting(true)

    try {
      const { employeeId } = values
      const { data, error } = await getEmployeeIdAuthPerson({
        variables: { employeeId },
      })

      if (error) {
        handleError(error)
      } else {
        setPerson(data.employeeIdAuthPerson)
      }
    } catch (error) {
      handleError(error)
    }

    setSubmitting(false)
  }

  async function handleSubmitEmail(email: string) {
    setSubmitting(true)

    try {
      setAuthValue('email', email)
      setAuthValue('fullName', person.fullName)
      await submitEmail(email, onSubmit)
    } catch (error) {
      handleError(error)
      setSubmitting(false)
    }
  }

  async function handleSubmitPhoneNumber(phoneNumber: string) {
    setSubmitting(true)

    try {
      const { employeeId } = person
      setAuthValue('fullName', person.fullName)
      setAuthValue('phoneNumber', phoneNumber)
      const { data } = await sendEmployeeIdAuth({
        variables: { employeeId, phoneAuthMethod, phoneNumber },
      })
      const { userId } = data.sendEmployeeIdAuth

      onSubmit({
        headerEl: <PhoneAuthCodeHeader phoneNumber={phoneNumber} />,
        userId,
      })
    } catch (error) {
      handleError(error)
      setSubmitting(false)
    }
  }

  if (!person) {
    return (
      <Formik
        initialValues={values}
        onSubmit={handleSubmitEmployeeId}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ handleSubmit }) => (
          <form id='sign-in-employee-id' noValidate onSubmit={handleSubmit}>
            <Typography className='auth' variant='p2'>
              Sign in to {organization.name}.
            </Typography>

            <fieldset disabled={isSubmitting}>
              <Field
                name='employeeId'
                label='Your Employee ID'
                onChangeValue={(value: string) =>
                  setAuthValue('employeeId', value)
                }
                autoFocus
              />

              <div className='form-group'>
                <AuthButton type='submit'>Next</AuthButton>
              </div>
            </fieldset>
          </form>
        )}
      </Formik>
    )
  }

  const { email } = person
  const optionClassName =
    'tw-flex tw-items-center tw-gap-3 btn btn-dark tw-w-96'
  const phoneAuthMethodMeta = phoneAuthMethodMetas[phoneAuthMethod]
  const phoneNumbers = getPhoneNumbers(person)
  return (
    <>
      <Typography className='auth' variant='p2'>
        Choose a contact method below to verify your identity.
      </Typography>

      <div className='tw-flex tw-flex-col tw-gap-4'>
        {email && (
          <>
            <div className='tw-text-lg tw-font-semibold'>Receive an Email</div>

            <Link
              className={optionClassName}
              onClick={() => handleSubmitEmail(email)}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              {email}
            </Link>
          </>
        )}

        {phoneNumbers.length > 0 && (
          <>
            <div>
              <Dropdown.Provider>
                <Dropdown.ToggleButton className='tw-text-lg tw-font-semibold'>
                  {phoneAuthMethodMeta.label}
                </Dropdown.ToggleButton>
                <Dropdown.Menu sameWidth>
                  <Dropdown.Item
                    onClick={() => setPhoneAuthMethod(PhoneAuthMethod.Sms)}
                  >
                    {phoneAuthMethodMetas.sms.label}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => setPhoneAuthMethod(PhoneAuthMethod.Voice)}
                  >
                    {phoneAuthMethodMetas.voice.label}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown.Provider>
            </div>

            {phoneNumbers.map((phoneNumber) => (
              <Link
                className={optionClassName}
                key={phoneNumber}
                onClick={() => handleSubmitPhoneNumber(phoneNumber)}
              >
                <FontAwesomeIcon icon={phoneAuthMethodMeta.icon} />
                {formatPhoneNumber(phoneNumber)}
              </Link>
            ))}

            <Typography variant='muted'>
              {phoneAuthMethodMeta.disclaimer({ organization })}
            </Typography>
          </>
        )}
      </div>
    </>
  )
}

export const obfuscatePhone = (phone: string) => {
  const digitCount = phone.replace(/\D/g, '').length
  let hideDigitCount = digitCount - 3

  let result = ''
  for (let index = 0; index < phone.length; index++) {
    let char = phone[index]
    if (char.match(/\d/) && hideDigitCount > 0) {
      hideDigitCount -= 1
      char = 'x'
    }
    result += char
  }
  return result
}

export function PhoneAuthCodeHeader({ phoneNumber }: { phoneNumber: string }) {
  return (
    <Typography className='auth' variant='p2'>
      We sent a 6-digit code to {obfuscatePhone(phoneNumber)}.
    </Typography>
  )
}
