import { colors } from '@blissbook/ui/branding'

import { Section, Typography } from '../../components'

import { images } from '../howToConfig'

export default ({ children, title, ...props }) => (
  <Section
    style={{ backgroundColor: colors['blurple-50'], margin: '60px 0' }}
    {...props}
  >
    <div className='container tw-py-10'>
      <div className='row'>
        <div className='col-md-8'>
          <Typography
            variant='h4'
            style={{ color: colors['blurple-500'], marginBottom: '30px' }}
          >
            {title}
          </Typography>
          {children}
        </div>
        <div className='col-md-4'>
          <div className='text-center tw-my-6 md:tw-my-0 md:tw-block tw-hidden'>
            <img {...images.caseStudy} alt='case study' />
          </div>
        </div>
      </div>
    </div>
  </Section>
)
