import { gridBreakpoints } from '@blissbook/ui/branding'
import {
  BackgroundShapeGrid,
  ForwardLink,
  Link,
  gridShapeSize,
} from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { Typography } from '../../components'
import { HeroSection } from '../sections'

export default () => (
  <HeroSection
    css={css`
      min-height: ${gridShapeSize * 4}px;
      @media (min-width: ${gridBreakpoints.lg}px) {
        min-height: ${gridShapeSize * 5}px;
      }
    `}
    background={
      <BackgroundShapeGrid
        alignX='right'
        alignY='top'
        shapeDefaults={{ fill: true }}
        shapes={[
          { x: -1, y: 0, type: 'wedge', color: 'aqua-300' },
          { x: 0, y: 0, type: 'wedge', color: 'aqua-500', rotate: 90 },
          { x: 1, y: 0, type: 'square', color: 'red-500' },
          { x: 2, y: 0, type: 'square', color: 'sunshine-500' },
          { x: 3, y: 0, type: 'wedge', color: 'sunshine-300', rotate: -90 },

          { x: 0, y: 1, type: 'quotes', color: 'sunshine-500', scale: 0.6 },
          { x: 1, y: 1, type: 'square', color: 'blurple-500' },
          { x: 2, y: 1, type: 'wedge', color: 'aqua-500', rotate: 90 },

          { x: 0, y: 2, type: 'square', color: 'red-500' },
          { x: 1, y: 2, type: 'wedge', color: 'purple-500', rotate: -90 },

          { x: 1, y: 3, type: 'square', color: 'purple-300' },

          {
            x: 2,
            y: 4,
            type: 'square',
            color: 'purple-100',
            className: 'tw-hidden lg:tw-block',
          },
        ]}
      />
    }
  >
    <Typography variant='h1'>Be a handbook superhero.</Typography>
    <Typography variant='p1'>
      Everything you need to create, distribute, and maintain an online employee
      handbook.
    </Typography>

    <div className='md:tw-flex tw-items-center'>
      <Link className='btn btn-primary tw-mr-6' to='/request-a-demo'>
        Get Started for Free
      </Link>
      <ForwardLink
        className='tw-block tw-my-6'
        to='/employee-handbook-software'
      >
        Learn More
      </ForwardLink>
    </div>
  </HeroSection>
)
