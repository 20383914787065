import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import { Col, ContactLink, Container, Row } from '@blissbook/ui/lib'
import { ShapeConfetti } from '@blissbook/ui/lib/shapes'
import { css } from '@emotion/react'
import tinycolor from 'tinycolor2'
import {
  DotsHorizontal,
  HeadingDivider,
  MarketingLayout,
  MaxWidthContainer,
  Section,
  Typography,
} from '../components'
import { CallToActionSection, SolutionSection } from './sections'

const backgroundColor = tinycolor(colors['purple-100'])
  .setAlpha(0.5)
  .toRgbString()
const color = 'purple-500'
const contactMessage = `Hello, I'd like to learn more about the Agency early access program.`
const dotColor = color + 'Light'

const FEATURES = [
  {
    title: 'Edit Once, Update Everywhere',
    description: `No more addendums, no more waiting years to update policies. Changes are quick and easy! Edit your master policies and push changes out to all of your
    clients’ handbooks.`,
  },
  {
    title: 'Custom Branding for Every Client',
    description: `Every handbook can “break” from your master templates so you can customize the logo, colors, fonts,
    and overall branding for any of your clients’ handbooks.`,
  },
  {
    title: 'Easily Onboard New Clients',
    description:
      'Create handbooks for new clients with ease. Just choose locations and policies and Blissbook builds the handbook for you, pulling in the necessary legal language.',
  },
  {
    title: 'Unburden Your Associates',
    description: `Lighten your policy management workload via automation (and reduce human error), so your
    associates can do the work they want to do instead of manually updating policies.`,
  },
  {
    title: 'Differentiate Yourself',
    description: `It can be tough to differentiate yourself as a PEO! Demonstrate to prospects how you outclass your
    competition by caring about the employee experience.`,
  },
  {
    title: 'Client Value-Add',
    description:
      'Your clients get a modern, up-to-date, and better-looking, working, and feeling handbook that makes your company look “with the times”.',
  },
]

export default () => (
  <MarketingLayout
    title='Employee Handbook Template Builder by Blissbook'
    description='Use a master employee handbook template to create many handbooks for different audiences and easily maintain them all.'
  >
    <SolutionSection
      color={color}
      image='Windows.svg'
      imageDesktopCss={css(`
        top: 40px;
        right: -150px;
        height: 480px;

        @media (min-width: ${gridBreakpoints.xl}px) {
          right: -110px;
        }
      `)}
      greeting='Hey Agencies!'
      title='Manage many handbooks.'
      description='Are you a PEO, ASO, HR consultant, benefits advisor, or another kind of agency that helps many clients with their employee handbooks and policy management? We’re working on something for you!'
    />

    <Section
      css={{
        background: `linear-gradient(180deg, white 0%, ${backgroundColor} 100%);`,
      }}
    >
      <Container className='py-sm' css={{ position: 'relative' }}>
        <ShapeConfetti
          css={css(`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
          `)}
          className='tw-hidden lg:tw-block'
          shapeDefaults={{ color: color + 'Light' }}
          shapes={[
            { x: -570, y: 120, type: 'wedge' },
            { x: -440, y: 170, type: 'square' },
            { x: -470, y: 300, type: 'wedge' },
            { x: -540, y: 450, type: 'square' },
            { x: -400, y: 500, type: 'quote', size: 45 },

            { x: 570, y: 120, type: 'wedge', rotate: 180 },
            { x: 450, y: 170, type: 'square' },
            { x: 500, y: 300, type: 'quote', size: 45 },
            { x: 400, y: 450, type: 'wedge' },
            { x: 560, y: 480, type: 'square' },
          ]}
        />

        <MaxWidthContainer className='tw-mx-auto tw-mb-14' xs={600}>
          <Typography variant='h2' className='tw-mx-auto text-center'>
            Agency Early Access Program
          </Typography>

          <Typography variant='p2'>
            Blissbook currently serves customers of all sizes via direct
            relationships. We’ve heard from several PEOs and consultants that
            they’d like a way to manage all of the handbooks they create and
            maintain for their clients in one place.
            <br />
            <br />
            In order to build out this solution we’re starting an early access
            program to partner with a few select early adopter customers who can
            help us bring this product to market. Think of it like a Kickstarter
            campaign. If you’re interested, please let us know.
          </Typography>

          <div className='text-center tw-mt-10'>
            <ContactLink
              className='btn btn-purple tw-mx-auto'
              message={contactMessage}
            >
              Contact Us
            </ContactLink>
          </div>
        </MaxWidthContainer>

        <MaxWidthContainer className='tw-mx-auto' xs={800}>
          <DotsHorizontal
            className='tw-my-18'
            color={dotColor}
            css={{ width: '100%' }}
          />

          <Row>
            {FEATURES.map((feature, index) => (
              <Col
                key={index}
                xs={12}
                md={6}
                lg={index % 2 === 0 ? 7 : 5}
                className='tw-mb-10'
              >
                <MaxWidthContainer lg={320}>
                  <HeadingDivider variant='h2' divider={{ color: dotColor }}>
                    {feature.title}
                  </HeadingDivider>

                  <Typography variant='p2'>{feature.description}</Typography>
                </MaxWidthContainer>
              </Col>
            ))}
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <CallToActionSection
      title='Now what?'
      description='Our Agency Early Access program is in the early stages and we’re looking for launch partners. If managing many handbooks for your clients is a problem that you’d like our help solving, please let us know.'
    >
      <ContactLink className='btn btn-primary' message={contactMessage}>
        Contact Us
      </ContactLink>
    </CallToActionSection>
  </MarketingLayout>
)
