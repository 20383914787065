import { css } from '@emotion/react'

import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import {
  BackgroundShapeGrid,
  Container,
  DesktopBrowser,
  ForwardLink,
  Link,
  MobileBrowser,
} from '@blissbook/ui/lib'
import {
  Card,
  MarketingLayout,
  MaxWidthContainer,
  Section,
  Typography,
} from '../components'
import { CallToActionSection, SolutionSection } from './sections'

import config from '@blissbook/ui-config'
const { assetsUrl = '' } = config
const imgFolder =
  assetsUrl + '/images/marketing/resources/enterprise-policy-management/'
const imgFolderAlt =
  assetsUrl + '/images/marketing/resources/hr-policy-content-services/'

const assets = {
  browserHandbook: '/images/marketing/resources/screens/browser-handbook.png',
  brain: imgFolder + 'brain.png',
  ffrown: imgFolder + 'ffrown.svg',
  thumbsUp: imgFolder + 'thumbs-up.svg',
  annotation: imgFolder + 'annotation.png',
  logos: imgFolder + 'logos.png',
  queryBuilder: imgFolder + 'query-builder.png',
  limitAccess: imgFolder + 'limit-access.png',
  group: imgFolder + 'group.svg',
  lawChangeAlerts: imgFolderAlt + 'law-change-alerts-illustration.png',
  dashboard: imgFolder + 'dashboard.png',
  workdayLogo: imgFolder + 'workday.png',
  ukgLogo: imgFolder + 'ukg.png',
  activeDirLogo: imgFolder + 'active-directory.png',
  ceridianLogo: imgFolder + 'ceridian.png',
  oktaLogo: imgFolder + 'okta.png',
  samlLogo: imgFolder + 'saml.png',
  adpLogo: imgFolder + 'adp.png',
  bambooLogo: imgFolder + 'bamboo-hr.png',
  gWorkspaceLogo: imgFolder + 'google-workspace.png',
}

export default () => (
  <MarketingLayout
    title='Blissbook Product Overview: Enterprise Policy Management Software'
    description='Enterprise Policy Management Software: Manage thousands of employees’ relationships with their handbooks, policies, procedures, SOPs, and more.'
    className='enterprise-policy-management'
  >
    <SolutionSection
      color='red-500'
      image='Building.svg'
      imageDesktopCss={css(`
          top: 70px;
          right: -250px;
          height: 400px;

          @media (min-width: ${gridBreakpoints.xl}px) {
            right: -220px;
          }
        `)}
      imageMobileCss={css(`
          transform: scaleX(-1);
        `)}
      greeting='All sizes welcome'
      title='Enterprise Policy Management.'
      description='Blissbook gives teams and individuals at large organizations the power to manage thousands of employees’ relationships with their handbooks, policies, procedures, SOPs, and more.'
    />

    <Section>
      <Container className='tw-py-14 tw-mb-14'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <div className='row tw-align-stretch tw-flex-row-reverse'>
            <div className='col-lg-6 text-center'>
              <DesktopBrowser
                className='box-shadow tw-mx-auto tw-mb-14 lg:tw-mb-0'
                css={{ width: '400px' }}
              >
                <img
                  alt='a browser displaying a handbook'
                  src={assets.browserHandbook}
                  style={{
                    height: '221px',
                    width: '400px',
                  }}
                />
              </DesktopBrowser>
            </div>
            <div className='col-lg-6 my-auto'>
              <Typography variant='h2'>
                Compliance Reporting at Your Fingertips
              </Typography>
              <Typography variant='p2'>
                Ace your{' '}
                <Link to='/legal-compliance'>SOC 2 or other audits</Link> and be
                ready for any kind of litigation or hearing. Blissbook documents
                everything and allows you to slice and dice documentation to
                create the reports you need.
              </Typography>
            </div>
          </div>
        </MaxWidthContainer>
      </Container>
    </Section>

    <Section style={{ backgroundColor: colors['blurple-50'] }}>
      <Container className='py-sm text-center'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <img
            alt='a Brain'
            src={assets.brain}
            className='tw-mx-auto tw-mb-8'
            style={{
              height: '96px',
              width: '94px',
            }}
          />
          <Typography variant='h2'>
            Employee Experience &amp; Engagement
          </Typography>
          <Typography variant='p1'>
            Change how your employees think about their employment relationship:
          </Typography>
          <div className='row lg:tw-mx-18 tw-items-center'>
            <div className='col-lg-6 tw-my-8'>
              <Card
                className='text-left tw-mx-auto lg:tw-mb-0 tw-mb-14'
                color='sunshine-500'
                style={{ maxWidth: '370px', overflow: 'visible' }}
                title='From:'
                titleCss={{ textAlign: 'left' }}
                shape={
                  <img
                    alt='face frown emoji'
                    src={assets.ffrown}
                    className='tw-mx-auto tw-mb-8'
                    style={{
                      position: 'absolute',
                      right: 20,
                      top: -13,
                      height: '46px',
                      width: '46px',
                    }}
                  />
                }
              >
                <Typography variant='p2'>
                  I’m entering a legal transaction between two adversarial
                  parties.
                </Typography>
              </Card>
            </div>
            <div className='col-lg-6 tw-my-8'>
              <Card
                className='text-left tw-mx-auto'
                color='aqua-500'
                style={{ maxWidth: '370px', overflow: 'visible' }}
                title='To:'
                titleCss={{ textAlign: 'left' }}
                shape={
                  <img
                    alt='thumbs up emoji'
                    src={assets.thumbsUp}
                    className='tw-mx-auto tw-mb-8'
                    style={{
                      position: 'absolute',
                      right: 20,
                      top: -25,
                      height: '55px',
                      width: '47px',
                    }}
                  />
                }
              >
                <Typography variant='p2'>
                  I’m joining a team on a mission with some rules for how we
                  work together successfully.
                </Typography>
              </Card>
            </div>
          </div>
        </MaxWidthContainer>
      </Container>
    </Section>

    <Section className='tw-mt-14 lg:tw-mt-0'>
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <div className='row tw-mb-18 lg:tw-mb-14'>
            <div className='col-lg-6 tw-mb-18'>
              <div
                className='tw-mb-14 lg:tw-mb-6 tw-p-4 box-shadow'
                style={{
                  borderRadius: 5,
                  display: 'inline-block',
                }}
              >
                <img
                  alt='a example of an annotation'
                  src={assets.annotation}
                  style={{
                    maxHeight: '149px',
                    maxWidth: '229px',
                  }}
                />
              </div>
              <Typography variant='h2'>
                Communicate Changes with Employees
              </Typography>
              <Typography variant='p2'>
                Keep thousands of employees updated in a personalized way.
                Attach{' '}
                <Link to='/policy-communication'>
                  digital sticky notes to policies
                </Link>{' '}
                to provide context alongside new or updated content.
              </Typography>
            </div>
            <div className='col-lg-6 lg:tw-mt-0 tw-mt-8'>
              <div
                className='tw-grid tw-grid-cols-3 tw-gap-8 tw-mb-14 lg:tw-mb-6'
                style={{
                  maxWidth: '600px',
                  margin: '0 auto',
                }}
              >
                {[
                  { src: assets.workdayLogo, alt: 'Workday logo' },
                  { src: assets.ukgLogo, alt: 'Ultimate Software logo' },
                  {
                    src: assets.activeDirLogo,
                    alt: 'Microsoft Active Directory logo',
                  },
                  { src: assets.ceridianLogo, alt: 'Ceridian logo' },
                  { src: assets.oktaLogo, alt: 'Okta logo' },
                  { src: assets.samlLogo, alt: 'SAML 2.0 logo' },
                  { src: assets.adpLogo, alt: 'ADP logo' },
                  { src: assets.bambooLogo, alt: 'BambooHR logo' },
                  { src: assets.gWorkspaceLogo, alt: 'Google Suite logo' },
                ].map((logo, i) => (
                  <div
                    key={i}
                    className='tw-flex tw-items-center tw-justify-center'
                  >
                    <img
                      src={logo.src}
                      alt={logo.alt}
                      style={{
                        maxHeight: '40px',
                        maxWidth: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                ))}
              </div>
              <Typography variant='h2'>
                Securely Automate On- and Off-boarding
              </Typography>
              <Typography variant='p2'>
                Automatically handle new and termed employees by integrating
                with your HRIS and SSO providers. We support Workday, UltiPro,
                ADP, Okta, OneLogin, <a href='/integrations'>and more</a>.
              </Typography>
            </div>
          </div>
          <div className='row tw-mb-18 lg:tw-mb-14'>
            <div className='col-xl-4 col-md-6 text-center tw-p-2'>
              <div
                className='tw-p-4 box-shadow tw-mb-14 lg:tw-mb-6'
                style={{
                  borderRadius: 5,
                  display: 'inline-block',
                }}
              >
                <img
                  alt='an example of limiting access to content'
                  src={assets.limitAccess}
                  style={{
                    maxHeight: '140px',
                  }}
                />
              </div>
            </div>
            <div className='col-xl-8 col-md-6'>
              <Typography variant='h2'>Personalized Content</Typography>
              <Typography variant='p2'>
                Dozens of handbooks &amp; policies become one or a few with
                Blissbook's{' '}
                <Link to='/personalized-handbooks'>personalized content</Link>{' '}
                features. Employees simply sign in and see the content that’s
                relevant to them.
              </Typography>
            </div>
          </div>
          <div className='row tw-mb-18 lg:tw-mb-14 tw-flex-row-reverse'>
            <div className='col-lg-4 col-md-6 text-center tw-mb-14 lg:tw-mb-6'>
              <img
                alt='a group of people'
                src={assets.group}
                style={{
                  maxHeight: '126px',
                }}
              />
            </div>
            <div className='col-lg-8 col-md-6'>
              <Typography variant='h2'>
                Teamwork Makes the Dream Work
              </Typography>
              <Typography variant='p2'>
                Give managers reports about their teams’ signature status, CC
                them on reminders, and use orgCollaborator roles to enlist admin
                help from your colleagues with Blissbook's{' '}
                <Link to='/manager-participation'>manager participation</Link>{' '}
                features.
              </Typography>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-4 col-md-6 text-center tw-p-2'>
              <div
                className='tw-p-4 box-shadow tw-mb-14 lg:tw-mb-6'
                style={{
                  borderRadius: 5,
                  display: 'inline-block',
                }}
              >
                <img
                  alt='an example of a law change alert'
                  src={assets.lawChangeAlerts}
                  style={{
                    maxHeight: '140px',
                  }}
                />
              </div>
            </div>
            <div className='col-xl-8 col-md-6'>
              <Typography variant='h2'>Law Change Alerts</Typography>
              <Typography variant='p2'>
                Blissbook’s{' '}
                <Link to='/employee-handbook-hr-policy-content'>
                  Law Change Alerts
                </Link>{' '}
                keep you informed of changes in US federal, state, county, and
                city laws that may require you to update your handbook policies.
              </Typography>
            </div>
          </div>
        </MaxWidthContainer>
      </Container>
    </Section>

    <Section className='tw-py-14'>
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <div className='row tw-flex-row-reverse tw-items-center'>
            <div className='col-lg-6'>
              <DesktopBrowser
                className='box-shadow tw-mx-auto tw-mb-18 lg:tw-mb-0'
                css={css(`
                    width: 400px;
                    max-width: 100%;
                    @media (min-width: ${gridBreakpoints.lg}px) {
                      width: 820px;
                      max-width: 820px;
                    }
                  `)}
              >
                <img
                  src={assets.dashboard}
                  style={{ width: '100%' }}
                  alt='an example of a dashboard with some fake data'
                />
              </DesktopBrowser>
            </div>
            <div className='col-lg-6'>
              <Typography variant='h4' style={{ color: colors['red-500'] }}>
                There’s no place like home
              </Typography>
              <Typography variant='h2'>Employee Policy Dashboard</Typography>
              <Typography variant='p2'>
                Organize content how you see fit. Put all your content in one
                handbook, with most of it behind a read more button to keep it
                readable, or split it amongst several documents and collect
                separate signatures for specific policies.
              </Typography>
              <Typography variant='p2'>
                No matter how you do it, it’s easy for employees to view,
                search, and sign all handbooks, manuals, policies, SOPs,
                procedures, etc. in one place. They'll even see an alert if
                their signature is required or they have changes to view.
              </Typography>
            </div>
          </div>
        </MaxWidthContainer>
      </Container>
      <BackgroundShapeGrid
        className='tw-hidden lg:tw-block'
        alignX='left'
        alignY='bottom'
        style={{
          position: 'relative',
          bottom: '-120px',
        }}
        shapes={[
          { x: 0, y: 2, type: 'square', color: 'red-700', fill: true },
          {
            x: 1,
            y: 1,
            type: 'wedge',
            color: 'red-300',
            rotate: 0,
            fill: true,
          },
        ]}
      />
    </Section>

    <Section>
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <div className='row tw-flex-row-reverse'>
            <div className='col-lg-6 tw-flex tw-flex-col tw-items-center tw-mb-8 lg:tw-mb-0'>
              <DesktopBrowser className='box-shadow' css={{ width: 400 }}>
                <img
                  alt='a desktop'
                  src='/images/marketing/home/Create-desktop.svg'
                  width='100%'
                />
              </DesktopBrowser>
              <MobileBrowser
                className='box-shadow'
                css={{
                  marginTop: -100,
                  marginRight: -320,
                  width: 150,
                  zIndex: 1,
                }}
              >
                <img
                  alt='a mobile browser'
                  src='/images/marketing/home/Create-mobile.svg'
                  width='100%'
                  css={{
                    background: `linear-gradient(white 50%,${colors['blurple-200']} 50%);`,
                  }}
                />
              </MobileBrowser>
            </div>
            <div className='col-lg-6'>
              <Typography variant='h2'>100% Custom Branded</Typography>
              <Typography variant='p2'>
                Make asking employees to read policies a reasonable request.
              </Typography>
              <Typography variant='p2'>
                Your{' '}
                <Link to='/branded-employee-handbook'>
                  employees’ experience can be 100% custom branded
                </Link>
                , with a custom email sending account, a secure custom domain,
                and a custom look and feel for your handbooks, policies, and
                even your emails.
              </Typography>
              <Typography variant='p2'>
                Our design services team will help you build and design
                everything and, because your handbook is digital and not a PDF,
                you’ll be able to update your content after you launch without
                breaking anything. It just works!
              </Typography>
            </div>
          </div>
        </MaxWidthContainer>
      </Container>
    </Section>

    <Section
      style={{
        background: `linear-gradient(180deg, white 0%, ${colors['red-50']} 100%)`,
      }}
    >
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <div className='text-center'>
            <Typography variant='h2'>Why Blissbook?</Typography>
            <Typography
              variant='p1'
              className='tw-mx-auto'
              style={{ maxWidth: '720px' }}
            >
              <Link to='/customers'>Customers love us!</Link> Blissbook is the
              original culture-first employee handbook platform, built for
              organizations of all sizes.
            </Typography>
          </div>
          <div className='row'>
            <div className='col-lg-4'>
              <Card
                className='text-left lg:tw-mx-2 tw-mb-10 lg:tw-mb-0'
                color='sunshine-500'
                title='We’ve Got Your Back'
                titleCss={{ textAlign: 'left' }}
                shape=''
              >
                <Typography variant='ul2' style={{ fontSize: 17 }}>
                  <li>Content Migration</li>
                  <li>Content Services</li>
                  <li>Design Services</li>
                  <li>Copywriting/Editing</li>
                  <li>Project Management</li>
                  <li>Unlimited Training</li>
                  <li>Dedicated Account Manager</li>
                  <li>Law Change Alerts</li>
                </Typography>
              </Card>
            </div>
            <div className='col-lg-4'>
              <Card
                className='text-left lg:tw-mx-2 tw-mb-10 lg:tw-mb-0'
                color='blurple-500'
                title='The Features You Need'
                titleCss={{ textAlign: 'left' }}
                shape=''
              >
                <Typography variant='ul2' style={{ fontSize: 17 }}>
                  <li>eSignatures &amp; Reporting</li>
                  <li>Reminders</li>
                  <li>Change Annotations</li>
                  <li>Personalized Content</li>
                  <li>Multiple Handbooks &amp; Employee Dashboard</li>
                  <li>Foreign Languages</li>
                  <li>Version Tracking</li>
                  <li>Mobile-Friendly</li>
                  <li>Manager Participation</li>
                  <li>Customized Notifications</li>
                  <li>Role-Based Admin Access</li>
                  <li>No-Password Sign-In</li>
                </Typography>
              </Card>
            </div>
            <div className='col-lg-4'>
              <Card
                className='text-left lg:tw-mx-2 tw-mb-10 lg:tw-mb-0'
                color='aqua-500'
                title='Secure &amp; Redundant'
                titleCss={{ textAlign: 'left' }}
                shape=''
              >
                <Typography variant='ul2' style={{ fontSize: 17 }}>
                  <li>Automated Employee Sync</li>
                  <li>Integrates with HRIS &amp; SSO</li>
                  <li>Secure Custom Domain</li>
                  <li>Custom Email Server</li>
                  <li>GDPR &amp; CCPA Compliant</li>
                  <li>
                    Enterprise Level{' '}
                    <Link to='/information-security'>Security</Link>
                  </li>
                  <li>SOC 2 Type II (Q1 2025)</li>
                  <li>99.9% Guaranteed Uptime</li>
                </Typography>
              </Card>
            </div>
          </div>
        </MaxWidthContainer>
      </Container>
    </Section>

    <CallToActionSection
      title='Ready to digitize your company policies?'
      description='Hundreds of companies, large and small, trust Blissbook with their policy management. Tell us about your project and we’ll give your team a customized demo.'
      contentClassName=''
      includeLogos
    >
      <div className='md:tw-flex tw-items-center tw-mb-8'>
        <Link className='btn btn-primary tw-mr-6' to='/request-a-demo'>
          Request a Demo
        </Link>
        <ForwardLink className='tw-block tw-my-6' to='/pricing'>
          Learn more
        </ForwardLink>
      </div>
    </CallToActionSection>
  </MarketingLayout>
)
