import { ForwardLink, Link } from '@blissbook/ui/lib'
import CallToActionSection from './CallToActionSection'

export default () => (
  <CallToActionSection
    title='Ready to create your new employee handbook?'
    description='We’re here for you! Get in touch so we can learn about you and your project. We can give a demo and set up a free trial as needed.'
  >
    <Link className='btn btn-primary tw-mr-6' to='/request-a-demo'>
      Request a Demo
    </Link>
    <ForwardLink className='tw-block tw-my-6' to='/pricing'>
      Check Out Pricing and Try It Free
    </ForwardLink>
  </CallToActionSection>
)
