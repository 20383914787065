import { colors } from '@blissbook/ui/branding'
import { Link } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'

import { items, urls } from './howToConfig'

export const TOCList = ({ activeIndex, ...props }) => (
  <Fragment>
    <ul
      className='md:tw-block tw-hidden'
      style={{
        margin: '8px 0 0 0',
        textTransform: 'uppercase',
        fontWeight: '600',
      }}
      {...props}
    >
      {items.map((item, index) => (
        <a
          key={index}
          href={item.href}
          style={{
            color: colors['blurple-500'],
            fontWeight: 600,
            textTransform: 'uppercase',
          }}
        >
          <li
            style={{
              marginBottom: '5px',
              opacity: index === activeIndex ? '1' : '0.4',
            }}
          >
            {item.text}
          </li>
        </a>
      ))}
    </ul>
    <Link
      href={urls.ebook}
      className='btn btn-sm btn-primary tw-my-10 tw-mx-6 tw-px-6 tw-hidden md:tw-inline-block'
      download
    >
      <FontAwesomeIcon icon='file-pdf' /> Download this eBook as a PDF
    </Link>
  </Fragment>
)

export const TOCImages = ({ activeIndex, ...props }) => (
  <div className='row' {...props}>
    {items
      .filter((item) => item.img !== undefined)
      .map((item, index) => (
        <div
          key={index}
          className='col-lg-2 col-md-4 col-6 tw-mb-6 tw-px-1 tw-align-middle'
        >
          <a href={item.href}>
            <img {...item.img} alt={item.alt} />
          </a>
        </div>
      ))}
  </div>
)
