import { getFirstName } from '@blissbook/lib/name'
import type { SuperUserRoleId } from '@blissbook/lib/rbac'
import { immerable } from 'immer'
import { Person } from './person'
import { fromJSON, mapJSONValues } from './util/json'

type SessionSuperUser = {
  id: number
  type: 'superUser'
  authType: string
  authId: string

  email: string
  fullName: string
  roleId: SuperUserRoleId
}

type SessionUser = {
  id: number
  type: 'user'
  authType: string
  authId: string
}

type ISessionUser = SessionSuperUser | SessionUser

export class Session {
  expiresAt?: Date
  isImpersonating: boolean
  permissionIds: string[]
  person?: Person
  user?: ISessionUser

  static get ATTRIBUTES() {
    return {
      expiresAt: fromJSON.date,
      person: (v: any) => Person.fromJSON(v),
    }
  }

  static mapJSON(json: any) {
    return mapJSONValues(json, Session.ATTRIBUTES)
  }

  static fromJSON(json: any): Session {
    return Object.assign(new Session(), Session.mapJSON(json))
  }

  get email() {
    const { person, superUser } = this
    return person?.email || superUser?.email
  }

  get firstName() {
    return getFirstName(this.fullName)
  }

  get preferredName() {
    return this.person?.preferredName || this.firstName
  }

  get fullName() {
    const { person, superUser } = this
    return person?.fullName || superUser?.fullName
  }

  get canAdminBlissbook() {
    return this.isSuperUser && this.permissionIds.includes('blissbook.admin')
  }

  get isSuperUser() {
    return this.user?.type === 'superUser'
  }

  get personId() {
    const { person } = this
    return person?.id
  }

  get superUser() {
    if (this.user?.type === 'superUser') return this.user
    return null
  }

  can(permissionId: string) {
    return this.permissionIds.includes(permissionId)
  }
}

// @ts-ignore: immerable
Session[immerable] = true
