import { colors } from '@blissbook/ui/branding'
import get from 'lodash/get'

export const demoColors = {
  gray: '#bbbbbb',
  grayLight: '#dadada',
  grayLighter: '#ebebeb',
  grayWhite: '#f2f2f2',
}

export const demoTextVariants = {
  h1: {
    height: 16,
    marginBottom: 12,
    marginTop: 12,
  },
  h2: {
    height: 12,
    marginBottom: 12,
    marginTop: 12,
  },
  p: {
    height: 4,
    marginBottom: 8,
  },
  li: {
    height: 4,
    marginBottom: 8,
    marginLeft: 16,
  },
  small: {
    height: 2,
    marginBottom: 4,
  },
}

const themeColors = {
  branded: {
    h1: colors['red-300'],
    h2: colors['red-50'],
  },
  light: {
    h1: colors['aqua-300'],
    h2: colors['aqua-100'],
  },
}

export const DemoTextLine = ({
  color,
  style,
  theme,
  transitionDuration = 500,
  variant,
  width = 0,
  ...props
}) => {
  const variantCss = demoTextVariants[variant]
  if (!variant) throw new Error(`Unknown variant: ${variant}`)

  color = color || get(themeColors[theme], variant, demoColors.grayLight)
  return (
    <div
      {...props}
      css={{
        ...variantCss,
        backgroundColor: color,
        borderRadius: variantCss.height / 2,
        transition: `all ${transitionDuration}ms ease`,
      }}
      style={{
        ...style,
        width,
      }}
    />
  )
}

export const DemoTextBlock = ({ items, ...props }) =>
  items.map((item, index) => <DemoTextLine key={index} {...props} {...item} />)
