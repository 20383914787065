import { Variant } from '@blissbook/lib/expression'
import { immerable } from 'immer'
import { fromJSON, mapJSONValues } from './util/json'

export default class Organization {
  static get ATTRIBUTES() {
    return {
      createdAt: (v) => fromJSON.date(v),
      updatedAt: (v) => fromJSON.date(v),
      publishedAt: (v) => fromJSON.date(v),
    }
  }

  // Map attributes
  static mapJSON(json) {
    return mapJSONValues(json, Organization.ATTRIBUTES)
  }

  // Create a handbook from JSON
  static fromJSON(json) {
    return Object.assign(new Organization(), Organization.mapJSON(json))
  }

  // Variant
  static get variantType() {
    return 'organization'
  }
  get displayName() {
    return this.name
  }
  get uid() {
    return this.toVariant().uid
  }
  toVariant() {
    return new Variant(Organization.variantType, this.id)
  }
}

Organization[immerable] = true
