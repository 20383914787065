import config from '@blissbook/ui-config'
import { ButtonLink, Field } from '@blissbook/ui/lib'
import { setAuthValue } from '@blissbook/ui/marketing/actions'
import { handleError } from '@blissbook/ui/util/errors'
import { submitForm } from '@blissbook/ui/util/integrations/intercom'
import { useStore } from '@blissbook/ui/util/store'
import { Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { AuthButton, Typography } from '../../components'

const canRecaptcha = !!config.recaptcha
const defaultBody = `I'm having trouble signing in. Can you help?`

let validationSchemaShape = {
  body: Yup.string().required(),
  email: Yup.string().required().email(),
  name: Yup.string().required(),
}

if (canRecaptcha)
  validationSchemaShape = {
    ...validationSchemaShape,
    recaptcha: Yup.string().required().recaptcha(),
  }

const validationSchema = Yup.object().shape(validationSchemaShape)

export const HelpForm = ({ initialBody = defaultBody, onClose, ...props }) => {
  const { email, fullName } = useStore('auth')
  const [isComplete, setComplete] = useState(false)

  const onSubmit = async (values, { setStatus }) => {
    setStatus({ isSubmitting: true })

    try {
      const tag = 'Requested Authentication Help'
      await submitForm({ ...values, tag })
      setComplete(true)
    } catch (error) {
      handleError(error)
      setStatus()
    }
  }

  return (
    <Formik
      initialValues={{
        body: initialBody,
        email,
        name: fullName,
        recaptcha: undefined,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ handleSubmit, status: { isSubmitting } = {} }) => (
        <form {...props} noValidate onSubmit={handleSubmit}>
          <fieldset disabled={isSubmitting}>
            <Typography className='auth' variant='h1'>
              Help With Sign In.
            </Typography>

            <Typography className='auth' variant='p2'>
              Sorry you're having trouble! Submit this form and someone will be
              in touch to help as soon as possible.
            </Typography>

            <Field
              name='name'
              label='Name'
              placeholder='Your Name'
              onChangeValue={(value) => setAuthValue('fullName', value)}
              autoFocus
            />

            <Field
              name='email'
              label='Email Address'
              placeholder='Email Address'
              onChangeValue={(value) => setAuthValue('email', value)}
            />

            <Field type='textarea' name='body' label='Message' />

            {canRecaptcha && <Field type='recaptcha' name='recaptcha' />}

            <div className='tw-flex tw-items-center'>
              {isComplete ? (
                <AuthButton color='success' disabled>
                  Thanks! We've received your help request.
                </AuthButton>
              ) : (
                <AuthButton type='submit'>Send Help Request</AuthButton>
              )}
            </div>
          </fieldset>

          <ButtonLink className='tw-mt-6' onClick={onClose}>
            Go Back
          </ButtonLink>
        </form>
      )}
    </Formik>
  )
}
