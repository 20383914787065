import { client as apolloClient } from '@blissbook/ui/util/apollo'
import { hot } from 'react-hot-loader/root'
import { renderRoutes } from 'react-router-config'
import { Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { routes } from './routes'
import './icons'
import { ApolloProvider } from '@apollo/client'

export const MarketingPage = () => (
  <ApolloProvider client={apolloClient}>
    <Switch>{renderRoutes(routes)}</Switch>
    <ToastContainer />
  </ApolloProvider>
)

export const HotMarketingPage = hot(MarketingPage)
