import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Color: any;
  HandbookColor: any;
  BodyContent: any;
  EmailContent: any;
  HelpContent: any;
  TextContent: any;
  TitleContent: any;
  Domain: any;
  Subdomain: any;
  EmailAddress: any;
  HTML: any;
  PhoneNumber: any;
  HttpUrl: any;
  HttpsUrl: any;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AuthPerson = {
  __typename?: 'AuthPerson';
  employeeId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  homePhoneNumber?: Maybe<Scalars['PhoneNumber']>;
  mobilePhoneNumber?: Maybe<Scalars['PhoneNumber']>;
  workPhoneNumber?: Maybe<Scalars['PhoneNumber']>;
};

export enum PhoneAuthMethod {
  Sms = 'sms',
  Voice = 'voice'
}

export type SendAuthResponse = {
  __typename?: 'SendAuthResponse';
  signInUrl?: Maybe<Scalars['HttpUrl']>;
  userId?: Maybe<Scalars['Int']>;
};

export enum OperandType {
  Date = 'date',
  Groups = 'groups',
  Handbooks = 'handbooks',
  Managers = 'managers',
  People = 'people',
  SavedSegments = 'savedSegments',
  Select = 'select',
  Text = 'text',
  Url = 'url'
}

export type Operand = {
  id?: Maybe<Scalars['ID']>;
  type: OperandType;
};

export enum DateOperandTimeUnit {
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
  Years = 'years'
}

export enum DateOperandCondition {
  LessThanDaysAgo = 'lessThanDaysAgo',
  LessThanOrEqualToDaysAgo = 'lessThanOrEqualToDaysAgo',
  ExactlyDaysAgo = 'exactlyDaysAgo',
  MoreThanDaysAgo = 'moreThanDaysAgo',
  MoreThanOrEqualToDaysAgo = 'moreThanOrEqualToDaysAgo',
  LessThanDaysFromNow = 'lessThanDaysFromNow',
  LessThanOrEqualToDaysFromNow = 'lessThanOrEqualToDaysFromNow',
  ExactlyDaysFromNow = 'exactlyDaysFromNow',
  MoreThanDaysFromNow = 'moreThanDaysFromNow',
  MoreThanOrEqualToDaysFromNow = 'moreThanOrEqualToDaysFromNow',
  BeforeDate = 'beforeDate',
  OnDate = 'onDate',
  AfterDate = 'afterDate',
  HasNoValue = 'hasNoValue',
  HasValue = 'hasValue',
  IsOverdue = 'isOverdue',
  IsOnOrAfter = 'isOnOrAfter',
  IsOnOrBefore = 'isOnOrBefore'
}

export type DateOperand = Operand & {
  __typename?: 'DateOperand';
  id?: Maybe<Scalars['ID']>;
  type: OperandType;
  field?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  dateCondition: DateOperandCondition;
  days?: Maybe<Scalars['Int']>;
  timeUnit?: Maybe<DateOperandTimeUnit>;
  date?: Maybe<Scalars['Date']>;
};

export type DateOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  dateCondition: DateOperandCondition;
  days?: InputMaybe<Scalars['Int']>;
  timeUnit?: InputMaybe<DateOperandTimeUnit>;
  date?: InputMaybe<Scalars['Date']>;
};

export enum DocumentsDateOperandField {
  LastPublishedAt = 'lastPublishedAt',
  Property = 'property',
  ReviewDueDate = 'reviewDueDate',
  UpdatedAt = 'updatedAt'
}

export type DocumentsDateOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  field: DocumentsDateOperandField;
  path?: InputMaybe<Scalars['String']>;
  dateCondition: DateOperandCondition;
  days?: InputMaybe<Scalars['Int']>;
  timeUnit?: InputMaybe<DateOperandTimeUnit>;
  date?: InputMaybe<Scalars['Date']>;
};

export enum HandbookRecipientsDateOperandField {
  ArchivedAt = 'archivedAt',
  LastHiredOn = 'lastHiredOn',
  LastNotifiedAt = 'lastNotifiedAt',
  LastSignedAt = 'lastSignedAt',
  LastViewedAt = 'lastViewedAt',
  SignatureDueDate = 'signatureDueDate',
  AddedAt = 'addedAt',
  RemovedAt = 'removedAt'
}

export type HandbookRecipientsDateOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  field: HandbookRecipientsDateOperandField;
  dateCondition: DateOperandCondition;
  days?: InputMaybe<Scalars['Int']>;
  timeUnit?: InputMaybe<DateOperandTimeUnit>;
  date?: InputMaybe<Scalars['Date']>;
};

export enum HrisPeopleDateOperandField {
  LastHiredOn = 'lastHiredOn'
}

export type HrisPeopleDateOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  field: HrisPeopleDateOperandField;
  dateCondition: DateOperandCondition;
  days?: InputMaybe<Scalars['Int']>;
  timeUnit?: InputMaybe<DateOperandTimeUnit>;
  date?: InputMaybe<Scalars['Date']>;
};

export enum PeopleDateOperandField {
  ArchivedAt = 'archivedAt',
  LastEmployeeDigestQueuedAt = 'lastEmployeeDigestQueuedAt',
  LastHiredOn = 'lastHiredOn',
  LastNotifiedAt = 'lastNotifiedAt',
  LastManagerDigestQueuedAt = 'lastManagerDigestQueuedAt'
}

export type PeopleDateOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  field: PeopleDateOperandField;
  dateCondition: DateOperandCondition;
  days?: InputMaybe<Scalars['Int']>;
  timeUnit?: InputMaybe<DateOperandTimeUnit>;
  date?: InputMaybe<Scalars['Date']>;
};

export type IdsOperand = Operand & {
  __typename?: 'IdsOperand';
  id?: Maybe<Scalars['ID']>;
  type: OperandType;
  isEvery: Scalars['Boolean'];
  isNot: Scalars['Boolean'];
  ids: Array<Scalars['Int']>;
  field?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Scalars['String']>>;
  targets?: Maybe<Array<Scalars['String']>>;
};

export type IdsOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  isEvery?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  ids: Array<Scalars['Int']>;
};

export enum DocumentsGroupsOperandTarget {
  AudienceGroupIds = 'audienceGroupIds',
  HtmlAudienceGroupIds = 'htmlAudienceGroupIds'
}

export type DocumentsGroupsOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  isEvery?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  targets?: InputMaybe<Array<DocumentsGroupsOperandTarget>>;
};

export enum DocumentsPeopleOperandField {
  Property = 'property',
  Reviewers = 'reviewers',
  ReviewRequester = 'reviewRequester'
}

export enum DocumentsPeopleOperandOption {
  NotReviewed = 'notReviewed'
}

export type DocumentsPeopleOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  field: DocumentsPeopleOperandField;
  path?: InputMaybe<Scalars['String']>;
  isEqual?: InputMaybe<Scalars['Boolean']>;
  isEvery?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  options?: InputMaybe<Array<DocumentsPeopleOperandOption>>;
};

export type ManagersOperand = Operand & {
  __typename?: 'ManagersOperand';
  id?: Maybe<Scalars['ID']>;
  type: OperandType;
  isEvery: Scalars['Boolean'];
  isNot: Scalars['Boolean'];
  managesDirectly: Scalars['Boolean'];
  ids: Array<Scalars['Int']>;
};

export type ManagersOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  isEvery?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  managesDirectly: Scalars['Boolean'];
  ids: Array<Scalars['Int']>;
};

export type SelectOperand = Operand & {
  __typename?: 'SelectOperand';
  id?: Maybe<Scalars['ID']>;
  type: OperandType;
  field?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  isEvery: Scalars['Boolean'];
  isNot: Scalars['Boolean'];
  targets?: Maybe<Array<Scalars['String']>>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type SelectOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  isEvery?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export enum AudienceSelectOperandField {
  Metadata = 'metadata'
}

export type AudienceSelectOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  field: AudienceSelectOperandField;
  path?: InputMaybe<Scalars['String']>;
  isEvery?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export enum DocumentsSelectOperandField {
  Annotated = 'annotated',
  AudienceMetadata = 'audienceMetadata',
  Status = 'status',
  Property = 'property'
}

export enum DocumentsAnnotated {
  HasComments = 'hasComments',
  HasSuggestions = 'hasSuggestions',
  HasAnnotations = 'hasAnnotations',
  HasPublishedAnnotations = 'hasPublishedAnnotations'
}

export enum DocumentsSelectOperandTarget {
  AudienceMetadata = 'audienceMetadata',
  HtmlAudienceMetadata = 'htmlAudienceMetadata'
}

export type DocumentsSelectOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  field: DocumentsSelectOperandField;
  path?: InputMaybe<Scalars['String']>;
  isEqual?: InputMaybe<Scalars['Boolean']>;
  isEvery?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  targets?: InputMaybe<Array<DocumentsSelectOperandTarget>>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export enum HrisPeopleSelectOperandField {
  GroupName = 'groupName',
  Metadata = 'metadata'
}

export type HrisPeopleSelectOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  field: HrisPeopleSelectOperandField;
  path?: InputMaybe<Scalars['String']>;
  isEvery?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export enum PeopleSelectOperandField {
  ComplianceStatus = 'complianceStatus',
  GroupName = 'groupName',
  Level = 'level',
  Metadata = 'metadata'
}

export type PeopleSelectOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  field: PeopleSelectOperandField;
  path?: InputMaybe<Scalars['String']>;
  isEvery?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type UrlOperand = Operand & {
  __typename?: 'UrlOperand';
  id?: Maybe<Scalars['ID']>;
  type: OperandType;
  field?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  isNot: Scalars['Boolean'];
  textCondition: TextOperandCondition;
  url: Scalars['String'];
};

export type UrlOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  textCondition: TextOperandCondition;
  url?: InputMaybe<Scalars['String']>;
};

export enum DocumentsUrlOperandField {
  Property = 'property'
}

export type DocumentsUrlOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  field: DocumentsUrlOperandField;
  path?: InputMaybe<Scalars['String']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  textCondition: TextOperandCondition;
  url?: InputMaybe<Scalars['String']>;
};

export enum TextOperandCondition {
  Contains = 'contains',
  Equals = 'equals',
  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
  HasValue = 'hasValue'
}

export type TextOperand = Operand & {
  __typename?: 'TextOperand';
  id?: Maybe<Scalars['ID']>;
  type: OperandType;
  field?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  isNot: Scalars['Boolean'];
  textCondition: TextOperandCondition;
  text: Scalars['String'];
};

export type TextOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  textCondition: TextOperandCondition;
  text?: InputMaybe<Scalars['String']>;
};

export enum DocumentsTextOperandField {
  Content = 'content',
  Property = 'property',
  Name = 'name'
}

export type DocumentsTextOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  field: DocumentsTextOperandField;
  path?: InputMaybe<Scalars['String']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  textCondition: TextOperandCondition;
  text?: InputMaybe<Scalars['String']>;
};

export enum PeopleTextOperandField {
  CustomSsoId = 'customSsoId',
  Email = 'email',
  EmployeeId = 'employeeId',
  ExternalId = 'externalId',
  FullName = 'fullName',
  MobilePhoneNumber = 'mobilePhoneNumber',
  HomePhoneNumber = 'homePhoneNumber',
  WorkPhoneNumber = 'workPhoneNumber',
  PreferredName = 'preferredName'
}

export type PeopleTextOperandInput = {
  id?: InputMaybe<Scalars['ID']>;
  field: PeopleTextOperandField;
  isNot?: InputMaybe<Scalars['Boolean']>;
  textCondition: TextOperandCondition;
  text: Scalars['String'];
};

export enum ExpressionConjunction {
  And = 'and',
  Or = 'or'
}

export type Expression = {
  __typename?: 'Expression';
  conjunction: ExpressionConjunction;
  operands: Array<Operand>;
};

export type RootExpression = {
  __typename?: 'RootExpression';
  conjunction: ExpressionConjunction;
  expressions: Array<Expression>;
};

export type AudienceOperandInput = {
  groups?: InputMaybe<IdsOperandInput>;
  people?: InputMaybe<IdsOperandInput>;
  savedSegments?: InputMaybe<IdsOperandInput>;
  select?: InputMaybe<AudienceSelectOperandInput>;
};

export type AudienceExpressionInput = {
  conjunction: ExpressionConjunction;
  isNot?: InputMaybe<Scalars['Boolean']>;
  operands: Array<AudienceOperandInput>;
};

export type AudienceRootExpressionInput = {
  conjunction: ExpressionConjunction;
  isNot?: InputMaybe<Scalars['Boolean']>;
  expressions: Array<AudienceExpressionInput>;
};

export type DocumentsOperandInput = {
  date?: InputMaybe<DocumentsDateOperandInput>;
  groups?: InputMaybe<DocumentsGroupsOperandInput>;
  handbooks?: InputMaybe<IdsOperandInput>;
  people?: InputMaybe<DocumentsPeopleOperandInput>;
  select?: InputMaybe<DocumentsSelectOperandInput>;
  text?: InputMaybe<DocumentsTextOperandInput>;
  url?: InputMaybe<DocumentsUrlOperandInput>;
};

export type DocumentsExpressionInput = {
  conjunction: ExpressionConjunction;
  isNot?: InputMaybe<Scalars['Boolean']>;
  operands: Array<DocumentsOperandInput>;
};

export type DocumentsRootExpressionInput = {
  conjunction: ExpressionConjunction;
  isNot?: InputMaybe<Scalars['Boolean']>;
  expressions: Array<DocumentsExpressionInput>;
};

export type DocumentsRootExpressionsInput = {
  conjunction: ExpressionConjunction;
  isNot?: InputMaybe<Scalars['Boolean']>;
  expressions: Array<DocumentsRootExpressionInput>;
};

export type HandbookRecipientsOperandInput = {
  date?: InputMaybe<HandbookRecipientsDateOperandInput>;
  groups?: InputMaybe<IdsOperandInput>;
  managers?: InputMaybe<ManagersOperandInput>;
  people?: InputMaybe<IdsOperandInput>;
  savedSegments?: InputMaybe<IdsOperandInput>;
  select?: InputMaybe<PeopleSelectOperandInput>;
  text?: InputMaybe<PeopleTextOperandInput>;
};

export type HandbookRecipientsExpressionInput = {
  conjunction: ExpressionConjunction;
  isNot?: InputMaybe<Scalars['Boolean']>;
  operands: Array<HandbookRecipientsOperandInput>;
};

export type HandbookRecipientsRootExpressionInput = {
  conjunction: ExpressionConjunction;
  isNot?: InputMaybe<Scalars['Boolean']>;
  expressions: Array<HandbookRecipientsExpressionInput>;
};

export type HrisPeopleOperandInput = {
  date?: InputMaybe<HrisPeopleDateOperandInput>;
  select?: InputMaybe<HrisPeopleSelectOperandInput>;
  text?: InputMaybe<PeopleTextOperandInput>;
};

export type HrisPeopleExpressionInput = {
  conjunction: ExpressionConjunction;
  isNot?: InputMaybe<Scalars['Boolean']>;
  operands: Array<HrisPeopleOperandInput>;
};

export type HrisPeopleRootExpressionInput = {
  conjunction: ExpressionConjunction;
  isNot?: InputMaybe<Scalars['Boolean']>;
  expressions: Array<HrisPeopleExpressionInput>;
};

export type PeopleOperandInput = {
  date?: InputMaybe<PeopleDateOperandInput>;
  groups?: InputMaybe<IdsOperandInput>;
  handbooks?: InputMaybe<IdsOperandInput>;
  managers?: InputMaybe<ManagersOperandInput>;
  people?: InputMaybe<IdsOperandInput>;
  savedSegments?: InputMaybe<IdsOperandInput>;
  select?: InputMaybe<PeopleSelectOperandInput>;
  text?: InputMaybe<PeopleTextOperandInput>;
};

export type PeopleExpressionInput = {
  conjunction: ExpressionConjunction;
  isNot?: InputMaybe<Scalars['Boolean']>;
  operands: Array<PeopleOperandInput>;
};

export type PeopleRootExpressionInput = {
  conjunction: ExpressionConjunction;
  isNot?: InputMaybe<Scalars['Boolean']>;
  expressions: Array<PeopleExpressionInput>;
};

export type PeopleRootExpressionsInput = {
  conjunction: ExpressionConjunction;
  isNot?: InputMaybe<Scalars['Boolean']>;
  expressions: Array<PeopleRootExpressionInput>;
};

export enum IntegrationProviderId {
  Bamboohr = 'bamboohr',
  Mergedev = 'mergedev',
  Rippling = 'rippling',
  Sftp = 'sftp'
}

export type Integration = {
  id: Scalars['Int'];
  providerId: IntegrationProviderId;
  rejectPeopleExpression?: Maybe<RootExpression>;
  lastSyncAt?: Maybe<Scalars['DateTime']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastError?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type IntegrationCustomField = {
  __typename?: 'IntegrationCustomField';
  key: Scalars['String'];
  label: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type UpdateIntegrationInput = {
  rejectPeopleExpression?: InputMaybe<HrisPeopleRootExpressionInput>;
  syncHourUtc?: InputMaybe<Scalars['Int']>;
};

export enum BambooHrGroupId {
  State = 'state',
  Country = 'country',
  Location = 'location',
  Division = 'division',
  Department = 'department',
  EmploymentHistoryStatus = 'employmentHistoryStatus',
  Exempt = 'exempt',
  PayType = 'payType'
}

export enum BambooHrSyncTiming {
  HireDate = 'hireDate'
}

export type BambooHrIntegration = Integration & {
  __typename?: 'BambooHRIntegration';
  id: Scalars['Int'];
  providerId: IntegrationProviderId;
  rejectPeopleExpression?: Maybe<RootExpression>;
  lastSyncAt?: Maybe<Scalars['DateTime']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastError?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  groups: Array<BambooHrGroupId>;
  hostname: Scalars['String'];
  subdomain: Scalars['String'];
  syncTiming?: Maybe<BambooHrSyncTiming>;
  userId?: Maybe<Scalars['String']>;
};

export type UpdateBambooHrIntegrationInput = {
  groups: Array<BambooHrGroupId>;
  syncTiming?: InputMaybe<BambooHrSyncTiming>;
};

export enum RipplingGroupId {
  Country = 'country',
  State = 'state',
  City = 'city',
  Department = 'department',
  EmploymentType = 'employmentType'
}

export type RipplingIntegration = Integration & {
  __typename?: 'RipplingIntegration';
  id: Scalars['Int'];
  providerId: IntegrationProviderId;
  rejectPeopleExpression?: Maybe<RootExpression>;
  lastSyncAt?: Maybe<Scalars['DateTime']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastError?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  customFields: Array<IntegrationCustomField>;
  customFieldKeys: Array<Scalars['String']>;
  ripplingGroupIds: Array<RipplingGroupId>;
};

export type UpdateRipplingIntegrationInput = {
  customFieldKeys: Array<Scalars['String']>;
  ripplingGroupIds: Array<RipplingGroupId>;
};

export type SshFingerprints = {
  __typename?: 'SshFingerprints';
  md5: Scalars['String'];
  sha256: Scalars['String'];
};

export type SftpIntegration = Integration & {
  __typename?: 'SftpIntegration';
  id: Scalars['Int'];
  providerId: IntegrationProviderId;
  rejectPeopleExpression?: Maybe<RootExpression>;
  fingerprints: SshFingerprints;
  username: Scalars['String'];
  lastSyncAt?: Maybe<Scalars['DateTime']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastError?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type NewSftpIntegration = Integration & {
  __typename?: 'NewSftpIntegration';
  id: Scalars['Int'];
  providerId: IntegrationProviderId;
  rejectPeopleExpression?: Maybe<RootExpression>;
  fingerprints: SshFingerprints;
  username: Scalars['String'];
  password: Scalars['String'];
  lastSyncAt?: Maybe<Scalars['DateTime']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastError?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type HttpsIntegration = Integration & {
  __typename?: 'HttpsIntegration';
  id: Scalars['Int'];
  providerId: IntegrationProviderId;
  rejectPeopleExpression?: Maybe<RootExpression>;
  lastSyncAt?: Maybe<Scalars['DateTime']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastError?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type MergeDevIntegration = Integration & {
  __typename?: 'MergeDevIntegration';
  id: Scalars['Int'];
  providerId: IntegrationProviderId;
  rejectPeopleExpression?: Maybe<RootExpression>;
  lastSyncAt?: Maybe<Scalars['DateTime']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
  lastError?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  image: Scalars['String'];
  squareImage: Scalars['String'];
  integrationName: Scalars['String'];
  integrationSlug: Scalars['String'];
  externalId: Scalars['String'];
  syncHourUtc?: Maybe<Scalars['Int']>;
};

export enum AssetType {
  Image = 'image',
  Pdf = 'pdf'
}

export type Asset = {
  id: Scalars['Int'];
  type: AssetType;
  tag: Scalars['String'];
  filename: Scalars['String'];
  filesize: Scalars['Int'];
  path: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  mimeType?: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type Image = Asset & {
  __typename?: 'Image';
  id: Scalars['Int'];
  type: AssetType;
  tag: Scalars['String'];
  filename: Scalars['String'];
  filesize: Scalars['Int'];
  path: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  mimeType?: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type Pdf = Asset & {
  __typename?: 'Pdf';
  id: Scalars['Int'];
  type: AssetType;
  tag: Scalars['String'];
  filename: Scalars['String'];
  filesize: Scalars['Int'];
  path: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  mimeType?: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type Billing = {
  __typename?: 'Billing';
  organizationId: Scalars['Int'];
  exempt: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
};

export type BillingEmailSettings = {
  __typename?: 'BillingEmailSettings';
  toEmails: Array<Scalars['EmailAddress']>;
  toAdmins: Scalars['Boolean'];
};

export type CustomDomain = {
  __typename?: 'CustomDomain';
  domain: Scalars['Domain'];
  domainTarget: Scalars['Domain'];
  isDnsValid?: Maybe<Scalars['Boolean']>;
  dnsLastValidAt?: Maybe<Scalars['DateTime']>;
  isAutoRenew: Scalars['Boolean'];
  renewAfter?: Maybe<Scalars['DateTime']>;
  certificateDigest?: Maybe<Scalars['String']>;
  certificate?: Maybe<CustomDomainCertificate>;
  certificates: Array<CustomDomainCertificate>;
};

export type CustomDomainCertificate = {
  __typename?: 'CustomDomainCertificate';
  digest: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['Int'];
  name: Scalars['String'];
  subdomain: Scalars['Subdomain'];
  origin: Scalars['HttpUrl'];
  blissbookOrigin: Scalars['HttpUrl'];
  isFullServe: Scalars['Boolean'];
  isCrmDisabled: Scalars['Boolean'];
  isIntercomDisabled: Scalars['Boolean'];
  isAuthDisabled: Scalars['Boolean'];
  isHandbookPositionEnabled: Scalars['Boolean'];
  showPrivacyPolicy: Scalars['Boolean'];
  logoImage?: Maybe<Image>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  preferences: Scalars['JSON'];
  billingPeopleCount: Scalars['Int'];
  customSsoIdPeopleCount: Scalars['Int'];
  emailPeopleCount: Scalars['Int'];
  employeeIdPeopleCount: Scalars['Int'];
  friendsCount: Scalars['Int'];
  handbooksCount: Scalars['Int'];
  integrationsCount: Scalars['Int'];
  lastHiredOnPeopleCount: Scalars['Int'];
  managersCount: Scalars['Int'];
  membersCount: Scalars['Int'];
  peopleCount: Scalars['Int'];
  preferredNamePeopleCount: Scalars['Int'];
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export enum HandbookCollaboratorRoleId {
  Editor = 'editor',
  Publisher = 'publisher'
}

export enum OrganizationAdminRoleId {
  BrandingAdmin = 'brandingAdmin',
  BillingAdmin = 'billingAdmin',
  TechAdmin = 'techAdmin',
  OrgAdmin = 'orgAdmin'
}

export type OrganizationAdminRole = {
  __typename?: 'OrganizationAdminRole';
  id: OrganizationAdminRoleId;
  label: Scalars['String'];
  description: Scalars['String'];
  permissionIds: Array<Scalars['String']>;
  permissions: Array<Permission>;
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['String'];
  label: Scalars['String'];
  description: Scalars['String'];
};

export enum SuperUserRoleId {
  SuperOwner = 'superOwner',
  SuperAdmin = 'superAdmin',
  SuperBrander = 'superBrander',
  SuperUser = 'superUser'
}

export enum TeamRoleId {
  Editor = 'editor',
  Publisher = 'publisher',
  Owner = 'owner'
}

export type Session = {
  __typename?: 'Session';
  isImpersonating: Scalars['Boolean'];
  person?: Maybe<SessionPerson>;
  user?: Maybe<ISessionUser>;
  expiresAt: Scalars['DateTime'];
  permissionIds: Array<Scalars['String']>;
  permissions: Array<Permission>;
};

export type SessionPerson = {
  __typename?: 'SessionPerson';
  organizationId: Scalars['Int'];
  id: Scalars['Int'];
  idHash?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  fullName: Scalars['String'];
  preferredName?: Maybe<Scalars['String']>;
  member: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  adminRoleId?: Maybe<OrganizationAdminRoleId>;
  hasDocumentRole: Scalars['Boolean'];
  hasHandbookRole: Scalars['Boolean'];
  hasTeamRole: Scalars['Boolean'];
  isTeamCollaborator: Scalars['Boolean'];
  teammates: Array<SessionTeammate>;
};

export type SessionTeammate = {
  __typename?: 'SessionTeammate';
  organizationId: Scalars['Int'];
  id: Scalars['Int'];
  teamId: Scalars['Int'];
  personId: Scalars['Int'];
  canReport: Scalars['Boolean'];
  canSuperBrand: Scalars['Boolean'];
  roleId?: Maybe<TeamRoleId>;
};

export type ISessionUser = {
  id: Scalars['Int'];
  type: UserType;
  authType: UserAuthType;
  authId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  lastSignedInAt?: Maybe<Scalars['DateTime']>;
};

export type SessionSuperUser = ISessionUser & {
  __typename?: 'SessionSuperUser';
  id: Scalars['Int'];
  type: UserType;
  authType: UserAuthType;
  authId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  lastSignedInAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  roleId?: Maybe<SuperUserRoleId>;
};

export type SessionUser = ISessionUser & {
  __typename?: 'SessionUser';
  id: Scalars['Int'];
  type: UserType;
  authType: UserAuthType;
  authId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  lastSignedInAt?: Maybe<Scalars['DateTime']>;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrder = {
  __typename?: 'SortOrder';
  field: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  direction: SortDirection;
};

export type Chargebee = {
  __typename?: 'Chargebee';
  remote: ChargebeeRemote;
  site: Scalars['String'];
};

export type ChargebeeRemote = {
  __typename?: 'ChargebeeRemote';
  coupons: Array<ChargebeeCoupon>;
  itemPrices: Array<ChargebeeItemPrice>;
};

export type ChargebeePortal = {
  __typename?: 'ChargebeePortal';
  checkoutPage: ChargebeeHostedPage;
};


export type ChargebeePortalCheckoutPageArgs = {
  subscriptionItems: Array<ChargebeeSubscriptionItemInput>;
};

export type ChargebeeHostedPage = {
  __typename?: 'ChargebeeHostedPage';
  id: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
  state: Scalars['String'];
  embed: Scalars['Boolean'];
  created_at: Scalars['Int'];
  expires_at: Scalars['Int'];
};

export type ChargebeeSubscriptionItemInput = {
  amountInCents?: InputMaybe<Scalars['Int']>;
  itemPriceId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type ChargebeeSubscription = {
  __typename?: 'ChargebeeSubscription';
  addOnIds: Array<Scalars['String']>;
  customerId: Scalars['String'];
  lastSyncAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['Int'];
  planId: Scalars['String'];
  status: Scalars['String'];
  subscriptionId: Scalars['String'];
  remote: ChargebeeSubscriptionRemote;
};

export type ChargebeeSubscriptionRemote = {
  __typename?: 'ChargebeeSubscriptionRemote';
  cancellationScheduledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  currentTermEnd?: Maybe<Scalars['DateTime']>;
  currentTermStart?: Maybe<Scalars['DateTime']>;
  nextBillingAt?: Maybe<Scalars['DateTime']>;
  coupons: Array<ChargebeeCoupon>;
  items: Array<ChargebeeSubscriptionItem>;
  lastPaidInvoices: Array<ChargebeeInvoice>;
  outstandingInvoices: Array<ChargebeeInvoice>;
  portalSession: ChargebeePortalSession;
  scheduledItems?: Maybe<Array<ChargebeeSubscriptionItem>>;
};

export type ChargebeeCoupon = {
  __typename?: 'ChargebeeCoupon';
  id: Scalars['String'];
  name: Scalars['String'];
  discountPercentage: Scalars['Int'];
};

export type ChargebeeItemPrice = {
  __typename?: 'ChargebeeItemPrice';
  externalName: Scalars['String'];
  id: Scalars['String'];
  itemId: Scalars['String'];
  itemType: ChargebeeItemType;
  name: Scalars['String'];
  period?: Maybe<Scalars['Int']>;
  periodUnit?: Maybe<ChargebeeItemPricePeriodUnit>;
  priceInCents?: Maybe<Scalars['Int']>;
  tiers?: Maybe<Array<ChargebeeItemPriceTier>>;
};

export type ChargebeeItemPriceTier = {
  __typename?: 'ChargebeeItemPriceTier';
  startingUnit: Scalars['Int'];
  endingUnit?: Maybe<Scalars['Int']>;
  priceInCents: Scalars['Int'];
};

export enum ChargebeeItemPricePeriodUnit {
  Month = 'month',
  Year = 'year'
}

export enum ChargebeeItemType {
  Addon = 'addon',
  Charge = 'charge',
  Plan = 'plan'
}

export type ChargebeeInvoice = {
  __typename?: 'ChargebeeInvoice';
  id: Scalars['String'];
  date: Scalars['DateTime'];
  dueDate?: Maybe<Scalars['DateTime']>;
  generatedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  totalInCents: Scalars['Int'];
  creditsInCents: Scalars['Int'];
  currencyCode: Scalars['String'];
  downloadUrl?: Maybe<Scalars['String']>;
};

export type ChargebeePortalSession = {
  __typename?: 'ChargebeePortalSession';
  id: Scalars['String'];
  token: Scalars['String'];
  access_url: Scalars['String'];
  status: Scalars['String'];
  customer_id: Scalars['String'];
};

export type ChargebeeSubscriptionItem = {
  __typename?: 'ChargebeeSubscriptionItem';
  amountInCents: Scalars['Int'];
  itemPrice: ChargebeeItemPrice;
  itemPriceId: Scalars['String'];
  itemType: ChargebeeItemType;
  quantity: Scalars['Int'];
};

export enum UserAuthType {
  Email = 'email',
  EmployeeId = 'employeeId',
  CustomSsoId = 'customSsoId'
}

export enum UserType {
  SuperUser = 'superUser',
  User = 'user'
}

export type WhodunnitPerson = {
  __typename?: 'WhodunnitPerson';
  id: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  isBlissbook: Scalars['Boolean'];
  avatarUrl?: Maybe<Scalars['String']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
};

export enum AnnotationAudienceType {
  AudienceMembers = 'audienceMembers',
  Collaborators = 'collaborators'
}

export enum AnnotationTargetType {
  Document = 'document',
  HandbookSection = 'handbookSection'
}

export type Annotation = {
  __typename?: 'Annotation';
  id: Scalars['ID'];
  audienceType: AnnotationAudienceType;
  html: Scalars['HTML'];
  targetType: AnnotationTargetType;
  targetId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdByPersonId: Scalars['Int'];
  createdBy?: Maybe<WhodunnitPerson>;
};

export type CreateAnnotationInput = {
  audienceType: AnnotationAudienceType;
  html: Scalars['HTML'];
};

export enum DocumentPhaseId {
  Drafting = 'drafting',
  InReview = 'inReview',
  Approved = 'approved',
  Rejected = 'rejected',
  NoDecision = 'noDecision',
  Clean = 'clean'
}

export enum DocumentType {
  Page = 'page',
  PageTemplate = 'pageTemplate',
  Policy = 'policy'
}

export type Document = {
  __typename?: 'Document';
  type: DocumentType;
  id: Scalars['Int'];
  ckeditorChannelId: Scalars['ID'];
  phaseId: DocumentPhaseId;
  properties: Array<PropertyValue>;
  sourceHandbookId?: Maybe<Scalars['Int']>;
  sourceHandbookSectionId?: Maybe<Scalars['Int']>;
  sortPosition: Scalars['Int'];
  cannotDelete: Scalars['Boolean'];
  name: Scalars['String'];
  slug: Scalars['String'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<WhodunnitPerson>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<WhodunnitPerson>;
  draftVersion: DocumentVersion;
  lastPublishedVersion?: Maybe<DocumentVersion>;
  lastPublishedVersionNumber: Scalars['Int'];
  publishedVersions: Array<DocumentVersion>;
  annotations: Array<Annotation>;
  sourceDocumentId?: Maybe<Scalars['Int']>;
  sourceDocument?: Maybe<SourceDocument>;
  sourceDocumentVersionNumber?: Maybe<Scalars['Int']>;
  handbookIds: Array<Scalars['Int']>;
  handbookRefs: Array<HandbookDocumentRef>;
  activeReviewRound?: Maybe<DocumentReviewRound>;
  lastReviewRound?: Maybe<DocumentReviewRound>;
  reviewRounds: Array<DocumentReviewRound>;
  inheritTeamRoles: Scalars['Boolean'];
  collaborators: Array<DocumentCollaborator>;
  sortKey?: Maybe<Scalars['String']>;
};

export type SourceDocument = {
  __typename?: 'SourceDocument';
  type: DocumentType;
  id: Scalars['Int'];
  name: Scalars['String'];
  lastPublishedVersion: SourceDocumentVersion;
  lastPublishedVersionNumber: Scalars['Int'];
  publishedVersions: Array<SourceDocumentVersion>;
};

export type DocumentVersion = {
  __typename?: 'DocumentVersion';
  documentId: Scalars['Int'];
  id: Scalars['Int'];
  versionNumber: Scalars['Int'];
  isPublished: Scalars['Boolean'];
  annotationIds: Array<Scalars['String']>;
  audienceExpression?: Maybe<RootExpression>;
  audienceGroupIds?: Maybe<Array<Scalars['Int']>>;
  audienceMetadata?: Maybe<Array<AudienceMetadataEntry>>;
  audienceExpressionHash: Scalars['BigInt'];
  commitMessageHtml?: Maybe<Scalars['HTML']>;
  html?: Maybe<Scalars['HTML']>;
  htmlCommentsCount: Scalars['Int'];
  htmlAudienceGroupIds?: Maybe<Array<Scalars['Int']>>;
  htmlAudienceMetadata?: Maybe<Array<AudienceMetadataEntry>>;
  htmlHash: Scalars['BigInt'];
  htmlSuggestionsCount: Scalars['Int'];
  htmlTitle?: Maybe<Scalars['String']>;
  isDirty: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<WhodunnitPerson>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishedBy?: Maybe<WhodunnitPerson>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<WhodunnitPerson>;
};

export type SourceDocumentVersion = {
  __typename?: 'SourceDocumentVersion';
  documentId: Scalars['Int'];
  id: Scalars['Int'];
  versionNumber: Scalars['Int'];
  audienceExpression?: Maybe<RootExpression>;
  audienceExpressionHash: Scalars['BigInt'];
  html?: Maybe<Scalars['HTML']>;
  htmlHash: Scalars['BigInt'];
  htmlTitle?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<WhodunnitPerson>;
};

export enum DocumentsColumnId {
  Audience = 'audience',
  Collaborators = 'collaborators',
  Created = 'created',
  Handbooks = 'handbooks',
  HtmlAudience = 'htmlAudience',
  LastModified = 'lastModified',
  LastPublished = 'lastPublished',
  Name = 'name',
  Property = 'property',
  ReviewDueDate = 'reviewDueDate',
  ReviewRequester = 'reviewRequester',
  Reviewers = 'reviewers',
  Select = 'select',
  Status = 'status',
  VersionNumber = 'versionNumber'
}

export type DocumentsColumnStateInput = {
  field: DocumentsColumnId;
  path?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
};

export type DocumentsFilter = {
  documentIds?: InputMaybe<Array<Scalars['Int']>>;
  expression?: InputMaybe<DocumentsRootExpressionInput>;
  expressions?: InputMaybe<DocumentsRootExpressionsInput>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<DocumentType>>;
};

export enum DocumentsFilterId {
  ReviewRequester = 'reviewRequester',
  ReviewerNotReviewed = 'reviewerNotReviewed',
  Reviewer = 'reviewer'
}

export enum DocumentSortField {
  CreatedAt = 'createdAt',
  Handbooks = 'handbooks',
  LastPublishedAt = 'lastPublishedAt',
  LastPublishedVersionNumber = 'lastPublishedVersionNumber',
  LastModifiedAt = 'lastModifiedAt',
  LastUpdatedAt = 'lastUpdatedAt',
  Name = 'name',
  PhaseId = 'phaseId',
  Property = 'property',
  ReviewDueDate = 'reviewDueDate',
  ReviewRequester = 'reviewRequester',
  SortPosition = 'sortPosition'
}

export type DocumentsSortOrder = {
  field: DocumentSortField;
  path?: InputMaybe<Scalars['String']>;
  direction: SortDirection;
};

export enum DocumentsGroupByField {
  Organization = 'organization',
  Property = 'property'
}

export type DocumentsGroupBy = {
  __typename?: 'DocumentsGroupBy';
  field: DocumentsGroupByField;
  path?: Maybe<Scalars['String']>;
};

export type DocumentsGroupByInput = {
  field: DocumentsGroupByField;
  path?: InputMaybe<Scalars['String']>;
};

export type DocumentGroupInterface = {
  field: DocumentsGroupByField;
  path?: Maybe<Scalars['String']>;
  documentsCount: Scalars['Int'];
};

export type DocumentGroup = DocumentGroupInterface & {
  __typename?: 'DocumentGroup';
  field: DocumentsGroupByField;
  path?: Maybe<Scalars['String']>;
  documentsCount: Scalars['Int'];
};

export type NumberDocumentGroup = DocumentGroupInterface & {
  __typename?: 'NumberDocumentGroup';
  field: DocumentsGroupByField;
  path?: Maybe<Scalars['String']>;
  documentsCount: Scalars['Int'];
  number?: Maybe<Scalars['Float']>;
};

export type PeopleDocumentGroup = DocumentGroupInterface & {
  __typename?: 'PeopleDocumentGroup';
  field: DocumentsGroupByField;
  path?: Maybe<Scalars['String']>;
  documentsCount: Scalars['Int'];
  personIds?: Maybe<Array<Scalars['Int']>>;
  people?: Maybe<Array<WhodunnitPerson>>;
};

export type SelectDocumentGroup = DocumentGroupInterface & {
  __typename?: 'SelectDocumentGroup';
  field: DocumentsGroupByField;
  path?: Maybe<Scalars['String']>;
  documentsCount: Scalars['Int'];
  ids?: Maybe<Array<Scalars['String']>>;
};

export type TextDocumentGroup = DocumentGroupInterface & {
  __typename?: 'TextDocumentGroup';
  field: DocumentsGroupByField;
  path?: Maybe<Scalars['String']>;
  documentsCount: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
};

export type UrlDocumentGroup = DocumentGroupInterface & {
  __typename?: 'UrlDocumentGroup';
  field: DocumentsGroupByField;
  path?: Maybe<Scalars['String']>;
  documentsCount: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
};

export type CreateDocumentPropertiesInput = {
  chapterName?: InputMaybe<Scalars['String']>;
};

export type CreateDocumentInput = {
  audienceExpression?: InputMaybe<PeopleRootExpressionInput>;
  ckeditorChannelId?: InputMaybe<Scalars['ID']>;
  html?: InputMaybe<Scalars['HTML']>;
  inheritTeamRoles?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  type: DocumentType;
  properties?: InputMaybe<CreateDocumentPropertiesInput>;
};

export type UpdateDocumentArgs = {
  documentId: Scalars['Int'];
  input: UpdateDocumentInput;
};

export type UpdateDocumentInput = {
  inheritTeamRoles?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type MoveDocumentsInput = {
  documentId: Scalars['Int'];
  after: Scalars['Boolean'];
};

export type UpdateDocumentDraftVersionInput = {
  audienceExpression?: InputMaybe<PeopleRootExpressionInput>;
  html?: InputMaybe<Scalars['HTML']>;
  htmlCommentsCount?: InputMaybe<Scalars['Int']>;
};

export type DocumentReviewRound = {
  __typename?: 'DocumentReviewRound';
  id: Scalars['ID'];
  documentId: Scalars['Int'];
  roundNumber: Scalars['Int'];
  /** @deprecated Use phaseId instead */
  isApproved: Scalars['Boolean'];
  phaseId: DocumentPhaseId;
  dueDate: Scalars['Date'];
  messageToReviewers?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<WhodunnitPerson>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<WhodunnitPerson>;
  reviewers: Array<DocumentReviewer>;
};

export type CreateDocumentReviewRoundInput = {
  dueDate: Scalars['Date'];
  messageToReviewers?: InputMaybe<Scalars['String']>;
  personIds: Array<Scalars['Int']>;
};

export type UpdateDocumentReviewRoundInput = {
  dueDate?: InputMaybe<Scalars['Date']>;
  messageToReviewers?: InputMaybe<Scalars['String']>;
  personIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type DocumentReviewer = {
  __typename?: 'DocumentReviewer';
  id: Scalars['ID'];
  documentId: Scalars['Int'];
  reviewRoundNumber: Scalars['Int'];
  personId: Scalars['Int'];
  person: WhodunnitPerson;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<WhodunnitPerson>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<WhodunnitPerson>;
  feedbacks: Array<DocumentReviewerFeedback>;
};

export type DocumentReviewerFeedback = {
  __typename?: 'DocumentReviewerFeedback';
  id: Scalars['ID'];
  documentId: Scalars['Int'];
  reviewRoundNumber: Scalars['Int'];
  personId: Scalars['Int'];
  person: WhodunnitPerson;
  isApproved?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type AddDocumentReviewerFeedbackInput = {
  isApproved?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
};

export enum DocumentCollaboratorRoleId {
  Viewer = 'viewer',
  Commenter = 'commenter',
  Editor = 'editor',
  Publisher = 'publisher',
  FullAccess = 'fullAccess'
}

export type DocumentCollaborator = {
  __typename?: 'DocumentCollaborator';
  id: Scalars['ID'];
  documentId: Scalars['Int'];
  personId: Scalars['Int'];
  roleId: DocumentCollaboratorRoleId;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  person: WhodunnitPerson;
};

export type DocumentCollaboratorInput = {
  personId: Scalars['Int'];
  roleId: DocumentCollaboratorRoleId;
};

export enum EventType {
  DocumentAddedEvent = 'DocumentAddedEvent',
  DocumentArchivedEvent = 'DocumentArchivedEvent',
  DocumentCreatedEvent = 'DocumentCreatedEvent',
  DocumentPublishedEvent = 'DocumentPublishedEvent',
  DocumentRemovedEvent = 'DocumentRemovedEvent',
  DocumentCollaboratorAddedEvent = 'DocumentCollaboratorAddedEvent',
  DocumentCollaboratorUpdatedEvent = 'DocumentCollaboratorUpdatedEvent',
  DocumentCollaboratorRemovedEvent = 'DocumentCollaboratorRemovedEvent',
  DocumentReviewRoundAddedEvent = 'DocumentReviewRoundAddedEvent',
  DocumentReviewRoundUpdatedEvent = 'DocumentReviewRoundUpdatedEvent',
  DocumentReviewerAddedEvent = 'DocumentReviewerAddedEvent',
  DocumentReviewerFeedbackAddedEvent = 'DocumentReviewerFeedbackAddedEvent',
  DocumentReviewerRemovedEvent = 'DocumentReviewerRemovedEvent',
  DocumentUnarchivedEvent = 'DocumentUnarchivedEvent',
  DocumentUpdatedEvent = 'DocumentUpdatedEvent',
  FeatureFlagAddedEvent = 'FeatureFlagAddedEvent',
  FeatureFlagRemovedEvent = 'FeatureFlagRemovedEvent',
  HandbookCreatedEvent = 'HandbookCreatedEvent',
  HandbookPublishedEvent = 'HandbookPublishedEvent',
  HandbookSignatureRoundCreatedEvent = 'HandbookSignatureRoundCreatedEvent',
  HandbookSignedEvent = 'HandbookSignedEvent',
  HandbookViewedEvent = 'HandbookViewedEvent',
  PeopleSyncEvent = 'PeopleSyncEvent',
  PersonAddedToHandbookEvent = 'PersonAddedToHandbookEvent',
  PersonArchivedEvent = 'PersonArchivedEvent',
  PersonCreatedEvent = 'PersonCreatedEvent',
  PersonNotifiedEvent = 'PersonNotifiedEvent',
  PersonRemovedFromHandbookEvent = 'PersonRemovedFromHandbookEvent',
  PersonUnarchivedEvent = 'PersonUnarchivedEvent'
}

export enum EventTargetType {
  Document = 'document',
  Handbook = 'handbook',
  Notification = 'notification',
  Organization = 'organization',
  Person = 'person'
}

export type Event = {
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
};

export type EventsFilter = {
  documentId?: InputMaybe<Scalars['Int']>;
  handbookId?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['Int']>;
  superUserId?: InputMaybe<Scalars['Int']>;
  types?: InputMaybe<Array<EventType>>;
};

export enum EventsSortField {
  CreatedAt = 'createdAt'
}

export type EventsSortOrder = {
  field: EventsSortField;
  direction: SortDirection;
};

export type EventDocument = {
  __typename?: 'EventDocument';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type EventDocumentReviewerFeedback = {
  __typename?: 'EventDocumentReviewerFeedback';
  isApproved?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type EventDocumentVersion = {
  __typename?: 'EventDocumentVersion';
  id: Scalars['Int'];
  documentId: Scalars['Int'];
  versionNumber: Scalars['Int'];
  commitMessageHtml?: Maybe<Scalars['HTML']>;
};

export type EventHandbook = {
  __typename?: 'EventHandbook';
  id: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type EventHandbookSignatureRound = {
  __typename?: 'EventHandbookSignatureRound';
  id: Scalars['Int'];
  number: Scalars['Int'];
};

export type EventNotification = {
  __typename?: 'EventNotification';
  id: Scalars['Int'];
  subject?: Maybe<Scalars['String']>;
  handbookId?: Maybe<Scalars['Int']>;
};

export type MaybeRootExpressionUpdated = {
  __typename?: 'MaybeRootExpressionUpdated';
  prevValue?: Maybe<RootExpression>;
  value?: Maybe<RootExpression>;
};

export type StringUpdated = {
  __typename?: 'StringUpdated';
  prevValue: Scalars['String'];
  value: Scalars['String'];
};

export type DocumentAddedEvent = Event & {
  __typename?: 'DocumentAddedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
  handbook: EventHandbook;
};

export type DocumentArchivedEvent = Event & {
  __typename?: 'DocumentArchivedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
};

export type DocumentCreatedEvent = Event & {
  __typename?: 'DocumentCreatedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
};

export type DocumentPublishedEventAttributes = {
  __typename?: 'DocumentPublishedEventAttributes';
  versionNumber: Scalars['Int'];
};

export type DocumentPublishedEvent = Event & {
  __typename?: 'DocumentPublishedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
  documentVersion: EventDocumentVersion;
  attributes: DocumentPublishedEventAttributes;
};

export type DocumentRemovedEvent = Event & {
  __typename?: 'DocumentRemovedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
  handbook: EventHandbook;
};

export type DocumentCollaboratorAddedEventAttributes = {
  __typename?: 'DocumentCollaboratorAddedEventAttributes';
  documentCollaboratorRoleId: DocumentCollaboratorRoleId;
};

export type DocumentCollaboratorAddedEvent = Event & {
  __typename?: 'DocumentCollaboratorAddedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
  documentCollaboratorPerson?: Maybe<WhodunnitPerson>;
  attributes: DocumentCollaboratorAddedEventAttributes;
};

export type DocumentCollaboratorUpdatedEventAttributes = {
  __typename?: 'DocumentCollaboratorUpdatedEventAttributes';
  documentCollaboratorRoleId: DocumentCollaboratorRoleId;
};

export type DocumentCollaboratorUpdatedEvent = Event & {
  __typename?: 'DocumentCollaboratorUpdatedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
  documentCollaboratorPerson?: Maybe<WhodunnitPerson>;
  attributes: DocumentCollaboratorUpdatedEventAttributes;
};

export type DocumentCollaboratorRemovedEvent = Event & {
  __typename?: 'DocumentCollaboratorRemovedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
  documentCollaboratorPerson?: Maybe<WhodunnitPerson>;
};

export type DocumentReviewRoundAddedEventAttributes = {
  __typename?: 'DocumentReviewRoundAddedEventAttributes';
  documentReviewRoundNumber: Scalars['Int'];
  dueDate: Scalars['DateTime'];
  messageToReviewers?: Maybe<Scalars['String']>;
};

export type DocumentReviewRoundAddedEvent = Event & {
  __typename?: 'DocumentReviewRoundAddedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
  attributes: DocumentReviewRoundAddedEventAttributes;
};

export type DocumentReviewRoundUpdatedEventAttributes = {
  __typename?: 'DocumentReviewRoundUpdatedEventAttributes';
  documentReviewRoundNumber: Scalars['Int'];
  dueDate: Scalars['DateTime'];
  messageToReviewers?: Maybe<Scalars['String']>;
};

export type DocumentReviewRoundUpdatedEvent = Event & {
  __typename?: 'DocumentReviewRoundUpdatedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
  attributes: DocumentReviewRoundUpdatedEventAttributes;
};

export type DocumentReviewerAddedEvent = Event & {
  __typename?: 'DocumentReviewerAddedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
  documentReviewerPerson?: Maybe<WhodunnitPerson>;
};

export type DocumentReviewerFeedbackAddedEvent = Event & {
  __typename?: 'DocumentReviewerFeedbackAddedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
  documentReviewerFeedback: EventDocumentReviewerFeedback;
  documentReviewerPerson?: Maybe<WhodunnitPerson>;
};

export type DocumentReviewerRemovedEvent = Event & {
  __typename?: 'DocumentReviewerRemovedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
  documentReviewerPerson?: Maybe<WhodunnitPerson>;
};

export type DocumentUnarchivedEvent = Event & {
  __typename?: 'DocumentUnarchivedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
};

export type DocumentUpdatedEvent = Event & {
  __typename?: 'DocumentUpdatedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  document: EventDocument;
  versionNumber: Scalars['Int'];
  audienceExpression?: Maybe<MaybeRootExpressionUpdated>;
  html: Scalars['Boolean'];
  name?: Maybe<StringUpdated>;
};

export type FeatureFlagEventAttributes = {
  __typename?: 'FeatureFlagEventAttributes';
  featureFlagId: Scalars['String'];
};

export type FeatureFlagAddedEvent = Event & {
  __typename?: 'FeatureFlagAddedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  attributes: FeatureFlagEventAttributes;
};

export type FeatureFlagRemovedEvent = Event & {
  __typename?: 'FeatureFlagRemovedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  attributes: FeatureFlagEventAttributes;
};

export type HandbookCreatedEvent = Event & {
  __typename?: 'HandbookCreatedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  handbook: EventHandbook;
};

export type HandbookPublishedEventAttributes = {
  __typename?: 'HandbookPublishedEventAttributes';
  versionNumber: Scalars['Int'];
};

export type HandbookPublishedEvent = Event & {
  __typename?: 'HandbookPublishedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  handbook: EventHandbook;
  attributes: HandbookPublishedEventAttributes;
};

export type HandbookSignatureRoundCreatedEvent = Event & {
  __typename?: 'HandbookSignatureRoundCreatedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  handbook: EventHandbook;
  signatureRound: EventHandbookSignatureRound;
};

export type HandbookSignedEventAttributes = {
  __typename?: 'HandbookSignedEventAttributes';
  handbookSignatureId: Scalars['Int'];
};

export type HandbookSignedEvent = Event & {
  __typename?: 'HandbookSignedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  handbook: EventHandbook;
  attributes: HandbookSignedEventAttributes;
};

export type HandbookViewedEventAttributes = {
  __typename?: 'HandbookViewedEventAttributes';
  handbookSessionId: Scalars['Int'];
};

export type HandbookViewedEvent = Event & {
  __typename?: 'HandbookViewedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  handbook: EventHandbook;
  attributes: HandbookViewedEventAttributes;
};

export type PeopleSyncEvent = Event & {
  __typename?: 'PeopleSyncEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
};

export type PersonCreatedEvent = Event & {
  __typename?: 'PersonCreatedEvent';
  type: EventType;
  id: Scalars['ID'];
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
};

export type PersonNotifiedEvent = Event & {
  __typename?: 'PersonNotifiedEvent';
  type: EventType;
  id: Scalars['ID'];
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  notification: EventNotification;
};

export type PersonAddedToHandbookEventAttributes = {
  __typename?: 'PersonAddedToHandbookEventAttributes';
  handbookId: Scalars['Int'];
};

export type PersonAddedToHandbookEvent = Event & {
  __typename?: 'PersonAddedToHandbookEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  attributes: PersonAddedToHandbookEventAttributes;
  handbook: EventHandbook;
  targetPerson: WhodunnitPerson;
};

export type PersonRemovedFromHandbookEventAttributes = {
  __typename?: 'PersonRemovedFromHandbookEventAttributes';
  handbookId: Scalars['Int'];
  personalizationId: Scalars['ID'];
};

export type PersonRemovedFromHandbookEvent = Event & {
  __typename?: 'PersonRemovedFromHandbookEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
  attributes: PersonRemovedFromHandbookEventAttributes;
  handbook: EventHandbook;
  targetPerson: WhodunnitPerson;
};

export type PersonArchivedEvent = Event & {
  __typename?: 'PersonArchivedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
};

export type PersonUnarchivedEvent = Event & {
  __typename?: 'PersonUnarchivedEvent';
  id: Scalars['ID'];
  type: EventType;
  targetType: EventTargetType;
  targetId: Scalars['Int'];
  person?: Maybe<WhodunnitPerson>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  sortKey: Scalars['String'];
};

export type Handbook = {
  __typename?: 'Handbook';
  id: Scalars['Int'];
  teamId: Scalars['Int'];
  name: Scalars['String'];
  defaultLanguageCode: Scalars['String'];
  customDomain?: Maybe<Scalars['Domain']>;
  customDomainOrigin?: Maybe<Scalars['HttpUrl']>;
  isPrintDisabled: Scalars['Boolean'];
  position: Scalars['Int'];
  public: Scalars['Boolean'];
  publicToken: Scalars['String'];
  version: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']>;
  lastModifiedBy?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedBy?: Maybe<Scalars['Int']>;
  lastPublishedByPerson?: Maybe<WhodunnitPerson>;
  slug?: Maybe<Scalars['String']>;
  url: Scalars['HttpUrl'];
  printUrl: Scalars['HttpUrl'];
  publishedUrl: Scalars['HttpUrl'];
  permissionIds: Array<Scalars['String']>;
  acknowledgementForm: HandbookAcknowledgement;
  branding: HandbookBranding;
  contactFragments: HandbookContact;
  collaborators: Array<HandbookCollaborator>;
  audience: HandbookAudience;
  hasAudience: Scalars['Boolean'];
  audienceInvitationsCount: Scalars['Int'];
  viewersCount: Scalars['Int'];
  archivedSections: Array<HandbookSection>;
  assets: Array<HandbookAsset>;
  content: Scalars['BodyContent'];
  contentLength: Scalars['Int'];
  deletePreviewLinks: Scalars['Boolean'];
  fonts: Array<HandbookFont>;
  hasPersonalizedContent: Scalars['Boolean'];
  sections: Array<HandbookSection>;
  previewLinks: Array<HandbookPreviewLink>;
  publishNotification?: Maybe<PublishHandbookNotification>;
  signatureRounds: Array<HandbookSignatureRound>;
  tasks: Array<HandbookTask>;
  versions: Array<HandbookVersionSummary>;
  statistics: HandbookStatistics;
};


export type HandbookAudienceInvitationsCountArgs = {
  audience: HandbookAudienceInput;
};


export type HandbookViewersCountArgs = {
  canNotify?: InputMaybe<Scalars['Boolean']>;
  canSign?: InputMaybe<Scalars['Boolean']>;
  notMe?: InputMaybe<Scalars['Boolean']>;
};


export type HandbookAssetsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<AssetType>;
};


export type HandbookFontsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
};


export type HandbookStatisticsArgs = {
  canView?: InputMaybe<Scalars['Boolean']>;
  groupIds?: InputMaybe<Array<Scalars['Int']>>;
  managerId?: InputMaybe<Scalars['Int']>;
  managesDirectly?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
};

export type HandbookInput = {
  acknowledgementForm?: InputMaybe<HandbookAcknowledgementInput>;
  branding?: InputMaybe<HandbookBrandingInput>;
  contactFragments?: InputMaybe<HandbookContactInput>;
  defaultLanguageCode?: InputMaybe<Scalars['String']>;
  deletePreviewLinks?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  isPrintDisabled?: InputMaybe<Scalars['Boolean']>;
  public?: InputMaybe<Scalars['Boolean']>;
};

export type HandbookCssInput = {
  branding?: InputMaybe<HandbookBrandingAdminInput>;
};

export type HandbookAcknowledgement = {
  __typename?: 'HandbookAcknowledgement';
  title?: Maybe<Scalars['TitleContent']>;
  body?: Maybe<Scalars['BodyContent']>;
  forceSign: Scalars['Boolean'];
};

export type HandbookAcknowledgementInput = {
  title?: InputMaybe<Scalars['TitleContent']>;
  body?: InputMaybe<Scalars['BodyContent']>;
  forceSign?: InputMaybe<Scalars['Boolean']>;
};

export type HandbookAsset = {
  id: Scalars['Int'];
  type: AssetType;
  filename: Scalars['String'];
  filesize: Scalars['Int'];
  path: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  mimeType?: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type HandbookDocumentRef = {
  __typename?: 'HandbookDocumentRef';
  handbookId: Scalars['Int'];
  documentId: Scalars['Int'];
  isDraft: Scalars['Boolean'];
  isLive: Scalars['Boolean'];
};

export type HandbookImage = HandbookAsset & {
  __typename?: 'HandbookImage';
  id: Scalars['Int'];
  type: AssetType;
  filename: Scalars['String'];
  filesize: Scalars['Int'];
  path: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  mimeType?: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type HandbookPdf = HandbookAsset & {
  __typename?: 'HandbookPdf';
  id: Scalars['Int'];
  type: AssetType;
  filename: Scalars['String'];
  filesize: Scalars['Int'];
  path: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  mimeType?: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type HandbookBranding = {
  __typename?: 'HandbookBranding';
  blackColor: Scalars['Color'];
  bodyFont: HandbookBrandingFont;
  customHtml?: Maybe<Scalars['String']>;
  favicon?: Maybe<Scalars['HttpUrl']>;
  handbookCss?: Maybe<Scalars['String']>;
  headingFont: HandbookBrandingFont;
  lessButtonText: Scalars['String'];
  moreButtonText: Scalars['String'];
  primaryColor: Scalars['Color'];
  printCss?: Maybe<Scalars['String']>;
  scriptFont: HandbookBrandingFont;
  whiteColor: Scalars['Color'];
  linkColor?: Maybe<Scalars['String']>;
};

export type HandbookBrandingInput = {
  blackColor?: InputMaybe<Scalars['Color']>;
  bodyFont?: InputMaybe<HandbookBrandingFontInput>;
  favicon?: InputMaybe<Scalars['HttpUrl']>;
  headingFont?: InputMaybe<HandbookBrandingFontInput>;
  lessButtonText?: InputMaybe<Scalars['String']>;
  moreButtonText?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['Color']>;
  scriptFont?: InputMaybe<HandbookBrandingFontInput>;
  whiteColor?: InputMaybe<Scalars['Color']>;
  linkColor?: InputMaybe<Scalars['String']>;
};

export type HandbookBrandingAdminInput = {
  handbookCss?: InputMaybe<Scalars['String']>;
  printCss?: InputMaybe<Scalars['String']>;
};

export enum HandbookBrandingFontType {
  Custom = 'custom',
  Google = 'google',
  System = 'system'
}

export type HandbookBrandingFont = {
  __typename?: 'HandbookBrandingFont';
  type: HandbookBrandingFontType;
  family?: Maybe<Scalars['String']>;
  fontId?: Maybe<Scalars['Int']>;
  bold?: Maybe<Scalars['Int']>;
  normal?: Maybe<Scalars['Int']>;
  variants?: Maybe<Array<Scalars['String']>>;
};

export type HandbookBrandingFontInput = {
  type: HandbookBrandingFontType;
  family?: InputMaybe<Scalars['String']>;
  fontId?: InputMaybe<Scalars['Int']>;
  bold?: InputMaybe<Scalars['Int']>;
  normal?: InputMaybe<Scalars['Int']>;
  variants?: InputMaybe<Array<Scalars['String']>>;
};

export type HandbookCollaborator = {
  __typename?: 'HandbookCollaborator';
  id: Scalars['Int'];
  handbookId: Scalars['Int'];
  personId: Scalars['Int'];
  roleId?: Maybe<HandbookCollaboratorRoleId>;
};

export type HandbookCollaboratorInput = {
  roleId?: InputMaybe<HandbookCollaboratorRoleId>;
};

export type HandbookContact = {
  __typename?: 'HandbookContact';
  title?: Maybe<Scalars['TitleContent']>;
  body?: Maybe<Scalars['BodyContent']>;
};

export type HandbookContactInput = {
  title?: InputMaybe<Scalars['TitleContent']>;
  body?: InputMaybe<Scalars['BodyContent']>;
};

export type HandbookFont = {
  __typename?: 'HandbookFont';
  id: Scalars['Int'];
  filename: Scalars['String'];
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type HandbookNotification = {
  __typename?: 'HandbookNotification';
  id: Scalars['Int'];
  handbookId?: Maybe<Scalars['Int']>;
  personId: Scalars['Int'];
  email: Scalars['EmailAddress'];
  toName?: Maybe<Scalars['String']>;
  fromEmail?: Maybe<Scalars['EmailAddress']>;
  fromName?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  sentAt?: Maybe<Scalars['DateTime']>;
  lastError?: Maybe<Scalars['String']>;
  lastErrorAt?: Maybe<Scalars['DateTime']>;
};

export type HandbookPreviewLink = {
  __typename?: 'HandbookPreviewLink';
  handbookId: Scalars['Int'];
  personId?: Maybe<Scalars['Int']>;
  token: Scalars['String'];
  previewUrl: Scalars['HttpUrl'];
  createdAt: Scalars['DateTime'];
};

export type HandbookAudience = {
  __typename?: 'HandbookAudience';
  member: Scalars['Boolean'];
  groupIds: Array<Scalars['Int']>;
  personIds: Array<Scalars['Int']>;
  savedSegmentIds: Array<Scalars['Int']>;
};

export type HandbookAudienceInput = {
  member?: InputMaybe<Scalars['Boolean']>;
  groupIds?: InputMaybe<Array<Scalars['Int']>>;
  personIds?: InputMaybe<Array<Scalars['Int']>>;
  savedSegmentIds?: InputMaybe<Array<Scalars['Int']>>;
  newPeople?: InputMaybe<Array<NewPersonInput>>;
};

export type DocumentRefNodeAttrsInput = {
  hideToc: Scalars['Boolean'];
  tocTitle?: InputMaybe<Scalars['String']>;
};

export enum HandbookRecipientsColumnId {
  EmployeeId = 'employeeId',
  FullName = 'fullName',
  Email = 'email',
  DocumentStatus = 'documentStatus',
  LastHiredOn = 'lastHiredOn',
  Level = 'level',
  Manager = 'manager',
  LastSigned = 'lastSigned',
  ComplianceStatus = 'complianceStatus',
  SignatureDueDate = 'signatureDueDate',
  LastViewed = 'lastViewed',
  LastNotified = 'lastNotified',
  Groups = 'groups',
  AddedAt = 'addedAt',
  RemovedAt = 'removedAt',
  Metadata = 'metadata'
}

export type HandbookRecipientsColumnKey = {
  field: HandbookRecipientsColumnId;
  path?: InputMaybe<Scalars['String']>;
};

export type HandbookRecipientsColumnStateInput = {
  field: HandbookRecipientsColumnId;
  path?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
};

export type HandbookRecipientsFilter = {
  canNotify?: InputMaybe<Scalars['Boolean']>;
  canSign?: InputMaybe<Scalars['Boolean']>;
  canView?: InputMaybe<Scalars['Boolean']>;
  expression?: InputMaybe<HandbookRecipientsRootExpressionInput>;
  isManager?: InputMaybe<Scalars['Boolean']>;
  isSignatureRequired?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  canViewUpdatedAt?: InputMaybe<Scalars['Date']>;
};

export enum HandbookRecipientsSortField {
  AddedAt = 'addedAt',
  Email = 'email',
  EmployeeId = 'employeeId',
  FullName = 'fullName',
  IsManager = 'isManager',
  IsSignatureRequired = 'isSignatureRequired',
  LastHiredOn = 'lastHiredOn',
  LastNotifiedAt = 'lastNotifiedAt',
  LastSignedAt = 'lastSignedAt',
  LastViewedAt = 'lastViewedAt',
  RemovedAt = 'removedAt',
  Score = 'score',
  SignatureDueDate = 'signatureDueDate'
}

export type HandbookRecipientsSortOrder = {
  field: HandbookRecipientsSortField;
  direction: SortDirection;
};

export type HandbookRecipient = {
  __typename?: 'HandbookRecipient';
  handbookId: Scalars['Int'];
  personId: Scalars['Int'];
  email?: Maybe<Scalars['EmailAddress']>;
  employeeId?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  groupIds: Array<Scalars['Int']>;
  groupNames: Array<Scalars['String']>;
  isManager: Scalars['Boolean'];
  lastHiredOn?: Maybe<Scalars['Date']>;
  managers: Array<PersonManager>;
  metadata?: Maybe<Scalars['JSON']>;
  canNotify: Scalars['Boolean'];
  canView: Scalars['Boolean'];
  signatureRoundId?: Maybe<Scalars['Int']>;
  signatureRequestedAt?: Maybe<Scalars['DateTime']>;
  signatureDueDate?: Maybe<Scalars['Date']>;
  signatureDueAt?: Maybe<Scalars['DateTime']>;
  isSignatureRequired: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  signatureValid: Scalars['Boolean'];
  hasValidSignature: Scalars['Boolean'];
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  notifications: Array<HandbookNotification>;
  lastNotificationQueuedAt?: Maybe<Scalars['DateTime']>;
  lastViewedAt?: Maybe<Scalars['DateTime']>;
  lastViewedVersion?: Maybe<Scalars['Int']>;
  sessions: Array<HandbookSession>;
  lastSignedAt?: Maybe<Scalars['DateTime']>;
  lastSignedUserId?: Maybe<Scalars['Int']>;
  lastSignedVersion?: Maybe<Scalars['Int']>;
  signatures: Array<HandbookSignature>;
  signatureDueDateOverrides: Array<HandbookSignatureDueDateOverride>;
  signatureRoundIds: Array<Scalars['Int']>;
  score: Scalars['Float'];
  /** @deprecated Field no longer supported */
  maxScore: Scalars['Float'];
  sortKey?: Maybe<Scalars['String']>;
  canViewUpdatedAt?: Maybe<Scalars['DateTime']>;
};

export enum HandbookReminderDate {
  Invitation = 'invitation',
  Due = 'due'
}

export type HandbookReminder = {
  __typename?: 'HandbookReminder';
  id: Scalars['Int'];
  isEnabled: Scalars['Boolean'];
  days: Scalars['Int'];
  date: HandbookReminderDate;
  cc: Array<Scalars['EmailAddress']>;
  ccManagers: Scalars['Boolean'];
  subjectContent: Scalars['TextContent'];
  buttonContent: Scalars['TextContent'];
  messageContent: Scalars['EmailContent'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

export type HandbookReminderInput = {
  days: Scalars['Int'];
  date: HandbookReminderDate;
  cc: Array<Scalars['EmailAddress']>;
  ccManagers: Scalars['Boolean'];
  subjectContent: Scalars['TextContent'];
  buttonContent: Scalars['TextContent'];
  messageContent: Scalars['EmailContent'];
};

export enum HandbookSectionType {
  Beliefs = 'beliefs',
  Chapter = 'chapter',
  Cover = 'cover',
  Credo = 'credo',
  Credoh = 'credoh',
  Goal = 'goal',
  Hero = 'hero',
  History = 'history',
  Leaders = 'leaders',
  Letter = 'letter',
  List = 'list',
  Mission = 'mission',
  Origin = 'origin',
  Overview = 'overview',
  Principles = 'principles',
  Text = 'text',
  Timeline = 'timeline',
  Values = 'values',
  Worthiness = 'worthiness'
}

export type HandbookSection = {
  __typename?: 'HandbookSection';
  handbookId: Scalars['Int'];
  id: Scalars['Int'];
  type: HandbookSectionType;
  version: Scalars['Int'];
  dirty?: Maybe<Scalars['Boolean']>;
  dirtyFields?: Maybe<Array<Scalars['String']>>;
  theme?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  hideToc: Scalars['Boolean'];
  attrs: HandbookSectionAttributes;
  fragments: HandbookSectionContent;
  listEntries?: Maybe<Array<HandbookSectionEntry>>;
  hidden: Scalars['Boolean'];
  audienceExpression?: Maybe<RootExpression>;
  viewAnnotation?: Maybe<Scalars['String']>;
  viewAnnotationIds: Array<Scalars['String']>;
  editAnnotation?: Maybe<Scalars['String']>;
  hideAnnotations: Array<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['Int']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']>;
  lastPublishedBy?: Maybe<Scalars['Int']>;
  annotations: Array<Annotation>;
};

export type HandbookSectionState = {
  __typename?: 'HandbookSectionState';
  dirty?: Maybe<Scalars['Boolean']>;
  dirtyFields?: Maybe<Array<Scalars['String']>>;
};

export type HandbookSectionAttributes = {
  __typename?: 'HandbookSectionAttributes';
  keys: Array<Scalars['String']>;
  titleAlign?: Maybe<HandbookSectionTextAlign>;
  subtitleAlign?: Maybe<HandbookSectionTextAlign>;
  goalAlign?: Maybe<HandbookSectionTextAlign>;
  statementAlign?: Maybe<HandbookSectionTextAlign>;
  animation?: Maybe<Scalars['Boolean']>;
  backgroundAnimation?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['HandbookColor']>;
  backgroundImage?: Maybe<Scalars['HttpUrl']>;
  backgroundOverlayColor?: Maybe<Scalars['HandbookColor']>;
  backgroundOverlayOpacity?: Maybe<Scalars['Int']>;
  bottom?: Maybe<Scalars['String']>;
  bottomArrow?: Maybe<Scalars['Boolean']>;
  headingBackgroundImage?: Maybe<Scalars['HttpUrl']>;
  height?: Maybe<Scalars['String']>;
  entryAngle?: Maybe<Scalars['String']>;
  entryAnimation?: Maybe<Scalars['Boolean']>;
  linkColor?: Maybe<Scalars['HandbookColor']>;
  maxColumns?: Maybe<Scalars['Int']>;
  showChapterIcon?: Maybe<Scalars['Boolean']>;
  signatureType?: Maybe<Scalars['String']>;
  textShadow?: Maybe<Scalars['Boolean']>;
  titleHighlight?: Maybe<Scalars['String']>;
  titleHighlightColor?: Maybe<Scalars['HandbookColor']>;
  textColor?: Maybe<Scalars['HandbookColor']>;
  textBackgroundColor?: Maybe<Scalars['HandbookColor']>;
  textBorderColor?: Maybe<Scalars['HandbookColor']>;
  textBorderStyle?: Maybe<Scalars['String']>;
  textPadding?: Maybe<Scalars['String']>;
  textBorderWidth?: Maybe<Scalars['String']>;
  textBorderRadius?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['HttpUrl']>;
  photo?: Maybe<Scalars['HttpUrl']>;
  signatureImage?: Maybe<Scalars['HttpUrl']>;
  video?: Maybe<HandbookVideo>;
};

export type HandbookSectionAttributesInput = {
  titleAlign?: InputMaybe<HandbookSectionTextAlign>;
  subtitleAlign?: InputMaybe<HandbookSectionTextAlign>;
  goalAlign?: InputMaybe<HandbookSectionTextAlign>;
  statementAlign?: InputMaybe<HandbookSectionTextAlign>;
  animation?: InputMaybe<Scalars['Boolean']>;
  backgroundAnimation?: InputMaybe<Scalars['String']>;
  backgroundColor?: InputMaybe<Scalars['HandbookColor']>;
  backgroundImage?: InputMaybe<Scalars['HttpUrl']>;
  backgroundOverlayColor?: InputMaybe<Scalars['HandbookColor']>;
  backgroundOverlayOpacity?: InputMaybe<Scalars['Int']>;
  bottom?: InputMaybe<Scalars['String']>;
  bottomArrow?: InputMaybe<Scalars['Boolean']>;
  headingBackgroundImage?: InputMaybe<Scalars['HttpUrl']>;
  height?: InputMaybe<Scalars['String']>;
  entryAngle?: InputMaybe<Scalars['String']>;
  entryAnimation?: InputMaybe<Scalars['Boolean']>;
  linkColor?: InputMaybe<Scalars['HandbookColor']>;
  maxColumns?: InputMaybe<Scalars['Int']>;
  showChapterIcon?: InputMaybe<Scalars['Boolean']>;
  signatureType?: InputMaybe<Scalars['String']>;
  textShadow?: InputMaybe<Scalars['Boolean']>;
  titleHighlight?: InputMaybe<Scalars['String']>;
  titleHighlightColor?: InputMaybe<Scalars['HandbookColor']>;
  textColor?: InputMaybe<Scalars['HandbookColor']>;
  textBackgroundColor?: InputMaybe<Scalars['HandbookColor']>;
  textBorderColor?: InputMaybe<Scalars['HandbookColor']>;
  textBorderStyle?: InputMaybe<Scalars['String']>;
  textPadding?: InputMaybe<Scalars['String']>;
  textBorderWidth?: InputMaybe<Scalars['String']>;
  textBorderRadius?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['HttpUrl']>;
  photo?: InputMaybe<Scalars['HttpUrl']>;
  signatureImage?: InputMaybe<Scalars['HttpUrl']>;
  video?: InputMaybe<HandbookVideoInput>;
};

export type HandbookSectionContent = {
  __typename?: 'HandbookSectionContent';
  keys: Array<Scalars['String']>;
  title?: Maybe<Scalars['TitleContent']>;
  subtitle?: Maybe<Scalars['TitleContent']>;
  goal?: Maybe<Scalars['TitleContent']>;
  statement?: Maybe<Scalars['TitleContent']>;
  headline?: Maybe<Scalars['BodyContent']>;
  introduction?: Maybe<Scalars['BodyContent']>;
  body?: Maybe<Scalars['BodyContent']>;
  description?: Maybe<Scalars['BodyContent']>;
  signature?: Maybe<Scalars['BodyContent']>;
};

export type HandbookSectionContentInput = {
  title?: InputMaybe<Scalars['TitleContent']>;
  subtitle?: InputMaybe<Scalars['TitleContent']>;
  goal?: InputMaybe<Scalars['TitleContent']>;
  statement?: InputMaybe<Scalars['TitleContent']>;
  headline?: InputMaybe<Scalars['BodyContent']>;
  introduction?: InputMaybe<Scalars['BodyContent']>;
  body?: InputMaybe<Scalars['BodyContent']>;
  description?: InputMaybe<Scalars['BodyContent']>;
  signature?: InputMaybe<Scalars['BodyContent']>;
};

export type HandbookSectionEntry = {
  __typename?: 'HandbookSectionEntry';
  keys: Array<Scalars['String']>;
  uid: Scalars['String'];
  title?: Maybe<Scalars['TitleContent']>;
  titleAlign?: Maybe<HandbookSectionTextAlign>;
  subtitle?: Maybe<Scalars['TitleContent']>;
  subtitleAlign?: Maybe<HandbookSectionTextAlign>;
  body?: Maybe<Scalars['BodyContent']>;
  image?: Maybe<Scalars['HttpUrl']>;
};

export type HandbookSectionEntryInput = {
  title?: InputMaybe<Scalars['TitleContent']>;
  titleAlign?: InputMaybe<HandbookSectionTextAlign>;
  subtitle?: InputMaybe<Scalars['TitleContent']>;
  subtitleAlign?: InputMaybe<HandbookSectionTextAlign>;
  body?: InputMaybe<Scalars['BodyContent']>;
  image?: InputMaybe<Scalars['HttpUrl']>;
};

export type NewHandbookSectionInput = {
  type: HandbookSectionType;
  theme?: InputMaybe<Scalars['String']>;
  attrs?: InputMaybe<HandbookSectionAttributesInput>;
  fragments?: InputMaybe<HandbookSectionContentInput>;
  listEntries?: InputMaybe<Array<HandbookSectionEntryInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type CloneHandbookSectionsResult = {
  __typename?: 'CloneHandbookSectionsResult';
  index: Scalars['Int'];
  content: Scalars['BodyContent'];
  sections: Array<HandbookSection>;
};

export type AddHandbookSectionEntryResult = {
  __typename?: 'AddHandbookSectionEntryResult';
  sectionState: HandbookSectionState;
  entry: HandbookSectionEntry;
};

export type PatchHandbookSectionEntryResult = {
  __typename?: 'PatchHandbookSectionEntryResult';
  sectionState: HandbookSectionState;
  entry: HandbookSectionEntry;
};

export type MoveHandbookSectionEntryResult = {
  __typename?: 'MoveHandbookSectionEntryResult';
  sectionState: HandbookSectionState;
};

export type RemoveHandbookSectionEntryResult = {
  __typename?: 'RemoveHandbookSectionEntryResult';
  sectionState: HandbookSectionState;
};

export type RevertHandbookDocumentRefsResult = {
  __typename?: 'RevertHandbookDocumentRefsResult';
  content: Scalars['BodyContent'];
  sections: Array<HandbookSection>;
};

export type HandbookSectionInput = {
  attrs?: InputMaybe<HandbookSectionAttributesInput>;
  editAnnotation?: InputMaybe<Scalars['String']>;
  audienceExpression?: InputMaybe<AudienceRootExpressionInput>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  hideAnnotations?: InputMaybe<Array<Scalars['Int']>>;
  hideToc?: InputMaybe<Scalars['Boolean']>;
  languageCode?: InputMaybe<Scalars['String']>;
  fragments?: InputMaybe<HandbookSectionContentInput>;
  theme?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  viewAnnotation?: InputMaybe<Scalars['String']>;
  viewAnnotationIds?: InputMaybe<Array<Scalars['String']>>;
};

export type HandbookSectionRead = {
  __typename?: 'HandbookSectionRead';
  sectionId: Scalars['Int'];
  version: Scalars['Int'];
  personId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
};

export enum HandbookSectionTextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

export type HandbookSectionVersion = {
  __typename?: 'HandbookSectionVersion';
  sectionId: Scalars['Int'];
  sectionVersion: Scalars['Int'];
  handbookId: Scalars['Int'];
  handbookVersion: Scalars['Int'];
  type: HandbookSectionType;
  theme?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  hideToc: Scalars['Boolean'];
  attrs: HandbookSectionAttributes;
  fragments: HandbookSectionContent;
  listEntries?: Maybe<Array<HandbookSectionEntry>>;
  hidden: Scalars['Boolean'];
  audienceExpression?: Maybe<RootExpression>;
  viewAnnotation?: Maybe<Scalars['String']>;
  viewAnnotationIds: Array<Scalars['String']>;
  editAnnotation?: Maybe<Scalars['String']>;
  hideAnnotations: Array<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['Int']>;
};

export enum HandbookSeedType {
  Empty = 'empty',
  Inspirational = 'inspirational'
}

export type Personalization = {
  __typename?: 'Personalization';
  groupIds: Array<Scalars['Int']>;
  metadata?: Maybe<Scalars['JSON']>;
  personId: Scalars['Int'];
  savedSegmentIds: Array<Scalars['Int']>;
};

export type HandbookSession = {
  __typename?: 'HandbookSession';
  id: Scalars['Int'];
  handbookId: Scalars['Int'];
  handbookVersion: Scalars['Int'];
  personId: Scalars['Int'];
  languageCode: Scalars['String'];
  personalization: Personalization;
  createdAt: Scalars['DateTime'];
  lastViewedAt: Scalars['DateTime'];
  user: HandbookUser;
};

export type HandbookSignature = {
  __typename?: 'HandbookSignature';
  id: Scalars['Int'];
  handbookId: Scalars['Int'];
  handbookVersion: Scalars['Int'];
  personId: Scalars['Int'];
  roundId: Scalars['Int'];
  acknowledgementForm: HandbookSignatureAcknowledgement;
  signedAt: Scalars['DateTime'];
  signature?: Maybe<Scalars['String']>;
  languageCode: Scalars['String'];
  personalization: Personalization;
  toc: Array<HandbookSignatureTocItem>;
  createdAt: Scalars['DateTime'];
  recordedByPersonId?: Maybe<Scalars['Int']>;
  recordedByUserId?: Maybe<Scalars['Int']>;
  recordedByUser?: Maybe<HandbookUser>;
  userId?: Maybe<Scalars['Int']>;
  user?: Maybe<HandbookUser>;
};

export type HandbookSignatureAcknowledgement = {
  __typename?: 'HandbookSignatureAcknowledgement';
  title: Scalars['TitleContent'];
  body: Scalars['BodyContent'];
  wysiwyg?: Maybe<Scalars['String']>;
};

export type HandbookSignatureDueDateOverride = {
  __typename?: 'HandbookSignatureDueDateOverride';
  id: Scalars['Int'];
  personId: Scalars['Int'];
  roundId: Scalars['Int'];
  previousDueDate?: Maybe<Scalars['Date']>;
  dueDate: Scalars['Date'];
  note?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['Int']>;
};

export type HandbookSignatureTocItem = {
  __typename?: 'HandbookSignatureTocItem';
  bookmark: Scalars['String'];
  title: Scalars['String'];
  printUrl: Scalars['String'];
};

export type HandbookSignatureRound = {
  __typename?: 'HandbookSignatureRound';
  id: Scalars['Int'];
  handbookId: Scalars['Int'];
  number: Scalars['Int'];
  audienceExpression?: Maybe<RootExpression>;
  /** @deprecated Field no longer supported */
  onlyFuture: Scalars['Boolean'];
  onlyAtLaunch?: Maybe<HandbookSignatureRoundOnlyAtLaunch>;
  onlyMembers: Scalars['Boolean'];
  dueDays?: Maybe<Scalars['Int']>;
  dueDaysFuture?: Maybe<Scalars['Int']>;
  archived: Scalars['Boolean'];
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<WhodunnitPerson>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<WhodunnitPerson>;
  peopleCount: Scalars['Int'];
};

export enum HandbookSignatureRoundOnlyAtLaunch {
  Existing = 'existing',
  Future = 'future'
}

export type HandbookSignatureRoundInput = {
  audienceExpression?: InputMaybe<AudienceRootExpressionInput>;
  onlyAtLaunch?: InputMaybe<HandbookSignatureRoundOnlyAtLaunch>;
  onlyMembers?: InputMaybe<Scalars['Boolean']>;
  dueDays?: InputMaybe<Scalars['Int']>;
  dueDaysFuture?: InputMaybe<Scalars['Int']>;
};

export type HandbookStatistics = {
  __typename?: 'HandbookStatistics';
  peopleCount: Scalars['Int'];
  canNotifyPeopleCount: Scalars['Int'];
  canNotifyNonCompliantPeopleCount: Scalars['Int'];
  nonCompliantPeopleCount: Scalars['Int'];
  notifiedPeopleCount: Scalars['Int'];
  maxNotifiedPeopleCount: Scalars['Int'];
  viewedPeopleCount: Scalars['Int'];
  maxViewedPeopleCount: Scalars['Int'];
  signedPeopleCount: Scalars['Int'];
  maxSignedPeopleCount: Scalars['Int'];
  validSignedPeopleCount: Scalars['Int'];
  maxValidSignedPeopleCount: Scalars['Int'];
  score?: Maybe<Scalars['Float']>;
};

export enum HandbookTaskId {
  ContentWizard = 'contentWizard',
  AudienceWizard = 'audienceWizard',
  Policies = 'policies',
  Content = 'content',
  Help = 'help',
  Acknowledgement = 'acknowledgement',
  Audience = 'audience',
  Signatures = 'signatures',
  Notification = 'notification',
  Links = 'links'
}

export type HandbookTask = {
  __typename?: 'HandbookTask';
  id: HandbookTaskId;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedBy?: Maybe<Scalars['Int']>;
};

export type HandbookVersion = {
  __typename?: 'HandbookVersion';
  handbookId: Scalars['Int'];
  version: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdByPerson?: Maybe<WhodunnitPerson>;
  wysiwyg?: Maybe<Scalars['String']>;
  acknowledgementForm: HandbookAcknowledgement;
  branding: HandbookBranding;
  contactFragments: HandbookContact;
  content: Scalars['BodyContent'];
  defaultLanguageCode: Scalars['String'];
  languageCodes: Array<Scalars['String']>;
  sections: Array<HandbookSectionVersion>;
};

export type HandbookVersionSummary = {
  __typename?: 'HandbookVersionSummary';
  handbookId: Scalars['Int'];
  version: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdByPerson?: Maybe<WhodunnitPerson>;
  defaultLanguageCode: Scalars['String'];
  languageCodes: Array<Scalars['String']>;
  url: Scalars['HttpUrl'];
  printUrl: Scalars['HttpUrl'];
};

export type UpdateHandbookVersionInput = {
  label?: InputMaybe<Scalars['String']>;
};

export type HandbookVideo = {
  __typename?: 'HandbookVideo';
  url: Scalars['HttpsUrl'];
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type HandbookVideoInput = {
  url: Scalars['HttpsUrl'];
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type HandbookUser = {
  __typename?: 'HandbookUser';
  id: Scalars['Int'];
  authType: UserAuthType;
  authId: Scalars['String'];
};

export type PublishHandbookContentInput = {
  acknowledgement: Scalars['Boolean'];
  branding: Scalars['Boolean'];
  contact: Scalars['Boolean'];
  sectionIds: Array<Scalars['Int']>;
};

export type PublishHandbookNotification = {
  __typename?: 'PublishHandbookNotification';
  toViewers?: Maybe<Scalars['Boolean']>;
  signers?: Maybe<Notification>;
  viewers?: Maybe<Notification>;
};

export type PublishHandbookNotificationInput = {
  toViewers?: InputMaybe<Scalars['Boolean']>;
  signers?: InputMaybe<NotificationInput>;
  viewers?: InputMaybe<NotificationInput>;
};

export type HandbookViewersNotificationInput = {
  signersNotification?: InputMaybe<NotificationInput>;
  viewersNotification?: InputMaybe<NotificationInput>;
};

export type SignHandbookSessionInput = {
  languageCode?: InputMaybe<Scalars['String']>;
  signature: Scalars['String'];
};

export type RecordHandbookSignatureInput = {
  personId: Scalars['Int'];
  languageCode?: InputMaybe<Scalars['String']>;
  signedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SourceOrganization = {
  __typename?: 'SourceOrganization';
  id: Scalars['Int'];
  name: Scalars['String'];
  isClient: Scalars['Boolean'];
  blissbookOrigin: Scalars['HttpUrl'];
};

export type LinkedOrganization = {
  __typename?: 'LinkedOrganization';
  id: Scalars['Int'];
  name: Scalars['String'];
  documentIds: Array<Scalars['Int']>;
  blissbookOrigin: Scalars['HttpUrl'];
};

export type AudienceDashboardSettings = {
  __typename?: 'AudienceDashboardSettings';
  primaryColor: Scalars['Color'];
  accentColor: Scalars['Color'];
  faviconImageId?: Maybe<Scalars['Int']>;
  faviconImage?: Maybe<Image>;
  logoImageId?: Maybe<Scalars['Int']>;
  logoImage?: Maybe<Image>;
  bannerImageId?: Maybe<Scalars['Int']>;
  bannerImage?: Maybe<Image>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type AudienceDashboardSettingsInput = {
  primaryColor?: InputMaybe<Scalars['Color']>;
  accentColor?: InputMaybe<Scalars['Color']>;
  faviconImageId?: InputMaybe<Scalars['Int']>;
  logoImageId?: InputMaybe<Scalars['Int']>;
  bannerImageId?: InputMaybe<Scalars['Int']>;
};

export type AudienceMetadataEntry = {
  __typename?: 'AudienceMetadataEntry';
  key: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  key: Scalars['String'];
  createdAt: Scalars['DateTime'];
  archivedAt?: Maybe<Scalars['DateTime']>;
};

export type NewApiKey = {
  __typename?: 'NewApiKey';
  id: Scalars['Int'];
  key: Scalars['String'];
  secret: Scalars['String'];
  createdAt: Scalars['DateTime'];
  archivedAt?: Maybe<Scalars['DateTime']>;
};

export type CertificateInput = {
  certificate: Scalars['String'];
  privateKey: Scalars['String'];
};

export type BillingInput = {
  exempt?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EmailAuthSettings = {
  __typename?: 'EmailAuthSettings';
  isEnabled: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailAuthSettingsInput = {
  isEnabled: Scalars['Boolean'];
};

export enum EmailDomainEmailStatus {
  Failed = 'failed',
  Pending = 'pending',
  Success = 'success'
}

export type EmailDomain = {
  __typename?: 'EmailDomain';
  id: Scalars['Int'];
  domain: Scalars['Domain'];
  environment: Scalars['String'];
  fromEmail: Scalars['EmailAddress'];
  fromName: Scalars['String'];
  dkimStatus: EmailDomainDkimStatus;
  returnPathStatus: EmailDomainReturnPathStatus;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type EmailDomainStatus = {
  __typename?: 'EmailDomainStatus';
  dkimStatus: EmailDomainDkimStatus;
  dkimDns: EmailDomainDkimDns;
  returnPathStatus: EmailDomainReturnPathStatus;
  returnPathDns: EmailDomainReturnPathDns;
};

export type EmailDomainDkimStatus = {
  __typename?: 'EmailDomainDkimStatus';
  verified: Scalars['Boolean'];
  lastVerifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailDomainDkimDns = {
  __typename?: 'EmailDomainDkimDns';
  host: Scalars['String'];
  textValue: Scalars['String'];
  pendingHost: Scalars['String'];
  pendingTextValue: Scalars['String'];
};

export type EmailDomainReturnPathStatus = {
  __typename?: 'EmailDomainReturnPathStatus';
  verified: Scalars['Boolean'];
  lastVerifiedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailDomainReturnPathDns = {
  __typename?: 'EmailDomainReturnPathDns';
  domain: Scalars['Domain'];
  cnameValue: Scalars['String'];
};

export type EmailDomainInput = {
  fromEmail: Scalars['EmailAddress'];
  fromName: Scalars['String'];
};

export type EmailFooterLink = {
  __typename?: 'EmailFooterLink';
  text: Scalars['String'];
  url: Scalars['HttpUrl'];
};

export type EmailFooterLinkInput = {
  text: Scalars['String'];
  url: Scalars['HttpUrl'];
};

export type EmailSettings = {
  __typename?: 'EmailSettings';
  autoSendInvitations?: Maybe<Scalars['Boolean']>;
  fromEmail?: Maybe<Scalars['EmailAddress']>;
  fromName?: Maybe<Scalars['String']>;
  replyToEmail?: Maybe<Scalars['EmailAddress']>;
  defaultSenderFromName?: Maybe<Scalars['String']>;
  defaultSenderReplyToEmail?: Maybe<Scalars['EmailAddress']>;
  bannerImageId?: Maybe<Scalars['Int']>;
  bannerImage?: Maybe<Image>;
  bodyFont: Scalars['String'];
  footerText: Scalars['String'];
  footerLinks: Array<EmailFooterLink>;
  logoImageId?: Maybe<Scalars['Int']>;
  logoImage?: Maybe<Image>;
  primaryColor: Scalars['Color'];
  hasCustomBranding: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailBrandingInput = {
  bannerImageId?: InputMaybe<Scalars['Int']>;
  bodyFont?: InputMaybe<Scalars['String']>;
  footerText?: InputMaybe<Scalars['String']>;
  footerLinks?: InputMaybe<Array<EmailFooterLinkInput>>;
  logoImageId?: InputMaybe<Scalars['Int']>;
  primaryColor?: InputMaybe<Scalars['Color']>;
};

export type EmailSettingsInput = {
  autoSendInvitations?: InputMaybe<Scalars['Boolean']>;
  fromEmail?: InputMaybe<Scalars['EmailAddress']>;
  defaultSenderFromName?: InputMaybe<Scalars['String']>;
  defaultSenderReplyToEmail?: InputMaybe<Scalars['EmailAddress']>;
};

export enum EmailTemplateId {
  EmployeeDigest = 'employee_digest',
  ManagerDigest = 'manager_digest',
  InvitationView = 'invitation_view',
  InvitationViewSign = 'invitation_view_sign',
  ReminderView = 'reminder_view',
  ReminderSign = 'reminder_sign'
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  id: EmailTemplateId;
  subjectContent: Scalars['TextContent'];
  ccManagers: Scalars['Boolean'];
  buttonContent: Scalars['TextContent'];
  messageContent: Scalars['EmailContent'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailTemplateInput = {
  subjectContent: Scalars['TextContent'];
  ccManagers?: InputMaybe<Scalars['Boolean']>;
  buttonContent: Scalars['TextContent'];
  messageContent: Scalars['EmailContent'];
};

export enum FeatureFlagId {
  CustomHtml = 'customHtml',
  AllowCsvSync = 'allowCsvSync',
  AllowMergeDev = 'allowMergeDev',
  AllowEmployeeIdAuth = 'allowEmployeeIdAuth',
  ImportHandbooks = 'importHandbooks',
  AllowKioskMode = 'allowKioskMode',
  AllowManagers = 'allowManagers',
  AllowMultipleSamlSsos = 'allowMultipleSamlSsos',
  HideOverallGrade = 'hideOverallGrade',
  NoLaunchCountdown = 'noLaunchCountdown',
  ShowFriendsPage = 'showFriendsPage',
  HideLawChanges = 'hideLawChanges',
  ShowLawCenter = 'showLawCenter',
  ShowPolicyLibrary = 'showPolicyLibrary',
  IsSourceOrganization = 'isSourceOrganization',
  AllowPolicyWorkflows = 'allowPolicyWorkflows',
  ForceCustomPricing = 'forceCustomPricing'
}

export type Font = {
  __typename?: 'Font';
  id: Scalars['Int'];
  filename: Scalars['String'];
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['Int'];
  name: Scalars['String'];
  archived: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  peopleCount: Scalars['Int'];
};

export type GroupInput = {
  name: Scalars['String'];
};

export type HrisPeopleFilter = {
  expression?: InputMaybe<HrisPeopleRootExpressionInput>;
};

export type HrisPerson = {
  __typename?: 'HrisPerson';
  email?: Maybe<Scalars['EmailAddress']>;
  externalId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  phone?: Maybe<Scalars['PhoneNumber']>;
  preferredName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  lastHiredOn?: Maybe<Scalars['Date']>;
  groupNames?: Maybe<Array<Scalars['String']>>;
  metadata?: Maybe<Scalars['JSON']>;
  mobilePhoneNumber?: Maybe<Scalars['PhoneNumber']>;
  homePhoneNumber?: Maybe<Scalars['PhoneNumber']>;
  workPhoneNumber?: Maybe<Scalars['PhoneNumber']>;
  sortKey: Scalars['String'];
};

export type HrisPeopleStatistics = {
  __typename?: 'HrisPeopleStatistics';
  totalCount: Scalars['Int'];
};

export type HrisSyncTestModeInput = {
  maxEmployees?: InputMaybe<Scalars['Int']>;
  waitUntilFinished?: InputMaybe<Scalars['Boolean']>;
};

export type KioskModeSettings = {
  __typename?: 'KioskModeSettings';
  isEnabled: Scalars['Boolean'];
  inactivitySeconds: Scalars['Int'];
  timeoutSeconds: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KioskModeSettingsInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  inactivitySeconds?: InputMaybe<Scalars['Int']>;
  timeoutSeconds?: InputMaybe<Scalars['Int']>;
};

export enum ManagerDigestDate {
  Invitation = 'invitation',
  Due = 'due'
}

export type ManagerDigestSettings = {
  __typename?: 'ManagerDigestSettings';
  isEnabled: Scalars['Boolean'];
  isDirectReportsOnly: Scalars['Boolean'];
  daysAfter: Scalars['Int'];
  date: ManagerDigestDate;
  subjectContent: Scalars['TextContent'];
  buttonContent: Scalars['TextContent'];
  messageContent: Scalars['EmailContent'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ManagerDigestSettingsInput = {
  isEnabled: Scalars['Boolean'];
  isDirectReportsOnly: Scalars['Boolean'];
  daysAfter: Scalars['Int'];
  date: ManagerDigestDate;
  subjectContent?: InputMaybe<Scalars['TextContent']>;
  buttonContent?: InputMaybe<Scalars['TextContent']>;
  messageContent?: InputMaybe<Scalars['EmailContent']>;
};

export type ManagerSettings = {
  __typename?: 'ManagerSettings';
  allowNotificationsToReports: Scalars['Boolean'];
  allowNotificationsToManagers: Scalars['Boolean'];
  organizationId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ManagerSettingsInput = {
  allowNotificationsToReports: Scalars['Boolean'];
  allowNotificationsToManagers: Scalars['Boolean'];
};

export type ManagerStatistics = {
  __typename?: 'ManagerStatistics';
  compliantCount: Scalars['Int'];
  nonCompliantCount: Scalars['Int'];
  notifyCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ManagerPerson = {
  __typename?: 'ManagerPerson';
  personId: Scalars['Int'];
  fullName: Scalars['String'];
  preferredName?: Maybe<Scalars['String']>;
  isManager: Scalars['Boolean'];
  isSignatureRequired: Scalars['Boolean'];
  employeeStatistics: ManagerStatistics;
  handbooks: Array<ManagerPersonHandbook>;
  managers?: Maybe<Array<ManagerPersonManager>>;
  managerStatistics: ManagerStatistics;
};

export type ManagerPersonHandbook = {
  __typename?: 'ManagerPersonHandbook';
  dueDate?: Maybe<Scalars['DateTime']>;
  handbookId: Scalars['Int'];
  handbookName: Scalars['String'];
  handbookUrl: Scalars['String'];
  hasValidSignature: Scalars['Boolean'];
  isSignatureRequired: Scalars['Boolean'];
  lastSignedAt?: Maybe<Scalars['DateTime']>;
};

export type ManagerPersonManager = {
  __typename?: 'ManagerPersonManager';
  id: Scalars['Int'];
  fullName: Scalars['String'];
};

export type ManagerPersonReport = {
  __typename?: 'ManagerPersonReport';
  personId: Scalars['Int'];
  fullName: Scalars['String'];
  isManager: Scalars['Boolean'];
  isEmployeeSignatureRequired: Scalars['Boolean'];
  isSignatureRequired: Scalars['Boolean'];
  canSendEmployeeDigest: Scalars['Boolean'];
  canSendManagerDigest: Scalars['Boolean'];
  lastEmployeeDigestQueuedAt?: Maybe<Scalars['DateTime']>;
  lastManagerDigestQueuedAt?: Maybe<Scalars['DateTime']>;
  employeeStatistics?: Maybe<ManagerStatistics>;
  handbooks: Array<ManagerPersonHandbook>;
};

export type MetadataField = {
  __typename?: 'MetadataField';
  key: Scalars['String'];
  label: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  subjectContent: Scalars['TextContent'];
  buttonContent: Scalars['TextContent'];
  messageContent: Scalars['EmailContent'];
};

export type NotificationInput = {
  subjectContent: Scalars['TextContent'];
  buttonContent: Scalars['TextContent'];
  messageContent: Scalars['EmailContent'];
};

export type OrganizationInput = {
  name?: InputMaybe<Scalars['String']>;
  isHandbookPositionEnabled?: InputMaybe<Scalars['Boolean']>;
  logoImageId?: InputMaybe<Scalars['Int']>;
};

export type OrganizationAdminInput = {
  isFullServe?: InputMaybe<Scalars['Boolean']>;
  isCrmDisabled?: InputMaybe<Scalars['Boolean']>;
  isIntercomDisabled?: InputMaybe<Scalars['Boolean']>;
  isAuthDisabled?: InputMaybe<Scalars['Boolean']>;
  showPrivacyPolicy?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationRoleQuery = {
  any?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<OrganizationAdminRoleId>>;
};

export enum OrganizationTaskId {
  Authentication = 'authentication',
  EmailDeliverability = 'emailDeliverability',
  CustomDomain = 'customDomain',
  EmailBranding = 'emailBranding',
  AudienceDashboard = 'audienceDashboard',
  KioskMode = 'kioskMode',
  Sync = 'sync',
  Invitations = 'invitations',
  Reminders = 'reminders'
}

export type OrganizationTask = {
  __typename?: 'OrganizationTask';
  id: OrganizationTaskId;
  completedAt?: Maybe<Scalars['DateTime']>;
  completedBy?: Maybe<Scalars['Int']>;
};

export enum PeopleColumnId {
  ComplianceStatus = 'complianceStatus',
  CustomSsoId = 'customSsoId',
  Email = 'email',
  EmployeeId = 'employeeId',
  FullName = 'fullName',
  LastHiredOn = 'lastHiredOn',
  Level = 'level',
  MobilePhoneNumber = 'mobilePhoneNumber',
  HomePhoneNumber = 'homePhoneNumber',
  WorkPhoneNumber = 'workPhoneNumber',
  Manager = 'manager',
  LastNotifiedAt = 'lastNotifiedAt',
  LastViewedAt = 'lastViewedAt',
  LastSignedAt = 'lastSignedAt',
  LastManagerDigestQueuedAt = 'lastManagerDigestQueuedAt',
  AllReports = 'allReports',
  DirectReports = 'directReports',
  Groups = 'groups',
  Metadata = 'metadata',
  ArchivedAt = 'archivedAt',
  PreferredName = 'preferredName'
}

export type PeopleColumnKey = {
  field: PeopleColumnId;
  path?: InputMaybe<Scalars['String']>;
};

export type PeopleColumnStateInput = {
  field: PeopleColumnId;
  path?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
};

export type PeopleFilter = {
  canNotify?: InputMaybe<Scalars['Boolean']>;
  expression?: InputMaybe<PeopleRootExpressionInput>;
  expressions?: InputMaybe<PeopleRootExpressionsInput>;
  handbookAudience?: InputMaybe<HandbookAudienceInput>;
  isAudienceMember?: InputMaybe<Scalars['Boolean']>;
  isEmployeeSignatureRequired?: InputMaybe<Scalars['Boolean']>;
  isManager?: InputMaybe<Scalars['Boolean']>;
  isSignatureRequired?: InputMaybe<Scalars['Boolean']>;
  member?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
};

export enum PeopleSource {
  HrisPeople = 'hrisPeople',
  People = 'people'
}

export type PeopleStatistics = {
  __typename?: 'PeopleStatistics';
  canNotifyCount: Scalars['Int'];
  canNotifyNonCompliantCount: Scalars['Int'];
  canNotifyNonCompliantManagersCount: Scalars['Int'];
  nonCompliantCount: Scalars['Int'];
  nonCompliantManagersCount: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalManagersCount: Scalars['Int'];
};

export enum PeopleVariantType {
  Group = 'group',
  Person = 'person'
}

export type PeopleVariantInput = {
  type: PeopleVariantType;
  id: Scalars['Int'];
};

export enum PersonSortField {
  Archived = 'archived',
  ArchivedAt = 'archivedAt',
  CustomSsoId = 'customSsoId',
  Email = 'email',
  EmployeeId = 'employeeId',
  ExternalId = 'externalId',
  FullName = 'fullName',
  HomePhoneNumber = 'homePhoneNumber',
  IsManager = 'isManager',
  IsSignatureRequired = 'isSignatureRequired',
  LastEmployeeDigestQueuedAt = 'lastEmployeeDigestQueuedAt',
  LastHiredOn = 'lastHiredOn',
  LastManagerDigestQueuedAt = 'lastManagerDigestQueuedAt',
  LastNotifiedAt = 'lastNotifiedAt',
  LastViewedAt = 'lastViewedAt',
  LastSignedAt = 'lastSignedAt',
  MobilePhoneNumber = 'mobilePhoneNumber',
  NonCompliantDirectReportsPercentage = 'nonCompliantDirectReportsPercentage',
  NonCompliantReportsPercentage = 'nonCompliantReportsPercentage',
  PreferredName = 'preferredName',
  WorkPhoneNumber = 'workPhoneNumber'
}

export type PeopleSortOrder = {
  field: PersonSortField;
  direction: SortDirection;
};

export type Person = {
  __typename?: 'Person';
  id: Scalars['Int'];
  customSsoId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  externalId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  phone?: Maybe<Scalars['PhoneNumber']>;
  fullName: Scalars['String'];
  archived: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  member: Scalars['Boolean'];
  adminRoleId?: Maybe<OrganizationAdminRoleId>;
  isSuperUser: Scalars['Boolean'];
  lastHiredOn?: Maybe<Scalars['Date']>;
  avatarUrl?: Maybe<Scalars['String']>;
  lastActivatedAt: Scalars['DateTime'];
  autoSendReminders: Scalars['Boolean'];
  isSignatureRequired: Scalars['Boolean'];
  isEmployeeSignatureRequired: Scalars['Boolean'];
  totalDirectReportsCount: Scalars['Int'];
  nonCompliantDirectReportsCount: Scalars['Int'];
  nonCompliantDirectReportsPercentage: Scalars['Int'];
  totalReportsCount: Scalars['Int'];
  nonCompliantReportsCount: Scalars['Int'];
  nonCompliantReportsPercentage: Scalars['Int'];
  lastEmployeeDigestQueuedAt?: Maybe<Scalars['DateTime']>;
  lastManagerDigestQueuedAt?: Maybe<Scalars['DateTime']>;
  lastNotifiedAt?: Maybe<Scalars['DateTime']>;
  lastViewedAt?: Maybe<Scalars['DateTime']>;
  lastSignedAt?: Maybe<Scalars['DateTime']>;
  isManager: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  metadata?: Maybe<Scalars['JSON']>;
  mobilePhoneNumber?: Maybe<Scalars['PhoneNumber']>;
  homePhoneNumber?: Maybe<Scalars['PhoneNumber']>;
  workPhoneNumber?: Maybe<Scalars['PhoneNumber']>;
  groupIds: Array<Scalars['Int']>;
  handbookCollaborators: Array<HandbookCollaborator>;
  handbookRecipients: Array<HandbookRecipient>;
  handbookIds: Array<Scalars['Int']>;
  events: Array<Event>;
  managerIds: Array<Scalars['Int']>;
  managers: Array<PersonManager>;
  notifications: Array<HandbookNotification>;
  preferredName?: Maybe<Scalars['String']>;
  savedSegmentIds: Array<Scalars['Int']>;
  sessions: Array<HandbookSession>;
  signatures: Array<HandbookSignature>;
  teammates: Array<Teammate>;
  userIds: Array<Scalars['Int']>;
  sortKey?: Maybe<Scalars['String']>;
};

export type PersonInput = {
  id: Scalars['Int'];
  email?: InputMaybe<Scalars['EmailAddress']>;
  employeeId?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  member?: InputMaybe<Scalars['Boolean']>;
  mobilePhoneNumber?: InputMaybe<Scalars['PhoneNumber']>;
  homePhoneNumber?: InputMaybe<Scalars['PhoneNumber']>;
  workPhoneNumber?: InputMaybe<Scalars['PhoneNumber']>;
  groupIds?: InputMaybe<Array<Scalars['Int']>>;
  groupNames?: InputMaybe<Array<Scalars['String']>>;
  autoSendReminders?: InputMaybe<Scalars['Boolean']>;
};

export type PersonManager = {
  __typename?: 'PersonManager';
  id: Scalars['Int'];
  email?: Maybe<Scalars['EmailAddress']>;
  employeeId?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
};

export type NewPersonInput = {
  email: Scalars['EmailAddress'];
  employeeId?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  member?: InputMaybe<Scalars['Boolean']>;
  groupIds?: InputMaybe<Array<Scalars['Int']>>;
  groupNames?: InputMaybe<Array<Scalars['String']>>;
  autoSendReminders?: InputMaybe<Scalars['Boolean']>;
};

export type NewOrgCollaboratorInput = {
  email: Scalars['EmailAddress'];
  fullName: Scalars['String'];
  member?: InputMaybe<Scalars['Boolean']>;
};

export type OrgCollaboratorNotificationInput = {
  messageContent?: InputMaybe<Scalars['EmailContent']>;
};

export type EmployeeIdAuthSettings = {
  __typename?: 'EmployeeIdAuthSettings';
  isEnabled: Scalars['Boolean'];
  buttonText: Scalars['String'];
  helpContent: Scalars['HelpContent'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmployeeIdAuthSettingsInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  buttonText?: InputMaybe<Scalars['String']>;
  helpContent?: InputMaybe<Scalars['HelpContent']>;
};

export type SecuritySettings = {
  __typename?: 'SecuritySettings';
  emailAuth: EmailAuthSettings;
  employeeIdAuth: EmployeeIdAuthSettings;
  kioskMode: KioskModeSettings;
  /** @deprecated Use employeeIdAuth instead */
  phoneAuth: EmployeeIdAuthSettings;
  singleSignOns: Array<SingleSignOn>;
};

export type SftpExportIntegrationInput = {
  host: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
  password: Scalars['String'];
  folderPath: Scalars['String'];
};

export type SftpExportIntegration = {
  __typename?: 'SftpExportIntegration';
  host: Scalars['String'];
  port: Scalars['Int'];
  username: Scalars['String'];
  folderPath: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum SingleSignOnAuthKey {
  Email = 'email',
  EmployeeId = 'employeeId',
  CustomSsoId = 'customSsoId'
}

export enum SingleSignOnProviderId {
  Azure = 'azure',
  Google = 'google',
  Jwt = 'jwt',
  Okta = 'okta',
  Rippling = 'rippling',
  Saml = 'saml',
  SamlSecondary = 'saml_secondary'
}

export type SingleSignOn = {
  __typename?: 'SingleSignOn';
  providerId: SingleSignOnProviderId;
  issuer?: Maybe<Scalars['HttpUrl']>;
  loginUrl?: Maybe<Scalars['HttpsUrl']>;
  authKey?: Maybe<SingleSignOnAuthKey>;
  buttonText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  certificates: Array<SingleSignOnCertificate>;
};

export type SingleSignOnCertificate = {
  __typename?: 'SingleSignOnCertificate';
  digest: Scalars['String'];
  createdAt: Scalars['DateTime'];
  archivedAt?: Maybe<Scalars['DateTime']>;
};

export type NewSingleSignOnInput = {
  certificate: Scalars['String'];
  loginUrl: Scalars['HttpsUrl'];
  issuer?: InputMaybe<Scalars['HttpUrl']>;
  authKey?: InputMaybe<SingleSignOnAuthKey>;
  buttonText?: InputMaybe<Scalars['String']>;
};

export type SingleSignOnInput = {
  loginUrl?: InputMaybe<Scalars['HttpsUrl']>;
  issuer?: InputMaybe<Scalars['HttpUrl']>;
  authKey?: InputMaybe<SingleSignOnAuthKey>;
  buttonText?: InputMaybe<Scalars['String']>;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  handbookCollaborators: Array<HandbookCollaborator>;
  teammates: Array<Teammate>;
  permissionIds: Array<Scalars['String']>;
  /** @deprecated Field no longer supported */
  ckboxWorkspaceId?: Maybe<Scalars['String']>;
};

export type Teammate = {
  __typename?: 'Teammate';
  id: Scalars['Int'];
  teamId: Scalars['Int'];
  personId: Scalars['Int'];
  person: WhodunnitPerson;
  canReport: Scalars['Boolean'];
  canSuperBrand: Scalars['Boolean'];
  roleId?: Maybe<TeamRoleId>;
};

export type TeammateInput = {
  canReport?: InputMaybe<Scalars['Boolean']>;
  canSuperBrand?: InputMaybe<Scalars['Boolean']>;
  roleId?: InputMaybe<TeamRoleId>;
};

export type TeamRoleQuery = {
  any?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<TeamRoleId>>;
};

export type CreateMergeDevLinkTokenResponse = {
  __typename?: 'CreateMergeDevLinkTokenResponse';
  linkToken: Scalars['String'];
  integrationName: Scalars['String'];
};

export enum HandbooksSort {
  RelevanceDesc = 'relevanceDesc',
  LastModifiedAtDesc = 'lastModifiedAtDesc',
  LastPublishedAtDesc = 'lastPublishedAtDesc',
  PublishedAtAsc = 'publishedAtAsc',
  PublishedAtDesc = 'publishedAtDesc',
  ViewersCountDesc = 'viewersCountDesc',
  NameAsc = 'nameAsc',
  NameDesc = 'nameDesc'
}

export enum HandbooksView {
  Cozy = 'cozy',
  Compact = 'compact'
}

export type Preferences = {
  __typename?: 'Preferences';
  defaultLanguageCode?: Maybe<Scalars['String']>;
  documentationHandbooksSort?: Maybe<HandbooksSort>;
  handbooksSort?: Maybe<HandbooksSort>;
  handbooksView?: Maybe<HandbooksView>;
  hideProTips: Array<Scalars['String']>;
};

export type PreferencesInput = {
  defaultLanguageCode?: InputMaybe<Scalars['String']>;
  documentationHandbooksSort?: InputMaybe<HandbooksSort>;
  handbooksSort?: InputMaybe<HandbooksSort>;
  handbooksView?: InputMaybe<HandbooksView>;
};

export enum PropertyTargetType {
  Document = 'document',
  Person = 'person'
}

export enum PropertyType {
  Date = 'date',
  Number = 'number',
  Person = 'person',
  Select = 'select',
  Text = 'text',
  Url = 'url'
}

export type PropertyOption = {
  __typename?: 'PropertyOption';
  id: Scalars['ID'];
  label: Scalars['String'];
  color?: Maybe<Scalars['String']>;
};

export type Property = {
  __typename?: 'Property';
  id: Scalars['ID'];
  targetType: PropertyTargetType;
  friendlyId?: Maybe<Scalars['String']>;
  type: PropertyType;
  label: Scalars['String'];
  allowMultiple: Scalars['Boolean'];
  copyToLinkedOrganization: Scalars['Boolean'];
  options?: Maybe<Array<PropertyOption>>;
  archivedAt?: Maybe<Scalars['DateTime']>;
};

export type PropertyValue = {
  propertyId: Scalars['ID'];
};

export type NumberPropertyValue = PropertyValue & {
  __typename?: 'NumberPropertyValue';
  propertyId: Scalars['ID'];
  number: Scalars['String'];
};

export type PersonPropertyValue = PropertyValue & {
  __typename?: 'PersonPropertyValue';
  propertyId: Scalars['ID'];
  personIds?: Maybe<Array<Scalars['Int']>>;
  people?: Maybe<Array<WhodunnitPerson>>;
};

export type SelectPropertyValue = PropertyValue & {
  __typename?: 'SelectPropertyValue';
  propertyId: Scalars['ID'];
  ids?: Maybe<Array<Scalars['ID']>>;
};

export type TextPropertyValue = PropertyValue & {
  __typename?: 'TextPropertyValue';
  propertyId: Scalars['ID'];
  text: Scalars['String'];
};

export type DatePropertyValue = PropertyValue & {
  __typename?: 'DatePropertyValue';
  propertyId: Scalars['ID'];
  date: Scalars['DateTime'];
};

export type UrlPropertyValue = PropertyValue & {
  __typename?: 'UrlPropertyValue';
  propertyId: Scalars['ID'];
  url: Scalars['String'];
};

export type PropertyOptionInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
  color?: InputMaybe<Scalars['String']>;
};

export type CreatePropertyInput = {
  targetType: PropertyTargetType;
  type: PropertyType;
  allowMultiple: Scalars['Boolean'];
  label: Scalars['String'];
  options?: InputMaybe<Array<PropertyOptionInput>>;
};

export type UpdatePropertyInput = {
  label: Scalars['String'];
  allowMultiple: Scalars['Boolean'];
  copyToLinkedOrganization: Scalars['Boolean'];
  options?: InputMaybe<Array<PropertyOptionInput>>;
};

export type PropertyValueInput = {
  date?: InputMaybe<Scalars['Date']>;
  number?: InputMaybe<Scalars['Float']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  personIds?: InputMaybe<Array<Scalars['Int']>>;
  text?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type SavedSegment = {
  __typename?: 'SavedSegment';
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  expression: RootExpression;
  peopleCount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<WhodunnitPerson>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<WhodunnitPerson>;
  archivedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateSavedSegmentInput = {
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  expression: PeopleRootExpressionInput;
};

export type UpdateSavedSegmentInput = {
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<PeopleRootExpressionInput>;
};

export enum SavedViewType {
  Employees = 'employees',
  Managers = 'managers',
  Document = 'document',
  Documents = 'documents'
}

export type SavedViewColumnState = {
  __typename?: 'SavedViewColumnState';
  field: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  hidden: Scalars['Boolean'];
};

export type SavedViewGroupBy = {
  __typename?: 'SavedViewGroupBy';
  field: Scalars['String'];
  path?: Maybe<Scalars['String']>;
};

export type SavedView = {
  __typename?: 'SavedView';
  id: Scalars['Int'];
  type: SavedViewType;
  name: Scalars['String'];
  columnKeys: Array<SavedViewColumnState>;
  description?: Maybe<Scalars['String']>;
  expression?: Maybe<RootExpression>;
  groupBy?: Maybe<SavedViewGroupBy>;
  handbookId?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  showArchived: Scalars['Boolean'];
  order?: Maybe<SortOrder>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<WhodunnitPerson>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<WhodunnitPerson>;
  archivedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateDocumentSavedReportInput = {
  handbookId: Scalars['Int'];
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<HandbookRecipientsRootExpressionInput>;
  search?: InputMaybe<Scalars['String']>;
  showArchived: Scalars['Boolean'];
  columnKeys: Array<HandbookRecipientsColumnStateInput>;
  order: HandbookRecipientsSortOrder;
};

export type UpdateDocumentSavedReportInput = {
  handbookId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<HandbookRecipientsRootExpressionInput>;
  search?: InputMaybe<Scalars['String']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  columnKeys?: InputMaybe<Array<HandbookRecipientsColumnStateInput>>;
  order?: InputMaybe<HandbookRecipientsSortOrder>;
};

export type CreateDocumentsSavedViewInput = {
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<DocumentsRootExpressionInput>;
  groupBy?: InputMaybe<DocumentsGroupByInput>;
  search?: InputMaybe<Scalars['String']>;
  showArchived: Scalars['Boolean'];
  columnKeys: Array<DocumentsColumnStateInput>;
  order?: InputMaybe<DocumentsSortOrder>;
};

export type UpdateDocumentsSavedViewInput = {
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<DocumentsRootExpressionInput>;
  groupBy?: InputMaybe<DocumentsGroupByInput>;
  search?: InputMaybe<Scalars['String']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  columnKeys?: InputMaybe<Array<DocumentsColumnStateInput>>;
  order?: InputMaybe<DocumentsSortOrder>;
};

export enum PeopleSavedViewType {
  Employees = 'employees',
  Managers = 'managers'
}

export type CreatePeopleSavedReportInput = {
  type: PeopleSavedViewType;
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<PeopleRootExpressionInput>;
  search?: InputMaybe<Scalars['String']>;
  showArchived: Scalars['Boolean'];
  columnKeys: Array<PeopleColumnStateInput>;
  order: PeopleSortOrder;
};

export type UpdatePeopleSavedReportInput = {
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expression?: InputMaybe<PeopleRootExpressionInput>;
  search?: InputMaybe<Scalars['String']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  columnKeys?: InputMaybe<Array<PeopleColumnStateInput>>;
  order?: InputMaybe<PeopleSortOrder>;
};

export type TextSearchResult = {
  __typename?: 'TextSearchResult';
  before: Scalars['String'];
  match: Scalars['String'];
  after: Scalars['String'];
};

export enum HandbookNodeType {
  DocumentRef = 'documentRef',
  HandbookSection = 'handbookSection'
}

export type HandbookNodeSearchResult = {
  __typename?: 'HandbookNodeSearchResult';
  type: HandbookNodeType;
  id: Scalars['Int'];
  hash: Scalars['String'];
  title: Scalars['String'];
  languageCode?: Maybe<Scalars['String']>;
  texts?: Maybe<Array<TextSearchResult>>;
};

export type HandbookSearchResult = {
  __typename?: 'HandbookSearchResult';
  handbookId: Scalars['Int'];
  node?: Maybe<HandbookNodeSearchResult>;
};

export type UserAuth = {
  __typename?: 'UserAuth';
  email?: Maybe<Scalars['EmailAddress']>;
  employeeId?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['Int']>;
};

/** Type of export to perform */
export enum ExportType {
  LatestSignedForms = 'latestSignedForms',
  AllForms = 'allForms',
  SpecificRound = 'specificRound'
}

/** Response when starting a bulk export */
export type BulkExportResponse = {
  __typename?: 'BulkExportResponse';
  /** ID to track the export job */
  jobId: Scalars['String'];
  /** Status message */
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  sendEmailAuth: SendAuthResponse;
  sendEmployeeIdAuth: SendAuthResponse;
  enterAuthCode: Scalars['Boolean'];
  setPreferences: Preferences;
  hideProTip: Array<Scalars['String']>;
  dismissAnnotations: Scalars['Boolean'];
  signHandbookSession: HandbookSignature;
  sendRemindersToMyManagers: Scalars['Int'];
  sendRemindersToMyReports: Scalars['Int'];
  createApiKey: NewApiKey;
  archiveApiKey: ApiKey;
  unarchiveApiKey: ApiKey;
  deleteApiKey: Scalars['Boolean'];
  archiveHandbookFont: Scalars['Boolean'];
  archiveHandbookAsset: Scalars['Boolean'];
  archiveAsset: Scalars['Boolean'];
  setCustomDomain: CustomDomain;
  addCustomDomainCertificate: CustomDomain;
  renewCustomDomainCertificate: CustomDomain;
  setCustomDomainCertificate: CustomDomain;
  deleteCustomDomainCertificate: CustomDomain;
  removeCustomDomain: Scalars['Boolean'];
  createDocuments: Array<Document>;
  cloneDocuments: Array<Document>;
  updateDocuments: Array<Document>;
  moveDocuments: Array<Document>;
  archiveDocuments: Array<Document>;
  unarchiveDocuments: Array<Document>;
  deleteDocuments: Scalars['Boolean'];
  updateDocumentDraftVersion: Array<Document>;
  publishDocuments: Array<Document>;
  copySourceDocumentsToLinkedOrganization: Array<Maybe<Scalars['Int']>>;
  createDocumentReviewRounds: Array<Document>;
  updateDocumentReviewRounds: Array<Document>;
  addDocumentReviewers: Array<Document>;
  addDocumentReviewerFeedback: DocumentReviewerFeedback;
  createDocumentAnnotation: Annotation;
  addEmailDomain: EmailDomain;
  updateEmailDomain: EmailDomain;
  deleteEmailDomain: Scalars['Boolean'];
  sendTestEmail: Scalars['String'];
  setManagerDigestSettings: ManagerDigestSettings;
  updateEmailBranding: EmailSettings;
  updateEmailSettings: EmailSettings;
  updateEmailTemplate: EmailTemplate;
  initializeHandbookContent: Handbook;
  patchHandbook: Handbook;
  patchHandbookCss: Handbook;
  patchHandbookCustomHtml: Handbook;
  setHandbookCustomDomain: Handbook;
  addDocumentRefsToHandbook: Array<Scalars['String']>;
  addHandbookSection: HandbookSection;
  cloneHandbookSection: CloneHandbookSectionsResult;
  patchHandbookSection: HandbookSection;
  moveHandbookNode: Scalars['Boolean'];
  moveHandbookSection: Scalars['Boolean'];
  removeHandbookSection: Scalars['Boolean'];
  updateDocumentRefNode: Scalars['Boolean'];
  removeHandbookNode: Scalars['Boolean'];
  convertHandbookSectionsToPolicies: Scalars['BodyContent'];
  revertHandbookDocumentRefs: RevertHandbookDocumentRefsResult;
  unarchiveHandbookSection: Array<Scalars['Int']>;
  revertHandbookSection: HandbookSection;
  addHandbookSectionEntry: AddHandbookSectionEntryResult;
  patchHandbookSectionEntry: AddHandbookSectionEntryResult;
  moveHandbookSectionEntry: MoveHandbookSectionEntryResult;
  removeHandbookSectionEntry: RemoveHandbookSectionEntryResult;
  createHandbookPreviewLink?: Maybe<HandbookPreviewLink>;
  resetHandbookPreviewLinkTokens: Array<HandbookPreviewLink>;
  deleteHandbookPreviewLink?: Maybe<Scalars['Boolean']>;
  deleteHandbookPreviewLinks?: Maybe<Scalars['Boolean']>;
  setSignatureDueDate: HandbookSignatureDueDateOverride;
  inviteHandbookRecipient: HandbookNotification;
  remindHandbookRecipient: HandbookNotification;
  recordHandbookSignature: HandbookSignature;
  createHandbookReminder?: Maybe<HandbookReminder>;
  updateHandbookReminder?: Maybe<HandbookReminder>;
  setHandbookReminderEnabled?: Maybe<HandbookReminder>;
  deleteHandbookReminder: Scalars['Boolean'];
  createHandbook: Handbook;
  cloneHandbook: Handbook;
  deleteHandbook?: Maybe<Scalars['Boolean']>;
  setPublishHandbookNotification: PublishHandbookNotification;
  publishHandbook: Handbook;
  updateHandbookVersion: HandbookVersionSummary;
  setHandbookTaskComplete: HandbookTask;
  setHandbookPositions: Array<Handbook>;
  initializeHandbookAudience: Handbook;
  patchHandbookAudience: Handbook;
  resetHandbookPublicToken: Handbook;
  createHandbookSignatureRound: HandbookSignatureRound;
  updateHandbookSignatureRound: HandbookSignatureRound;
  deleteHandbookSignatureRound: Scalars['Boolean'];
  startBulkAcknowledgmentExport: BulkExportResponse;
  triggerHrisSync: Scalars['Boolean'];
  addBambooHRIntegration: BambooHrIntegration;
  addBambooHRIntegrationViaKey: BambooHrIntegration;
  addSftpIntegration: NewSftpIntegration;
  updateBambooHRIntegration: BambooHrIntegration;
  updateRipplingIntegration: RipplingIntegration;
  updateIntegration: Integration;
  deleteIntegration: Scalars['Boolean'];
  setSftpExportIntegration: SftpExportIntegration;
  deleteSftpExportIntegration: Scalars['Boolean'];
  createMergeDevLinkToken: CreateMergeDevLinkTokenResponse;
  createMergeDevIntegration: MergeDevIntegration;
  updateOrganization: Organization;
  setOrganizationTaskComplete: OrganizationTask;
  setAudienceDashboardSettings: AudienceDashboardSettings;
  setManagerSettings: ManagerSettings;
  createGroup: Group;
  updateGroup: Group;
  archiveGroup: Scalars['Boolean'];
  archivePerson: Person;
  unarchivePerson: Person;
  upsertPeople: Array<Person>;
  createProperty: Property;
  updateProperty: Property;
  archiveProperty: Property;
  unarchiveProperty: Property;
  deleteProperty: Scalars['Boolean'];
  setDocumentProperties: Array<Document>;
  inviteOrgCollaborator: Person;
  addAdmin: Person;
  addNewAdmin: Person;
  updateAdmin: Person;
  removeAdmin: Person;
  addTeammate: Teammate;
  addNewTeammate: Teammate;
  removeTeammate: Scalars['Boolean'];
  updateTeammate: Teammate;
  addHandbookCollaborator: HandbookCollaborator;
  addNewHandbookCollaborator: HandbookCollaborator;
  removeHandbookCollaborator: Scalars['Boolean'];
  updateHandbookCollaborator: HandbookCollaborator;
  upsertDocumentCollaborators: Array<Document>;
  removeDocumentCollaborators: Array<Document>;
  exportHandbookRecipientsToCSV: Scalars['String'];
  exportPeopleToCSV: Scalars['String'];
  sendHandbookReminders: Scalars['Int'];
  sendEmployeeDigest: Scalars['Int'];
  sendManagerDigest: Scalars['Int'];
  createSavedSegment: SavedSegment;
  updateSavedSegment: SavedSegment;
  archiveSavedSegment: SavedSegment;
  unarchiveSavedSegment: SavedSegment;
  createDocumentSavedReport: SavedView;
  updateDocumentSavedReport: SavedView;
  createDocumentsSavedView: SavedView;
  updateDocumentsSavedView: SavedView;
  createPeopleSavedReport: SavedView;
  updatePeopleSavedReport: SavedView;
  archiveSavedView: SavedView;
  unarchiveSavedView: SavedView;
  addSingleSignOn: SingleSignOn;
  patchSingleSignOn: SingleSignOn;
  removeSingleSignOn: SecuritySettings;
  addSingleSignOnCertificate: SecuritySettings;
  archiveSingleSignOnCertificate: SecuritySettings;
  unarchiveSingleSignOnCertificate: SecuritySettings;
  deleteSingleSignOnCertificate: SecuritySettings;
  setEmailAuthSettings: SecuritySettings;
  setKioskModeSettings: SecuritySettings;
  setEmployeeIdAuthSettings: SecuritySettings;
  linkChargebeeCheckout: ChargebeeSubscription;
  refreshChargebeeSubscription: ChargebeeSubscription;
  replaceChargebeeSubscriptionItems: ChargebeeSubscription;
  setChargebeeAutoCollectionOn: ChargebeeSubscription;
  addRipplingIntegration: RipplingIntegration;
  resetDocumentCKEditorChannelIds: Scalars['Boolean'];
  generatePages: Scalars['Int'];
  setFeatureFlag: Array<Scalars['String']>;
  clearHandbookSectionDirty: HandbookSection;
  deleteHandbookSignature: Scalars['Boolean'];
  mergeHandbook: Handbook;
  moveHandbookSectionToHandbook: Scalars['Boolean'];
  archiveHandbookSignatureRound: HandbookSignatureRound;
  unarchiveHandbookSignatureRound: HandbookSignatureRound;
  linkMergeDevIntegration: MergeDevIntegration;
  setSourceOrganizations: Array<SourceOrganization>;
  setSubdomain: Organization;
  updateOrganizationAdmin: Organization;
  deletePerson: Scalars['Boolean'];
  updateBilling: Billing;
  linkChargebeeSubscription: ChargebeeSubscription;
  unlinkChargebeeSubscription: Scalars['Boolean'];
};


export type MutationSendEmailAuthArgs = {
  email: Scalars['EmailAddress'];
};


export type MutationSendEmployeeIdAuthArgs = {
  employeeId: Scalars['String'];
  phoneAuthMethod: PhoneAuthMethod;
  phoneNumber: Scalars['PhoneNumber'];
};


export type MutationEnterAuthCodeArgs = {
  userId: Scalars['Int'];
  authCode: Scalars['String'];
};


export type MutationSetPreferencesArgs = {
  input: PreferencesInput;
};


export type MutationHideProTipArgs = {
  tipId: Scalars['String'];
};


export type MutationDismissAnnotationsArgs = {
  annotationIds: Array<Scalars['ID']>;
};


export type MutationSignHandbookSessionArgs = {
  handbookId: Scalars['Int'];
  handbookSessionId: Scalars['Int'];
  input: SignHandbookSessionInput;
  signatureRoundId: Scalars['Int'];
};


export type MutationSendRemindersToMyManagersArgs = {
  managerId?: InputMaybe<Scalars['Int']>;
  personIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationSendRemindersToMyReportsArgs = {
  managerId?: InputMaybe<Scalars['Int']>;
  personIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationArchiveApiKeyArgs = {
  key: Scalars['String'];
};


export type MutationUnarchiveApiKeyArgs = {
  key: Scalars['String'];
};


export type MutationDeleteApiKeyArgs = {
  key: Scalars['String'];
};


export type MutationArchiveHandbookFontArgs = {
  handbookId: Scalars['Int'];
  fontId: Scalars['Int'];
};


export type MutationArchiveHandbookAssetArgs = {
  handbookId: Scalars['Int'];
  assetId: Scalars['Int'];
};


export type MutationArchiveAssetArgs = {
  assetId: Scalars['Int'];
};


export type MutationSetCustomDomainArgs = {
  domain: Scalars['Domain'];
  certificate?: InputMaybe<CertificateInput>;
};


export type MutationAddCustomDomainCertificateArgs = {
  certificate: CertificateInput;
};


export type MutationSetCustomDomainCertificateArgs = {
  digest: Scalars['String'];
};


export type MutationDeleteCustomDomainCertificateArgs = {
  digest: Scalars['String'];
};


export type MutationCreateDocumentsArgs = {
  inputs: Array<CreateDocumentInput>;
};


export type MutationCloneDocumentsArgs = {
  documentIds: Array<Scalars['Int']>;
};


export type MutationUpdateDocumentsArgs = {
  updates: Array<UpdateDocumentArgs>;
};


export type MutationMoveDocumentsArgs = {
  documentIds: Array<Scalars['Int']>;
  input: MoveDocumentsInput;
};


export type MutationArchiveDocumentsArgs = {
  documentIds: Array<Scalars['Int']>;
};


export type MutationUnarchiveDocumentsArgs = {
  documentIds: Array<Scalars['Int']>;
};


export type MutationDeleteDocumentsArgs = {
  documentIds: Array<Scalars['Int']>;
};


export type MutationUpdateDocumentDraftVersionArgs = {
  documentIds: Array<Scalars['Int']>;
  input: UpdateDocumentDraftVersionInput;
};


export type MutationPublishDocumentsArgs = {
  documentIds: Array<Scalars['Int']>;
  commitMessageHtml?: InputMaybe<Scalars['HTML']>;
};


export type MutationCopySourceDocumentsToLinkedOrganizationArgs = {
  documentIds: Array<Scalars['Int']>;
  linkedOrganizationId: Scalars['Int'];
};


export type MutationCreateDocumentReviewRoundsArgs = {
  documentIds: Array<Scalars['Int']>;
  input: CreateDocumentReviewRoundInput;
};


export type MutationUpdateDocumentReviewRoundsArgs = {
  documentIds: Array<Scalars['Int']>;
  input: UpdateDocumentReviewRoundInput;
};


export type MutationAddDocumentReviewersArgs = {
  documentIds: Array<Scalars['Int']>;
  personIds: Array<Scalars['Int']>;
};


export type MutationAddDocumentReviewerFeedbackArgs = {
  documentId: Scalars['Int'];
  input: AddDocumentReviewerFeedbackInput;
};


export type MutationCreateDocumentAnnotationArgs = {
  documentId: Scalars['Int'];
  input: CreateAnnotationInput;
};


export type MutationAddEmailDomainArgs = {
  input: EmailDomainInput;
};


export type MutationUpdateEmailDomainArgs = {
  id: Scalars['Int'];
  input: EmailDomainInput;
};


export type MutationDeleteEmailDomainArgs = {
  id: Scalars['Int'];
};


export type MutationSendTestEmailArgs = {
  fromEmail?: InputMaybe<Scalars['EmailAddress']>;
};


export type MutationSetManagerDigestSettingsArgs = {
  input: ManagerDigestSettingsInput;
};


export type MutationUpdateEmailBrandingArgs = {
  input: EmailBrandingInput;
};


export type MutationUpdateEmailSettingsArgs = {
  input: EmailSettingsInput;
};


export type MutationUpdateEmailTemplateArgs = {
  id: EmailTemplateId;
  input: EmailTemplateInput;
};


export type MutationInitializeHandbookContentArgs = {
  id: Scalars['Int'];
  seedType: HandbookSeedType;
  branding?: InputMaybe<HandbookBrandingInput>;
};


export type MutationPatchHandbookArgs = {
  handbookId: Scalars['Int'];
  input: HandbookInput;
};


export type MutationPatchHandbookCssArgs = {
  handbookId: Scalars['Int'];
  input: HandbookCssInput;
};


export type MutationPatchHandbookCustomHtmlArgs = {
  handbookId: Scalars['Int'];
  customHtml: Scalars['String'];
};


export type MutationSetHandbookCustomDomainArgs = {
  handbookId: Scalars['Int'];
  customDomain?: InputMaybe<Scalars['Domain']>;
};


export type MutationAddDocumentRefsToHandbookArgs = {
  chapterName?: InputMaybe<Scalars['String']>;
  documentIds: Array<Scalars['Int']>;
  handbookId: Scalars['Int'];
  index?: InputMaybe<Scalars['Int']>;
};


export type MutationAddHandbookSectionArgs = {
  handbookId: Scalars['Int'];
  input: NewHandbookSectionInput;
  position?: InputMaybe<Scalars['Int']>;
};


export type MutationCloneHandbookSectionArgs = {
  sectionId: Scalars['Int'];
  children?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPatchHandbookSectionArgs = {
  sectionId: Scalars['Int'];
  input: HandbookSectionInput;
};


export type MutationMoveHandbookNodeArgs = {
  handbookId: Scalars['Int'];
  fromIndex: Scalars['Int'];
  toIndex: Scalars['Int'];
};


export type MutationMoveHandbookSectionArgs = {
  sectionId: Scalars['Int'];
  position: Scalars['Int'];
};


export type MutationRemoveHandbookSectionArgs = {
  sectionId: Scalars['Int'];
  children?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateDocumentRefNodeArgs = {
  handbookId: Scalars['Int'];
  nodeId: Scalars['String'];
  attrs: DocumentRefNodeAttrsInput;
};


export type MutationRemoveHandbookNodeArgs = {
  handbookId: Scalars['Int'];
  nodeId: Scalars['String'];
};


export type MutationConvertHandbookSectionsToPoliciesArgs = {
  handbookId: Scalars['Int'];
  sectionIds: Array<Scalars['Int']>;
};


export type MutationRevertHandbookDocumentRefsArgs = {
  handbookId: Scalars['Int'];
  documentIds: Array<Scalars['Int']>;
};


export type MutationUnarchiveHandbookSectionArgs = {
  id: Scalars['Int'];
};


export type MutationRevertHandbookSectionArgs = {
  id: Scalars['Int'];
};


export type MutationAddHandbookSectionEntryArgs = {
  sectionId: Scalars['Int'];
  input?: InputMaybe<HandbookSectionEntryInput>;
};


export type MutationPatchHandbookSectionEntryArgs = {
  sectionId: Scalars['Int'];
  entryUid: Scalars['String'];
  input: HandbookSectionEntryInput;
};


export type MutationMoveHandbookSectionEntryArgs = {
  sectionId: Scalars['Int'];
  entryUid: Scalars['String'];
  position: Scalars['Int'];
};


export type MutationRemoveHandbookSectionEntryArgs = {
  sectionId: Scalars['Int'];
  entryUid: Scalars['String'];
};


export type MutationCreateHandbookPreviewLinkArgs = {
  handbookId: Scalars['Int'];
  personId: Scalars['Int'];
};


export type MutationResetHandbookPreviewLinkTokensArgs = {
  handbookId: Scalars['Int'];
};


export type MutationDeleteHandbookPreviewLinkArgs = {
  handbookId: Scalars['Int'];
  personId: Scalars['Int'];
};


export type MutationDeleteHandbookPreviewLinksArgs = {
  handbookId: Scalars['Int'];
};


export type MutationSetSignatureDueDateArgs = {
  handbookId: Scalars['Int'];
  personId: Scalars['Int'];
  roundId: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  dueDate: Scalars['Date'];
};


export type MutationInviteHandbookRecipientArgs = {
  handbookId: Scalars['Int'];
  personId: Scalars['Int'];
};


export type MutationRemindHandbookRecipientArgs = {
  handbookId: Scalars['Int'];
  personId: Scalars['Int'];
};


export type MutationRecordHandbookSignatureArgs = {
  handbookId: Scalars['Int'];
  input: RecordHandbookSignatureInput;
};


export type MutationCreateHandbookReminderArgs = {
  input: HandbookReminderInput;
};


export type MutationUpdateHandbookReminderArgs = {
  id: Scalars['Int'];
  input: HandbookReminderInput;
};


export type MutationSetHandbookReminderEnabledArgs = {
  id: Scalars['Int'];
  isEnabled: Scalars['Boolean'];
};


export type MutationDeleteHandbookReminderArgs = {
  id: Scalars['Int'];
};


export type MutationCreateHandbookArgs = {
  name: Scalars['String'];
};


export type MutationCloneHandbookArgs = {
  handbookId: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationDeleteHandbookArgs = {
  id: Scalars['Int'];
};


export type MutationSetPublishHandbookNotificationArgs = {
  handbookId: Scalars['Int'];
  input: PublishHandbookNotificationInput;
};


export type MutationPublishHandbookArgs = {
  handbookId: Scalars['Int'];
  content?: InputMaybe<PublishHandbookContentInput>;
  notifyViewers?: InputMaybe<HandbookViewersNotificationInput>;
};


export type MutationUpdateHandbookVersionArgs = {
  handbookId: Scalars['Int'];
  versionNumber: Scalars['Int'];
  input: UpdateHandbookVersionInput;
};


export type MutationSetHandbookTaskCompleteArgs = {
  handbookId: Scalars['Int'];
  taskId: HandbookTaskId;
  complete: Scalars['Boolean'];
};


export type MutationSetHandbookPositionsArgs = {
  handbookIds: Array<Scalars['Int']>;
};


export type MutationInitializeHandbookAudienceArgs = {
  id: Scalars['Int'];
  hasAudience: Scalars['Boolean'];
  hasSignatures: Scalars['Boolean'];
};


export type MutationPatchHandbookAudienceArgs = {
  handbookId: Scalars['Int'];
  audience: HandbookAudienceInput;
  sendNotifications?: InputMaybe<Scalars['Boolean']>;
};


export type MutationResetHandbookPublicTokenArgs = {
  handbookId: Scalars['Int'];
};


export type MutationCreateHandbookSignatureRoundArgs = {
  handbookId: Scalars['Int'];
  input: HandbookSignatureRoundInput;
  notification?: InputMaybe<NotificationInput>;
};


export type MutationUpdateHandbookSignatureRoundArgs = {
  id: Scalars['Int'];
  input: HandbookSignatureRoundInput;
};


export type MutationDeleteHandbookSignatureRoundArgs = {
  id: Scalars['Int'];
};


export type MutationStartBulkAcknowledgmentExportArgs = {
  handbookId: Scalars['Int'];
  filter?: InputMaybe<HandbookRecipientsFilter>;
  order?: InputMaybe<HandbookRecipientsSortOrder>;
};


export type MutationTriggerHrisSyncArgs = {
  forceFullSync?: InputMaybe<Scalars['Boolean']>;
  testMode?: InputMaybe<HrisSyncTestModeInput>;
};


export type MutationAddBambooHrIntegrationArgs = {
  email: Scalars['EmailAddress'];
  hostname: Scalars['String'];
  password: Scalars['String'];
  subdomain: Scalars['String'];
};


export type MutationAddBambooHrIntegrationViaKeyArgs = {
  hostname: Scalars['String'];
  key: Scalars['String'];
  subdomain: Scalars['String'];
};


export type MutationUpdateBambooHrIntegrationArgs = {
  id: Scalars['Int'];
  input: UpdateBambooHrIntegrationInput;
};


export type MutationUpdateRipplingIntegrationArgs = {
  id: Scalars['Int'];
  input: UpdateRipplingIntegrationInput;
};


export type MutationUpdateIntegrationArgs = {
  id: Scalars['Int'];
  input: UpdateIntegrationInput;
};


export type MutationDeleteIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationSetSftpExportIntegrationArgs = {
  input: SftpExportIntegrationInput;
};


export type MutationCreateMergeDevIntegrationArgs = {
  publicToken: Scalars['String'];
};


export type MutationUpdateOrganizationArgs = {
  input: OrganizationInput;
};


export type MutationSetOrganizationTaskCompleteArgs = {
  taskId: OrganizationTaskId;
  complete: Scalars['Boolean'];
};


export type MutationSetAudienceDashboardSettingsArgs = {
  input: AudienceDashboardSettingsInput;
};


export type MutationSetManagerSettingsArgs = {
  input: ManagerSettingsInput;
};


export type MutationCreateGroupArgs = {
  name: Scalars['String'];
};


export type MutationUpdateGroupArgs = {
  id: Scalars['Int'];
  input: GroupInput;
};


export type MutationArchiveGroupArgs = {
  id: Scalars['Int'];
};


export type MutationArchivePersonArgs = {
  id: Scalars['Int'];
};


export type MutationUnarchivePersonArgs = {
  id: Scalars['Int'];
};


export type MutationUpsertPeopleArgs = {
  people?: InputMaybe<Array<PersonInput>>;
  newPeople?: InputMaybe<Array<NewPersonInput>>;
  sendNotifications: Scalars['Boolean'];
};


export type MutationCreatePropertyArgs = {
  input: CreatePropertyInput;
};


export type MutationUpdatePropertyArgs = {
  propertyId: Scalars['ID'];
  input: UpdatePropertyInput;
};


export type MutationArchivePropertyArgs = {
  propertyId: Scalars['ID'];
};


export type MutationUnarchivePropertyArgs = {
  propertyId: Scalars['ID'];
};


export type MutationDeletePropertyArgs = {
  propertyId: Scalars['ID'];
};


export type MutationSetDocumentPropertiesArgs = {
  documentIds: Array<Scalars['Int']>;
  propertyId: Scalars['ID'];
  value?: InputMaybe<PropertyValueInput>;
};


export type MutationInviteOrgCollaboratorArgs = {
  personId: Scalars['Int'];
};


export type MutationAddAdminArgs = {
  adminRoleId: OrganizationAdminRoleId;
  notification?: InputMaybe<OrgCollaboratorNotificationInput>;
  personId: Scalars['Int'];
};


export type MutationAddNewAdminArgs = {
  adminRoleId: OrganizationAdminRoleId;
  notification?: InputMaybe<OrgCollaboratorNotificationInput>;
  personInput: NewOrgCollaboratorInput;
};


export type MutationUpdateAdminArgs = {
  personId: Scalars['Int'];
  adminRoleId: OrganizationAdminRoleId;
};


export type MutationRemoveAdminArgs = {
  personId: Scalars['Int'];
};


export type MutationAddTeammateArgs = {
  notification?: InputMaybe<OrgCollaboratorNotificationInput>;
  personId: Scalars['Int'];
  teamId: Scalars['Int'];
  teammateInput: TeammateInput;
};


export type MutationAddNewTeammateArgs = {
  notification?: InputMaybe<OrgCollaboratorNotificationInput>;
  personInput: NewOrgCollaboratorInput;
  teamId: Scalars['Int'];
  teammateInput: TeammateInput;
};


export type MutationRemoveTeammateArgs = {
  personId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type MutationUpdateTeammateArgs = {
  teammateInput: TeammateInput;
  personId: Scalars['Int'];
  teamId: Scalars['Int'];
};


export type MutationAddHandbookCollaboratorArgs = {
  handbookCollaboratorInput?: InputMaybe<HandbookCollaboratorInput>;
  handbookId: Scalars['Int'];
  notification?: InputMaybe<OrgCollaboratorNotificationInput>;
  personId: Scalars['Int'];
};


export type MutationAddNewHandbookCollaboratorArgs = {
  handbookCollaboratorInput?: InputMaybe<HandbookCollaboratorInput>;
  handbookId: Scalars['Int'];
  notification?: InputMaybe<OrgCollaboratorNotificationInput>;
  personInput: NewOrgCollaboratorInput;
};


export type MutationRemoveHandbookCollaboratorArgs = {
  handbookId: Scalars['Int'];
  personId: Scalars['Int'];
};


export type MutationUpdateHandbookCollaboratorArgs = {
  handbookCollaboratorInput: HandbookCollaboratorInput;
  handbookId: Scalars['Int'];
  personId: Scalars['Int'];
};


export type MutationUpsertDocumentCollaboratorsArgs = {
  documentIds: Array<Scalars['Int']>;
  people: Array<DocumentCollaboratorInput>;
};


export type MutationRemoveDocumentCollaboratorsArgs = {
  documentIds: Array<Scalars['Int']>;
  personIds: Array<Scalars['Int']>;
};


export type MutationExportHandbookRecipientsToCsvArgs = {
  columnKeys: Array<HandbookRecipientsColumnKey>;
  filter?: InputMaybe<HandbookRecipientsFilter>;
  handbookId: Scalars['Int'];
  order?: InputMaybe<HandbookRecipientsSortOrder>;
};


export type MutationExportPeopleToCsvArgs = {
  columnKeys: Array<PeopleColumnKey>;
  filter?: InputMaybe<PeopleFilter>;
  order?: InputMaybe<PeopleSortOrder>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSendHandbookRemindersArgs = {
  filter?: InputMaybe<HandbookRecipientsFilter>;
  handbookId: Scalars['Int'];
  isSignatureRequired: Scalars['Boolean'];
  template?: InputMaybe<EmailTemplateInput>;
};


export type MutationSendEmployeeDigestArgs = {
  filter?: InputMaybe<PeopleFilter>;
  template?: InputMaybe<EmailTemplateInput>;
};


export type MutationSendManagerDigestArgs = {
  filter?: InputMaybe<PeopleFilter>;
  template?: InputMaybe<EmailTemplateInput>;
};


export type MutationCreateSavedSegmentArgs = {
  input: CreateSavedSegmentInput;
};


export type MutationUpdateSavedSegmentArgs = {
  id: Scalars['Int'];
  input: UpdateSavedSegmentInput;
  sendNotifications?: InputMaybe<Scalars['Boolean']>;
};


export type MutationArchiveSavedSegmentArgs = {
  id: Scalars['Int'];
};


export type MutationUnarchiveSavedSegmentArgs = {
  id: Scalars['Int'];
};


export type MutationCreateDocumentSavedReportArgs = {
  input: CreateDocumentSavedReportInput;
};


export type MutationUpdateDocumentSavedReportArgs = {
  savedViewId: Scalars['Int'];
  input: UpdateDocumentSavedReportInput;
};


export type MutationCreateDocumentsSavedViewArgs = {
  input: CreateDocumentsSavedViewInput;
};


export type MutationUpdateDocumentsSavedViewArgs = {
  savedViewId: Scalars['Int'];
  input: UpdateDocumentsSavedViewInput;
};


export type MutationCreatePeopleSavedReportArgs = {
  input: CreatePeopleSavedReportInput;
};


export type MutationUpdatePeopleSavedReportArgs = {
  savedViewId: Scalars['Int'];
  input: UpdatePeopleSavedReportInput;
};


export type MutationArchiveSavedViewArgs = {
  savedViewId: Scalars['Int'];
};


export type MutationUnarchiveSavedViewArgs = {
  savedViewId: Scalars['Int'];
};


export type MutationAddSingleSignOnArgs = {
  providerId: SingleSignOnProviderId;
  input?: InputMaybe<NewSingleSignOnInput>;
};


export type MutationPatchSingleSignOnArgs = {
  providerId: SingleSignOnProviderId;
  input: SingleSignOnInput;
};


export type MutationRemoveSingleSignOnArgs = {
  providerId: SingleSignOnProviderId;
};


export type MutationAddSingleSignOnCertificateArgs = {
  providerId: SingleSignOnProviderId;
  certificate: Scalars['String'];
};


export type MutationArchiveSingleSignOnCertificateArgs = {
  providerId: SingleSignOnProviderId;
  digest: Scalars['String'];
};


export type MutationUnarchiveSingleSignOnCertificateArgs = {
  providerId: SingleSignOnProviderId;
  digest: Scalars['String'];
};


export type MutationDeleteSingleSignOnCertificateArgs = {
  providerId: SingleSignOnProviderId;
  digest: Scalars['String'];
};


export type MutationSetEmailAuthSettingsArgs = {
  input: EmailAuthSettingsInput;
};


export type MutationSetKioskModeSettingsArgs = {
  input: KioskModeSettingsInput;
};


export type MutationSetEmployeeIdAuthSettingsArgs = {
  input: EmployeeIdAuthSettingsInput;
};


export type MutationLinkChargebeeCheckoutArgs = {
  hostedPageId: Scalars['String'];
};


export type MutationReplaceChargebeeSubscriptionItemsArgs = {
  endOfTerm?: InputMaybe<Scalars['Boolean']>;
  items: Array<ChargebeeSubscriptionItemInput>;
};


export type MutationAddRipplingIntegrationArgs = {
  code: Scalars['String'];
};


export type MutationResetDocumentCkEditorChannelIdsArgs = {
  documentIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationGeneratePagesArgs = {
  pageTypes: Array<Scalars['String']>;
};


export type MutationSetFeatureFlagArgs = {
  flagId: FeatureFlagId;
  enabled: Scalars['Boolean'];
};


export type MutationClearHandbookSectionDirtyArgs = {
  sectionId: Scalars['Int'];
};


export type MutationDeleteHandbookSignatureArgs = {
  signatureId: Scalars['Int'];
};


export type MutationMergeHandbookArgs = {
  handbookId: Scalars['Int'];
  mergeHandbookId: Scalars['Int'];
};


export type MutationMoveHandbookSectionToHandbookArgs = {
  sectionId: Scalars['Int'];
  handbookId: Scalars['Int'];
};


export type MutationArchiveHandbookSignatureRoundArgs = {
  id: Scalars['Int'];
};


export type MutationUnarchiveHandbookSignatureRoundArgs = {
  id: Scalars['Int'];
};


export type MutationLinkMergeDevIntegrationArgs = {
  accountToken: Scalars['String'];
};


export type MutationSetSourceOrganizationsArgs = {
  sourceOrganizationIds: Array<Scalars['Int']>;
};


export type MutationSetSubdomainArgs = {
  subdomain: Scalars['Subdomain'];
};


export type MutationUpdateOrganizationAdminArgs = {
  input: OrganizationAdminInput;
};


export type MutationDeletePersonArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateBillingArgs = {
  input: BillingInput;
};


export type MutationLinkChargebeeSubscriptionArgs = {
  subscriptionId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  employeeIdAuthPerson: AuthPerson;
  searchHandbooks: Array<HandbookSearchResult>;
  session?: Maybe<Session>;
  preferences: Preferences;
  manager: ManagerPerson;
  managerReports: Array<ManagerPersonReport>;
  documents: Array<Document>;
  document: Document;
  documentCounts: Scalars['Int'];
  documentGroups: Array<DocumentGroupInterface>;
  handbooks: Array<Handbook>;
  handbook: Handbook;
  handbookVersion: HandbookVersion;
  handbookRecipients: Array<HandbookRecipient>;
  handbookStatistics: HandbookStatistics;
  handbookReminders: Array<HandbookReminder>;
  handbookSectionHistory: Array<HandbookSectionVersion>;
  apiKeys: Array<ApiKey>;
  audienceDashboardSettings: AudienceDashboardSettings;
  assets: Array<Asset>;
  billing?: Maybe<Billing>;
  linkedOrganizations: Array<LinkedOrganization>;
  customDomain?: Maybe<CustomDomain>;
  emailDomains: Array<EmailDomain>;
  emailDomainStatus: EmailDomainStatus;
  emailSettings: EmailSettings;
  emailTemplates: Array<EmailTemplate>;
  events: Array<Event>;
  featureFlagIds: Array<Scalars['String']>;
  fonts: Array<Font>;
  groups: Array<Group>;
  groupNames: Array<Scalars['String']>;
  integrations: Array<Integration>;
  hrisPeople: Array<HrisPerson>;
  hrisPeopleStatistics: HrisPeopleStatistics;
  managerDigestSettings: ManagerDigestSettings;
  managerSettings: ManagerSettings;
  metadataKeys: Array<Scalars['String']>;
  metadataValues: Array<Scalars['String']>;
  organization: Organization;
  organizationTasks: Array<OrganizationTask>;
  people: Array<Person>;
  peopleStatistics: PeopleStatistics;
  properties: Array<Property>;
  savedViews: Array<SavedView>;
  savedSegments: Array<SavedSegment>;
  securitySettings: SecuritySettings;
  sourceOrganizations: Array<SourceOrganization>;
  sftpExportIntegration?: Maybe<SftpExportIntegration>;
  teams: Array<Team>;
  chargebee: Chargebee;
  chargebeePortal: ChargebeePortal;
  chargebeeSubscription?: Maybe<ChargebeeSubscription>;
};


export type QueryEmployeeIdAuthPersonArgs = {
  employeeId: Scalars['String'];
};


export type QuerySearchHandbooksArgs = {
  search: Scalars['String'];
};


export type QueryManagerArgs = {
  managerId?: InputMaybe<Scalars['Int']>;
};


export type QueryManagerReportsArgs = {
  isDirect?: InputMaybe<Scalars['Boolean']>;
  managerId?: InputMaybe<Scalars['Int']>;
  personIds?: InputMaybe<Array<Scalars['Int']>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<DocumentsFilter>;
  filterId?: InputMaybe<DocumentsFilterId>;
  order?: InputMaybe<DocumentsSortOrder>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDocumentArgs = {
  documentId: Scalars['Int'];
};


export type QueryDocumentCountsArgs = {
  filter?: InputMaybe<DocumentsFilter>;
  filterId?: InputMaybe<DocumentsFilterId>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryDocumentGroupsArgs = {
  filter?: InputMaybe<DocumentsFilter>;
  groupBy: DocumentsGroupByInput;
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHandbooksArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryHandbookArgs = {
  id: Scalars['Int'];
};


export type QueryHandbookVersionArgs = {
  handbookId: Scalars['Int'];
  versionNumber: Scalars['Int'];
};


export type QueryHandbookRecipientsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HandbookRecipientsFilter>;
  handbookId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<HandbookRecipientsSortOrder>;
  personId?: InputMaybe<Scalars['Int']>;
};


export type QueryHandbookStatisticsArgs = {
  filter?: InputMaybe<HandbookRecipientsFilter>;
  handbookId: Scalars['Int'];
};


export type QueryHandbookSectionHistoryArgs = {
  sectionIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryAssetsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
  tag?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssetType>;
};


export type QueryEmailDomainStatusArgs = {
  id: Scalars['Int'];
};


export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<EventsFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<EventsSortOrder>;
};


export type QueryFontsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGroupNamesArgs = {
  source?: InputMaybe<PeopleSource>;
};


export type QueryHrisPeopleArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<HrisPeopleFilter>;
  integrationId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PeopleSortOrder>;
};


export type QueryHrisPeopleStatisticsArgs = {
  filter?: InputMaybe<HrisPeopleFilter>;
  integrationId: Scalars['Int'];
};


export type QueryMetadataKeysArgs = {
  source?: InputMaybe<PeopleSource>;
};


export type QueryMetadataValuesArgs = {
  metadataKey: Scalars['String'];
  source?: InputMaybe<PeopleSource>;
};


export type QueryPeopleArgs = {
  after?: InputMaybe<Scalars['String']>;
  canNotify?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PeopleFilter>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isOrgCollaborator?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  notIds?: InputMaybe<Array<Scalars['Int']>>;
  order?: InputMaybe<PeopleSortOrder>;
  role?: InputMaybe<OrganizationRoleQuery>;
  search?: InputMaybe<Scalars['String']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  teamRole?: InputMaybe<TeamRoleQuery>;
};


export type QueryPeopleStatisticsArgs = {
  filter?: InputMaybe<PeopleFilter>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPropertiesArgs = {
  targetType?: InputMaybe<PropertyTargetType>;
};


export type QuerySavedViewsArgs = {
  types?: InputMaybe<Array<SavedViewType>>;
};

export type GetEmployeeIdAuthPersonQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetEmployeeIdAuthPersonQuery = { __typename?: 'Query', employeeIdAuthPerson: { __typename?: 'AuthPerson', employeeId: string, email?: string | null, fullName: string, homePhoneNumber?: any | null, mobilePhoneNumber?: any | null, workPhoneNumber?: any | null } };

export type SendEmployeeIdAuthMutationVariables = Exact<{
  employeeId: Scalars['String'];
  phoneAuthMethod: PhoneAuthMethod;
  phoneNumber: Scalars['PhoneNumber'];
}>;


export type SendEmployeeIdAuthMutation = { __typename?: 'Mutation', sendEmployeeIdAuth: { __typename?: 'SendAuthResponse', signInUrl?: any | null, userId?: number | null } };


export const GetEmployeeIdAuthPersonDocument = gql`
    query getEmployeeIdAuthPerson($employeeId: String!) {
  employeeIdAuthPerson(employeeId: $employeeId) {
    employeeId
    email
    fullName
    homePhoneNumber
    mobilePhoneNumber
    workPhoneNumber
  }
}
    `;

/**
 * __useGetEmployeeIdAuthPersonQuery__
 *
 * To run a query within a React component, call `useGetEmployeeIdAuthPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeIdAuthPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeIdAuthPersonQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetEmployeeIdAuthPersonQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeIdAuthPersonQuery, GetEmployeeIdAuthPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeIdAuthPersonQuery, GetEmployeeIdAuthPersonQueryVariables>(GetEmployeeIdAuthPersonDocument, options);
      }
export function useGetEmployeeIdAuthPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeIdAuthPersonQuery, GetEmployeeIdAuthPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeIdAuthPersonQuery, GetEmployeeIdAuthPersonQueryVariables>(GetEmployeeIdAuthPersonDocument, options);
        }
export type GetEmployeeIdAuthPersonQueryHookResult = ReturnType<typeof useGetEmployeeIdAuthPersonQuery>;
export type GetEmployeeIdAuthPersonLazyQueryHookResult = ReturnType<typeof useGetEmployeeIdAuthPersonLazyQuery>;
export type GetEmployeeIdAuthPersonQueryResult = Apollo.QueryResult<GetEmployeeIdAuthPersonQuery, GetEmployeeIdAuthPersonQueryVariables>;
export const SendEmployeeIdAuthDocument = gql`
    mutation sendEmployeeIdAuth($employeeId: String!, $phoneAuthMethod: PhoneAuthMethod!, $phoneNumber: PhoneNumber!) {
  sendEmployeeIdAuth(
    employeeId: $employeeId
    phoneAuthMethod: $phoneAuthMethod
    phoneNumber: $phoneNumber
  ) {
    signInUrl
    userId
  }
}
    `;
export type SendEmployeeIdAuthMutationFn = Apollo.MutationFunction<SendEmployeeIdAuthMutation, SendEmployeeIdAuthMutationVariables>;

/**
 * __useSendEmployeeIdAuthMutation__
 *
 * To run a mutation, you first call `useSendEmployeeIdAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmployeeIdAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmployeeIdAuthMutation, { data, loading, error }] = useSendEmployeeIdAuthMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      phoneAuthMethod: // value for 'phoneAuthMethod'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useSendEmployeeIdAuthMutation(baseOptions?: Apollo.MutationHookOptions<SendEmployeeIdAuthMutation, SendEmployeeIdAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmployeeIdAuthMutation, SendEmployeeIdAuthMutationVariables>(SendEmployeeIdAuthDocument, options);
      }
export type SendEmployeeIdAuthMutationHookResult = ReturnType<typeof useSendEmployeeIdAuthMutation>;
export type SendEmployeeIdAuthMutationResult = Apollo.MutationResult<SendEmployeeIdAuthMutation>;
export type SendEmployeeIdAuthMutationOptions = Apollo.BaseMutationOptions<SendEmployeeIdAuthMutation, SendEmployeeIdAuthMutationVariables>;