import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import { Collapse, Link } from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import Typography from './Typography'

const SIZES = {
  sm: {
    iconCss: {
      fontSize: 14,
      marginTop: 7,
    },
    LeftColumn: (props) => (
      <div
        {...props}
        css={css`
          width: 30px;
          @media (min-width: ${gridBreakpoints.lg}px) {
            width: 45px;
          }
        `}
      />
    ),
    Link: (props) => (
      <Link
        {...props}
        css={css`
          color: ${colors.black};
          font-size: 20px;
          &:hover {
            color: ${colors.black};
          }
        `}
      />
    ),
  },
  lg: {
    iconCss: {
      fontSize: 16,
      marginTop: 17,
    },
    LeftColumn: (props) => (
      <div
        {...props}
        css={css`
          width: 45px;
          @media (min-width: ${gridBreakpoints.lg}px) {
            width: 90px;
          }
        `}
      />
    ),
    Link: (props) => (
      <Link
        {...props}
        css={css`
          font-size: 20px;
          font-weight: 600;
          @media (min-width: ${gridBreakpoints.lg}px) {
            font-size: 32px;
          }
        `}
      />
    ),
  },
}

const ToggleIcon = ({ animationDuration, fontSize, isOpen, ...props }) => (
  <div
    {...props}
    css={{
      display: 'flex',
      position: 'relative',
    }}
  >
    <FontAwesomeIcon
      icon='minus'
      css={{
        transform: isOpen ? 'rotate(90deg) scaleX(0)' : undefined,
        transition: `all ${animationDuration}ms linear`,
      }}
    />
    <FontAwesomeIcon
      icon='minus'
      css={{
        position: 'absolute',
        left: 0,
        top: 0,
        transform: `rotate(${isOpen ? 180 : 90}deg)`,
        transition: `all ${animationDuration}ms linear`,
      }}
    />
  </div>
)

const Accordion = ({
  animationDuration = 250,
  children,
  history,
  id,
  location,
  match,
  size = 'sm',
  staticContext,
  title,
  ...props
}) => {
  const { iconCss, LeftColumn, Link } = SIZES[size]
  const [isOpen, setOpen] = useState(false)
  const onToggle = () => {
    setOpen(!isOpen)
  }

  // Open if the current hash is our id
  useEffect(() => {
    if (`#${id}` === location.hash) {
      setOpen(true)
    }
  }, [location.hash])

  return (
    <div
      {...props}
      css={{
        display: 'flex',
        marginBottom: 30,
        position: 'relative',
      }}
    >
      {/* biome-ignore lint/a11y/useAnchorContent: marketing site cares less */}
      {id && <a name={id} />}

      <LeftColumn>
        <ToggleIcon
          animationDuration={animationDuration}
          css={{
            ...iconCss,
            color: colors['aqua-500'],
            cursor: 'pointer',
            userSelect: 'none',
          }}
          fontSize={iconCss.fontSize}
          isOpen={isOpen}
          onClick={onToggle}
        />
      </LeftColumn>

      <div css={{ flex: 1 }}>
        <Link className='tw-flex tw-items-center' onClick={onToggle}>
          {title}
        </Link>

        <Collapse animationDuration={animationDuration} isOpen={isOpen}>
          <Typography
            variant='p2'
            children={children}
            className='tw-mt-2 tw-mb-0'
            css={{ flex: 1 }}
          />
        </Collapse>
      </div>
    </div>
  )
}

export default withRouter(Accordion)
