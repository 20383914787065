import { Link } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'

import { MarketingLayout, Section, Typography } from '../components'

import { TOCList } from './TOC'
import {
  CaseStudy,
  ChapterHeader,
  DownloadWorkbook,
  ExternalLink,
  GetStartedHero,
  HowToSection,
  PersonCard,
  QuoteBordered,
} from './components'

import { images, urls } from './howToConfig'

import './styles/how-to-ebook.scss'

export default () => (
  <MarketingLayout
    title='Preparing to Write Policies and Procedures: The Prep Work'
    description={
      'Tips on preparation when undertaking a project to update or write new policies and procedures or an employee handbook.'
    }
    className='how-to-ebook'
  >
    <ChapterHeader
      title='1. The Prep Work'
      subtitle='Getting Started with Your Policies and Handbook'
      prev={{ url: urls.root, text: 'Introduction' }}
      next={{ url: urls.stepWork, text: '2. The Step Work' }}
      img={images.headerPrepWork.src}
    />

    <HowToSection
      className='container'
      main={
        <QuoteBordered
          quote={
            'As soon as a company has grown beyond the founders, and they’re hiring that first employee, they need to have written policies in place. They need to communicate, “Here’s our culture. Here’s our expectations.”'
          }
          attribution={{
            name: 'Kate Tylee Herz',
            title: (
              <span>
                Counsel, Employment Services Group at{' '}
                <ExternalLink href='https://www.dwt.com/'>
                  Davis Wright Tremaine, LLP
                </ExternalLink>
              </span>
            ),
          }}
        />
      }
      side={<TOCList style={{ paddingTop: '25px' }} activeIndex={1} />}
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='p2'>
            It’s time to update your employee policies and procedures handbook!
            Before you curl up into a ball or run for the hills, remember that
            starting a project tends to be the hardest part. By doing basic prep
            work before you begin, you will set yourself up for success.
          </Typography>
          <Typography variant='p2'>
            As Lindsey Kucharski, former HR Manager, US &amp; LATAM at Blue
            Prism, explained “things have changed a lot since the 40-50 page
            binder.” But, some things are still the same, such as your need to
            address changes in your industry, in your company, and in the role
            of a human resources professional.
          </Typography>
          <Typography variant='p2'>
            So, where in the world <del>is Carmen Sandiego</del> do you begin?
            First, you need to determine the scope of the project you’re
            undertaking – and go from there. Think big-picture and determine who
            and what you may need along the way. This includes defining{' '}
            <strong>objectives</strong>, considering potential{' '}
            <strong>roadblocks</strong>, deciding who to{' '}
            <strong>involve</strong>, and developing a <strong>project</strong>{' '}
            plan to share with stakeholders.
          </Typography>
        </Fragment>
      }
    />

    <DownloadWorkbook
      extraMarginTop='-130px'
      extraMarginBottom='-120px'
      text='Need help with these 4 items? Use our companion workbook to keep your policy project organized.'
      stack={[
        images.workbook.milestones1,
        images.workbook.distributionMethods,
        images.workbook.milestones2,
      ]}
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h2'>What’s the Goal?</Typography>
          <Typography variant='p2'>
            In the wee stages of the project, you’ll want to identify key
            objectives for updating your policies and procedures. Here are a few
            common goals... or refer to the first page of your{' '}
            <a href={urls.workbook} download>
              Policies &amp; Procedures Workbook
            </a>{' '}
            to check your own!
          </Typography>
          <ul className='p2'>
            <li>
              Your existing policies need a review to ensure they're still
              compliant with updated laws.
            </li>
            <li>
              You're revamping policies such as parental leave, remote working,
              data privacy, or others to achieve business goals.
            </li>
            <li>
              You’re hoping to better express your employer brand &amp; company
              culture during onboarding.
            </li>
            <li>
              You're updating legal-speak (read: boring) policies to make them
              more interactive and friendly for employees.
            </li>
            <li>
              Your company is going digital and it’s a good time to review your
              content.
            </li>
          </ul>
        </Fragment>
      }
    />

    <CaseStudy title='Case Study: Handling Multiple Locations'>
      <Typography variant='p2'>
        Bringing employees from all locations and divisions “together as one
        company” was Chanin’s main goal and challenge. She needed a set of
        policies and procedures that supported the company as a whole and
        communicated who they were regardless of where an employee worked
        (across multiple states). Her team standardized many policies and then
        used Blissbook's{' '}
        <a href='/employee-handbook-software#personalized-content'>
          personalized content
        </a>{' '}
        feature to tailor specific content to specific audiences.
      </Typography>
      <PersonCard
        img={images.profileChanin.src}
        name='Chanin Christensen'
        title={
          <span>
            SVP / HR Manager at{' '}
            <ExternalLink href='https://merrickbank.com/'>
              Merrick Bank
            </ExternalLink>
          </span>
        }
      />
    </CaseStudy>

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            As you list out your objectives, consider any potential roadblocks
            that could occur:
          </Typography>
          <ul className='p2'>
            <li>
              Does a new change to a law significantly impact any of your
              policies?
            </li>
            <li>Do your benefits need to be updated?</li>
            <li>
              Will certain teams or departments be affected, and, if so, might
              they push back on the new policies?{' '}
            </li>
            <li>
              Do you need budget approval for new software, legal support, or
              additional staff support?{' '}
            </li>
            <li>Do you need leadership approval to even start the project? </li>
          </ul>
          <Typography variant='p2'>
            By proactively considering obstacles, you can be prepared for
            challenges when they arise.
          </Typography>
          <Typography variant='h3' className='dark'>
            Consider what happens after your new policies achieve those goals,
            as well.
          </Typography>
          <Typography variant='p2'>
            How will you handle <strong>ad-hoc changes</strong>? It’s easy to
            plan for things that happen cyclically, like benefits enrollment,
            but what about an employment law change at the federal or state
            level?
          </Typography>
          <Typography variant='p2'>
            How will you{' '}
            <strong>update, distribute, and obtain acknowledgement</strong> from
            all of your employees? This includes reminding 25+% of employees who
            ignore your initial request for acknowledgement.
          </Typography>
          <Typography variant='p2'>
            How will you <strong>manage an audit</strong> to determine your
            company’s compliance with federal and state laws or a specific IT
            certification (e.g. SOC 2)?
          </Typography>
          <Typography variant='p2'>
            Ideally, you can conquer your objectives while establishing a
            process that satisfies your future needs. By investing the time now
            to consider eventual what-if scenarios, you can save your company
            (and yourself) a lot of frustration.
          </Typography>
        </Fragment>
      }
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h2'>What Tools are Available?</Typography>
          <Typography variant='p2'>
            Updating your policies and procedures can be a daunting task, so
            technology can be a life-saver. Consider investing in software that
            helps you get through creation, review, publication, and
            distribution.
          </Typography>
          <Typography variant='h3' className='dark'>
            Helpful Tools for Policies and Procedures:
          </Typography>
          <ul className='p2'>
            <li>
              Collaboration tools for sharing, reviewing, and commenting on
              content
            </li>
            <li>Project management software</li>
            <li>Secure delivery software that allows for tracking reviews</li>
            <li>E-Signature software</li>
            <li>Photography, illustrations, and video</li>
            <li>Survey software to engage with employees</li>
          </ul>
          <Typography variant='p2'>
            By taking care of these tasks early, you’ll set yourself up to have
            what you need when you need it – including the support of people
            inside or outside your company.
          </Typography>
        </Fragment>
      }
    />

    <HowToSection className='container' main={<GetStartedHero />} />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h2'>What’s Trending?</Typography>
          <Typography variant='p2'>
            A little friendly research will provide insight into what others are
            doing and, more importantly, what will best align with your company
            and values. Research also keeps your company competitive in an
            ever-evolving job market. By staying familiar with the latest trends
            and offerings, you can confidently propose changes to established
            policies while providing a fail-proof rationale: everybody’s doing
            it; we’re actually behind the times.
          </Typography>
        </Fragment>
      }
    />

    <CaseStudy title='Case Study: Data Hunt'>
      <Typography variant='p2'>
        Lindsey Kucharski wanted to recommend unlimited PTO for two of their
        locations, so she looked for data from other similar companies. She used
        that data to demonstrate that unlimited PTO was a fairly common practice
        within their industry, so a change to the PTO policy would help them
        attract and retain the best talent.
      </Typography>
      <PersonCard
        img={images.profileLindsey.src}
        name='Lindsey Kucharski'
        title={
          <span>
            Former Global HR Business Partner & HR Manager at{' '}
            <ExternalLink href='https://www.blueprism.com/'>
              Blue Prism
            </ExternalLink>
          </span>
        }
      />
    </CaseStudy>

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            Great Resources for Up-to-Date Research:
          </Typography>
          <ul className='p2'>
            <li>
              <ExternalLink href='https://go.forrester.com'>
                Forrester
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href='https://www.jdsupra.com'>
                JD Supra
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href='https://www.law360.com'>Law 360</ExternalLink>
            </li>
            <li>
              <ExternalLink href='https://www.neli.org'>NELI</ExternalLink>
            </li>
            <li>
              <ExternalLink href='https://hbr.org'>
                Harvard Business Review
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href='https://www.shrm.org'>SHRM</ExternalLink> and{' '}
              <ExternalLink href='https://www.shrm.org/membership/communities/chapters/pages/default.aspx'>
                Local SHRM Chapters
              </ExternalLink>
            </li>
          </ul>
          <Typography variant='p2'>
            The{' '}
            <strong>
              <ExternalLink href='https://www.shrm.org/learningandcareer/learning/onsite-training/pages/shrm-california-employment-law-micro-credential.aspx'>
                California Law Certification Program
              </ExternalLink>
            </strong>{' '}
            offered by SHRM was recommended by Jeni Strand, Senior VP of HR at
            AgCountry, as a great resource for anyone wanting to learn more
            about some of the most employee-friendly laws in the country.
          </Typography>
        </Fragment>
      }
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h2'>Interview Your Employees</Typography>
          <Typography variant='p2'>
            Employee interviews can provide a look into their perspective,
            offering another source of research. Engage members of marketing,
            recruiting, leadership, and other teams to develop a better idea of
            key culture-oriented messages. Evaluate the perception of your
            employer brand, considering the following questions:
          </Typography>
          <ul className='p2'>
            <li>
              How do your employees, customers, vendors, and suppliers view your
              company?
            </li>
            <li>What's a new candidate's perception of your company?</li>
            <li>
              What nouns, verbs, and adjectives are used to describe your
              company?
            </li>
            <li>
              How can you interweave culture-focused messages into the guide to
              break up sections that may be lengthy or otherwise (*yawn*)
              uninteresting?
            </li>
            <li>
              Can you include inspiring quotes, statistics or fun facts to
              inject some life while underscoring a key policy?
            </li>
          </ul>
          <Typography variant='p2'>
            If you don’t have time to conduct interviews or if you’d like a
            broader viewpoint from many people in your company, you can always{' '}
            <strong>try a survey</strong>.
          </Typography>
        </Fragment>
      }
    />

    <CaseStudy title='Case Study: FAMILY LEAVE STUDY'>
      <Typography variant='p2'>
        Jeni Strand used a survey to learn more about employee needs,
        particularly relating to family leave. When she got the results back, it
        became clear that the company needed to build a policy that would better
        serve employees, whether they were welcoming a new baby or caring for
        aging parents.
      </Typography>
      <PersonCard
        img={images.profileJeni.src}
        name='Jeni Strand'
        title={
          <span>
            Senior VP HR at{' '}
            <ExternalLink href='https://www.agcountry.com/'>
              AgCountry
            </ExternalLink>
          </span>
        }
      />
    </CaseStudy>

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='p2'>
            After outlining your objectives, identifying useful resources and
            orgCollaborators, and evaluating the current landscape within your
            company and beyond, it’s time to start planning.
          </Typography>
        </Fragment>
      }
    />

    <Section className='container tw-p-18 text-center'>
      <Fragment>
        <Typography variant='h2'>Next Chapter: The Step Work</Typography>
        <Typography variant='p2'>
          <Link className='btn btn-link tw-m-2' href={urls.root}>
            <FontAwesomeIcon className='tw-mr-2' icon='arrow-left' />
            Previous
          </Link>
          <Link className='btn btn-primary tw-m-2' href={urls.stepWork}>
            Next: Plan Your Handbook Project
          </Link>
        </Typography>
      </Fragment>
    </Section>
  </MarketingLayout>
)
